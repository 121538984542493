import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface PaperworkProps extends SVGIconProps {}

export const PaperworkIcon: React.FunctionComponent<PaperworkProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M24 0v24H0V0h24zm-1.5 1.5h-21v21h21v-21zM18 16v1.5H6V16h12zm0-5v1.5H6V11h12zm0-5v1.5H6V6h12z"
      />
    </SVGIcon>
  );
};
