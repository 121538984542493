import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface PlayButtonIconProps extends SVGIconProps {}

export const PlayButtonIcon: React.FunctionComponent<PlayButtonIconProps> = (props) => {
  return (
    <SVGIcon {...{ width: 38, height: 44, viewBox: '0 0 38 44', ...props }}>
      <path
        d="M36 18.5598C38.6667 20.0994 38.6667 23.9484 36 25.488L6 42.8085C3.33333 44.3481 -2.11832e-06 42.4236 -1.98355e-06 39.3444L-4.67412e-07 4.70342C-3.32644e-07 1.62421 3.33333 -0.300286 6 1.23932L36 18.5598Z"
        fill="inherit"
      />
    </SVGIcon>
  );
};
