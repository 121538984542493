import styled from 'styled-components';
import { RebrandedButton } from '@marbletech/components';
import { breakpoints } from '@marbletech/theme';
import { ChevronSmallRebrandIcon } from '@marble/icons';
import React from 'react';

const Button = styled(RebrandedButton)`
  min-width: 210px;
  background: #2c816e;
  &:hover {
    background: #226456 !important;
    color: #ffffffb3 !important;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const BrandAbTestButton = ({ size, variant, onClick, children }: any) => {
  return (
    <Button size={size || 'xlarge'} variant={variant || 'primary'} onClick={onClick}>
      {children}
      <div style={{ marginLeft: '8px' }}>
        <ChevronSmallRebrandIcon />
      </div>
    </Button>
  );
};
