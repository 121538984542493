import React from 'react';
import { Subtitle2 } from '@marbletech/components';
import { useWindowSize } from '@marbletech/hooks';
import { theme } from '@marbletech/theme';

export const FunnelTitle = ({ isSubtitle, children }: any) => {
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;

  return (
    <Subtitle2
      textAlign={'center'}
      fill
      margin={{ bottom: isSubtitle ? '0px' : isDesktop ? '56px' : '40px', top: '0px' }}
      whiteSpace={isDesktop ? 'pre-wrap' : 'normal'}
    >
      {children}
    </Subtitle2>
  );
};
