import { EventProps } from '@marbletech/services';
import { eventService } from '../../services/eventService';
import { addUrlReferrer, clearDisallowedParams, clearEncodedCharacters, sleep, waitForWorkingState } from './utils';

export enum NewBiEventsOptions {
  WebOpenSiteCross = 'web__open_site__cross',
  WebPageView = 'web__page__view',
  WebPageClick = 'web__page__click',
  WebPageCtaClick = 'web__page__cta_click',
  WebCalendarPageView = 'web__calendar__page_view',
  WebCalendarClick = 'web__calendar__click',
  WebCalendarSuccess = 'web__calendar__success',
  WebThankyouPageView = 'web__thankyou__page_view',
  WebFunnelView = 'web__funnel__view',
  WebFunnelClick = 'web__funnel__click',
  WebFunnelZipcodeClick = 'web__funnel__zipcode_click',
}

let store: NewBiEventsContext = null;

export const getStore = () => {
  if (!store) {
    store = new NewBiEventsContext();
  }
  return store;
};

export interface INewBiEventContext {
  setWorkingState: (isWorking: boolean) => void;
  getWorkingState: () => boolean;
  setMany: (data: Record<string, any>) => void;
  set: (key: string, value: any) => void;
  get: (key: string) => any;
  triggerListener: (key: string, value: any) => void;
  addRouteChange: (route: string) => void;
}

class NewBiEventsContext implements INewBiEventContext {
  private data: Record<string, any> = {};
  private listeners: Record<string, Function[]> = {};
  private isInWorkingState = false;
  SESSION_COUNTER_KEY = 'session_counter';

  setWorkingState(isWorking: boolean) {
    this.isInWorkingState = isWorking;
  }

  getWorkingState() {
    return !!this.isInWorkingState;
  }

  setMany(data: Record<string, any>) {
    const dataToTrigger: { key: string; newValue: any; oldValue: any }[] = [];
    Object.keys(data).forEach((key) => {
      dataToTrigger.push({
        key,
        newValue: data[key],
        oldValue: this?.get(key),
      });
      this.set(key, data[key]);
    });
    // trigger listeners
    dataToTrigger.forEach(({ key, newValue, oldValue }) => {
      this.triggerListener(`update:${key}`, oldValue, newValue);
    });
  }

  set(key: string, value: any) {
    this.data[key] = value;
  }

  has(key: string): boolean {
    return key in this.data;
  }

  get(key: string): any {
    return this.data[key];
  }

  on(eventName: string, callback: Function) {
    if (!this?.listeners?.[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName] = [callback];
  }

  getContext(): Record<string, any> {
    return this.data;
  }

  incrementSessionCounter() {
    const counter = this?.data?.[this.SESSION_COUNTER_KEY] || 0;
    this.set(this.SESSION_COUNTER_KEY, counter + 1);
  }

  addRouteChange(route: string) {
    const routeHistory = this?.data?.route_history || [];
    routeHistory.push(route);
    this.set('route_history', routeHistory);
  }

  triggerListener(eventName: string, ...args: any[]) {
    const listeners = this?.listeners?.[eventName];
    if (listeners) {
      for (const listener of listeners) {
        listener(...args);
      }
    }
  }
}

export const onRouteChange = (route: string) => {
  try {
    const store = getStore();
    store?.addRouteChange(route);
  } catch (e) {
    console.error(`onRouteChange - error` + e);
  }
};

export const onChangeTrigger = (key: string, callback: Function) => {
  try {
    const store = getStore();
    store?.on(`update:${key}`, callback);
  } catch (e) {
    console.error(`onChangeTrigger - error` + e);
  }
};

export const setNewBiEventsContext = async (data: any) => {
  try {
    if (!data) return;
    const store = getStore();
    store.setWorkingState(true);
    store?.setMany(data);
    store.setWorkingState(false);
  } catch (e) {
    console.error(`setNewBiEventsContext - error` + e);
    store.setWorkingState(false);
  }
};

export const triggerNewBiEvent = async (props: EventProps) => {
  try {
    console.log(props);
    let { eventName, eventData } = props;
    const store = getStore();
    await waitForWorkingState(store);
    store?.incrementSessionCounter();
    const context = store?.getContext();
    eventData = clearDisallowedParams({ ...context, ...eventData });
    eventData = clearEncodedCharacters(eventData);
    eventService?.triggerEvent({ eventName, eventData });
  } catch (e) {
    console.error(`triggerNewBiEvent - error` + e);
  }
};
