import React, { useEffect } from 'react';
import { useWindowSize } from '@marbletech/hooks';
import { theme } from '@marbletech/theme';
import { setItem } from '@marble/website/src/utils/local-storage/local-storage';
import { HomepageContainer } from './Homepage.styled';
import { Hero } from './components/Hero';
import { GetStarted } from './components/GetStarted';
import { PracticeAreas } from './components/PracticeAreas';
import { StepperSection } from './components/StepperSection';
import { TestimonialsSection } from './components/TestimonialsSection';
import { MobileApp } from './components/MobileApp';
import { MovesYouForward } from './components//MovesYouForward';
import { HomepageProps } from './Homepage.types';
import { EventName, eventService } from '../../services/eventService';

export const Homepage = ({ data: { pageContent: content } }: HomepageProps) => {
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;

  useEffect(() => {
    setItem('calendlyLink', '');
  }, []);

  useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelMainPageView,
    });
  }, []);

  return (
    <HomepageContainer>
      <Hero pageContent={content} isDesktop={isDesktop} />
      <GetStarted pageContent={content} isDesktop={isDesktop} />
      <PracticeAreas pageContent={content} isDesktop={isDesktop} />
      <StepperSection pageContent={content} isDesktop={isDesktop} />
      <TestimonialsSection pageContent={content} isDesktop={isDesktop} />
      <MobileApp pageContent={content} isDesktop={isDesktop} />
      <MovesYouForward pageContent={content} isDesktop={isDesktop} />
    </HomepageContainer>
  );
};

export default Homepage;
