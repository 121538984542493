import styled from 'styled-components';
import { Box } from 'grommet';
import { theme } from '@marbletech/theme';
import { H1, H2 } from '@marbletech/components';

export const Container = styled(Box)`
  justify-content: center;
  align-items: center;
  height: 624px;
  max-width: 100%;

  @media (max-width: ${theme.breakpoints.md}px) {
    height: 400px;
  }
`;

export const Title = styled(H1).attrs({
  color: 'bright',
  textAlign: 'center',
})`
  padding-bottom: 48px;
  max-width: 100%;
  white-space: pre-wrap;
`;

export const TitleMobile = styled(H2).attrs({
  color: 'bright',
  textAlign: 'center',
})`
  padding-bottom: 40px;
  max-width: 100%;
`;
