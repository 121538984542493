import React from 'react';
import { ChevronSmallIcon } from '@marbletech/icons';
import { Flex, H3, P1, P2 } from '@marbletech/components';
import { useBreakpoint } from '@marbletech/hooks';
import { spacings } from '@marbletech/theme';
import { StepContainer, Container } from './Step.styled';
import { StepProps } from './Step.types';

export const Step: React.FC<StepProps> = (props) => {
  const { number, description } = props;
  const { desktop } = useBreakpoint();
  const StepDescription = desktop ? P1 : P2;

  return (
    <Container>
      <StepContainer>
        <H3 color={'primary100'} margin={{ bottom: desktop ? spacings[3] : spacings[1], top: '0px' }}>
          {number}
        </H3>
        <StepDescription>{description}</StepDescription>
      </StepContainer>
      {desktop && (
        <Flex flex={'grow'}>
          <ChevronSmallIcon color={'primary'}></ChevronSmallIcon>
        </Flex>
      )}
    </Container>
  );
};
