import * as React from 'react';
import { debounce } from 'lodash';
import {
  extractUTM,
  FEATURE_FLAG_FOOTER_ON_FUNNEL,
  PhoneNumbersArray,
  useFeatureFlag,
  useStore,
} from '@marble/website';
import '@fontsource/assistant';
import '@fontsource/assistant/600.css';
import '@fontsource/assistant';
import '@fontsource/assistant/700.css';
import '@fontsource/playfair-display';
import '@fontsource/playfair-display/700.css';
import Menu from '../components/Menu/Menu';
import { FooterContainer } from '../components/Footer';
import SEO, { SEOProps } from '../components/SEO/SEO';
import { MenuType } from '@marble/website/src/components/Menu/MainNavigation/MainNavigation';
import { GeoLocation, Language, useLang } from '@marble/website/src/context/Context';
import { PageType as pageTypeEnum } from '@marble/website/src/utils';
import { eventService, EventName } from '../services/eventService';
import { createGlobalStyle } from 'styled-components';
import { PageComponent } from '../types/page-component';
import { fullstoryReadyHandler } from '../services/handlers/fullstoryHandler';
import { theme } from '@marbletech/theme';
import { useWindowSize } from '@marbletech/hooks';
import { useStoryblok } from '../utils/storyblok-v2';
import { sbEditable } from '@storyblok/storyblok-editable';
import { NewBiEventsOptions, onRouteChange, setNewBiEventsContext, triggerNewBiEvent } from '../utils/newEvents';
import { getPageName, getReferrer } from '../utils/newEvents/utils';
import { v4 as uuid4 } from 'uuid';

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
  html,
  body {
    width: 100%;
    height: 100%;
  }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  a {
    text-decoration: none;
  }
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
`;

export interface MainLayoutProps {
  phoneNumbers: PhoneNumbersArray;
  funnelLink?: string;
  seo: SEOProps;
  menuContent: any;
  deprecatedMenuContent: any;
  footerContent: any;
  menuType: MenuType;
  isFunnel?: boolean;
  isBookACall?: boolean;
  is404?: boolean;
  isAboutPage?: boolean;
  isLandingPage?: boolean;
  pageContent: any;
  lang: Language;
  pageCandidateContent: any;
  children: React.ReactElement;
  pageType: pageTypeEnum;
  rebrand: boolean;
  pageComponent: PageComponent;
}

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  children,
  phoneNumbers,
  funnelLink,
  menuContent,
  deprecatedMenuContent,
  seo,
  isFunnel,
  isBookACall,
  pageContent,
  pageType,
  rebrand,
  lang,
  pageCandidateContent,
  menuType,
  footerContent,
  isLandingPage,
  pageComponent,
}) => {
  const {
    setUTM,
    setPhoneNumbers,
    setPageComponent,
    setIsFromLandingPage,
    funnelMeta,
    setIsRebrand,
    setIsFromRebrandLandingPage,
    geoLocation,
    abTestData,
  } = useStore();
  const { storyContent: latestPageContent, storyPath, isEditMode } = useStoryblok(
    pageContent,
    children?.props?.location,
    children?.props?.path,
  );

  const referrerUrl = getReferrer();
  const isNoFooterFunnelOn = useFeatureFlag(FEATURE_FLAG_FOOTER_ON_FUNNEL);
  const { setLang } = useLang();
  const { width, height } = useWindowSize();
  const isFromCalendly = typeof window !== 'undefined' && window && window.document.referrer.includes('calendly');
  const isDesktop = width > theme.breakpoints.md;
  // shouldShowFooter is used to hide the footer on funnel pages
  const shouldShowFooter = isNoFooterFunnelOn ? !isFunnel : true;

  const setGeoLocationEventContext = (geoLocation: GeoLocation) => {
    if (!geoLocation?.city) return;

    eventService.setContext([
      { key: 'user_city', value: geoLocation.city },
      { key: 'ip_city', value: geoLocation.ipCity },
      { key: 'ip_state', value: geoLocation.ipState },
      { key: 'ip_country_code', value: geoLocation.ipCountryCode },
      { key: 'ip_country_name', value: geoLocation.ipCountryName },
    ]);
  };

  React.useEffect(() => {
    if (pageContent) {
      setPageComponent(pageContent.component);
      eventService.setContext([
        { key: 'page_type', value: pageType },
        { key: 'page_name', value: getPageName(pageContent) },
      ]);
      setNewBiEventsContext({
        page_type: pageType,
        page_name: getPageName(pageContent),
      });
    }
  }, [pageContent.component, pageType]);

  React.useEffect(() => {
    if (pageContent.component === 'thankYou') {
      return;
    }
    setIsRebrand(rebrand);
  }, [rebrand, pageContent.component]);

  // This useEffect is used to detect a navigation one page to another
  // and trigger a new event for the new page
  const location = typeof window !== 'undefined' && window && document?.location?.pathname;
  React.useEffect(() => {
    onRouteChange(document?.location?.href);
    setNewBiEventsContext({
      page_url: document?.location?.pathname,
    });
  }, [location]);

  React.useEffect(() => {
    if (isFromCalendly) {
      return;
    }
    const utms = extractUTM();
    setUTM(utms);
    eventService.setContext([
      { key: 'practice', value: funnelMeta?.practiceArea },
      { key: 'utm_source', value: utms.source },
      { key: 'utm_medium', value: utms.medium },
      { key: 'utm_campaign', value: utms.campaignId },
      { key: 'utm_term', value: utms.keyword },
      { key: 'utm_content', value: utms.content },
      { key: 'url_referrer', value: utms.referrer },
      { key: 'screen_width', value: width },
      { key: 'screen_height', value: height },
      { key: 'affiliate_id', value: utms.affiliateId },
      { key: 'match_location', value: utms.matchLocation },
      { key: 'match_type', value: utms.matchType },
      { key: 'fbclid', value: utms.fbclid },
      { key: 'ttclid', value: utms.ttclid },
      { key: 'search_network', value: utms.searchNetwork },
    ]);

    setGeoLocationEventContext(geoLocation);

    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelOpenSiteCross,
    });

    // New BI Events
    const context = {
      brand: 'Marble',
      page_type: pageType,
      page_name: pageContent.component,
      utm_source: utms.source,
      utm_medium: utms.medium,
      utm_campaign: utms.campaignId,
      utm_sub_campaign: utms.adgroupId,
      utm_keyword: utms.keyword,
      utm_content: utms.content,
      screen_width: width,
      screen_height: height,
      affiliate_id: utms.affiliateId,
      match_location: utms.matchLocation,
      match_type: utms.matchType,
      fbclid: utms.fbclid,
      gclid: utms.gclid,
      ttclid: utms.ttclid,
      ip_city: geoLocation.ipCity,
      ip_state: geoLocation.ipState,
      ip_country_code: geoLocation.ipCountryCode,
      ip_country_name: geoLocation.ipCountryName,
      // Will be relevant once the new BI events will cover the funnel
      // page_practice: funnelMeta?.practiceArea,
      // page_state: 'some-state',
      session_counter: 0,
      session_id: uuid4(),
      search_network: utms.searchNetwork,
    };
    setNewBiEventsContext(context);
    triggerNewBiEvent({
      eventName: NewBiEventsOptions.WebOpenSiteCross,
    });

    eventService.registerSegmentReady(fullstoryReadyHandler);
    return () => {
      eventService.unregisterSegmentReady(fullstoryReadyHandler);
    };
  }, []);

  React.useEffect(() => {
    if (isFromCalendly) {
      return;
    }

    setLang(lang);
    setPhoneNumbers(phoneNumbers);
    setIsFromLandingPage(Boolean(isLandingPage));
    if (Boolean(isLandingPage) && Boolean(rebrand)) {
      setIsFromRebrandLandingPage(true);
    }
  }, [phoneNumbers]);

  React.useLayoutEffect(() => {
    if (isDesktop || isEditMode) {
      return;
    }
    const setVH = debounce(
      () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`),
      1,
    );
    setVH();
    // Commenting out until better solution is found
    // window.addEventListener('resize', setVH);
    // return () => window.removeEventListener('resize', setVH);
  }, []);

  React.useEffect(() => {
    setGeoLocationEventContext(geoLocation);
  }, [geoLocation?.city]);

  React.useEffect(() => {
    if (isBookACall) {
      triggerNewBiEvent({
        eventName: NewBiEventsOptions.WebCalendarPageView,
        eventData: {
          current_step_key: 'calendly',
        },
      });
    }

    if (!isFunnel && !isBookACall) {
      triggerNewBiEvent({
        eventName: NewBiEventsOptions.WebPageView,
      });
    }
  }, [isBookACall, isFunnel, location]);

  React.useEffect(() => {
    eventService.setContext({ key: 'url_referrer', value: referrerUrl });
  }, [referrerUrl]);

  let propPageContent = pageContent;
  if (isEditMode && storyPath === children?.props?.path) {
    propPageContent = latestPageContent;
  }

  return (
    <>
      <Global />
      <SEO {...seo} />
      <Menu
        {...{
          menuContent,
          funnelLink,
          menuType,
          deprecatedMenuContent,
          pageComponent,
        }}
      />
      <div {...sbEditable(propPageContent)}>
        {React.cloneElement(children, {
          data: { pageContent: propPageContent, pageCandidateContent },
        })}
      </div>
      {shouldShowFooter && <FooterContainer footerContent={footerContent} pageComponent={pageComponent} />}
    </>
  );
};

export default MainLayout;
