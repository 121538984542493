import React from 'react';

import { colors, spacings } from '@marbletech/theme';
import { isBrowser } from '@marbletech/utils';
import { P1, H5, H1, MaxWidthContainer } from '@marbletech/components';

import { HeaderSectionProps } from './HeaderSection.types';
import { Container, TextContainer } from './HeaderSection.styled';
import TextAnimation from '../../../../components/TextAnimation';

export const HeaderSection: React.FC<HeaderSectionProps> = ({ data, isDesktop }) => {
  const {
    desktop_image: { filename: desktopImg } = {},
    mobile_image: { filename: mobileImg } = {},
    hero_h1: title,
    hero_h5: subtitle,
  } = data;
  const isMobileView = isBrowser() && !isDesktop;

  return (
    <Container background={`${desktopImg}/m/0x0`} backgroundMobile={`${mobileImg}/m/0x0`}>
      <MaxWidthContainer>
        <TextAnimation Component={TextContainer}>
          <H1 color={colors.bright} whiteSpace={'pre-wrap'} margin={{ bottom: spacings[2], top: '0' }}>
            {title}
          </H1>
          {isMobileView ? (
            <P1 color={colors.bright} whiteSpace={'wrap'}>
              {subtitle}
            </P1>
          ) : (
            <H5 color={colors.bright} whiteSpace={'pre-wrap'}>
              {subtitle}
            </H5>
          )}
        </TextAnimation>
      </MaxWidthContainer>
    </Container>
  );
};
