import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Theme } from '@marbletech/theme';

export interface PromotionProps {}

export const Container = styled('section')<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
}));

export const Referral: React.FunctionComponent<PromotionProps> = ({}) => {
  return (
    <Container {...{}}>
      <Helmet>
        <script>
          {`
    window['friendbuy'] = window['friendbuy'] || [];
    window['friendbuy'].push(['site', 'site-9e730308-www.themarbleway.com']);
    (function (f, r, n, d, b, y) {
      b = f.createElement(r), y = f.getElementsByTagName(r)[0];b.async = 1;b.src = n;y.parentNode.insertBefore(b, y);
    })(document, 'script', '//djnf6e5yyirys.cloudfront.net/js/friendbuy.min.js');
    `}
        </script>
        <script>
          {`window['friendbuy'] = window['friendbuy'] || []; window['friendbuy'].push(['widget', "eHr-tu3"]);`}
        </script>
      </Helmet>
      <div className="friendbuy-eHr-tu3" />
    </Container>
  );
};

export default Referral;
