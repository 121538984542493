import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { MeetingDetails, Person } from '../../api/api';
import { useStore } from '@marble/website';
import * as Sentry from '@sentry/gatsby';
import { eventService } from '../../services/eventService';
import { EventName } from '@marbletech/services';
import { HashUserResult, Review, ThankYouPageProps } from './ThankYouPage.types';
import { useHash } from './utils';
import ThankYou from './components/ThankYou';
import DeprecatedThankYou from './components/DeprecatedThankYou';
import { ThankYouLoader } from './ThankYouLoader';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../utils/newEvents';

export const ThankYouPage: FunctionComponent<ThankYouPageProps> = ({ data: { pageContent } }) => {
  const { utm, person, funnelMeta, setPerson, externalStateCode } = useStore();
  const [meetingDetails, setMeetingDetails] = useState<MeetingDetails | null>(null);
  const [review, setReview] = useState<Review | null>(null);

  const hashUser = useHash();

  const isBrowser = typeof window !== 'undefined';

  const externalStateCodeFinal = externalStateCode ?? 'NA';

  useEffect(() => {
    if (!pageContent?.reviews?.length) return;

    const reviews = pageContent?.reviews ?? [];
    const randomReview = reviews[Math.floor(Math.random() * reviews.length)];
    setReview(randomReview);
  }, []);

  useEffect(() => {
    // TODO: Breakdown to smaller effects
    (async () => {
      const params = new URLSearchParams(location.search);
      const assign_to = params.get('assigned_to') ?? '';
      const meetingStart = params.get('event_start_time') ?? '';
      const meetingEnd = params.get('event_end_time') ?? '';
      const meetingName = params.get('event_type_name') ?? '';
      const firstName = params.get('invitee_first_name') ?? '';
      const lastName = params.get('invitee_last_name') ?? '';
      const email = params.get('invitee_email') ?? '';
      const phone = params.get('answer_1') ?? ''; // * phone
      const isApproved = Boolean(params.get('answer_2') ?? ''); // * isApproved

      // @ts-ignore
      const dataFromCalendly: MeetingDetails & Omit<Person, 'location'> = {
        assign_to,
        meetingStart,
        meetingEnd,
        meetingName,
        isApproved,
        firstName,
        lastName,
        email,
        phone,
      };

      setMeetingDetails(dataFromCalendly);

      const userDetails = { phone, email, firstName, lastName, fullName: `${firstName} ${lastName || ''}` };
      let hashResult: HashUserResult;
      try {
        hashResult = await hashUser(userDetails);
        const { hashedSaltedUser, hashedUnsaltedUser, hashVersion } = hashResult;

        // it is not being called in MainLayout when referrer is Calendly
        eventService.setContext([
          { key: 'practice', value: funnelMeta?.practiceArea },
          { key: 'utm_source', value: utm.source },
          { key: 'utm_medium', value: utm.medium },
          { key: 'utm_campaign', value: utm.campaignId },
          { key: 'utm_term', value: utm.keyword },
          { key: 'utm_content', value: utm.content },
          { key: 'url_referrer', value: utm.referrer },
          { key: 'affiliate_id', value: utm.affiliateId },
          { key: 'match_location', value: utm.matchLocation },
          { key: 'match_type', value: utm.matchType },
          { key: 'fbclid', value: utm.fbclid },
          { key: 'ttclid', value: utm.ttclid },
          { key: 'search_network', value: utm.searchNetwork },
        ]);
        eventService.identify({
          id: hashedSaltedUser.phone,
          payload: {
            name: hashedSaltedUser.fullName,
            email: hashedSaltedUser.email,
            hashVersion,
          },
        });

        eventService.setDataLayerVariables({
          hashed_salted_user_details: hashedSaltedUser,
          hashed_user_details: hashedUnsaltedUser,
        });

        triggerNewBiEvent({
          eventName: NewBiEventsOptions.WebThankyouPageView,
          eventData: {
            date_chosen: new Date(meetingStart).toDateString(),
            time_chosen: new Date(meetingStart).toTimeString(),
            phone_hashed: hashedSaltedUser.phone,
            email_hashed: hashedSaltedUser.email,
          },
        });
      } catch (err) {
        Sentry.captureException(err, { user: userDetails });
      }

      eventService.triggerEvent({
        eventName: EventName.OnlineFunnelThankYouPageView,
        eventData: {
          schedule_value: new Date(meetingStart).toDateString(),
          practice: funnelMeta?.practiceArea,
          state: externalStateCodeFinal,
        },
      });

      const sentryContext = {
        user: {
          ...person,
        },
        extra: {
          calendlyData: JSON.stringify(dataFromCalendly),
          hasLocalStorage: typeof window !== 'undefined' && typeof window.localStorage === 'object',
          funnelMeta: typeof funnelMeta !== 'undefined' ? JSON.stringify(funnelMeta) : 'undefined',
        },
        level: Sentry.Severity.Error,
      };

      if (!funnelMeta || !funnelMeta.practiceArea) {
        Sentry.captureException(new Error('ThankYou page missing practiceArea'), sentryContext);
        return;
      }

      if (!dataFromCalendly || !dataFromCalendly.phone) {
        Sentry.captureException(new Error('ThankYou page missing phone number'), sentryContext);
      }

      setPerson({ ...person, email: dataFromCalendly.email, phone: dataFromCalendly.phone });
    })();
  }, []);

  if (!isBrowser || !meetingDetails) {
    return <ThankYouLoader />;
  }

  return <ThankYou person={person} meetingDetails={meetingDetails} data={pageContent} review={review} />;
};

export default ThankYouPage;
