import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'grommet';
import { H2, Subtitle2, P2, RebrandedButton, OptimizedAssetImage } from '@marbletech/components';
import { colors, spacings } from '@marbletech/theme';
import { OptionsStackWithImageProps } from './OptionsStackWithImage.types';
import { Container, Content, ImageSection } from './OptionsStackWithImage.styles';
import { OptionsList } from '../OptionsList/OptionsList';

export const OptionsStackWithImage: React.FC<OptionsStackWithImageProps> = (props) => {
  const {
    itemsList,
    cta: btnText,
    image: { filename: image, alt },
    mobileImage: { filename: mobileImage, alt: mobileAlt },
    ctaLink: link,
    isDesktop,
  } = props;

  const { cardList, title, separatorText, separatorColor } = itemsList[0];

  return (
    <Box>
      <Container>
        <Content>
          <H2 whiteSpace={'pre-wrap'}>{title}</H2>
          <OptionsList separatorText={separatorText} separatorColor={separatorColor}>
            {cardList.map(({ subtitle, description }, index) => (
              <Box gap={spacings[1]} key={`${subtitle}${index}`}>
                {subtitle && <Subtitle2>{subtitle}</Subtitle2>}
                {description && <P2>{description}</P2>}
              </Box>
            ))}
          </OptionsList>
          <Link to={link}>
            <RebrandedButton size={'xxlarge'}>{btnText}</RebrandedButton>
          </Link>
        </Content>
        <ImageSection>
          {isDesktop ? (
            <OptimizedAssetImage src={image} alt={alt} maxWidth={592} />
          ) : !!mobileImage ? (
            <OptimizedAssetImage src={mobileImage} alt={mobileAlt} maxWidth={375} />
          ) : (
            <OptimizedAssetImage src={image} alt={alt} maxWidth={375} />
          )}
        </ImageSection>
      </Container>
    </Box>
  );
};
