import React from 'react';
import { Footer, FooterContent, FooterPadding } from '@marbletech/ca-footer';
import { eventService } from '@marble/website/src/utils';
import { OuterContainer } from './404footer.styled';
import { FooterContainerProps } from '../../footer.types';

export const NotFoundFooter = ({ language, LinkComponent }: FooterContainerProps): JSX.Element => {
  return (
    <OuterContainer>
      <Footer language={language} LinkComponent={LinkComponent} triggerEvent={eventService.triggerEvent}>
        <FooterPadding>
          <FooterContent />
        </FooterPadding>
      </Footer>
    </OuterContainer>
  );
};
