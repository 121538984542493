import React from 'react';

import { useWindowSize } from '@marbletech/hooks';
import { breakpoints } from '@marbletech/theme';

import { OurLawyersContainer, ContentWidthContainer } from './OurLawyers.styled';
import { HeaderSection } from './components/HeaderSection/HeaderSection';
import { AttorneysSection } from './components/AttorneysSection/AttorneysSection';
import { MovesYouForward } from './components/MovesYouForward/MovesYouForward';
import { FeaturedAttorneySection } from './components/FeaturedAttorneySection/FeaturedAttorneySection';
import { OurLawyersProps } from './OurLawyers.types';

const OurLawyers: React.FC<OurLawyersProps> = ({ data: { pageContent } }) => {
  const { width } = useWindowSize();
  const isDesktop = width > breakpoints.md;

  return (
    <OurLawyersContainer>
      <HeaderSection data={pageContent} isDesktop={isDesktop} />
      <ContentWidthContainer>
        <FeaturedAttorneySection data={pageContent} />
        <AttorneysSection data={pageContent} />
      </ContentWidthContainer>
      <MovesYouForward data={pageContent} isDesktop={isDesktop} />
    </OurLawyersContainer>
  );
};

export default OurLawyers;
