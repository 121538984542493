import React from 'react';
import { Link } from 'gatsby';
import { P2, Subtitle2 } from '@marbletech/components';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { Container, ContentWrapper, Reason, ReasonsWrapper } from './ChooseMarble.styled';
import { ChooseMarbleProps } from './ChooseMarble.types';
import { Button } from '../../../layouts/LandingPage/components/Button';
import { Title } from '../../../layouts/LandingPage/components/Title';

export const ChooseMarble: React.FC<ChooseMarbleProps> = (props) => {
  const {
    title,
    reasons,
    btnText,
    backgroundColor = 'neutral100',
    color = 'dark100',
    ctaLink: link,
    isDesktop,
  } = props;
  const handleCtaClick = getHandleCtaClick({ cta: btnText, position: 'Choose Marble' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container justify={'center'} align={'center'} background={backgroundColor}>
      <ContentWrapper>
        <Title color={color}>{title}</Title>
        <ReasonsWrapper desktopPadding={!!btnText ? '48px' : '0px'}>
          {reasons?.map((reason, index) => (
            <Reason
              key={index}
              isLast={index === reasons.length - 1}
              align={'start'}
              justify={'start'}
              direction={'column'}
              pad={{ right: isDesktop ? '40px' : '0px' }}
              color={color}
            >
              {reason?.subtitle2 && (
                <Subtitle2 margin={{ bottom: isDesktop ? '24px' : '16px', top: '0px' }} color={color}>
                  {reason?.subtitle2}
                </Subtitle2>
              )}
              {reason?.p2 && <P2 color={color}>{reason?.p2}</P2>}
            </Reason>
          ))}
        </ReasonsWrapper>
        {btnText && (
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button text={btnText} variant={'secondary'} onClick={handleCtaClick} />
          </Link>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ChooseMarble;
