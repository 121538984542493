import * as React from 'react';
import styled from 'styled-components';
import { scheduleMobile, scheduleDesktop, TimeIcon } from '@marble/icons';
import { theme, Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import { FunnelMeta, FunnelPayload, MeetingDetails, Person, submitFunnel } from '@marble/g-website/src/api/api';
import { Store, useFunnelAnswers, useStore } from '../../context/Context';
import { format } from 'date-fns';

export interface ThankYouPageProps {
  title: string;
  subtitle: string;
  meetingDetails: {
    meetingStart: Date;
  };
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), #d2d9ff)',
  minHeight: '100vh',
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.subtitle1,
  textAlign: 'center',
  maxWidth: 300,
  marginBottom: theme.gutters.base * 2,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h3,
    maxWidth: '100%',
    marginBottom: theme.gutters.base * 3,
  },
}));

export const Subtitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  marginBottom: theme.gutters.base * 1,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
    marginBottom: theme.gutters.base * 2,
  },
}));

export const DateWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  boxShadow: `0 12px 32px 0 ${theme.palette.main.secondary1}`,
  padding: `${theme.gutters.base * 1.5}px ${theme.gutters.base * 3}px`,
  display: 'grid',
  gridTemplateColumns: 'min-content max-content',
  gap: theme.gutters.base * 1,
  alignItems: 'center',
  borderRadius: 8,
  backgroundColor: theme.palette.main.bright,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    padding: `${theme.gutters.base * 1}px ${theme.gutters.base * 3}px`,
  },
}));

export const StyledDate = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2Bold,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h4,
  },
}));

export const ScheduleMobileIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 37,
  height: 40,
  marginTop: theme.gutters.base * 13,
  marginBottom: theme.gutters.base * 2,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const ScheduleDesktopIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    width: 107,
    height: 107,
    marginTop: theme.gutters.base * 11.75,
    marginBottom: theme.gutters.base * 5,
  },
}));

export const ThankYouPage: React.FunctionComponent<ThankYouPageProps> = ({ title, subtitle, meetingDetails }) => {
  return (
    <Container>
      <ScheduleMobileIcon {...{ src: scheduleMobile }} />
      <ScheduleDesktopIcon {...{ src: scheduleDesktop }} />
      <Title> {title}</Title>
      <Subtitle> {subtitle}</Subtitle>
      <DateWrap>
        <TimeIcon />
        <StyledDate>{meetingDetails}</StyledDate>
      </DateWrap>
    </Container>
  );
};

export default ThankYouPage;
