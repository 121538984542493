import React, { useState, useEffect, ReactNode, ReactNodeArray, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Button, Typography } from '@marbletech/components';
import { FunnelQuestion } from '../FunnelQuestion/FunnelQuestion';
import { Image, useMeetingURL, useNavigation, useStore } from '../../context/Context';
import FunnelStepDescription from '../FunnelStepDescription/FunnelStepDescription';
import SubmissionThankYouStep from '../SubmissionThankYouStep/SubmissionThankYouStep';
import { FunnelMeta, PracticeArea } from '@marble/g-website/src/api/api';
import PersonalDetailsForm, { PersonalDetailsFormProps } from '../PersonalDetailsForm/PersonalDetailsForm';
import AnythingElse, { AnythingElseProps } from '../AnythingElse/AnythingElse';
import StateUnavailable, { StateUnavailableProps } from '../StateUnavailable/StateUnavailable';
import NotHandlingCase, { NotHandlingCaseProps } from '../NotHandlingCase/NotHandlingCase';
import { getStateCodeByName } from '@marble/utils';
import { ButtonWrap } from '../shared-funnel/ButtonWrap';
import { ButtonWithChevron } from '../shared-funnel/ButtonWithChevron';
import { logFullStoryEvent } from '../../loggers';
import useSMSScheduleFromSplit from '../Funnel/useSMSScheduleFromSplit';
import FunnelStepContainer from './FunnelStepContainer';
import * as Sentry from '@sentry/gatsby';
import {
  FEATURE_FLAG_DEMO_DOWNLOAD_APP,
  FEATURE_FLAG_TRUSTPILOT_REVIEWS_OLD,
  useFeatureFlag,
} from '../../context/split';
import { eventService, EventName } from '../../utils';
import { useCreateRejectedCustomerMutation } from '@marble/g-website/src/generated/graphql';
// @ts-ignore
import TrustpilotReviews from '@pittica/gatsby-plugin-trustpilot-widget';
import { Box } from 'grommet';
import { devices } from '@marbletech/theme';

export interface FunnelProps {
  steps: Record<string, Step>;
  backgroundImage: Image;
  practiceArea: PracticeArea;
  category: FunnelMeta['category'];
  navigate: (to: string) => Promise<void>;
  calendlyLink: string;
  isReviewsWidgetEnabled?: boolean;
}

export type Step = any;

export type StepKey =
  | 'intro'
  | 'name'
  | 'location'
  | 'kickoff'
  | string
  | 'anythingElse'
  | 'summary'
  | 'calendly'
  | 'caseLocation'
  | 'stayInTouchLocation'
  | 'submissionThankYou';

// export interface Step {
//   key: StepKey;
//   isQuestion?: boolean;
//   component: ReactNode;
//   hasTextInput?: boolean;
//   button: { text: string; cb: (e: MouseEvent) => void } | null;
//   isNextStepDisabled?: boolean;
// }

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  padding: `0 ${theme.gutters.base * 2.5}px`,
  boxSizing: 'border-box',
  overflow: 'hidden',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    justifyContent: 'start',
  },
}));

export const ProgressBar = styled('div')<{ theme: Theme; progressBarLength: number }>(
  ({ theme, progressBarLength }) => ({
    height: 6,
    width: `${progressBarLength}%`,
    backgroundColor: theme.palette.main.secondary1,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: '0.4s',
  }),
);

export const BackButtonWrap = styled('div')<{ theme: Theme; isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  position: 'absolute',
  top: theme.gutters.base * 9,
  cursor: 'pointer',
  zIndex: 1000,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    top: theme.gutters.base * 13,
  },
}));

export const BackButtonMobile = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, min-content)',
  gridGap: theme.gutters.base * 0.5,
  alignItems: 'center',
  textTransform: 'uppercase',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const BackButtonDesktop = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    maxWidth: theme.gutters.base * 5,
  },
}));

export const MainButtonWrap = styled('div')<{
  theme: Theme;
}>(({ theme }) => ({
  margin: '0 auto',
  marginTop: 'auto',
  marginBottom: theme.gutters.base * 3,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 300,
  position: 'fixed',
  bottom: '3%',
  left: '50%',
  transform: 'translateX(-50%)',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    position: 'static',
    margin: '0 auto',
    transform: 'none',
  },
}));

export const Avatar = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: theme.gutters.base * 7,
  height: theme.gutters.base * 7,
  margin: '0 auto',
  marginTop: theme.gutters.base * 11,
  marginBottom: theme.gutters.base * 1,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: theme.gutters.base * 9,
    height: theme.gutters.base * 9,
    marginTop: theme.gutters.base * 19,
    marginBottom: theme.gutters.base * 3,
  },
}));

export const CurrentStepComponentWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const BackgroundImage = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: -1,
  },
}));

export const SMSDialogButton = styled('button')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.main.dark}`,
  borderRadius: 36,
  cursor: 'pointer',
  fontFamily: theme.typography.fontFamily[0],
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '24px',
  maxWidth: 335,
  width: '100%',
  color: theme.palette.main.dark,
  height: 56,
  marginBottom: theme.gutters.base * 2,
  '&:hover': {
    border: `1px solid ${theme.palette.main.primary}`,
    color: theme.palette.main.primary,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: '16px',
    marginRight: '0px',
    width: 296,
  },
}));

export const StyledButtonWrap = styled(ButtonWrap)<{ theme: Theme; hasButtonsGrid: boolean }>(
  ({ theme, hasButtonsGrid }) => ({
    minWidth: 335,
    marginBottom: theme.gutters.base * 3,

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      ...(hasButtonsGrid && {
        maxWidth: 608,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.gutters.base * 2,
      }),
    },
  }),
);

const PrivacyNote = styled(Typography)<{ theme: Theme; isReviewsWidgetEnabled?: boolean }>(
  ({ theme, isReviewsWidgetEnabled = false }) => ({
    backgroundColor: theme.palette.background.alpha,
    borderRadius: theme.radius.button,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.main.secondary0,
    fontWeight: 600,
    textAlign: 'center',
    padding: `${theme.gutters.base}px ${theme.gutters.base * 2}px`,
    marginTop: isReviewsWidgetEnabled ? `${theme.gutters.base * 8}px` : 0,

    [devices.mobile]: {
      marginTop: isReviewsWidgetEnabled ? `${theme.gutters.base * 5}px` : 0,
    },
  }),
);

const ButtonAndNoteWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [devices.mobile]: {
    marginTop: `${theme.gutters.base * 3}px`,
  },
}));

const ReviewsContainer = styled(Box)<{ theme: Theme; isReviewsReady: boolean }>(
  ({ theme, isReviewsReady = false }) => ({
    opacity: isReviewsReady ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  }),
);

export interface StepsDescription {
  key: string;
  title: string;
  subtitle: string;
  steps: Array<{ key: string; label: string }>;
  currentStep: string;
  buttonText: string;
  privacyNote: string;
  nextStepKey: string;
}

interface MultichoiceQuestion {
  question: string;
  answers: Array<Answer>;
  key: string;
}

interface Summary {
  title: string;
  subtitle?: string;
  list: Array<{ list_item: string }>;
  buttonText: string;
  key: string;
  nextStepKey: string;
}

export interface Answer {
  nextStepKey: string;
  text: string;
  rejectionType: string;
}

interface StepContactFormThankYou {
  title: string;
  buttonText: string;
  buttonLink: string;
}

interface PersonalDetails {
  title: string;
  subtitle?: string;
  fields: Array<Field>;
  buttonText: string;
  nextStepKey: string;
  key: string;
}

export type Field =
  | NotHandlingCaseProps['fields'][0]
  | StateUnavailableProps['fields'][0]
  | PersonalDetailsFormProps['fields'][0]
  | AnythingElseProps['fields'][0];

type stepsMapOutput = Record<string, (props: any) => ReactNode | ReactNodeArray>;

const triggerStepClick = (eventData?: Record<string, any>) =>
  eventService.triggerEvent({
    eventName: EventName.OnlineFunnelStepClick,
    eventData,
  });

const stepsMap = ({
  setCurrentStepKey,
  stepsBreadCrumbs,
  setStepsBreadCrumbs,
  practiceArea,
  navigate,
  rejectedCustomerSubmission,
  rejectionType,
  setRejectionType,
}: {
  setCurrentStepKey: (key: string) => void;
  stepsBreadCrumbs: Array<StepKey>;
  setStepsBreadCrumbs: (stepKeys: Array<StepKey>) => void;
  navigate: (to: string) => void;
  practiceArea: PracticeArea;
  rejectedCustomerSubmission: any;
  rejectionType: string;
  setRejectionType: (rejectionType: string) => void;
}): stepsMapOutput => {
  const { linkPrefix } = useNavigation();

  return {
    stepsDescription: ({
      step,
      onShowSMSDialog,
      onDemoDialog,
      isReviewsWidgetEnabled,
      isReviewsReady,
    }: {
      step: StepsDescription;
      onShowSMSDialog: () => void | undefined;
      onDemoDialog: () => void | undefined;
      isReviewsWidgetEnabled: boolean;
      isReviewsReady: boolean;
    }) => {
      return (
        <>
          <FunnelStepDescription
            {...{
              title: step.title,
              subtitle: step.subtitle,
              steps: step.steps,
              currentStep: step.currentStep,
            }}
          />
          <ButtonAndNoteWrap>
            <StyledButtonWrap {...{ hasButtonsGrid: Boolean(onShowSMSDialog) || Boolean(onDemoDialog) }}>
              {onShowSMSDialog && <SMSDialogButton onClick={onShowSMSDialog}>Text with us</SMSDialogButton>}
              {onDemoDialog && (
                <Button variant="dark" size="medium" onClick={onDemoDialog}>
                  Download the app
                </Button>
              )}
              {!onDemoDialog && (
                <ButtonWithChevron
                  {...{
                    text: step.buttonText,
                    disabled: false,
                    onClick: () => {
                      triggerStepClick();
                      if (step.nextStepKey === 'bookACall') {
                        eventService.triggerEvent({ eventName: EventName.OnlineFunnelIntroStep2SubmitClick });
                        navigate(linkPrefix + '/book-a-call');
                        logFullStoryEvent({ eventName: 'FINISH_FUNNEL' });
                      } else {
                        setStepsBreadCrumbs([...stepsBreadCrumbs, step.nextStepKey]);
                        setCurrentStepKey(step.nextStepKey);
                      }
                      if (step.key === 'intro') {
                        eventService.triggerEvent({ eventName: EventName.OnlineFunnelChooseStatesPageView });
                        logFullStoryEvent({ eventName: 'START_FUNNEL' });
                      }
                    },
                  }}
                />
              )}
            </StyledButtonWrap>
            {isReviewsWidgetEnabled && (
              <ReviewsContainer height={'28px'} isReviewsReady={isReviewsReady}>
                <TrustpilotReviews
                  language="en"
                  culture="US"
                  theme="light"
                  width="100%"
                  height="28px"
                  template="5419b637fa0340045cd0c936"
                  business="5ee9bc358adfce0001e91218"
                  username="themarbleway.com"
                />
              </ReviewsContainer>
            )}
            {step.privacyNote && (
              <PrivacyNote isReviewsWidgetEnabled={isReviewsWidgetEnabled}>{step.privacyNote}</PrivacyNote>
            )}
          </ButtonAndNoteWrap>
        </>
      );
    },
    multichoiceQuestion: ({ step }: { step: MultichoiceQuestion }) => {
      const { updateFunnelAnswers, setPerson, setStateCode, setCurrentPhoneNumber } = useStore();
      return (
        <FunnelQuestion
          {...{
            question: step.question,
            answers: step.answers,
            onAnswerSelection: (ind) => {
              if (typeof window !== 'undefined') {
                window.setTimeout(() => {
                  const selectedAnswer = step.answers[ind];
                  triggerStepClick({
                    next_step_key: selectedAnswer?.nextStepKey,
                  });
                  setStepsBreadCrumbs([...stepsBreadCrumbs, step.answers[ind].nextStepKey]);
                  setCurrentStepKey(selectedAnswer.nextStepKey);
                  if (selectedAnswer.nextStepKey === 'caseUnsupported') {
                    eventService.triggerEvent({
                      eventName: EventName.OnlineFunnelRejectedPageView,
                      eventData: { question_name: step.question },
                    });
                    setRejectionType(selectedAnswer.rejectionType);
                  }
                  if (step.key === 'stateSelector') {
                    const stateCode = getStateCodeByName(selectedAnswer.text) || 'CA';
                    setStateCode(stateCode);
                    setCurrentPhoneNumber('state', stateCode);
                    setPerson({ location: selectedAnswer.text });
                    eventService.setContext({ key: 'state', value: stateCode });
                    eventService.triggerEvent({ eventName: EventName.OnlineFunnelChooseStatesClick });
                  } else {
                    updateFunnelAnswers(practiceArea, {
                      [step.key]: selectedAnswer.text,
                    });
                  }
                }, 1000);
              }
            },
          }}
        />
      );
    },
    anythingElse: ({ step }: { step: PersonalDetails }) => {
      const { updateFunnelAnswers } = useStore();
      return (
        <AnythingElse
          {...{
            title: step.title,
            subtitle: step.subtitle,
            fields: step.fields,
            buttonText: step.buttonText,
            handleSubmit: ({ anythingElse }) => {
              triggerStepClick();
              eventService.triggerEvent({
                eventName: EventName.OnlineFunnelIntroStep2PageView,
                eventData: { optional_text: anythingElse ? 'exist' : 'not_exist' },
              });
              updateFunnelAnswers(practiceArea, { [step.key]: anythingElse });
              setCurrentStepKey(step.nextStepKey);
            },
          }}
        />
      );
    },
    notHandlingCase: ({ step }: { step: PersonalDetails }) => {
      const [rejectedCustomer] = rejectedCustomerSubmission;

      return (
        <NotHandlingCase
          {...{
            title: step.title,
            subtitle: step.subtitle,
            fields: step.fields,
            buttonText: step.buttonText,
            handleSubmit: async ({ email }) => {
              triggerStepClick();
              const resp: {
                data: {
                  createRejectedCustomer: {
                    customerRejectionType: string;
                    description: string;
                    email: string;
                    id: string;
                  };
                };
              } = await rejectedCustomer({
                variables: {
                  input: {
                    customerRejectionType: rejectionType,
                    email,
                    practiceArea: practiceArea.toUpperCase(),
                  },
                },
              });

              if (resp.data.createRejectedCustomer.id) {
                setCurrentStepKey(step.nextStepKey);
              } else {
                setCurrentStepKey(step.nextStepKey);
              }
            },
          }}
        />
      );
    },
    stateUnavailable: ({ step }: { step: PersonalDetails }) => {
      const [rejectedCustomer] = rejectedCustomerSubmission;

      return (
        <StateUnavailable
          {...{
            title: step.title,
            subtitle: step.subtitle,
            fields: step.fields,
            buttonText: step.buttonText,
            handleSubmit: async ({ state, email }) => {
              triggerStepClick();
              const resp: {
                data: {
                  createRejectedCustomer: {
                    customerRejectionType: string;
                    description: string;
                    email: string;
                    id: string;
                  };
                };
              } = await rejectedCustomer({
                variables: {
                  input: {
                    customerRejectionType: 'UNSUPPORTED_STATE',
                    email,
                    description: state,
                    practiceArea: practiceArea.toUpperCase(),
                  },
                },
              });

              if (resp.data.createRejectedCustomer.id) {
                setCurrentStepKey(step.nextStepKey);
              } else {
                setCurrentStepKey(step.nextStepKey);
              }
            },
          }}
        />
      );
    },
    contactFormThankYou: ({ step }: { step: StepContactFormThankYou }) => {
      return (
        <SubmissionThankYouStep
          {...{
            title: step.title,
            buttonLink: step.buttonLink,
            buttonText: step.buttonText,
            onClick: () => {
              triggerStepClick();
            },
          }}
        />
      );
    },
    personalDetails: ({ step }: { step: PersonalDetails }) => {
      const { updateFunnelAnswers, setPerson } = useStore();
      return (
        <PersonalDetailsForm
          {...{
            title: step.title,
            subtitle: step.subtitle,
            fields: step.fields,
            buttonText: step.buttonText,
            handleSubmit: ({ firstName, lastName, email, phone }) => {
              triggerStepClick();
              setPerson({
                firstName,
                lastName,
                email,
                phone,
              });
              setCurrentStepKey(step.nextStepKey);
            },
          }}
        />
      );
    },
    summary: ({ step }: { step: Summary }) => {
      return (
        <>
          <FunnelStepDescription
            {...{
              title: step.title,
              subtitle: step.subtitle,
              list: step.list.map((item) => item.list_item),
            }}
          />
          <ButtonWrap>
            <ButtonWithChevron
              {...{
                text: step.buttonText,
                disabled: false,
                onClick: () => {
                  triggerStepClick();
                  setStepsBreadCrumbs([...stepsBreadCrumbs, step.nextStepKey]);
                  setCurrentStepKey(step.nextStepKey);
                },
              }}
            />
          </ButtonWrap>
        </>
      );
    },
  };
};

export const FunnelComponent: FunctionComponent<FunnelProps> = ({
  navigate,
  steps,
  backgroundImage,
  category,
  practiceArea,
  calendlyLink,
  isReviewsWidgetEnabled: isFunnelReviewsEnabled = false,
}) => {
  const { setFunnelMeta } = useStore();
  const [currentStepKey, setCurrentStepKey] = useState('intro');
  const [stepsBreadCrumbs, setStepsBreadCrumbs] = useState(['intro']);
  const [showSMSDialog, setShowSMSDialog] = useState<boolean>(false);
  const [showDemoDialog, setShowDemoDialog] = useState<boolean>(false);
  const [rejectionType, setRejectionType] = useState<string>('');
  const [isReviewsWidgetEnabledTimeout, setisReviewsWidgetEnabledTimeout] = useState<boolean>(false);
  const isSMSOn = useSMSScheduleFromSplit();
  const isDemoOn = useFeatureFlag(FEATURE_FLAG_DEMO_DOWNLOAD_APP);
  const isReviewsWidgetEnabled = useFeatureFlag(FEATURE_FLAG_TRUSTPILOT_REVIEWS_OLD);
  const { setCalendlyLink } = useMeetingURL();

  const rejectedCustomerSubmission = useCreateRejectedCustomerMutation();
  const currentStep = steps[currentStepKey];

  const blockData = [
    { key: 'block_key', value: currentStep?.key },
    { key: 'block_title', value: currentStep?.title },
    { key: 'block_subtitle', value: currentStep?.subtitle },
  ];

  useEffect(() => {
    eventService.setContext(blockData, EventName.OnlineFunnelStepView);
    eventService.setContext(blockData, EventName.OnlineFunnelStepClick);
    eventService.setContext([
      { eventName: EventName.OnlineFunnelStepClick, key: 'question', value: currentStep?.question },
    ]);
  }, [currentStep]);

  useEffect(() => {
    if (!isReviewsWidgetEnabled) return;

    setTimeout(() => {
      setisReviewsWidgetEnabledTimeout(true);
    }, 500);
  }, [isReviewsWidgetEnabled]);

  if (!currentStep) {
    Sentry.captureException('NO currentStep', {
      extra: {
        currentStepKey,
        category,
        practiceArea,
      },
    });
  }

  const onShowSMSDialog = isSMSOn && currentStep.nextStepKey === 'bookACall' ? () => setShowSMSDialog(true) : undefined;
  const onDemoDialog = isDemoOn && currentStep.nextStepKey === 'bookACall' ? () => setShowDemoDialog(true) : undefined;

  const stepToShow = stepsMap({
    setCurrentStepKey,
    stepsBreadCrumbs,
    setStepsBreadCrumbs,
    practiceArea,
    navigate,
    rejectedCustomerSubmission,
    rejectionType,
    setRejectionType,
  })[currentStep.component]({
    step: currentStep,
    onShowSMSDialog,
    onDemoDialog,
    isReviewsWidgetEnabled: isFunnelReviewsEnabled && isReviewsWidgetEnabled,
    isReviewsReady: isReviewsWidgetEnabledTimeout,
  });

  useEffect(() => {
    const funnelMeta: FunnelMeta = { practiceArea, category, entry_url: '' };
    eventService.setContext({ key: 'practice', value: practiceArea });
    eventService.triggerEvent({ eventName: EventName.OnlineFunnelIntroStep1PageView });
    setFunnelMeta(funnelMeta);
    setCalendlyLink(calendlyLink);
  }, []);

  return (
    <FunnelStepContainer
      {...{
        stepToShow,
        backgroundImage,
        currentStepKey,
        stepsBreadCrumbs,
        setStepsBreadCrumbs,
        setCurrentStepKey,
        showSMSDialog,
        setShowSMSDialog,
        showDemoDialog,
        setShowDemoDialog,
      }}
    />
  );
};

export default FunnelComponent;
