import React from 'react';
import { Link } from 'gatsby';
import { H5 } from '@marbletech/components';
import { useNavigation } from '@marble/website/src/context/Context';
import { Content, Img, ImgContainer, SectionContainer, Text, TextWrapper, Title } from './MobileApp.styled';
import { MobileAppProps } from './MobileApp.types';
import { Button } from '../../shared';
import { EventName, eventService } from '../../../../services/eventService';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const MobileApp = ({ pageContent }: MobileAppProps) => {
  const {
    fold5_h5: subtitle,
    fold5_h3: title,
    fold5_p2: text,
    fold5_cta: buttonText,
    fold5_cta_link: link,
    fold5_image: { filename: image },
    fold5_image_mobile: { filename: mobile_image },
    fold5_alt_text: alt,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();
  const handleButtonClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPageCtaClick,
      eventData: {
        cta: buttonText,
        position: 'MobileApp Section',
      },
    });
    triggerNewBiEvent({
      eventName: NewBiEventsOptions.WebPageCtaClick,
      eventData: {
        location: 'Mobile App Section',
        cta_text: buttonText,
        click_url: link,
      },
    });
  };

  return (
    <SectionContainer>
      <Content>
        <ImgContainer>
          <picture>
            <source media="(max-width: 600px)" srcSet={mobile_image} />
            <Img src={image} alt={alt} />
          </picture>
        </ImgContainer>
        <TextWrapper>
          <H5 width={'fit-content'}>{subtitle}</H5>
          <Title width={'fit-content'}>{title}</Title>
          <Text width={'fit-content'}>{text}</Text>
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button text={buttonText} variant={'secondary'} onClick={handleButtonClick} />
          </Link>
        </TextWrapper>
      </Content>
    </SectionContainer>
  );
};

export default MobileApp;
