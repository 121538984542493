import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';

export interface PaddingContainerProps {}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `0px ${theme.gutters.base * 2.5}px`,
}));

export const PaddingContainer: React.FunctionComponent<PaddingContainerProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PaddingContainer;
