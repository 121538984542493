import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface CourseProps extends SVGIconProps {}

export const CourseIcon: React.FunctionComponent<CourseProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M24 0v24H0V0h24zM5.25 18.75H1.5v3.75h21v-3.75H6.75V20h-1.5v-1.25zM22.5 1.5h-21v15.75h3.75V16h1.5v1.25H22.5V1.5zM10 5.5l6 4.5-6 4.5v-9zm1.5 3v3l2-1.5-2-1.5z"
      />
    </SVGIcon>
  );
};
