import React from 'react';
import styled from 'styled-components';
import { P1, P2 } from '@marbletech/components';
import { ChevronSmallIcon } from '@marble/icons';
import { theme } from '@marbletech/theme';
import { useWindowSize } from '@marbletech/hooks';
export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: ${theme.gutters.base * 1.5}px;
  align-items: center;
`;
export const BackButton = () => {
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;
  const BackButtonTextComponent = isDesktop ? P1 : P2;
  return (
    <Container>
      <ChevronSmallIcon {...{ rotate: 180, fill: isDesktop ? 'auto' : '#A0A4C3' }} />
      <BackButtonTextComponent color={isDesktop ? '#192348' : '#A0A4C3'}>Back</BackButtonTextComponent>
    </Container>
  );
};
export default BackButton;
