import styled from 'styled-components';
import { Section as BaseSection } from '../../shared';
import { spacings, theme } from '@marbletech/theme';
import { H1 } from '@marbletech/components';

export const SectionContainer = styled(BaseSection)`
  background: ${({
    theme: {
      global: { colors },
    },
  }) => colors.neutral200};
  padding-bottom: 128px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-bottom: 120px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const Title = styled(H1)`
  white-space: pre-wrap;
  text-align: center;
  padding-top: ${spacings[14]};
  padding-bottom: ${spacings[6]};

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
    padding-bottom: ${spacings[5]};
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
`;

export const Img = styled.img`
  @media (max-width: ${theme.breakpoints.md}px) {
    width: 124px;
  }
`;
