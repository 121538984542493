import styled from 'styled-components';
import { Flex } from '@marbletech/components';
import { devices } from '@marbletech/theme';
import { StyledIframeProps } from './PdfViewr.types';

export const StyledIframe = styled.iframe<StyledIframeProps>`
  height: 100vh;
  width: 100vw;
  display: ${({ loader }) => (loader ? 'none' : 'box')};

  ${devices.mobile} {
    height: 70vh;
  }
`;

export const LoaderContainer = styled(Flex)`
  ${devices.mobile} {
    height: 70vh;
  }
`;
