import { Image } from '@marble/website/src/context/Context';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useDynamicTextReplacement } from '../../layouts/LandingPage/hooks/useDynamicTextReplacement';
export interface SEOProps {
  title: string;
  description: string;
  image: Partial<Image>;
  canonicalHref?: string;
  noIndex?: boolean;
}

export const SEO: React.FunctionComponent<SEOProps> = ({ title, description, image, canonicalHref, noIndex }) => {
  const { href } = useLocation();
  const { replaceDynamicText } = useDynamicTextReplacement();
  const dynamicTitle = replaceDynamicText(title);
  const dynamicDescription = replaceDynamicText(description);

  return (
    <div>
      <Helmet>
        <meta {...{ charSet: 'utf-8' }} />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />

        <link rel="preconnect" href="https://a.storyblok.com" />
        <link rel="preconnect" href="https://img2.storyblok.com" />
        <link rel="preconnect" href="https://cdn.segment.io" />
        <link rel="preconnect" href="https://api.segment.io" />
        <link rel="preconnect" href="https://sdk.split.io" />
        <link rel="preconnect" href="https://auth.split.io" />
        <link rel="preconnect" href="https://tr.outbrain.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.googleadservices.com" />
        <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
        <link rel="preconnect" href="https://www.google.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />

        <title>{dynamicTitle}</title>
        <meta property="og:title" content={dynamicTitle} />
        <meta property="og:description" content={dynamicDescription} />
        <meta property="og:image" content={image?.filename ?? ''} />
        <meta name="twitter:title" content={dynamicTitle} />
        <meta name="twitter:description" content={dynamicDescription} />
        <meta name="twitter:image" content={image?.filename ?? ''} />
        <link {...{ rel: 'canonical', href: canonicalHref || href }} />
        {noIndex && <meta name="robots" content="noindex" />}
        {/* {seo.url && <meta property="og:url" content={seo.url} />} */}
      </Helmet>
    </div>
  );
};

export default SEO;
