import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, spacings, devices } from '@marbletech/theme';
import { Flex } from '@marbletech/components';

export const PrimaryCardContainer = styled(Box).attrs({ round: spacings[1] })`
  border: 4px solid ${colors.green100};
  transform: translateX(${spacings[2]});
  box-shadow: -15px 20px 100px rgba(59, 65, 125, 0.2);

  ${devices.mobile} {
    border-radius: 0px 0px ${spacings[1]} ${spacings[1]};
    transform: unset;
    border: none;
    min-width: 339px;
    margin-bottom: ${spacings[4.5]};
    margin-left: ${spacings[2.5]};
  }
`;

export const PrimaryCardHeader = styled(Flex).attrs({
  background: colors.green100,
  textAlign: 'start',
  justify: 'start',
})`
  padding: ${spacings[5]};
  gap: ${spacings[3]};

  ${devices.mobile} {
    padding: ${spacings[3]};
    border-radius: ${spacings[1]} ${spacings[1]} 0px 0px;
    gap: ${spacings[1]};
  }
`;

export const MIconContainer = styled(Box).attrs({
  background: colors.green200,
  round: spacings[1.5],
  align: 'center',
  justify: 'center',
})`
  height: 86px;
  width: 86px;

  ${devices.mobile} {
    height: 64px;
    width: 64px;
    padding: ${spacings[2]};

    svg {
      width: 100%;
    }
  }
`;

export const PrimaryCarditem = styled(Flex).attrs({
  background: colors.bright,
  justify: 'start',
  border: { size: '1px', color: colors.disabled, side: 'bottom' },
})`
  padding: ${spacings[3]} ${spacings[5]};
  gap: ${spacings[4]};

  ${devices.mobile} {
    padding: ${spacings[2]} ${spacings[1.5]};
    padding-right: ${spacings[3]};
    gap: ${spacings[2]};

    :last-child {
      border-radius: 0px 0px ${spacings[1]} ${spacings[1]};
      border-bottom: unset;
    }
  }
`;

export const PrimaryCardListItemIcon = styled(Box).attrs({
  round: '50%',
  align: 'center',
  justify: 'center',
  flex: { grow: 1, shrink: 0 },
})`
  width: ${spacings[5]};
  height: ${spacings[5]};
  max-width: ${spacings[5]};

  ${devices.mobile} {
    max-width: ${spacings[2.5]};
    max-height: ${spacings[2.5]};

    svg {
      height: ${spacings[1]};
      width: ${spacings[1]};
    }
  }
`;
