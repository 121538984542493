import { ThankYouCallProps } from '../ThankYouCallPage.types';
import * as React from 'react';
import {
  ContentContainer,
  MainContainer,
  ScheduleDesktopIcon,
  ScheduleMobileIcon,
  TelephoneAnchor,
} from './ThankYouCall.styled';
import { H2, H4, P1, P3, RebrandedButton, Subtitle2 } from '@marbletech/components';
import { useWindowSize } from '@marbletech/hooks';
import { theme, spacings } from '@marbletech/theme';
import { Box } from 'grommet';
import { useNavigation } from '@marble/website/src/context/Context';

const getSubtitleParts = (subtitle: string, phone: string) => {
  let subtitleParts: any[] = subtitle.split('{{phone}}');
  if (subtitleParts.length < 2) return subtitleParts;

  subtitleParts = [
    subtitleParts[0],
    <TelephoneAnchor href={`tel:${phone}`}>{phone}</TelephoneAnchor>,
    subtitleParts[1],
  ];

  return subtitleParts;
};

export const ThankYouCall = ({ data, phone }: ThankYouCallProps) => {
  const { title, scheduleIconDesktop, scheduleIconMobile, subtitle, buttonText, buttonNavigation } = data;
  const { width } = useWindowSize();
  const { navigate } = useNavigation();

  const isSmallWindow = width <= theme.breakpoints.md;

  const HeadingComp = isSmallWindow ? H4 : H2;
  const ParagraphComp = isSmallWindow ? P3 : P1;

  const navigateHome = () => {
    navigate(buttonNavigation ?? '/book-a-call-2022');
  };

  const subtitleParts = getSubtitleParts(subtitle, phone);

  return (
    <MainContainer>
      <ContentContainer background={'neutral200'}>
        <Box align={isSmallWindow ? 'center' : 'flex-start'} direction={'column'} pad={{ bottom: '64px' }}>
          <ScheduleMobileIcon src={scheduleIconMobile?.filename} />
          <ScheduleDesktopIcon src={scheduleIconDesktop?.filename} />
          <HeadingComp
            textAlign={isSmallWindow ? 'center' : 'left'}
            margin={{ bottom: isSmallWindow ? '16px' : '40px', top: '0' }}
          >
            {`${title}`}
          </HeadingComp>
          <Subtitle2 margin={{ bottom: '0', top: '0' }} color={'denim100'}>
            {subtitleParts.map((part) => {
              return part;
            })}
          </Subtitle2>
          <ParagraphComp
            textAlign={isSmallWindow ? 'center' : 'left'}
            margin={{ bottom: spacings[6], top: spacings[6] }}
            color={'denim100'}
          >
            We’ll go over your case details and what you can expect when working with us.{' '}
          </ParagraphComp>
          <RebrandedButton onClick={navigateHome} size={'large'}>
            {buttonText}
          </RebrandedButton>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
};
