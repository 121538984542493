import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';

export interface MaxWidthContainerProps {
  className?: string;
  style?: object;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: theme.breakpoints.lg,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
}));

export const MaxWidthContainer: React.FunctionComponent<MaxWidthContainerProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default MaxWidthContainer;
