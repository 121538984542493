import styled from 'styled-components';
import { RebrandedButton } from '@marbletech/components';
import { spacings, theme } from '@marbletech/theme';

export const PanelLayout = styled.div`
  position: fixed;
  background: linear-gradient(180.68deg, rgba(255, 255, 255, 0) -0%, #ffffff 99.41%);
  padding-top: ${theme.gutters.base * 12}px;
  padding-bottom: ${theme.gutters.base * 2.5}px;
  padding-left: ${theme.gutters.base * 2.5}px;
  padding-right: ${theme.gutters.base * 2.5}px;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

export const ContinueButton = styled(RebrandedButton)`
  pointer-events: auto;
  margin-top: ${spacings[4]};
  align-self: flex-end;

  @media (max-width: ${theme.breakpoints.md}px) {
    align-self: stretch;
    width: 100%;
  }
`;
