export interface ContentItem {
  _editable: string;
  _uid: string;
  component: string;
}

export interface StoryblokImage {
  id: string | null;
  alt: string | null;
  name: string;
  focus: null;
  fieldType: 'asset';
  title: string | null;
  filename: string | null;
}

export enum Host {
  'MainWebsite' = 'MAIN_WEBSITE',
  'GuideCenter' = 'GUIDE_CENTER',
}
