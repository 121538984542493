import { theme, spacings } from '@marbletech/theme';
import { H2, H3 } from '@marbletech/components';
import { Box } from 'grommet';
import styled from 'styled-components';

export const SectionContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.gutters.base * 19}px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${spacings[8]};
  }
`;

export const Title = styled(H2)`
  text-align: center;
  white-space: pre-wrap;
  padding-bottom: ${spacings[13]};
`;

export const TitleMobile = styled(H3)`
  text-align: start;
  padding-bottom: ${spacings[7]};
`;
