import React from 'react';
import { OptimizedAssetImage } from '@marbletech/components';
import { MarbleShortIcon } from '@marbletech/icons';
import { PrimaryCardHeader, MIconContainer } from '../ComparisonTablePrimaryCard.styled';
import { CardItemsContainer } from '../../../ComparisonTableSection.styled';
import { PrimaryCardProps } from './PrimaryCard.types';

export const PrimaryCard: React.FC<PrimaryCardProps> = ({ title, children, image }) => {
  const { filename: imageLink, alt } = image;
  const headerImageComponent = imageLink ? (
    <OptimizedAssetImage src={imageLink} alt={alt} maxWidth={0} />
  ) : (
    <MIconContainer>
      <MarbleShortIcon />
    </MIconContainer>
  );
  return (
    <>
      <PrimaryCardHeader>
        {headerImageComponent}
        {title}
      </PrimaryCardHeader>
      <CardItemsContainer>{children}</CardItemsContainer>
    </>
  );
};
