import React from 'react';
import { getStateCodeByName } from '@marble/utils';
import { useStore } from '@marble/website';
import { getLocationFromUrlParam } from '../../../services/geo-location-service';
import { StateInfo } from '../LandingPage.types';

export const useSetGeolocationState = (stateInfo: StateInfo) => {
  const allowedCities = stateInfo?.allowed_cities || [];
  const { setStateInfo, geoLocation, setGeoLocation, setStateCode } = useStore();

  React.useEffect(() => {
    if (!stateInfo) {
      return;
    }
    setStateInfo(stateInfo);

    if (!stateInfo?.state_name) {
      return;
    }
    const stateCode = getStateCodeByName(stateInfo.state_name);
    if (!stateCode) {
      return;
    }
    setStateCode(stateCode);
  }, []);

  React.useEffect(() => {
    if (!geoLocation?.city) return;
    //If someone manually enters a custom location ?marble_location=<custom_location>
    const locationFromUrl = getLocationFromUrlParam();
    const isIpCity = !!allowedCities.find((cityItem) => cityItem.city === geoLocation.city);

    if (!isIpCity && !locationFromUrl) {
      // if there is location param, we don't care about the white list.
      setGeoLocation({ city: '' });
    }
  }, [allowedCities, geoLocation?.city]);
};
