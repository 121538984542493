import * as React from 'react';
import { useWindowSize } from '@marbletech/hooks';
import { theme } from '@marbletech/theme';

import { FunnelStepContainer } from '@marble/website/src/components/FunnelRebrand';
import { FunnelQuestionRebrand } from '../../components/FunnelQuestion/FunnelQuestionRebrand';
import { ContinuePanel } from './components/ContinuePanel/ContinuePanel';
import { PracticeAnswer } from './components/PracticeAnswer/PracticeAnswer';
import { PageProps } from 'gatsby';

import { useNavigation } from '@marble/website/src/context/Context';
import { ChoosePracticeAreaProps } from './PracticeRebrand.types';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../utils/newEvents';

export const ChoosePracticeArea: React.FC<PageProps<ChoosePracticeAreaProps>> = ({ data }) => {
  const content = data.pageContent;
  const { navigate } = useNavigation();
  // -1 = none selected
  const [activeAnswerIndex, setActiveAnswerIndex] = React.useState<number>(-1);

  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;

  const formattedAnswers = content.practiceAreas.map((area) => ({
    label: { title: area.title, text: area.text },
    nextStepKey: '',
  }));

  const onClickContinue = () => {
    navigate(content.practiceAreas[activeAnswerIndex]?.link);
  };

  const stepToShow = (
    <>
      <FunnelQuestionRebrand
        question={content.title}
        answers={formattedAnswers as any}
        onAnswerSelection={(index) => {
          const nextStepTitle = content.practiceAreas[index]?.title;
          triggerNewBiEvent({
            eventName: NewBiEventsOptions.WebFunnelClick,
            eventData: {
              current_step_key: 'practice',
              next_step: nextStepTitle,
              answer_value: nextStepTitle,
              full_answer: nextStepTitle,
              click_type: 'answer',
            },
          });
          if (isDesktop) {
            navigate(content.practiceAreas[index]?.link);
            return;
          }
          // -1 = none selected
          setActiveAnswerIndex(index);
        }}
        LabelContentComponent={PracticeAnswer}
      />
      {activeAnswerIndex !== -1 && <ContinuePanel onClick={onClickContinue} />}
    </>
  );

  return (
    <>
      <FunnelStepContainer
        stepToShow={stepToShow}
        currentStepKey={'practice'}
        backgroundImage={null}
        buttons
        stepsBreadCrumbs={[]}
        setStepsBreadCrumbs={() => {}}
        setCurrentStepKey={() => {}}
        showSMSDialog={false}
        setShowSMSDialog={() => {}}
        showDemoDialog={false}
        setShowDemoDialog={() => {}}
        privacyNote={content?.privacyNote}
      />
    </>
  );
};

export default ChoosePracticeArea;
