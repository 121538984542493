import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex, H2, H3, H5, Hero, Page } from '@marbletech/components';
import { Box } from 'grommet';
import { ChooseMarble } from '../components/ChooseMarble';
import { PageProps } from './page.types';
import { Container, Testimonials } from '../components/Testimonials';
import { TrustBox } from '../components/Trustbox';
import { devices, spacings, theme } from '@marbletech/theme';
import { SplitViewBanner } from '../components/SplitViewBanner';
import { MovesYouForward } from '../components/MovesYouForward';
import { useWindowSize } from '@marbletech/hooks';
import { eventService, EventName } from '../../../services/eventService';

interface TrustboxContainerProps {
  children: any;
  isLast?: boolean;
}

export const TrustboxContainer = styled(Flex)<TrustboxContainerProps>`
  padding-top: ${(props) => (props.isLast ? '0' : '160px')};
  padding-bottom: 192px;
  flex-direction: column;

  ${devices.mobile} {
    padding-top: ${(props) => (props.isLast ? '0' : spacings[10])};
    padding-bottom: ${spacings[10]};
  }
`;

interface TrustboxSectionProps {
  children: React.ReactNode;
  title: string;
  isLast?: boolean;
}
const TrustboxSection: React.FC<TrustboxSectionProps> = ({ children, title, isLast }) => {
  const { width } = useWindowSize();
  const desktop = width > theme.breakpoints.md;

  return (
    <TrustboxContainer isLast={isLast}>
      <Page.Content>
        <H3
          margin={desktop ? { top: '0', bottom: spacings[10] } : { top: '0', bottom: spacings[6] }}
          textAlign={'center'}
          css={Styles.text}
        >
          {title}
        </H3>
      </Page.Content>
      {children}
    </TrustboxContainer>
  );
};

const HeroContent = styled(Hero.Content)`
  ${devices.mobile} {
    margin-top: 168px;
  }
`;

const Mobile = styled.div`
  display: none;

  ${devices.mobile} {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Desktop = styled.div`
  display: none;

  ${devices.desktop} {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Styles: any = {
  text: css`
    ${devices.desktop} {
      white-space: pre-wrap;
    }
  `,
};

export const Reviews = ({ pageContent: content, isDesktop }: PageProps) => {
  const { image, h2, h5, image_mobile: mobileImage } = content;

  useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelReviewPageView,
    });
  }, []);

  return (
    <>
      <Box height={'100vh'}>
        <Mobile>
          <Hero bgImage={mobileImage.filename} bgPosition={'center top'}>
            <Page.Content flex>
              <HeroContent>
                <H5 color={'white'}>{h5}</H5>
                <H2 css={Styles.text} margin={{ top: spacings[1], bottom: spacings[3] }} color={'white'}>
                  {h2}
                </H2>

                <TrustBox
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-style-height="150px"
                  data-theme="dark"
                  data-text-color="#FFFFFF"
                  style={{ width: '255px', minHeight: '150px' }}
                />
              </HeroContent>
            </Page.Content>
          </Hero>
        </Mobile>

        <Desktop>
          <Hero bgImage={image.filename} bgPosition={'center top'}>
            <Page.Content flex>
              <HeroContent>
                <H5 color={'white'}>{h5}</H5>
                <H2 css={Styles.text} margin={{ top: spacings[5], bottom: spacings[7] }} color={'white'}>
                  {h2}
                </H2>

                <TrustBox
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-style-height="150px"
                  data-theme="dark"
                  data-text-color="#FFFFFF"
                  style={{ width: '255px', minHeight: '150px' }}
                />
              </HeroContent>
            </Page.Content>
          </Hero>
        </Desktop>
      </Box>

      <Container background={'neutral100'} margin={{ top: '0 !important' }}>
        <Testimonials arrowColor={'dark100'} color={'dark100'} pageContent={content} isDesktop={isDesktop} />
      </Container>

      <ChooseMarble pageContent={content} isDesktop={isDesktop} background={'denim100'} color={'neutral200'} />

      <TrustboxSection title={`It’s easy to hire the \n right lawyer`}>
        <TrustBox data-template-id={'54ad5defc6454f065c28af8b'} data-tags={'EasyHire'} />
      </TrustboxSection>

      <SplitViewBanner pageContent={content} isDesktop={isDesktop} />

      <TrustboxSection title={'Get clear prices \n and flexible payment options'}>
        <TrustBox data-template-id="54ad5defc6454f065c28af8b" data-tags="Payments" />
      </TrustboxSection>

      <TrustboxSection title={'Work with a qualified \n legal team that cares'} isLast>
        <TrustBox data-tags={'Caringteam'} data-stars={'5'} />
      </TrustboxSection>

      <MovesYouForward pageContent={content} isDesktop={isDesktop} />
    </>
  );
};
