import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import { Link } from 'gatsby';
import { ComplexSubItem, SubItem } from '../MainNavigation/MainNavigation';
import SubmenuTitleContainer from './SubmenuTitleContainer';
import { useNavigation } from '../../../context/Context';
import { eventService, EventName } from '../../../utils';

export interface MobileSubmenuProps {
  title: string;
  subItems?: Array<ComplexSubItem | SubItem>;
  link?: string;
  setIsExpanded: (flag: boolean) => void;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.main.secondary3}`,
  paddingTop: theme.gutters.base * 4,
}));

export const ItemsGrid = styled('ul')<{ theme: Theme }>(({ theme }) => {
  return {
    padding: 0,
    margin: 0,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridGap: theme.gutters.base * 2,
    width: '100%',
    listStyle: 'none',
    paddingBottom: theme.gutters.base * 4,
  };
});

export const LiWithoutIconWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 1,
  },
}));

export const LiWithoutIcon = styled('li')<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1Bold,
  fontFamily: theme.typography.fontFamily[0],
  color: theme.palette.main.dark,
}));

export const LiWithIcon = styled('li')<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.main.bright,
  boxShadow: '0px 8px 16px rgba(162, 193, 201, 0.435888)',
  borderRadius: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.gutters.base * 2}px ${theme.gutters.base * 3}px`,
  maxWidth: 335,
  margin: '0 auto',
}));

export const Icon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  height: 32,
  width: 32,
  display: 'block',
}));

export const LiText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1Bold,
  textAlign: 'center',
}));

export const MobileSubmenu: React.FunctionComponent<MobileSubmenuProps> = ({
  title,
  subItems,
  setIsExpanded,
  link,
}) => {
  const [isSubMenuExpanded, setIsSubMenuExpanded] = React.useState<boolean>(false);
  const { linkPrefix } = useNavigation();
  const handleSubmenuClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelNavbarItemExpand,
      eventData: { item_key: title, item_text: title },
    });

    if (subItems) {
      setIsSubMenuExpanded(!isSubMenuExpanded);
    }
  };

  return (
    <Container>
      {link ? (
        <Link {...{ to: linkPrefix + link, style: { width: '100%' } }}>
          <SubmenuTitleContainer {...{ title, subItems, handleSubmenuClick, isSubMenuExpanded }} />
        </Link>
      ) : (
        <SubmenuTitleContainer {...{ title, subItems, handleSubmenuClick, isSubMenuExpanded }} />
      )}
      {isSubMenuExpanded && (
        <ItemsGrid>
          {subItems?.map(({ text, servicePageLink, icon }) => {
            if (title === 'Resources') {
              const isRelativeLink = !servicePageLink.includes('http');

              return (
                <LiWithoutIconWrap>
                  <Link
                    {...{
                      to: isRelativeLink ? linkPrefix + servicePageLink : servicePageLink,
                      key: text,
                      onClick: () => setIsExpanded(false),
                    }}
                  >
                    <LiWithoutIcon>{text}</LiWithoutIcon>
                  </Link>
                </LiWithoutIconWrap>
              );
            } else {
              return (
                <Link
                  {...{
                    to: linkPrefix + servicePageLink,
                    key: text,
                    onClick: () => {
                      if (typeof window !== 'undefined') {
                        window.setTimeout(() => {
                          setIsExpanded(false);
                        }, 500);
                      }
                    },
                  }}
                >
                  <LiWithIcon>
                    <LiText>{text}</LiText>
                    <Icon {...{ src: icon?.filename }} />
                  </LiWithIcon>
                </Link>
              );
            }
          })}
        </ItemsGrid>
      )}
    </Container>
  );
};

export default MobileSubmenu;
