import React from 'react';
import { Link } from 'gatsby';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { Container, ImgSection, Image, ContentContainer, Text, sectionHeight } from './Banner.styled';
import { BannerProps } from './Banner.types';
import { Button } from '../Button';
import { Title } from '../Title';
import { useDynamicTextReplacement } from '../../hooks/useDynamicTextReplacement';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const Banner = ({ pageContent, isDesktop }: BannerProps) => {
  const { fold5_h2: title, p1: text, fold5_cta: buttonText, fold5_image: image, cta_link: link } = pageContent;
  const { replaceDynamicText } = useDynamicTextReplacement();
  const deynamicAlt = replaceDynamicText(image?.alt);
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Banner' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container background={'green100'}>
      <ImgSection>
        <Image src={`${image?.filename}/m/0x${sectionHeight}`} alt={deynamicAlt} />
      </ImgSection>
      <ContentContainer>
        <Title whiteSpace={isDesktop ? 'pre-wrap' : 'normal'}>{title}</Title>
        <Text>{text}</Text>
        {buttonText && (
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button
              text={buttonText}
              variant={'secondary'}
              onClick={() => {
                handleCtaClick();
                triggerNewBiEvent({
                  eventName: NewBiEventsOptions.WebPageCtaClick,
                  eventData: {
                    location: 'Banner',
                    cta_text: buttonText,
                    click_url: link,
                  },
                });
              }}
            />
          </Link>
        )}
      </ContentContainer>
    </Container>
  );
};
