import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@marbletech/components';
import { Theme } from '@marble/theme';

export interface FunnelSubtitleProps {}

export const Subtitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  maxWidth: 289,
  marginTop: theme.gutters.base * 2,
  textAlign: 'center',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
    marginTop: theme.gutters.base * 3,
    maxWidth: 500,
  },
}));

export const FunnelSubtitle: React.FunctionComponent<FunnelSubtitleProps> = ({ children }) => {
  return <Subtitle>{children}</Subtitle>;
};

export default FunnelSubtitle;
