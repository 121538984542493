import styled from 'styled-components';
import { colors, devices, Theme } from '@marbletech/theme';
import { P1, PageWidthContainer, Subtitle2 } from '@marbletech/components';
import { spacings } from '@marbletech/theme';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../../consts';
import { Box } from 'grommet';
import { theme } from '@marble/theme';

const HEIGHT_BREAKPOINT = '840px';

export const MainContainer = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: `calc(${HEADER_HEIGHT_MOBILE}px)`,

  [devices.desktop]: {
    height: '100vh',
    alignItems: 'flex-start',
    paddingTop: `calc(${HEADER_HEIGHT}px)`,
  },

  [`@media (max-height: ${HEIGHT_BREAKPOINT})`]: {
    height: 'auto',
  },
}));

export const ContentContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flexGrow: 1,

  [devices.desktop]: {
    minHeight: '600px',
  },
}));

export const TempContainer = styled(PageWidthContainer)<{ theme: Theme }>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const ScheduleMobileIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 112,
  height: 112,
  marginBottom: `${spacings[2]}`,
  [devices.desktop]: {
    display: 'none',
  },
}));

export const ScheduleDesktopIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [devices.desktop]: {
    display: 'block',
    width: 200,
    height: 200,
    marginBottom: `${spacings[1]}`,
  },
}));

export const Spacer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: '188px',
  width: '1px',
  height: '128px',
  backgroundColor: colors.primary400,
}));

export const Subtitle = styled(P1)`
  white-space: pre-wrap;
  text-align: center;
  padding-top: ${spacings[1]};
  padding-bottom: ${spacings[9]};

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
    padding-top: ${spacings[2.5]};
    padding-bottom: ${spacings[5]};
  }
`;

export const TelephoneAnchor = styled.a`
  color: ${colors.primary100};
  & :hover {
    text-decoration: underline;
  }
`;
