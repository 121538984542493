import styled from 'styled-components';
import { Box } from 'grommet';
import { Page } from '@marbletech/components';
import { devices, spacings } from '@marbletech/theme';

export const Container = styled(Page.Content)`
  flex-direction: row;
  justify-content: space-between;
  padding-top: 152px;
  padding-bottom: 152px;

  ${devices.mobile} {
    flex-direction: column-reverse;
    gap: ${spacings[7]};
    padding: ${spacings[12]} 0px ${spacings[14]} 0px;
    height: auto;
  }
`;

export const Content = styled(Box).attrs({ basis: '1/2', flex: { shrink: 0, grow: 1 } })`
  gap: ${spacings[7]};
  padding-right: 110px;

  ${devices.mobile} {
    padding: 0px ${spacings[2.5]};
    gap: ${spacings[5]};
  }
`;

export const ImageSection = styled(Box).attrs({
  basis: '1/2',
  flex: { shrink: 1 },
  justify: 'end',
})`
  img {
    display: flex;
    width: 100%;
  }
`;
