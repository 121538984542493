import styled from 'styled-components';
import { P1 } from '@marbletech/components';
import { theme, spacings } from '@marbletech/theme';
import { Box } from 'grommet';
import { MAX_WIDTH } from '../../../consts';

export const sectionHeight = 720;

export const Container = styled(Box)`
  height: ${sectionHeight}px;
  flex-direction: row;

  @media (max-width: ${theme.breakpoints.md}px) {
    height: 100%;
    flex-direction: column-reverse;
  }
`;

export const ImgSection = styled.div`
  width: 34%;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    height: 397px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled(Box)`
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  max-width: ${MAX_WIDTH * 0.7}px;
  padding-left: ${(props) => props.theme.gutters.base * 16.5}px;
  padding-right: ${spacings[2.5]};

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    padding: ${spacings[8]} ${spacings[2.5]};
    align-items: stretch;
  }
`;

export const Text = styled(P1)`
  padding: ${spacings[5]} 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: ${spacings[4]} 0;
  }
`;
