import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { ThankYouCallPageProps } from './ThankYouCallPage.types';
import { ThankYouCall } from './components/ThankYouCall';
import { useStore } from '@marble/website';
import { useBreakpoint } from '@marbletech/hooks';

export const ThankYouCallPage: FunctionComponent<ThankYouCallPageProps> = ({ data: { pageContent } }) => {
  const { mobile } = useBreakpoint();
  const { person } = useStore();

  const phone = '(800)-297-1008';

  useEffect(() => {
    if (!mobile) return;

    setTimeout(() => {
      document.location.href = `tel:${phone}`;
    }, 1000);
  }, []);

  return <ThankYouCall person={person} data={pageContent} phone={phone} />;
};

export default ThankYouCallPage;
