import styled from 'styled-components';
import { Box } from 'grommet';
import { ResponsiveGroup, Page, Panel, RebrandedButton } from '@marbletech/components';
import { devices, spacings, colors, Colors } from '@marbletech/theme';

export const ContentContainer = styled(Page.Content)`
  gap: ${spacings[8]};
  padding-top: 152px;
  padding-bottom: ${spacings[12]};

  ${devices.mobile} {
    align-items: flex-start;
    padding-top: ${spacings[10]};
    padding-bottom: ${spacings[10]};
    gap: ${spacings[5]};
  }
`;

export const PanelItem = styled(Panel).attrs({
  variant: 'custom',
  background: colors.neutral200,
  border: {
    color: colors.disabled,
  },
  round: spacings[0.5],
})`
  justify-content: space-between;
  gap: ${spacings[3]};
  padding: ${spacings[9]} ${spacings[7]} ${spacings[7]};

  ${devices.mobile} {
    gap: ${spacings[2]};
    padding: ${spacings[4]};
    padding-top: ${spacings[7]};
  }
`;

export const ItemsContainer = styled(ResponsiveGroup).attrs({ gapBottom: spacings[4] })`
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  ${devices.mobile} {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }
`;

export const ItemHeader = styled(Box)`
  gap: ${spacings[8]};

  ${devices.mobile} {
    gap: ${spacings[6]};
  }
`;

export const LinkContainer = styled(Box).attrs({ align: 'center', fill: true })`
  ${devices.mobile} {
    align-items: unset;
  }
`;

export const Button = styled(RebrandedButton)`
  ${devices.mobile} {
    width: 100%;
  }
`;

export const ImageContainer = styled(Box)`
  min-height: 124px;
`;
