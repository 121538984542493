import React from 'react';
import { Link } from 'gatsby';
import { H3, OptimizedAssetImage, VerticalStepper } from '@marbletech/components';
import { useNavigation } from '@marble/website/src/context/Context';
import { Content, ImgContainer, SectionContainer, StepperContainer, StepperWrapper } from './StepperSection.styled';
import { StepperProps } from './StepperSection.types';
import { Button } from '../../shared';
import { eventService, EventName } from '../../../../services/eventService';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const StepperSection = ({ pageContent, bgColor }: StepperProps) => {
  const {
    fold4_h3: title,
    fold4_image: { filename: image },
    fold4_cta: buttonText,
    fold4_cta_link: link,
    fold4_alt_text: alt,
    stepper: stepperContent,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();
  const handleButtonClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPageCtaClick,
      eventData: {
        cta: buttonText,
        position: 'Stepper Section',
      },
    });
    triggerNewBiEvent({
      eventName: NewBiEventsOptions.WebPageCtaClick,
      eventData: {
        location: 'Stepper Section',
        cta_text: buttonText,
        click_url: link,
      },
    });
  };

  return (
    <SectionContainer bgColor={bgColor}>
      <Content>
        <ImgContainer>
          <OptimizedAssetImage src={image} width="100%" maxWidth={546} alt={alt} />
        </ImgContainer>
        <StepperWrapper>
          <H3>{title}</H3>
          <StepperContainer>
            <VerticalStepper>
              {stepperContent.map((step, index) => (
                <VerticalStepper.StyledStep title={step.h5} index={index} key={index}>
                  {step.p2}
                </VerticalStepper.StyledStep>
              ))}
            </VerticalStepper>
          </StepperContainer>
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button text={buttonText} onClick={handleButtonClick} />
          </Link>
        </StepperWrapper>
      </Content>
    </SectionContainer>
  );
};

export default StepperSection;
