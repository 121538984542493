import styled from 'styled-components';
import { Section as BaseSection } from '../../shared';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../../../consts';
import { H1, H4 } from '@marbletech/components';
import { theme } from '@marbletech/theme';

export const SectionContainer = styled(BaseSection)`
  flex: 0 0 calc(100vh - ${HEADER_HEIGHT}px);
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: ${theme.breakpoints.md}px) {
    background-position: top;
    flex: 0 0 calc(100 * var(--vh, 1vh) - ${HEADER_HEIGHT_MOBILE}px);
    transition: flex-basis 300ms ease-in;
  }
`;

export const Subtitle = styled(H4).attrs({ color: 'bright' })`
  text-shadow: 0 0 24px #3c2113;
`;

export const Title = styled(H1).attrs({
  color: 'bright',
})`
  white-space: pre-wrap;
  padding: 24px 0 32px 0;
  text-shadow: 0 4px 40px #623f2c;

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
  }
`;
