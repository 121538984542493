import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { SectionContainer, Content, CardsContainer, Title } from './PracticeAreas.styled';
import { PracticeCard } from './PracticeCard/PracticeCard';
import { PracticeAreasProps } from './PracticeAreas.types';
import { sbEditable } from '@storyblok/storyblok-editable';

export const PracticeAreas = ({ pageContent, isDesktop }: PracticeAreasProps) => {
  const { fold3_h3: title, cards } = pageContent;
  const [animateCards, setAnimateCards] = useState(false);

  const handleVisibilityChanged = (isVisible: boolean) => {
    if (isVisible && !animateCards) {
      setAnimateCards(true);
    }
  };

  return (
    <SectionContainer>
      <Content>
        <Title>{title}</Title>
        <VisibilitySensor onChange={handleVisibilityChanged} partialVisibility>
          <CardsContainer gapBottom="24px" gap="32px" isVisible={animateCards}>
            {cards.map((card, index) => (
              <div {...sbEditable(card)} key={index}>
                <PracticeCard practiceAreaDetails={card} />
              </div>
            ))}
          </CardsContainer>
        </VisibilitySensor>
      </Content>
    </SectionContainer>
  );
};

export default PracticeAreas;
