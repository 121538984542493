import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { P3 } from '@marbletech/components';
import { useWindowSize } from '@marbletech/hooks';
import { theme, colors } from '@marbletech/theme';

interface PrivacyNoteProps {
  text: string;
  linkText?: string;
  link?: string;
}

const UnderlineText = styled.span`
  text-decoration: underline;
  margin: 0;
  padding-left: 2px;
  color: ${colors.dark100};
`;

export const PrivacyNote = ({ text, linkText, link }: PrivacyNoteProps) => {
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;

  return (
    <P3
      className={'privacy-note'}
      whiteSpace={'pre-wrap'}
      textAlign={'center'}
      margin={{
        top: isDesktop ? `${theme.gutters.base * 11.5}px` : `${theme.gutters.base * 5}px`,
        bottom: isDesktop ? `${theme.gutters.base * 10}px` : `${theme.gutters.base * 9}px`,
      }}
    >
      {text}
      {linkText && (
        <Link to={link || ''} target="_blank">
          <UnderlineText>{linkText}</UnderlineText>
        </Link>
      )}
    </P3>
  );
};

export default PrivacyNote;
