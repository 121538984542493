import React, { useEffect } from 'react';
import { Box } from 'grommet';
import { LoadingAbsolute } from '@marble/ui-lib';
import { useFeatureReadyFlag, useStore } from '@marble/website';
import { FunnelRebrandAbTestProps } from './FunnelRebrandAbTest.types';
import { useNavigation } from '@marble/website/src/context/Context';
import { eventService } from '../../../services/eventService';

export const FunnelRebrandAbTest: React.FC<FunnelRebrandAbTestProps> = ({ children, abTestData }) => {
  const { setABTestData } = useStore();
  const { abTestSlug, flagName } = abTestData;
  const [isTestOn, isSplitReady] = useFeatureReadyFlag(flagName);

  const { navigate } = useNavigation();

  const shouldNavigate = isSplitReady && isTestOn;

  useEffect(() => {
    if (shouldNavigate) {
      navigate(`/${abTestSlug}`, { replace: true });
      return;
    }
  }, [shouldNavigate]);

  useEffect(() => {
    if (!isSplitReady) {
      return;
    }
    const testGroup = isTestOn ? 'on' : 'off';
    setABTestData({
      testName: flagName,
      testGroup: testGroup,
    });
  }, [isSplitReady, isTestOn]);

  if (!abTestSlug) {
    return <>{children}</>;
  }

  // we want to display a loading spinner in 2 cases:
  // 1 - when split is not ready
  // 2 - when "shouldNavigate" is true, to prevent flickering until navigation occurs
  if (!isSplitReady || shouldNavigate) {
    return (
      <Box height={'100vh'}>
        <LoadingAbsolute size={'medium'} />
      </Box>
    );
  }

  return <>{children}</>;
};
