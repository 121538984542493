import * as React from 'react';
import { Box } from 'grommet';
import { FunnelTitle } from '../FunnelTitle/FunnelTitleRebrand';
import { CheckboxGroup, Checkbox } from '@marbletech/components';
import { theme } from '@marbletech/theme';
import { useCallback } from 'react';
import { FunnelQuestionProps } from './FunnelQuestionRebrand.types';
import { ItemWrapper } from './FunnelQuestionRebrand.styles';

export const FunnelQuestionRebrand: React.FunctionComponent<FunnelQuestionProps> = ({
  question,
  answers,
  onAnswerSelection,
  selectedAnswer,
  LabelContentComponent,
}) => {
  const [values, setValues] = React.useState<boolean[]>([]);

  const handleOnChange = (updatedValues: Array<boolean>) => {
    setValues(updatedValues);
    const selectedIndex = updatedValues.findIndex((val) => val);
    onAnswerSelection(selectedIndex);
  };

  const Item = useCallback(
    ({ checked, onChange, label, value }: any) => {
      const [isHovered, setIsHovered] = React.useState(false);

      return (
        <ItemWrapper
          variant={'dark300-with-hover'}
          pad={`${theme.gutters.base * 2.5}px ${theme.gutters.base * 3}px`}
          round={'8px'}
          direction={'row'}
          justify={'between'}
          active={checked}
          data-test-answer={label}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          {LabelContentComponent && <LabelContentComponent label={label} isChecked={checked} isHovered={isHovered} />}
          <Checkbox
            checked={checked}
            label={LabelContentComponent ? null : label}
            onChange={onChange}
            value={value}
            isVisible={false}
            absolute
          />
        </ItemWrapper>
      );
    },
    [LabelContentComponent],
  );

  return (
    <>
      <FunnelTitle>{question}</FunnelTitle>
      <Box width={'555px'} margin={'0 auto'}>
        <CheckboxGroup options={answers} CheckboxComponent={Item} onChange={handleOnChange} values={values} />
      </Box>
    </>
  );
};
