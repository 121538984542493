import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface VProps extends SVGIconProps {}

export const VIcon: React.FunctionComponent<VProps> = (props) => {
  return (
    <SVGIcon {...{ width: 12, height: 12, viewBox: '0 0 12 12', ...props }}>
      <path
        fillRule="evenodd"
        d="M2.05 5.117c-.469-.489-1.229-.49-1.698-.001-.469.488-.47 1.279 0 1.767l3.597 3.75c.5.52 1.32.482 1.772-.083l6-7.5c.425-.53.356-1.319-.153-1.76-.51-.442-1.267-.37-1.691.16L4.718 7.897 2.05 5.117z"
        transform="translate(-71 -334) translate(71 334)"
      />
    </SVGIcon>
  );
};
