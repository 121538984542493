import styled from 'styled-components';

import { ResponsiveGroup } from '@marbletech/components';
import { spacings, breakpoints } from '@marbletech/theme';

export const Container = styled.div`
  margin: 152px 0;
  @media (max-width: ${breakpoints.md}px) {
    margin: ${spacings[7]} 0;
  }
`;

export const CardsContainer = styled(ResponsiveGroup).attrs({ min: '384px', gapBottom: spacings[7], gap: spacings[4] })`
  margin-top: ${spacings[14]};
  @media (max-width: ${breakpoints.md}px) {
    margin: ${spacings[5]} 0;
  }
`;
