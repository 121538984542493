import * as React from 'react';
import { TextField } from '@marbletech/components';
import { FunnelTitle } from '../FunnelTitle/FunnelTitle';
import { FunnelSubtitle } from '../FunnelSubtitle/FunnelSubtitle';
import { Container } from '../shared-funnel/Container';
import { ButtonWrap } from '../shared-funnel/ButtonWrap';
import { ButtonWithChevron } from '../shared-funnel/ButtonWithChevron';
import { StyledForm } from '../shared-funnel/StyledForm';
import { TextFieldGrid } from '../TextFieldGrid';

export interface AnythingElseProps {
  title: string;
  subtitle?: string;
  fields: Array<{
    key: 'anythingElse';
    placeholder: string;
  }>;
  buttonText: string;
  handleSubmit: (data: { anythingElse: string }) => void;
}

export const AnythingElse: React.FunctionComponent<AnythingElseProps> = ({
  title,
  subtitle,
  fields,
  buttonText,
  handleSubmit,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = React.useState<boolean>(true);
  const [fieldsData, setFieldsData] = React.useState<Record<string, string>>({
    anythingElse: '',
  });

  const updateField = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldsData({
      ...fieldsData,
      [key]: e.target.value,
    });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit({
      anythingElse: fieldsData.anythingElse,
    });
  };

  return (
    <Container>
      <FunnelTitle>{title}</FunnelTitle>
      {subtitle && <FunnelSubtitle>{subtitle}</FunnelSubtitle>}
      <StyledForm
        {...{
          isContentCenteredDesktop: true,
          onSubmit,
        }}
      >
        <TextFieldGrid {...{ isDesktopFieldsColumn: true }}>
          {fields.map((field) => {
            return (
              <TextField
                {...{
                  key: field.key,
                  value: fieldsData[field.key],
                  onChange: updateField(field.key),
                  placeholder: field.placeholder,
                }}
              />
            );
          })}
        </TextFieldGrid>
        <ButtonWrap>
          <ButtonWithChevron
            {...{
              text: buttonText,
              disabled: !isButtonEnabled,
              onSubmit,
            }}
          />
        </ButtonWrap>
      </StyledForm>
    </Container>
  );
};

export default AnythingElse;
