import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ChevronSmallRebrandProps extends SVGIconProps {}

export const ChevronSmallRebrandIcon: React.FunctionComponent<ChevronSmallRebrandProps> = (props) => {
  return (
    <SVGIcon {...{ width: 16, height: 16, viewBox: '0 0 16 16', ...props }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57674 0L8.43699 1.04292L13.22 7H0V8.5H13.622L8.43699 14.9571L9.57674 16L16 8L9.57674 0Z"
        fill="white"
      />
    </SVGIcon>
  );
};
