import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@marbletech/components';
import { Theme } from '@marbletech/theme';

export interface PageTitleProps {}

export const StyledTypography = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.main.dark,
  fontSize: 48,
  fontWeight: 600,
  lineHeight: '56px',
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily[1],
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h1,
  },
}));

export const PageTitle: React.FunctionComponent<PageTitleProps> = ({ children }) => {
  return (
    <StyledTypography variant={'h1'} compinent={'h1'}>
      {children}
    </StyledTypography>
  );
};

export default PageTitle;
