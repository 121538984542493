import React from 'react';
import { Box } from 'grommet';
import { Link } from 'gatsby';
import {
  H2,
  Subtitle2,
  P2,
  Panel,
  H1,
  H3,
  RenderIfDesktop,
  RenderIfTablet,
  OptimizedAssetImage,
} from '@marbletech/components';
import { spacings } from '@marbletech/theme';
import { useNavigation } from '@marble/website/src/context/Context';
import { HorizontalPanelItemsProps } from './HorizontalPanelItems.types';
import {
  ItemsContainer,
  ContentContainer,
  PanelItem,
  ItemHeader,
  Button,
  LinkContainer,
  ImageContainer,
} from './HorizontalPanelItems.styled';

export const HorizontalPanelItems: React.FC<HorizontalPanelItemsProps> = ({ pageContent: content }) => {
  const { horizontalPanelItems } = content;
  const { items, title, btnLink: link, btnText, subtitle } = horizontalPanelItems[0];
  const { getLocalizedLink } = useNavigation();

  return (
    <ContentContainer align={'center'}>
      <RenderIfDesktop>
        <H2 textAlign={'center'}>
          {title}
          {subtitle && <P2 margin={{ top: spacings[2], bottom: '0px' }}>{subtitle}</P2>}
        </H2>
      </RenderIfDesktop>
      <RenderIfTablet>
        <H3 textAlign={'start'}>
          {title}
          <P2 margin={{ top: spacings[2], bottom: '0px' }}>{subtitle}</P2>
        </H3>
      </RenderIfTablet>
      <ItemsContainer gap={spacings[4]}>
        {items.map(({ title, description, color, jumboTitle, jumboImage }, index) => (
          <PanelItem key={`${title} ${index}`}>
            <ItemHeader flex={'shrink'}>
              {jumboTitle && (
                <H1 size={'small'} color={color}>
                  {jumboTitle}
                </H1>
              )}
              {jumboImage.filename && (
                <ImageContainer>
                  <OptimizedAssetImage src={jumboImage.filename} alt={jumboImage.alt} maxWidth={0} />
                </ImageContainer>
              )}
              <Subtitle2>{title}</Subtitle2>
            </ItemHeader>
            <Box flex={'grow'}>
              <P2>{description}</P2>
            </Box>
            <Panel.BottomStrip bgColor={color} />
          </PanelItem>
        ))}
      </ItemsContainer>
      {btnText && (
        <LinkContainer>
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button size={'xxlarge'}>{btnText}</Button>
          </Link>
        </LinkContainer>
      )}
    </ContentContainer>
  );
};
