import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, spacings, devices } from '@marbletech/theme';

export const StepContainer = styled(Box)`
  display: flex;
  justify-content: start;
  max-width: 232px;
  border: 1px solid ${colors.disabled};
  padding: ${spacings[3]};
  border-radius: ${spacings[0.5]};

  ${devices.mobile} {
    padding: ${spacings[2]};
    max-width: unset;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  column-gap: ${spacings[4]};

  &:last-child {
    svg {
      display: none;
    }
    column-gap: 0px;
  }
`;
