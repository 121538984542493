import styled from 'styled-components';
import { theme, spacings, Colors } from '@marbletech/theme';
import { Section as BaseSection } from '../../shared';

export const SectionContainer = styled(BaseSection)<{ bgColor?: Colors }>`
  background: ${({
    bgColor,
    theme: {
      global: { colors },
    },
  }) => colors[bgColor] ?? colors.neutral200};
  padding-top: ${spacings[18]};
  padding-bottom: ${spacings[18]};

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${spacings[7]};
    padding-bottom: ${spacings[7]};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column-reverse;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
`;

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding-left: 78px;
  flex-shrink: 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 0 0 56px 0;
  }
`;

export const StepperContainer = styled.div`
  max-width: 470px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 48px 0 40px 0;
`;
