import { H3, P1 } from '@marbletech/components';
import { spacings, theme } from '@marbletech/theme';
import styled from 'styled-components';
import { Section as BaseSection } from '../../shared';

export const SectionContainer = styled(BaseSection)`
  background: ${({
  theme: {
    global: { colors },
  },
}) => colors.denim100};
  padding-top: 112px;
  padding-bottom: 112px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
`;

export const Title = styled(H3)`
  white-space: pre-wrap;

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
  }
`;

export const Text = styled(P1)`
  white-space: pre-wrap;
  padding: ${spacings[3]} 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
  }
`;
