import * as React from 'react';
import styled from 'styled-components';
import { theme, Theme } from '@marbletech/theme';
import { flexBetweenCenter, flexCenterCenter, flexStartCenter } from '@marble/style-utils';
import { Button, Typography } from '@marbletech/components';
import {
  ApprovedIcon,
  CourseIcon,
  CourtIcon,
  DetermineIcon,
  MeetingIcon,
  NegotiateIcon,
  PaperworkIcon,
  ResearchIcon,
  StrategyIcon,
  TrialIcon,
} from '@marble/icons';
import { Link } from 'gatsby';

export interface FunnelDescriptionProps {
  title?: string;
  intro?: string;
  steps: FunnelDescriptionStep[];
  buttonText?: string;
  buttonLink?: string;
}

export type FunnelDescriptionStep = Array<{
  id: string;
  icon: React.ReactNode;
  title: string;
  description: string;
}>;

function mapIconKeyToComponent(iconKey: string) {
  const icons = {
    research: <ResearchIcon />,
    court: <CourtIcon />,
    meeting: <MeetingIcon />,
    trial: <TrialIcon />,
    approved: <ApprovedIcon />,
    determine: <DetermineIcon />,
    paperwork: <PaperworkIcon />,
    negotiate: <NegotiateIcon />,
    strategy: <StrategyIcon />,
    course: <CourseIcon />,
  };

  return icons[iconKey];
}

export const FunnelDescriptionContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  // overflow: 'hidden',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    maxWidth: '90%',
  },
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h3,
  fontFamily: theme.typography.fontFamily[1],
  fontWeight: 600,
  textAlign: 'center',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h2,
    textAlign: 'initial',
    maxWidth: 530,
  },
}));

export const Intro = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  marginTop: theme.gutters.base * 4,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
    maxWidth: 740,
  },
}));

export const Grid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  marginTop: theme.gutters.base * 8,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 12,
  },
}));

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.gutters.base * 2,
  height: 790,
  width: '100%',
  position: 'relative',
  marginBottom: theme.gutters.base * 3,
  '&::after': {
    content: "''",
    backgroundColor: theme.palette.main.secondary2,
    position: 'absolute',
    zIndex: -1,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 2,
    height: 769,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    height: 'initial',
    maxWidth: theme.gutters.base * 120,
    '&::after': {
      content: "''",
      backgroundColor: theme.palette.main.secondary2,
      position: 'absolute',
      zIndex: -1,
      top: '50%',
      width: '100%',
      height: 2,
    },
  },
}));

export const InfoContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'initial',
    borderLeft: `2px solid ${theme.palette.main.primary}`,
    padding: theme.gutters.base * 3,
    paddingTop: theme.gutters.base * 0.5,
    marginTop: theme.gutters.base * 22,
    width: '85%',
    maxWidth: 600,
    height: 160,
  },
}));

export const StepsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexBetweenCenter,
  flexDirection: 'column',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    flexDirection: 'row',
  },
}));

export const StepContainer = styled('div')<{ theme: Theme; position?: -1 | 0 | 1 }>(({ theme, position = 0 }) => ({
  ...flexStartCenter,
  position: 'relative',
  ...(position && {
    transform: `translate(${position === -1 ? -107 : 67}px, ${position === -1 ? 86 : -79}px)`,
  }),
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    justifyContent: 'start',
    transform: `translateY(${31 * position}px)`,
    '&::after': {
      backgroundColor: 'transparent',
    },
  },
}));

export const StepIcon = styled('div')<{ theme: Theme; hoveredOn: boolean }>(({ theme, hoveredOn }) => ({
  borderRadius: '50%',
  width: 64,
  height: 64,
  ...flexCenterCenter,
  backgroundColor: theme.palette.main.secondary3,
  '& svg': {
    fill: theme.palette.main.secondary0,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    backgroundColor: hoveredOn ? theme.palette.main.primary : theme.palette.main.secondary3,
    ...(hoveredOn && {
      '& svg': {
        fill: theme.palette.background.site,
      },
    }),
  },
}));

export const StepTitle = styled(Typography)<{ theme: Theme; hoveredOn: boolean; position?: 1 | 0 | -1 }>(
  ({ theme, hoveredOn, position }) => {
    return {
      top: '119%',
      position: 'absolute',
      minWidth: 'max-content',
      left: '50%',
      backgroundColor: 'white',
      paddingBottom: theme.gutters.base * 0.5,
      textAlign: 'center',
      transform: `translate(-50%, -12px)`,
      paddingTop: theme.gutters.base * 1,
      color: theme.palette.main.secondary0,
      ...((position === 1 || position === -1) && {
        transform: `translate(-50%, -13px)`,
        minWidth: 150,
        top: '120%',
      }),
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        minWidth: 150,
        top: position === 1 || position === -1 ? '120%' : '130%',
        color: hoveredOn ? theme.palette.main.primary : theme.palette.main.secondary1,
        ...(!position && {
          textAlign: 'initial',
          left: 0,
          transform: `translate(0, -10px)`,
        }),
      },
    };
  },
);

export const Step: React.FunctionComponent<{
  step: FunnelDescriptionStep[0];
  onSelection: () => void;
  position?: -1 | 0 | 1;
  hoveredOn: boolean;
}> = ({ step, onSelection, hoveredOn, position }) => {
  return (
    <StepContainer {...{ position, onMouseEnter: onSelection }}>
      <StepIcon {...{ hoveredOn }}>{mapIconKeyToComponent(step.icon as string)}</StepIcon>
      <StepTitle {...{ variant: 'p1', hoveredOn, position }}>{step.title}</StepTitle>
    </StepContainer>
  );
};

export const MultiStepContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  border: `2px solid ${theme.palette.main.secondary2}`,
  zIndex: 1,
  paddingRight: 98,
  paddingLeft: 73,
  backgroundColor: 'white',
  width: 0,
  minHeight: 328,
  position: 'relative',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'grid',
    gap: 100,
    gridTemplateColumns: '1fr',
    paddingRight: 112,
    paddingLeft: 113,
    width: 'initial',
    minHeight: 'initial',
    height: 222,
  },
}));

export const OrWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.main.secondary2}`,
  backgroundColor: 'white',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 101,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    left: 0,
    transform: 'translateX(-54%)',
    top: 93,
  },
}));

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 320,
  margin: '0 auto',
  marginTop: theme.gutters.base * 6,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const MultiStep: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <MultiStepContainer>
      <div
        {...{
          style: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 100,
          },
        }}
      >
        {React.Children.map(children, (child, ind) => {
          return React.cloneElement(child as React.ReactElement, {
            position: ind % 2 === 0 ? -1 : 1,
          });
        })}
      </div>
      <OrWrap>
        <Typography {...{ variant: 'p1', color: 'secondary1', center: true, marginTop: 2 }}>or</Typography>
      </OrWrap>
    </MultiStepContainer>
  );
};

export const FunnelDescription: React.FunctionComponent<FunnelDescriptionProps> = ({
  title,
  intro,
  steps,
  buttonText,
  buttonLink,
}) => {
  const [itemToShow, setItemToShow] = React.useState<[number, number]>([0, 0]);
  const onSelection = (first: number) => (second: number) => () => {
    setItemToShow([first, second]);
  };
  return (
    <FunnelDescriptionContainer>
      <Title>{title}</Title>
      <Intro>{intro}</Intro>
      <Grid>
        <Container>
          <StepsContainer>
            {steps
              .filter((step) => {
                // guards against an empty step
                return step.length > 0;
              })
              .map((step, ind) => {
                if (step.length === 0) return null;

                const isMulti = step.length > 1;
                // const isLast = ind === steps.length - 1;

                return (
                  <React.Fragment key={`${step.id}${ind}`}>
                    {isMulti ? (
                      <MultiStep {...{ key: ind }}>
                        {step.map((stp, jnd) => {
                          return (
                            <Step
                              {...{
                                key: `${stp.id}${ind}`,
                                step: stp,
                                onSelection: onSelection(ind)(jnd),
                                // isLast,
                                hoveredOn: itemToShow[0] === ind && itemToShow[1] === jnd,
                              }}
                            />
                          );
                        })}
                      </MultiStep>
                    ) : (
                      <Step
                        {...{
                          key: ind,
                          step: step[0],
                          onSelection: onSelection(ind)(0),
                          // isLast,
                          hoveredOn: itemToShow[0] === ind,
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </StepsContainer>
        </Container>
        <InfoContainer>
          <Typography {...{ variant: 'h4', component: 'h4', marginBottom: theme.gutters.base * 2 }}>
            {steps[itemToShow[0]][itemToShow[1]]?.title}
          </Typography>
          <Typography {...{ variant: 'p1SemiBold' }}>{steps[itemToShow[0]][itemToShow[1]]?.description}</Typography>
        </InfoContainer>
      </Grid>
      {buttonLink && buttonText && (
        <ButtonWrap>
          <Link {...{ to: buttonLink }}>
            <Button {...{ size: 'large' }}>{buttonText}</Button>
          </Link>
        </ButtonWrap>
      )}
    </FunnelDescriptionContainer>
  );
};

export default FunnelDescription;
