import styled from 'styled-components';
import { H2, H3, H5 } from '@marbletech/components';
import { theme, spacings } from '@marbletech/theme';
import { motion } from 'framer-motion';
import { Box } from 'grommet';
import { SectionProps } from './Hero.types';
import { HEADER_HEIGHT_MOBILE, MAX_WIDTH } from '../../../consts';

export const sectionHeightSplitView = 750;

export const Container = styled(Box)<SectionProps>`
  flex: ${(props) => (props.isSplitView ? `0 0 ${sectionHeightSplitView}px` : '0 0 612px')};
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.md}px) {
    flex: 1 0 ${(props) => (props.isSplitView ? `calc(100 * var(--vh, 1vh) - ${HEADER_HEIGHT_MOBILE}px)` : '480px')};
    flex-direction: ${(props) => (props.isSplitView ? 'column-reverse' : 'row')};
    transition: flex-basis 300ms ease-in;
  }
`;

export const HeroContentLayout = styled.div<SectionProps>`
  max-width: ${(props) => (props.isSplitView ? MAX_WIDTH / 2 : MAX_WIDTH)}px;
  display: flex;
  width: 100%;
  margin: ${(props) => (props.isSplitView ? 0 : '0 auto')};
  padding-right: ${spacings[2.5]};

  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: flex-start;
  }

  @media (max-width: 1140px) {
    padding-left: ${spacings[2.5]};
  }
`;

export const ImgSection = styled(Box)`
  justify-content: center;
  flex-direction: row;
  width: 50%;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    min-height: 175px;
    height: 100%;
    flex-grow: 1;

    @media (max-width: ${theme.breakpoints.sm}px) {
      min-height: 145px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center top;
`;

export const ContentContainer = styled(Box)<SectionProps>`
  width: ${(props) => (props.isSplitView ? '50%' : '100%')};
  flex-shrink: 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    padding-top: ${(props) => (props.isSplitView ? spacings[3] : spacings[5])};
    padding-bottom: ${(props) => (props.isSplitView ? spacings[4] : spacings[7])};
  }
`;

export const TextAnimationWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;

  @media (max-width: ${theme.breakpoints.md}px) {
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
  }
`;

export const Eyebrow = styled(H5).attrs({
  color: 'bright',
})`
  @media (max-width: ${theme.breakpoints.md}px) {
    // we use here media query instead of js (H3 component) to prevent the jump when the screen is loaded.
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Title = styled(H2).attrs({
  color: 'bright',
})<SectionProps>`
  height: ${(props) => (props.isSplitView ? 'auto' : '204px')};
  max-width: 938px;
  white-space: pre-wrap;
  margin: ${spacings[3]} 0 ${(props) => (props.isSplitView ? spacings[6] : spacings[7])} 0;
  flex-shrink: ${(props) => (props.isSplitView ? 0 : 'unset')};

  @media (max-width: ${theme.breakpoints.md}px) {
    // we use here media query instead of js (P1 component) to prevent the jump when the screen is loaded.
    font-size: 36px;
    line-height: 40px;
    margin: ${spacings[2]} 0 ${spacings[5]} 0;
    height: ${(props) => (props.isSplitView ? 'auto' : '240px')};
    max-width: 335px;
  }
`;

export const HighlightText = styled.span`
  color: ${(props) => props.theme.global.colors.green100};
`;
