import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import { MobileSubmenuProps } from './MobileSubmenu';
import { ChevronSmallIcon } from '@marble/icons';

export interface SubmenuTitleContainerProps {
  handleSubmenuClick: () => void;
  isSubMenuExpanded: boolean;
  title: string;
  subItems: MobileSubmenuProps['subItems'];
}

export const TitleContainer = styled('div')<{ theme: Theme; hasSmallerPadding: boolean }>(
  ({ theme, hasSmallerPadding }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    paddingBottom: hasSmallerPadding ? theme.gutters.base * 3 : theme.gutters.base * 4,
    WebkitTapHighlightColor: 'transparent',
  }),
);

export const SubmenuTitleContainer: React.FunctionComponent<SubmenuTitleContainerProps> = ({
  handleSubmenuClick,
  isSubMenuExpanded,
  title,
  subItems,
}) => {
  return (
    <TitleContainer
      {...{
        onClick: handleSubmenuClick,
        isSubMenuExpanded,
        hasSmallerPadding: Boolean(title === 'Resources' && isSubMenuExpanded),
      }}
    >
      <Typography {...{ variant: 'p1SemiBold' }}>{title}</Typography>
      {subItems && <ChevronSmallIcon {...{ rotate: isSubMenuExpanded ? -90 : 90 }} />}
    </TitleContainer>
  );
};

export default SubmenuTitleContainer;
