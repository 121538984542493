import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@marbletech/components';
import { theme, Theme } from '@marble/theme';
import { Link } from 'gatsby';
import { BurgerIcon, ChevronSmallIcon, XIcon, marbleTM } from '@marble/icons';
import DesktopMenuContainer from '../DesktopMenuContainer/DesktopMenuContainer';
import MobileMenu from '../MobileMenu/MobileMenu';
import { Image, useNavigation, useStore } from '../../../context/Context';
import { MaxWidthContainer, useLandingPagePhoneFlag } from '@marble/website';
import { ContentItem, StoryblokImage } from '../../../storyblok-defs';
import PortalButton from '../PortalButton/PortalButton';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import { useLocation } from '@reach/router';
import { eventService, EventName } from '../../../utils';

export type MenuType = 'full' | 'phoneNumber' | 'phoneNumberAndCTA' | 'none';

export interface MainNavigationProps {
  menuContent: MenuData;
  funnelLink: string;
  menuType: MenuType;
}

export interface MenuData extends ContentItem {
  navItems: Array<NavItem>;
  buttonText: string;
  mobileMenuSignInText: string;
  signInText: string;
  menuDetailsDesktop_box1_image: StoryblokImage;
  menuDetailsDesktop_box1_title: string;
  menuDetailsDesktop_box1_subtitle: string;
  menuDetailsDesktop_box1_buttonText: string;
  menuDetailsDesktop_box2_title: string;
  menuDetailsDesktop_box2_subtitle: string;

  pageContact: Array<PageContactItem>;
}

export interface NavItem {
  title: string;
  subItems?: Array<SubItem | ComplexSubItem>;
  link?: string;
  id?: string;
}

export interface SubItem {
  text: string;
  servicePageLink: string;
  icon: Image;
}

export interface ComplexSubItem extends SubItem {
  servicePageLink: string;
  funnelLink: string;
  thumbIcon: Image;
  testimonials: Array<{
    _uid: string;
    text: string;
    name: string;
    location: string;
  }>;
  backgroundImage: Image;
}

export interface PageContactItem {
  page: string;
  phoneMenuText: string;
  linkPhoneNumber: string;
  funnelLink: string;
}

export const Container = styled('nav')<{
  theme: Theme;
  scrollY: number;
  isExpanded: boolean;
}>(({ theme, scrollY, isExpanded }) => {
  return {
    display: 'block',
    padding: `${theme.gutters.base * 3}px 0`,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    backgroundColor: scrollY > 0 ? theme.palette.main.support3 : 'transparent',
    transition: '0.5s',
    ...(isExpanded && {
      backgroundColor: theme.palette.main.bright,
    }),
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      backgroundColor: scrollY > 0 ? theme.palette.main.support3 + 'CC' : 'transparent',
      ...(isExpanded && {
        backgroundColor: theme.palette.main.bright,
      }),
    },
  };
});

export const MarbleTMWrap = styled('div')<{ theme: Theme }>(() => ({
  gridArea: 'logo',
}));

export const InnerContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${theme.gutters.base * 2.5}px`,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr max-content',
    gridTemplateAreas: `
    'logo . navigation'
    `,
  },
  [`@media (min-width: 1170px)`]: {
    padding: 0,
  },
}));

export const BurgerWrap = styled('div')<{ theme: Theme; menuType: MainNavigationProps['menuType'] }>(
  ({ theme, menuType }) => ({
    display: menuType === 'phoneNumber' ? 'none' : 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      display: 'none',
    },
  }),
);

export const NavItemsContainer = styled('div')<{
  theme: Theme;
}>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, max-content) 116px 70px',
    gridTemplateRows: 30,
    gridGap: theme.gutters.base * 4,
    alignItems: 'center',
    gridArea: 'navigation',
    'div:last-child button': {
      whiteSpace: 'nowrap',
    },
  },
}));

export const GetStartedButton = styled('button')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.main.dark}`,
  borderRadius: 36,
  textTransform: 'uppercase',
  cursor: 'pointer',
  padding: `${theme.gutters.base * 1}px ${theme.gutters.base * 2}px`,
  fontFamily: theme.typography.fontFamily[0],
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '16px',
  width: 'max-content',
  color: theme.palette.main.dark,
  '&:hover': {
    border: `1px solid ${theme.palette.main.primary}`,
    color: theme.palette.main.primary,
  },
}));

export const SubMenuContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content min-content',
  gridGap: theme.gutters.base * 2,
  alignItems: 'center',
  cursor: 'pointer',
}));

export const LandingPageContactWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  gridGap: 16,
  alignItems: 'center',
  justifyContent: 'flex-end',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    gridGap: 48,
  },
}));

export const MarbleTM = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 72,
  height: 16,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: 106,
    height: 24,
  },
}));

export const MobilePortalButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.gutters.base * 3,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const MainNavigation: React.FunctionComponent<MainNavigationProps> = ({ menuContent, funnelLink, menuType }) => {
  const menuData = menuContent;
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [expandedMenu, setExpandedMenu] = React.useState<NavItem>({ title: '', subItems: [] });
  const [isPickedComplex, setIsPickedComplex] = React.useState(false);
  const [scrollY, setScrollY] = React.useState<number>(0);
  const { userDetails, currentPhoneNumber, setCurrentPhoneNumber, pageComponent } = useStore();
  const { linkPrefix } = useNavigation();
  const handleScroll = () => setScrollY(window.pageYOffset);
  const { pathname } = useLocation();

  const shouldShowPhoneNumberOnLandingPageAndFunnel = useLandingPagePhoneFlag();

  React.useEffect(() => {
    window.addEventListener('scroll', () => handleScroll());

    return window.removeEventListener('scroll', () => handleScroll());
  }, []);

  React.useEffect(() => {
    if (pageComponent !== 'funnel') {
      setCurrentPhoneNumber('url');
    }
  }, [pageComponent]);

  const handleSubmenuClick = ({ title, subItems }: NavItem) => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelNavbarItemExpand,
      eventData: { item_key: title, item_text: title },
    });

    if (expandedMenu.title === title) {
      handleCloseMenu();
    } else {
      handleCloseMenu();
      if (typeof window !== 'undefined') {
        window.setTimeout(() => {
          handleOpenMenu(title, subItems);
        }, 1);
      }
    }
  };

  const handleCloseMenu = () => {
    setExpandedMenu({ title: '', subItems: [] });
    setIsExpanded(false);
    setIsPickedComplex(false);
  };

  const handleOpenMenu = (title: NavItem['title'], subItems: NavItem['subItems']) => {
    setExpandedMenu({ title, subItems });
    setIsExpanded(true);
    setIsPickedComplex(false);
  };

  const handleCtaClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelNavbarCtaClick,
      eventData: { cta: menuData.buttonText },
    });
  };

  if (menuType === 'none') {
    return null;
  } else {
    return (
      <div {...{ style: { backgroundColor: theme.palette.main.bright } }}>
        <Container {...{ scrollY, isExpanded }}>
          <MaxWidthContainer>
            <InnerContainer>
              <MarbleTMWrap
                {...{
                  onClick: () => {
                    window.scrollTo(0, 0);
                    handleCloseMenu();
                  },
                }}
              >
                <Link {...{ to: linkPrefix + '/' }}>
                  <MarbleTM {...{ src: marbleTM }} />
                </Link>
              </MarbleTMWrap>
              {userDetails && (
                <MobilePortalButtonWrap>
                  {pageComponent !== 'funnel' && <PortalButton {...{ signInText: menuData.signInText ?? 'Sign In' }} />}
                </MobilePortalButtonWrap>
              )}
              {pageComponent !== 'landingPage' && (
                <BurgerWrap
                  {...{
                    onClick: () => {
                      setIsExpanded(!isExpanded);
                    },
                    menuType,
                  }}
                >
                  {isExpanded ? <XIcon {...{ height: 16, width: 16 }} /> : <BurgerIcon />}
                </BurgerWrap>
              )}
              {menuType === 'phoneNumber' && <PhoneNumber {...currentPhoneNumber} />}
              {menuType === 'phoneNumberAndCTA' && (
                <LandingPageContactWrap>
                  {shouldShowPhoneNumberOnLandingPageAndFunnel && <PhoneNumber {...currentPhoneNumber} />}
                  <Link to={funnelLink?.includes('#') ? pathname + funnelLink : linkPrefix + funnelLink}>
                    <GetStartedButton onClick={handleCtaClick}>{menuData.buttonText}</GetStartedButton>
                  </Link>
                </LandingPageContactWrap>
              )}
              {menuType === 'full' && (
                <NavItemsContainer>
                  {menuData.navItems?.map(({ title, subItems, link }) => {
                    if (subItems && subItems.length > 0) {
                      return (
                        <SubMenuContainer
                          {...{
                            key: title,
                            onClick: () => handleSubmenuClick({ title, subItems }),
                          }}
                        >
                          <Typography {...{ variant: 'p1SemiBold' }}>{title}</Typography>
                          <ChevronSmallIcon {...{ rotate: expandedMenu.title === title ? -90 : 90 }} />
                        </SubMenuContainer>
                      );
                    } else {
                      return (
                        <div {...{ key: title, onClick: () => window.scrollTo(0, 0) }}>
                          {link && (
                            <Link {...{ to: linkPrefix + link, style: { textDecoration: 'none' } }}>
                              <Typography {...{ variant: 'p1SemiBold' }}>{title}</Typography>
                            </Link>
                          )}
                        </div>
                      );
                    }
                  })}
                  <PhoneNumber {...currentPhoneNumber} />

                  <Link to={funnelLink?.includes('#') ? pathname + funnelLink : linkPrefix + funnelLink}>
                    <GetStartedButton
                      onClick={() => {
                        handleCloseMenu();
                        handleCtaClick();
                      }}
                    >
                      {menuData.buttonText}
                    </GetStartedButton>
                  </Link>
                  <PortalButton {...{ signInText: menuData.signInText ?? 'Sign In' }} />
                </NavItemsContainer>
              )}
            </InnerContainer>
          </MaxWidthContainer>
          {isExpanded && expandedMenu.subItems && (
            <DesktopMenuContainer
              {...{
                title: expandedMenu.title,
                subItems: expandedMenu.subItems,
                setIsExpanded,
                setExpandedMenu,
                isPickedComplex,
                setIsPickedComplex,

                menuDetailsDesktop_box1_image: menuData.menuDetailsDesktop_box1_image,
                menuDetailsDesktop_box1_title: menuData.menuDetailsDesktop_box1_title,
                menuDetailsDesktop_box1_subtitle: menuData.menuDetailsDesktop_box1_subtitle,
                menuDetailsDesktop_box1_buttonText: menuData.menuDetailsDesktop_box1_buttonText,
                menuDetailsDesktop_box2_title: menuData.menuDetailsDesktop_box2_title,
                menuDetailsDesktop_box2_subtitle: menuData.menuDetailsDesktop_box2_subtitle,
              }}
            />
          )}
          {isExpanded && (
            <MobileMenu
              {...{
                navItems: menuData.navItems,
                setIsExpanded,
                buttonText: menuData.buttonText,
                signInText: menuData.mobileMenuSignInText,
                signInButtonText: menuData.signInText,
              }}
            />
          )}
        </Container>
      </div>
    );
  }
};

export default MainNavigation;
