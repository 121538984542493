import * as Sentry from '@sentry/gatsby';
import { useStore } from './Context';

export const captureSentryException = (error: string, sentryContext = {}) => {
  Sentry.captureException(new Error(error), {
    ...sentryContext,
    level: Sentry.Severity.Error,
  })
}

export const useSentry = () => {
  const { person } = useStore();
  const sentryContext = {
    user: person,
  };

  return {
    captureException: (error: string) =>
      captureSentryException(error, sentryContext)
  };
};
