import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface CallLineProps extends SVGIconProps {}

export const CallLineIcon: React.FunctionComponent<CallLineProps> = (props) => {
  return (
    <SVGIcon {...{ width: 16, height: 16, viewBox: '0 0 16 16', ...props }}>
      <path
        fillRule="evenodd"
        d="M2.959 0c1.652 0 3.344 2.255 2.928 3.305-.08.2-.184.405-.3.605l-.185.296-.194.283-.194.263-.186.236-.167.202-.304.345.35.448.36.438.342.402.259.296.432.478.479.51.343.35.36.355.187.179.383.357c.13.118.263.236.397.353l.41.347c.69.57 1.425 1.098 2.172 1.512l1.516-1.226.306-.257c1.256-1.077 3.18 1.483 3.344 2.663.099 1.322-2.303 4.037-5.82 3.043C6.66 14.79.124 9.696 0 4.355 0 3.463 1.307 0 2.959 0zm0 1.5c-.084 0-.243.183-.426.458l-.162.257-.166.29c-.028.05-.056.101-.083.153l-.16.315C1.702 3.503 1.5 4.04 1.5 4.32c.09 3.89 5.173 8.914 9.085 10.02 2.04.576 3.97-.775 3.926-1.394-.034-.246-.407-.897-.832-1.319-.063-.062-.123-.116-.178-.16l-.089-.069-.602.495-1.046.84c-.474.378-1.13.433-1.66.139-1.216-.674-2.443-1.63-3.67-2.795-.838-.796-1.627-1.644-2.347-2.491l-.303-.362-.37-.456-.258-.335c-.408-.545-.394-1.29.016-1.818l.12-.138.13-.146.058-.066c.171-.201.342-.417.5-.637.132-.185.248-.362.34-.525l.085-.158.034-.072-.017-.03c-.064-.182-.197-.409-.377-.626-.374-.45-.826-.717-1.086-.717z"
        transform="translate(-1001 -32) translate(1001 32)"
      />
    </SVGIcon>
  );
};
