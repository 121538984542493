import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface CourtProps extends SVGIconProps {}

export const CourtIcon: React.FunctionComponent<CourtProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M24 19v5H9v-5h15zm-1.5 1.5h-12v2h12v-2zM24 0v15H14v-5H0V5h14V0h10zm-1.5 11.5h-7v2h7v-2zm0-6h-7V10h7V5.5zm-8.5 1H1.5v2H14v-2zm8.5-5h-7V4h7V1.5z"
      />
    </SVGIcon>
  );
};
