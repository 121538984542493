import styled, { css } from 'styled-components';
import { Section as BaseSection } from '../../shared';
import { ResponsiveGroup, H3 } from '@marbletech/components';
import { CardsContainerProps } from './PracticeAreas.types';
import { spacings, theme } from '@marbletech/theme';

export const SectionContainer = styled(BaseSection)`
  background: ${({
    theme: {
      global: { colors },
    },
  }) => colors.neutral100};
  padding-top: 144px;
  padding-bottom: 136px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: 56px;
    padding-bottom: 70px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const Title = styled(H3)`
  white-space: pre-wrap;
  padding-bottom: ${spacings[13]};
  text-align: center;

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
    padding-bottom: ${spacings[6]};
    text-align: start;
  }
`;

const hiddenCards = css`
  > .practiceCard {
    opacity: 0;
    transform: translateY(200px) !important;
  }
`;

export const CardsContainer = styled(ResponsiveGroup)<CardsContainerProps>`
  grid-template-columns: repeat(3, 1fr);
  > .practiceCard {
    transition: transform 1.5s ease-in-out;
  }

  > .practiceCard:nth-child(3n + 1),
  > .practiceCard:nth-child(3n + 3) {
    transform: translateY(48px);

    @media (max-width: ${theme.breakpoints.md}px) {
      transform: translateY(0);
    }
  }

  ${({ isVisible }) => (isVisible ? '' : hiddenCards)};

  @media (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-gap: 46px 0px;
  }
`;
