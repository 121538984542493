import { isBrowser } from '@marbletech/utils';
import { INewBiEventContext, getStore } from '.';

// per data team request, we are not sending the referer for any in app navigation
export const referralWhiteList = ['localhost', 'www.marble.co'];

export const isReferralWhiteListed = (referrer: string): boolean => {
  return !!referralWhiteList.find((whiteListedReferer: string) => referrer.includes(whiteListedReferer));
};

export const clearDisallowedParams = (data: Record<string, string>): Record<string, string> => {
  const disallowedParams = ['route_history', 'is_intake_funnel', 'rebranded_page', 'page_name'];
  const allowedParams = Object.keys(data).filter((key) => !disallowedParams.includes(key));
  const filteredData: Record<string, string> = {};
  allowedParams.forEach((key) => {
    filteredData[key] = data[key];
  });
  return filteredData;
};

export const getReferrer = (): string => {
  const store = getStore();
  const routeHistory = store?.get('route_history');
  let referrer = isBrowser() ? document.referrer : '';

  if (routeHistory?.length > 1) {
    const previousRoute = routeHistory[routeHistory.length - 2];
    referrer = previousRoute;
  }

  // Data team requested to make the referer an empty string for all in app navigation
  if (isReferralWhiteListed(referrer)) {
    referrer = '';
  }

  return referrer;
};

export const clearEncodedCharacters = (eventData: Record<string, string>): Record<string, string> => {
  const targetProperties = ['click_text', 'cta_text', 'answer_value', 'full_answer'];
  targetProperties.forEach((property) => {
    if (eventData.hasOwnProperty(property)) {
      eventData[property] = eventData[property].replaceAll('’', "'");
    }
  });
  return eventData;
};

export const getPageName = (pageContent: any): string => {
  const editedPageName = pageContent?.['meta_title']?.split(' | ')?.[0];
  if (editedPageName?.toLowerCase()?.includes('pricing')) {
    return 'PricePage';
  }
  return pageContent.component;
};

export const waitForWorkingState = async (storeInstance: INewBiEventContext) => {
  let isInWorkingState = storeInstance?.getWorkingState();
  for (let i = 0; i < 10 && isInWorkingState; i++) {
    await sleep(100);
    isInWorkingState = storeInstance?.getWorkingState();
  }
};

export const sleep = async (arg0: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, arg0);
  });
