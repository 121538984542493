import React from 'react';
import { Helmet } from 'react-helmet';

export const TrustBox = ({ ...props }: any) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      </Helmet>

      <div
        ref={ref}
        className={'trustpilot-widget'}
        data-locale="en-US"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5ee9bc358adfce0001e91218"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-review-languages="en"
        data-text-color="#192348"
        data-stars={'5'}
        style={{ width: '100%' }}
        {...props}
      >
        <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener" />
      </div>
    </>
  );
};
