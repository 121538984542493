import React from 'react';
import { Link } from 'gatsby';
import { H5 } from '@marbletech/components';
import { useNavigation } from '@marble/website/src/context/Context';
import { ButtonText, Card, Content, Img, LearnMoreWrapper, Text, LearnMoreContainer } from './PracticeCard.styled';
import { PracticeAreaCardProps } from './PracticeCard.types';
import { eventService, EventName } from '../../../../../services/eventService';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../../utils/newEvents';

export const PracticeCard = ({ practiceAreaDetails }: PracticeAreaCardProps) => {
  const {
    image: { filename: image },
    h5: title,
    p2: text,
    text_link: textLink,
    image_link: { filename: arrow },
    link,
  } = practiceAreaDetails;
  const { getLocalizedLink } = useNavigation();
  const handleCardClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPageCardClick,
      eventData: {
        card: title,
      },
    });
    triggerNewBiEvent({
      eventName: NewBiEventsOptions.WebPageClick,
      eventData: {
        location: 'Practice Areas',
        element_name: 'PracticeAreas',
        click_text: title,
        click_type: 'link',
        click_url: link,
      },
    });
  };

  return (
    <Link to={getLocalizedLink({ pathname: link })} className="practiceCard">
      <Card onClick={handleCardClick}>
        <Content>
          <Img src={image} />
          <H5 margin={'48px 0 16px 0'}>{title}</H5>
          <Text>{text}</Text>
        </Content>
        <LearnMoreWrapper>
          <LearnMoreContainer>
            <ButtonText>{textLink}</ButtonText>
            <img src={arrow} width={'5px'} />
          </LearnMoreContainer>
        </LearnMoreWrapper>
      </Card>
    </Link>
  );
};

export default PracticeCard;
