import styled from 'styled-components';
import { theme } from '@marbletech/theme';
import { RebrandedButton } from '@marbletech/components';

// TODO figure out a correct way to set button width in its props
// (setting width="..." is always overridden by width: auto)

export const PanelLayout = styled.div`
  position: fixed;
  background: linear-gradient(180.68deg, rgba(255, 255, 255, 0) -0%, #ffffff 99.41%);
  padding-top: ${theme.gutters.base * 12}px;
  padding-bottom: ${theme.gutters.base * 2.5}px;
  padding-left: ${theme.gutters.base * 2.5}px;
  padding-right: ${theme.gutters.base * 2.5}px;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;

  button {
    width: 100%;
  }
`;

export const ClickableRebrandedButton = styled(RebrandedButton)`
  pointer-events: auto;
`;
