import * as React from 'react';
import styled from 'styled-components';
import { Image, useStore } from '../../context/Context';
import { Theme } from '@marble/theme';
import steph from '../../images/steph.png';
import { AnimatePresence, motion } from 'framer-motion';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import { ChevronSmallIcon, roundButtonSecondary } from '@marble/icons';
import { Typography } from '@marbletech/components';
import { useWindowSize } from '@marble/utils';
import { StepKey } from './Funnel';
import { SMSDialog } from '../SMSDialog/SMSDialog';
import { DemoDialog } from '../DemoDialog/DemoDialog';
import { eventService, EventName } from '../../utils';
import { useEffect } from 'react';
import { useScrollIntoView } from '@marble/g-website/src/hooks/useScrollIntoView';

export interface FunnelStepContainerProps {
  stepToShow: React.ReactNode;
  currentStepKey: StepKey;
  backgroundImage: Image;
  buttons?: React.ReactNode;
  stepsBreadCrumbs: Array<StepKey>;
  setStepsBreadCrumbs: (stepKeys: Array<StepKey>) => void;
  setCurrentStepKey: (stepKey: StepKey) => void;
  showSMSDialog: boolean;
  setShowSMSDialog: (flag: boolean) => void;
  showDemoDialog: boolean;
  setShowDemoDialog: (flag: boolean) => void;
}

export const MainContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
}));

export const StyledPaddingContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  height: '100%',
  padding: `0px ${theme.gutters.base * 2.5}px`,
}));

export const Avatar = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: theme.gutters.base * 7,
  height: theme.gutters.base * 7,
  marginTop: theme.gutters.base * 8,
  marginBottom: theme.gutters.base * 1,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: theme.gutters.base * 9,
    height: theme.gutters.base * 9,
    marginTop: theme.gutters.base * 19,
    marginBottom: theme.gutters.base * 3,
  },
}));

export const AnimationContainer = styled(motion.div)<{ theme: Theme; height: number }>(({ theme, height }) => ({
  width: '100%',
  height: height ?? '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const BackButtonWrap = styled('div')<{ theme: Theme; isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  position: 'absolute',
  top: theme.gutters.base * 9,
  cursor: 'pointer',
  zIndex: 1000,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    top: theme.gutters.base * 13,
  },
}));

export const BackButtonMobile = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, min-content)',
  gridGap: theme.gutters.base * 0.5,
  alignItems: 'center',
  textTransform: 'uppercase',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const BackButtonDesktop = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    maxWidth: theme.gutters.base * 5,
  },
}));

export const ButtonsWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({}));

export const BackgroundImage = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: -1,
  },
}));

export const FunnelStepContainer: React.FunctionComponent<FunnelStepContainerProps> = ({
  stepToShow,
  currentStepKey,
  backgroundImage,
  buttons,
  stepsBreadCrumbs,
  setStepsBreadCrumbs,
  setCurrentStepKey,
  showSMSDialog,
  setShowSMSDialog,
  showDemoDialog,
  setShowDemoDialog,
}) => {
  const { ref } = useScrollIntoView(currentStepKey);
  const pageHeight = useWindowSize().height;
  const animationContainerHeight = pageHeight - 120;
  const {
    funnelMeta: { practiceArea },
    funnelAnswers,
  } = useStore();
  const subPractice = practiceArea && funnelAnswers[practiceArea]?.whatMatterRelated;
  eventService.setContext({ key: 'sub_practice', value: subPractice, eventName: EventName.OnlineFunnelStepView });
  eventService.setContext({ key: 'sub_practice', value: subPractice, eventName: EventName.OnlineFunnelStepClick });

  useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelStepView,
      eventData: { current_step_key: currentStepKey },
    });
  }, [currentStepKey]);

  return (
    <div {...{ style: { position: 'relative', height: '100%' } }} ref={ref}>
      {showSMSDialog && <SMSDialog onClose={() => setShowSMSDialog(false)} />}
      {showDemoDialog && <DemoDialog onClose={() => setShowDemoDialog(false)} />}
      <StyledPaddingContainer>
        <MainContainer data-test-id={currentStepKey}>
          <MaxWidthContainer>
            <div {...{ style: { position: 'relative' } }}>
              <BackButtonWrap
                {...{
                  isVisible: currentStepKey !== 'intro',
                  onClick: () => {
                    const stepsBreadCrumbsCopy = [...stepsBreadCrumbs];
                    const stepsBreadCrumbsWithoutLast = [
                      ...stepsBreadCrumbsCopy.slice(0, stepsBreadCrumbsCopy.length - 1),
                    ];
                    setStepsBreadCrumbs(stepsBreadCrumbsWithoutLast);
                    setCurrentStepKey(stepsBreadCrumbsWithoutLast[stepsBreadCrumbsWithoutLast.length - 1]);
                  },
                }}
              >
                <BackButtonMobile>
                  <ChevronSmallIcon {...{ color: 'secondary0', rotate: 180 }} />
                  <Typography {...{ component: 'span', color: 'secondary0', variant: 'caption' }}>Back</Typography>
                </BackButtonMobile>
                <BackButtonDesktop {...{ src: roundButtonSecondary }} />
              </BackButtonWrap>
            </div>
          </MaxWidthContainer>
          <Avatar {...{ src: steph }} />

          <AnimatePresence
            {...{
              exitBeforeEnter: true,
            }}
          >
            {stepToShow && (
              <AnimationContainer
                {...{
                  height: animationContainerHeight,
                  key: currentStepKey,
                  initial: {
                    x: 50,
                    opacity: 0,
                  },
                  animate: {
                    x: 0,
                    opacity: 1,
                  },
                  exit: {
                    x: -50,
                    opacity: 0,
                  },
                  transition: {
                    type: 'tween',
                    duration: 0.3,
                    ease: 'easeOut',
                  },
                }}
              >
                {stepToShow}
                {buttons && <ButtonsWrap>{buttons}</ButtonsWrap>}
              </AnimationContainer>
            )}
          </AnimatePresence>
        </MainContainer>
      </StyledPaddingContainer>
      {backgroundImage && <BackgroundImage {...{ src: backgroundImage?.filename, alt: backgroundImage?.alt }} />}
    </div>
  );
};

export default FunnelStepContainer;
