import React from 'react';
import { Hero } from '../components/Hero';
import { ChooseMarble } from '../components/ChooseMarble';
import { Timeline } from '../components/Timeline';
import { Banner } from '../components/Banner';
import { Faq } from '../components/Faq';
import { MovesYouForward } from '../components/MovesYouForward';
import { PageProps } from './page.types';
import { Testimonials, Container, QuotationMark } from '../components/Testimonials';

export const DefaultPage = ({ pageContent: content, isDesktop }: PageProps) => {
  return (
    <>
      <Hero pageContent={content} isDesktop={isDesktop} />
      <ChooseMarble pageContent={content} isDesktop={isDesktop} />
      <Timeline pageContent={content} isDesktop={isDesktop} />
      <Container background={'dark100'}>
        <QuotationMark />
        <Testimonials pageContent={content} isDesktop={isDesktop} />
      </Container>
      <Banner pageContent={content} isDesktop={isDesktop} />
      <Faq pageContent={content} isDesktop={isDesktop} />
      <MovesYouForward pageContent={content} isDesktop={isDesktop} />
    </>
  );
};
