import { H3, P2 } from '@marbletech/components';
import { spacings, theme } from '@marbletech/theme';
import styled from 'styled-components';
import { Section as BaseSection } from '../../shared';

export const SectionContainer = styled(BaseSection)`
  background: ${({
    theme: {
      global: { colors },
    },
  }) => colors.green100};
  padding-top: 88px;
  padding-bottom: 88px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: 56px;
    padding-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled(H3)`
  padding-top: ${spacings[4]};
  padding-bottom: ${spacings[5]};
  white-space: pre-wrap;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${spacings[3]};
    padding-bottom: ${spacings[4]};
    white-space: normal;
  }
`;

export const Text = styled(P2)`
  white-space: pre-wrap;
  padding-bottom: ${spacings[5]};

  @media (max-width: ${theme.breakpoints.md}px) {
    white-space: normal;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding-left: 78px;
  flex-shrink: 0;
  max-width: 700px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 0 0 56px 0;
  }
`;

export const Img = styled.img`
  display: block;
`;
