import * as React from 'react';
import { navigate, PageProps } from 'gatsby';
import { FunnelComponent, StepsDescription } from '@marble/website/src/components/FunnelRebrand';
import { FunnelContent, FunnelProps } from './FunnelRebrand.types';
import { FunnelRebrandAbTest } from './FunnelRebrandAbTest';
import { FEATURE_FLAG_FUNNELS_AA_TEST, useFeatureFlag } from '@marble/website';
import { useStoryblokAbTesting } from '../../hooks/useStoryblokAbTesting';
import { GreenFunnelABTestOverrides } from './Delete_After_AB_test__FunnelRebrand_Green';

export const Funnel: React.FunctionComponent<PageProps<FunnelProps>> = ({ data }) => {
  const content: FunnelContent = data.pageContent;
  const {
    steps = [],
    backgroundImage,
    practiceArea,
    calendlyLink,
    privacyNote,
    learnMoreText,
    learnMoreLink,
    isFullFunnel,
    notifyBerforeExit,
    ABTesting,
    isReviewsWidgetEnabled = false,
    campaignToPhoneData = {},
  } = content || {};

  useFeatureFlag(FEATURE_FLAG_FUNNELS_AA_TEST);
  const { isAssignedAbTestValue, abTestData } = useStoryblokAbTesting({ ABTesting });

  const children = (
    <FunnelComponent
      navigate={navigate}
      steps={steps.reduce((acc: Record<string, StepsDescription>, step: StepsDescription) => {
        return {
          ...acc,
          [step.key]: step,
        };
      }, {})}
      backgroundImage={backgroundImage}
      practiceArea={practiceArea}
      category={practiceArea}
      calendlyLink={calendlyLink}
      privacyNote={privacyNote}
      learnMoreLink={learnMoreLink}
      learnMoreText={learnMoreText}
      isFullFunnel={isFullFunnel}
      notifyBerforeExit={notifyBerforeExit}
      isReviewsWidgetEnabled={isReviewsWidgetEnabled}
      campaignToPhoneData={campaignToPhoneData}
    />
  );

  return (
    <>
      <GreenFunnelABTestOverrides />
      {isAssignedAbTestValue ? <FunnelRebrandAbTest abTestData={abTestData}>{children}</FunnelRebrandAbTest> : children}
    </>
  );
};

export default Funnel;
