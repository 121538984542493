import React from 'react';
import { useWindowSize } from '@marble/utils';
import { theme } from '@marble/theme';
import { P1, P1Bold } from '@marbletech/components';
import { LowerText } from './PracticeAnswer.styled';
import { PracticeAnswerProps } from './PracticeAnswer.types';
import { Collapsible } from 'grommet';

export const PracticeAnswer: React.FC<PracticeAnswerProps> = (props) => {
  const { label, isChecked, isHovered } = props;

  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;
  const isActive = isDesktop ? isHovered : isChecked;
  const Title = isActive ? P1Bold : P1;

  return (
    <div>
      <Title>{label.title}</Title>
      <Collapsible open={!!isActive}>
        <LowerText>{label.text}</LowerText>
      </Collapsible>
    </div>
  );
};

export default PracticeAnswer;
