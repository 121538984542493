export const getItem = (key: string) => {
  if (typeof window !== 'undefined') {
    const value = window?.localStorage.getItem(key);
    window?.FS?.log(`getItem key: ${key} value: ${value}`);
    return value;
  } else {
    return null;
  }
};

export const setItem = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    window?.FS?.log(`setItem key: ${key} value: ${value}`);
    return window?.localStorage.setItem(key, value);
  } else {
    return null;
  }
};

export const setIfNotPresent = (key: string, value: string) => {
  const item = getItem(key);

  if (!item) {
    setItem(key, value);
  }
};
