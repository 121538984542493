import React, { FunctionComponent } from 'react';
import { useWindowSize } from '@marbletech/hooks';
import { LandingPageProps } from './LandingPage.types';
import { Container } from './LandingPage.styled';
import { EmploymentPage, FreeTaxPage, Payments, Reviews, TaxPage } from './pages';
import { theme } from '@marbletech/theme';
import { useSetGeolocationState } from './hooks/useSetGeolocationState';
import { EventName, eventService } from '../../services/eventService';
import { DefaultPageAbTest } from './DELETE_WHEN_BRAND_AB_TEST_DONE/BrandAbTestDefaultPage';
import { useBrandAbTest } from './DELETE_WHEN_BRAND_AB_TEST_DONE/useBrandAbTest';

const pageMap: Record<string, FunctionComponent<any>> = {
  employment: EmploymentPage,
  tax: TaxPage,
  'free-tax': FreeTaxPage,
  payments: Payments,
  reviews: Reviews,
};

export const LandingPage = ({ data: { pageContent: content } }: LandingPageProps) => {
  const [isBrandTestOn, isBrandTestReady] = useBrandAbTest();
  const { stateInfo, h5: service } = content;
  const { page_type } = content;
  const { width } = useWindowSize({ width: 0, height: 0 });
  const isDesktop = width > theme.breakpoints.md;

  const PageComponent = pageMap[page_type] || DefaultPageAbTest;

  useSetGeolocationState(stateInfo);

  React.useEffect(() => {
    if (!isBrandTestReady) return;
    // TEMP SOLUTION - there is a render issue with the plugin were working with on gatsby, it fires the event before the context in segment is updated and we cant control when segment updates their context
    const id = setTimeout(() => {
      eventService.triggerEvent({
        eventName: EventName.OnlinefunnelServicePageView,
        eventData: {
          service,
          isGreenSchemeABTest: !!isBrandTestOn,
        },
      });
    }, 500);

    return () => clearTimeout(id);
  }, [isBrandTestReady]);

  return (
    <Container>
      <PageComponent pageContent={content} isDesktop={isDesktop} />
    </Container>
  );
};

export default LandingPage;
