import React from 'react';
import { DefaultFooter } from './variants/default-footer';
import { FooterPageProps } from './footer.types';
import { NotFoundFooter } from './variants/404-footer';
import { LinkProps } from '@marble/website/src/components/Link/interfaces';
import Link from '@marble/website/src/components/Link';
import { eventService } from '@marble/website/src/utils';
import { Host } from '../../types/storyblok';
import { PageComponent } from '../../types/page-component';

const getFooterComponent = ({ pageComponent }: { pageComponent: PageComponent }) => {
  switch (pageComponent) {
    case PageComponent.notFound:
      return NotFoundFooter;
    case PageComponent.funnel:
    case PageComponent.conditionalFunnel:
      return null;
    default: {
      return DefaultFooter;
    }
  }
};

export const FooterContainer = ({ footerContent, pageComponent }: Partial<FooterPageProps>) => {
  const Footer = getFooterComponent({ pageComponent });

  if (!Footer) {
    return null;
  }

  return (
    <Footer
      footerContent={footerContent}
      LinkComponent={({ to, host, children, className, onClick }: LinkProps & { host: Host }) => {
        const Component = host === Host.MainWebsite || to === '/' ? Link : 'a';

        return (
          <Component className={className} to={to} href={to} onClick={onClick}>
            {children}
          </Component>
        );
      }}
      triggerEvent={eventService.triggerEvent}
    />
  );
};
