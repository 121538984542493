import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@marbletech/components';
import { Theme } from '@marble/theme';
import { ChevronSmallIcon } from '@marble/icons';
import { navigate } from 'gatsby';
import YourLegalNeedsCardMobile from './YourLegalNeedsCardMobile';
import YourLegalNeedsCardDesktop from './YourLegalNeedsCardDesktop';
import { Image, useNavigation } from '../../context/Context';
import { motion } from 'framer-motion';

export interface YourLegalNeedsProps {
  title: string;
  buttonText: string;
  buttonLink: string;
  cardsData: Array<{ title: string; text: string; img: Image }>;
}

export const Container = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const TopContainer = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const TitleWrap = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 680,
  marginBottom: theme.gutters.base * 4,
}));

export const SectionTitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  fontFamily: theme.typography.fontFamily[1],
  ...theme.typography.meta.h3,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h2,
    textAlign: 'center',
  },
}));

export const TextButton = styled(motion.button)<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.main.primary,
  ...theme.typography.meta.p2Bold,
  fontFamily: theme.typography.fontFamily[0],
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  '& svg': {
    marginLeft: theme.gutters.base * 1,
  },
}));

export const CarouselWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 7,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const CardsContainerMobile = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 7,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const CardsContainerDesktop = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    marginTop: theme.gutters.base * 9,
    overflowX: 'initial',
  },
}));

export const CardWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 6,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: 0,
  },
}));

export const containerStaggerChildrenAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export const staggeredItemAnimation = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      ease: 'easeOut',
      stiffness: 0,
    },
  },
};

export const YourLegalNeeds: React.FunctionComponent<YourLegalNeedsProps> = ({
  title,
  buttonText,
  buttonLink,
  cardsData,
}) => {
  const { linkPrefix } = useNavigation();
  const topContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isShownTopContainer, setIsShownTopContainer] = React.useState(false);
  const [topContainerY, setTopContainerY] = React.useState(1000);

  const handleScroll = () => {
    setScrollY(window.pageYOffset);
    topContainerRef.current && setTopContainerY(topContainerRef.current.getBoundingClientRect().y);

    if (typeof window !== 'undefined') {
      if (topContainerY < window.innerHeight * 0.865) {
        setIsShownTopContainer(true);
      }
    }
  };

  React.useEffect(() => {
    topContainerRef.current && setTopContainerY(topContainerRef.current.getBoundingClientRect().y);
    window.addEventListener('scroll', () => handleScroll());

    if (isShownTopContainer) {
      return window.removeEventListener('scroll', () => handleScroll());
    }
    return window.removeEventListener('scroll', () => handleScroll());
  }, [scrollY]);

  const handleClick = () => {
    navigate(linkPrefix + buttonLink);
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <TopContainer
        {...{
          variants: containerStaggerChildrenAnimation,
          initial: 'hidden',
          animate: isShownTopContainer ? 'show' : 'hidden',
          ref: topContainerRef,
        }}
      >
        <TitleWrap
          {...{
            variants: staggeredItemAnimation,
          }}
        >
          <SectionTitle>{title}</SectionTitle>
        </TitleWrap>
        <TextButton
          {...{
            onClick: handleClick,
            variants: staggeredItemAnimation,
          }}
        >
          {buttonText} <ChevronSmallIcon {...{ color: 'primary' }} />
        </TextButton>
      </TopContainer>
      <CardsContainerMobile>
        {cardsData?.map(({ title, text, img }, ind) => (
          <CardWrap
            {...{
              key: ind,
            }}
          >
            <YourLegalNeedsCardMobile
              {...{
                number: `0${ind + 1}`,
                title,
                text,
                imgSrc: img?.filename ?? '',
                imgAlt: img?.alt ?? '',
                imgPosition: ind % 2 === 0 ? 'right' : 'left',
              }}
            />
          </CardWrap>
        ))}
      </CardsContainerMobile>

      <CardsContainerDesktop>
        {cardsData?.map(({ title, text, img }, ind) => (
          <CardWrap
            {...{
              key: ind,
            }}
          >
            <YourLegalNeedsCardDesktop
              {...{
                number: `0${ind + 1}`,
                title,
                text,
                imgSrc: img?.filename ?? '',
                imgAlt: img?.alt ?? '',
                imgPosition: ind % 2 === 0 ? 'right' : 'left',
              }}
            />
          </CardWrap>
        ))}
      </CardsContainerDesktop>
    </Container>
  );
};

export default YourLegalNeeds;
