import React from 'react';
import { Box } from 'grommet';
import { render } from 'storyblok-rich-text-react-renderer';
import { spacings } from '@marbletech/theme';
import { DefaultFAQElem } from '@marbletech/components';
import { EventName } from '@marbletech/services';
import { eventService } from '@marble/website/src/utils';
import { FaqProps } from './Faq.types';
import { ContentContainer } from '../../../layouts/LandingPage/components/ContentContainer';
import { Container, Text } from './Faq.styled';
import { Title } from '../../../layouts/LandingPage/components/Title';

export const Faq: React.FC<FaqProps> = (props) => {
  const { fold6_h2: title, Faq: faq, isDesktop, ctaLink } = props;

  const handleItemClick = ({ isOpen, title }: { isOpen: boolean; title: string }) => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelClickOnFaq,
      eventData: {
        item_key: title,
        item_text: title,
        action: isOpen ? 'expand' : 'collapse',
      },
    });
  };

  return (
    <Container>
      <ContentContainer>
        <Title margin={{ bottom: isDesktop ? spacings[15] : spacings[7], top: '0' }} fill>
          {title}
        </Title>
        <Box width={'100%'}>
          {faq?.map(({ p2: text, subtitle2: title }, index) => (
            <DefaultFAQElem
              key={`${index}-${title}`}
              title={title}
              onItemClicked={({ isOpen }) => handleItemClick({ isOpen, title })}
            >
              <Text>{render(text)}</Text>
            </DefaultFAQElem>
          ))}
        </Box>
      </ContentContainer>
    </Container>
  );
};
