import {
  FAQ,
  KnowYourPrice,
  logFullStoryEvent,
  MarbleGuides,
  MaxWidthContainer,
  PaddingContainer,
  SectionSeparator,
  Testimonials,
  VideoBlock,
  OurLawyersStoryblok,
  RelatedPractices,
  AvailableStates,
  OnTheCase,
} from '@marble/website';
import { useNavigation } from '@marble/website/src/context/Context';
import { graphql, PageProps, navigate } from 'gatsby';
import * as React from 'react';
import FunnelDescription, { FunnelDescriptionStep } from '../components/FunnelDescription/FunnelDescription';
import HeroGeneral from '../components/HeroGeneral/HeroGeneral';
import { ContentItem, StoryblokImage } from '../storyblok-defs';

export interface FamilyProps {
  pageContent: Content;
}

interface Content extends ContentItem, OurLawyersStoryblok {
  title: string;
  subtitle: string;
  heroButtonText: string;
  heroImageMobile: StoryblokImage;
  heroImageDesktop: StoryblokImage;

  funnelDescription_title: string;
  funnelDescription_intro: string;
  funnelDescriptionSteps: Array<
    {
      item: Array<Omit<FunnelDescriptionStep[0], 'id'> & ContentItem>;
    } & ContentItem
  >;
  transparentPricingItems: Array<{ image: StoryblokImage; text: string; title: string } & ContentItem>;
  transparentPricingTitle: string;

  howToPay_title: string;
  howToPay_intro: string;
  howToPay_cards: Array<
    {
      title: string;
      text: string;
      image: StoryblokImage;
    } & ContentItem
  >;

  marbleGuides_title: string;
  marbleGuides_buttonText: string;
  marbleGuides_moreGuidesLink: string;
  marbleGuides_guides: Array<
    {
      type: 'Blog' | 'Checklist' | 'Q&A' | 'Tools';
      title: string;
      excerpt: string;
      icon: string;
      link: string;
    } & ContentItem
  >;

  testimonials_title: string;
  testimonials_funnelLink: string;
  testimonials_buttonText: string;
  testimonials_items: Array<{ name: string; location: string; text: string } & ContentItem>;

  faq_title: string;
  faq_items: Array<{ summary: string; details: string }>;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;

  marbleGuides_readMore: string;

  buildingBlocks: Array<{ key: BuildingBlockKey } & ContentItem>;

  videoBlock_title: string;
  videoBlock_subtitle: string;
  videoBlock_videoId: string;
  videoBlock_videoTitle: string;
  videoBlock_videoSubtitle: string;
  videoBlock_buttonText: string;
  videoBlock_buttonLink: string;

  relatedPractices_title: string;
  relatedPractices_items: Array<{ link: string; title: string } & ContentItem>;

  availableStates_title: string;
  availableStates_items: Array<{ img: StoryblokImage; link: string; title: string } & ContentItem>;

  onTheCase_title: string;
  onTheCase_items: Array<{ image: StoryblokImage; text: string } & ContentItem>;
}

type BuildingBlockKey =
  | 'hero'
  | 'funnelDescription'
  | 'testimonials'
  | 'videoBlock'
  | 'knowYourPrice'
  | 'marbleGuides'
  | 'relatedPractices'
  | 'availableStates'
  | 'faq';

const componentMap: (
  content: Content,
  steps: { id: string; icon: string; title: string; description: string }[],
) => Record<BuildingBlockKey, React.ReactNode> = (content, steps) => {
  const { title, subtitle, heroImageMobile, heroImageDesktop } = content;
  const { linkPrefix } = useNavigation();

  return {
    hero: (
      <HeroGeneral
        {...{
          title,
          subtitle,
          buttonText: content.heroButtonText,
          bgSrcMobile: heroImageMobile,
          bgSrcDesktop: heroImageDesktop,
          isBgCover: true,
          onStartHere: () => navigate(linkPrefix + content.funnelLink),
        }}
      />
    ),

    funnelDescription: (
      <>
        <PaddingContainer>
          <MaxWidthContainer>
            <FunnelDescription
              {...{ title: content.funnelDescription_title, intro: content.funnelDescription_intro, steps }}
            />
          </MaxWidthContainer>
        </PaddingContainer>
        <SectionSeparator />
      </>
    ),

    testimonials: (
      <Testimonials
        {...{
          title: content.testimonials_title,
          testimonials: content.testimonials_items,
          buttonText: content.testimonials_buttonText,
          funnelLink: content.testimonials_funnelLink,
        }}
      />
    ),
    onTheCase: (
      <MaxWidthContainer>
        <OnTheCase {...{ title: content.onTheCase_title, items: content.onTheCase_items }} />
        <SectionSeparator />
      </MaxWidthContainer>
    ),
    videoBlock: (
      <>
        <PaddingContainer>
          <MaxWidthContainer>
            <VideoBlock
              {...{
                title: content.videoBlock_title,
                subtitle: content.videoBlock_subtitle,
                buttonText: content.videoBlock_buttonText,
                youTubeVideoId: content.videoBlock_videoId,
                videoTitle: content.videoBlock_videoTitle,
                videoSubTitle: content.videoBlock_videoSubtitle,
                buttonLink: content.videoBlock_buttonLink,
              }}
            />
          </MaxWidthContainer>
        </PaddingContainer>
        <SectionSeparator />
      </>
    ),
    relatedPractices: (
      <PaddingContainer>
        <MaxWidthContainer>
          <RelatedPractices
            {...{
              title: content.relatedPractices_title,
              practices: content.relatedPractices_items,
            }}
          />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    availableStates: (
      <PaddingContainer>
        <MaxWidthContainer>
          <AvailableStates
            {...{
              title: content.availableStates_title,
              data: content.availableStates_items,
            }}
          />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    knowYourPrice: (
      <>
        <PaddingContainer>
          <MaxWidthContainer>
            <KnowYourPrice
              {...{
                title: content.howToPay_title,
                intro: content.howToPay_intro,
                cards: content.howToPay_cards,
              }}
            />
          </MaxWidthContainer>
        </PaddingContainer>
        <SectionSeparator />
      </>
    ),
    marbleGuides: (
      <>
        <MaxWidthContainer>
          <MarbleGuides
            {...{
              title: content.marbleGuides_title,
              guides: content.marbleGuides_guides.map((guide) => ({
                ...guide,
                id: guide._uid,
              })),
              buttonText: content.marbleGuides_buttonText,
              moreGuidesLink: content.marbleGuides_moreGuidesLink,
              readMoreText: content.marbleGuides_readMore,
            }}
          />
        </MaxWidthContainer>
        <SectionSeparator />
      </>
    ),
    faq: (
      <>
        <MaxWidthContainer>
          <PaddingContainer>
            <FAQ {...{ title: content.faq_title, faqs: content.faq_items }} />
          </PaddingContainer>
        </MaxWidthContainer>
        <SectionSeparator />
      </>
    ),
  };
};

export const ServicePage: React.FunctionComponent<PageProps<FamilyProps>> = ({ data }) => {
  const content: Content = data.pageContent;

  const { funnelDescriptionSteps, buildingBlocks = [] } = content;

  const steps = funnelDescriptionSteps.map(({ item }) => {
    return item.map((step) => {
      return {
        id: step._uid,
        icon: step.icon,
        title: step.title,
        description: step.description,
      };
    });
  });

  React.useEffect(() => {
    logFullStoryEvent({ eventName: 'LP_SP' });
  }, []);

  return (
    <>
      {buildingBlocks.map((block) => {
        return <React.Fragment key={block._uid}>{componentMap(content, steps)[block.key]}</React.Fragment>;
      })}
    </>
  );
};

export default ServicePage;
