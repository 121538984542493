import React from 'react';
import { SectionContainer, Content, Title, Text } from './GetStarted.styled';
import { GetStartedProps } from './GetStarted.types';
import { H5 } from '@marbletech/components';

export const GetStarted = ({ pageContent, isDesktop }: GetStartedProps) => {
  const { h3: title, p1: text, h5: getStartedText } = pageContent;

  return (
    <SectionContainer>
      <Content>
        <Title color={'bright'} fill>
          {title}
        </Title>
        <Text color={'bright'}>{text}</Text>
        <H5 color={'bright'}>{getStartedText}</H5>
      </Content>
    </SectionContainer>
  );
};

export default GetStarted;
