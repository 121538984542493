import styled from 'styled-components';
import { Theme } from '@marble/theme';

export const TextFieldGrid = styled('div')<{ theme: Theme; isDesktopFieldsColumn?: boolean }>(
  ({ theme, isDesktopFieldsColumn }) => ({
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fill, max-content)',
    gridRowGap: theme.gutters.base * 6,
    width: '100%',
    marginBottom: theme.gutters.base * 6,
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'repeat(2, max-content)',
      ...(isDesktopFieldsColumn && {
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'repeat(auto-fill, max-content)',
        maxWidth: 448,
      }),
      gridColumnGap: theme.gutters.base * 2.5,
    },
  }),
);
