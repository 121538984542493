import * as React from 'react';
import { TextField } from '@marbletech/components';
import { FunnelTitle } from '../FunnelTitle/FunnelTitle';
import { FunnelSubtitle } from '../FunnelSubtitle/FunnelSubtitle';
import { object, string } from 'yup';
import { splitName } from '../../utils/split-name/split-name';
import { Container } from '../shared-funnel/Container';
import { ButtonWrap } from '../shared-funnel/ButtonWrap';
import { StyledForm } from '../shared-funnel/StyledForm';
import { ButtonWithChevron } from '../shared-funnel/ButtonWithChevron';
import { FEATURE_FLAG_FUNNEL_PERSONAL_DETAILS_SHOW_PHONE_FIELD, useFeatureFlag } from '../../context/split';
import { eventService, EventName } from '../../utils';
import { TextFieldGrid } from '../TextFieldGrid';

export enum FieldKeys {
  name = 'name',
  email = 'email',
  phone = 'phone',
}

export interface PersonalDetailsFormProps {
  title: string;
  subtitle?: string;
  fields: Array<{
    key: FieldKeys;
    placeholder: string;
  }>;
  buttonText: string;
  handleSubmit: (data: { email: string; firstName: string; lastName: string; phone: string }) => void;
}

const getValidationSchema = (showPhoneField: boolean) => {
  const schema = object().shape({
    name: string().required().min(3),
    email: string().email().required(),
  });

  return showPhoneField
    ? schema.shape({
        phone: string().min(9).required(),
      })
    : schema;
};

const normalizeFieldValue = {
  email: (value = '') => (value || '').trim(),
  name: (value = '') => value,
  phone: (value = '') => value,
};

export const PersonalDetailsForm: React.FunctionComponent<PersonalDetailsFormProps> = ({
  title,
  subtitle,
  fields,
  buttonText,
  handleSubmit,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = React.useState<boolean>(false);
  const [fieldsData, setFieldsData] = React.useState({
    name: '',
    email: '',
    phone: '',
  });

  const showPhoneField = useFeatureFlag(FEATURE_FLAG_FUNNEL_PERSONAL_DETAILS_SHOW_PHONE_FIELD);

  const filteredFields = fields.filter((field) => {
    // TODO: delete once FEATURE_FLAG_FUNNEL_PERSONAL_DETAILS_SHOW_PHONE_FIELD a/b test is killed
    return field?.key === 'phone' ? showPhoneField : true;
  });

  React.useEffect(() => {
    eventService.triggerEvent({ eventName: EventName.OnlineFunnelContactInfoPageView });
  }, []);

  React.useEffect(() => {
    const validationSchema = getValidationSchema(showPhoneField);

    validationSchema.isValid(fieldsData).then((valid) => {
      setIsButtonEnabled(valid);
    });
  }, [fieldsData]);

  const updateField = (key: FieldKeys) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const normalize = normalizeFieldValue?.[key] ?? ((value) => value);

    const value = normalize(e.target.value);

    setFieldsData({
      ...fieldsData,
      [key]: value,
    });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { firstName, lastName } = splitName(fieldsData.name);
    handleSubmit({
      email: fieldsData.email,
      phone: fieldsData.phone,
      firstName,
      lastName,
    });
  };

  return (
    <Container>
      <FunnelTitle>{title}</FunnelTitle>
      {subtitle && <FunnelSubtitle>{subtitle}</FunnelSubtitle>}
      <StyledForm
        {...{
          isContentCenteredDesktop: true,
          onSubmit,
        }}
      >
        <TextFieldGrid {...{ isDesktopFieldsColumn: true }}>
          {filteredFields.map((field) => (
            <TextField
              {...{
                key: field.key,
                value: fieldsData[field.key],
                onChange: updateField(field.key),
                placeholder: field.placeholder,
              }}
            />
          ))}
        </TextFieldGrid>
        <ButtonWrap>
          <ButtonWithChevron
            {...{
              text: buttonText,
              disabled: !isButtonEnabled,
              onSubmit,
            }}
          />
        </ButtonWrap>
      </StyledForm>
    </Container>
  );
};

export default PersonalDetailsForm;
