import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface NegotiateProps extends SVGIconProps {}

export const NegotiateIcon: React.FunctionComponent<NegotiateProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M24 0v24h-1c-5.572 0-10-5.412-10-12S17.428 0 23 0h1zM1 0c5.572 0 10 5.412 10 12S6.572 24 1 24H0V0h1zm1 2.078v19.843l.15-.025C5.989 21.2 9 17.066 9 12c0-5.066-3.012-9.199-6.85-9.896L2 2.078z"
      />
    </SVGIcon>
  );
};
