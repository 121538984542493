import * as React from 'react';
import styled from 'styled-components';
import { theme, Theme } from '@marbletech/theme';
import { flexBetweenCenter, flexCenterCenter } from '@marble/style-utils';
import { Button, Typography } from '@marbletech/components';
import {
  ApprovedIcon,
  CourseIcon,
  CourtIcon,
  DetermineIcon,
  InterviewIcon,
  MeetingIcon,
  NegotiateIcon,
  PaperworkIcon,
  ResearchIcon,
  StrategyIcon,
  TrialIcon,
  UpdatesIcon,
} from '@marble/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';

export interface FunnelDescriptionSingleThreadProps {
  title?: string;
  intro?: string;
  steps: FunnelDescriptionSingleThreadStep[];
  buttonText?: string;
  buttonLink?: string;
}

export type FunnelDescriptionSingleThreadStep = Array<{
  id: string;
  icon: React.ReactNode;
  title: string;
  description: string;
}>;

function mapIconKeyToComponent(iconKey: string) {
  const icons = {
    research: <ResearchIcon />,
    court: <CourtIcon />,
    meeting: <MeetingIcon />,
    trial: <TrialIcon />,
    approved: <ApprovedIcon />,
    determine: <DetermineIcon />,
    paperwork: <PaperworkIcon />,
    negotiate: <NegotiateIcon />,
    strategy: <StrategyIcon />,
    course: <CourseIcon />,
    interview: <InterviewIcon />,
    updates: <UpdatesIcon />,
  };

  return icons[iconKey];
}

export const FunnelDescriptionContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    maxWidth: '90%',
  },
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h3,
  fontFamily: theme.typography.fontFamily[1],
  fontWeight: 600,
  textAlign: 'center',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h2,
    textAlign: 'initial',
    maxWidth: 530,
  },
}));

export const Intro = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  marginTop: theme.gutters.base * 4,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
    maxWidth: 740,
  },
}));

export const Grid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  marginTop: theme.gutters.base * 8,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 12,
  },
}));

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.gutters.base * 2,
  height: 'auto',
  width: '100%',
  position: 'relative',
  marginBottom: theme.gutters.base * 3,
  '&::after': {
    content: "''",
    backgroundColor: theme.palette.main.secondary2,
    position: 'absolute',
    zIndex: -1,
    width: 2,
    height: '100%',
    left: 31,
    transform: 'none',
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    height: 'initial',
    maxWidth: theme.gutters.base * 120,
    '&::after': {
      content: "''",
      backgroundColor: theme.palette.main.secondary2,
      position: 'absolute',
      top: 33,
      width: '90%',
      height: 2,
      left: '45%',
      transform: 'translateX(-50%)',
    },
  },
}));

export const InfoContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'initial',
    borderLeft: `2px solid ${theme.palette.main.primary}`,
    padding: theme.gutters.base * 3,
    paddingTop: theme.gutters.base * 0.5,
    width: '85%',
    maxWidth: 600,
    height: 160,
  },
}));

export const StepsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexBetweenCenter,
  flexDirection: 'column',
  alignItems: 'start',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    flexDirection: 'row',
  },
}));

export const StepContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'start',
  flexDirection: 'column',
  position: 'relative',
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 9,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    justifyContent: 'start',
    '&::after': {
      backgroundColor: 'transparent',
    },
  },
}));

export const StepIcon = styled('div')<{ theme: Theme; hoveredOn: boolean; isExpanded: boolean }>(
  ({ theme, hoveredOn, isExpanded }) => ({
    borderRadius: '50%',
    width: 64,
    height: 64,
    ...flexCenterCenter,
    backgroundColor: isExpanded ? theme.palette.main.primary : theme.palette.main.secondary3,
    '& svg': {
      fill: isExpanded ? theme.palette.background.site : theme.palette.main.secondary0,
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      backgroundColor: hoveredOn ? theme.palette.main.primary : theme.palette.main.secondary3,
      '& svg': {
        fill: theme.palette.main.secondary0,
      },
      ...(hoveredOn && {
        '& svg': {
          fill: theme.palette.background.site,
        },
      }),
    },
  }),
);

export const StepTitle = styled(Typography)<{
  theme: Theme;
  hoveredOn: boolean;
  isExpanded: boolean;
}>(({ theme, hoveredOn, isExpanded }) => {
  return {
    ...theme.typography.meta.p1SemiBold,
    backgroundColor: 'white',
    paddingBottom: theme.gutters.base * 0.5,
    paddingTop: theme.gutters.base * 1,
    color: isExpanded ? theme.palette.main.dark : theme.palette.main.secondary1,
    maxWidth: 310,
    ...theme.typography.meta.h4,
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      ...theme.typography.meta.p1SemiBold,
      maxWidth: 160,
      color: hoveredOn ? theme.palette.main.primary : theme.palette.main.secondary1,
    },
  };
});

export const StepBodyText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  backgroundColor: theme.palette.main.bright,
  padding: `${theme.gutters.base * 1.5}px 0`,
  maxWidth: 350,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const Step: React.FunctionComponent<{
  step: FunnelDescriptionSingleThreadStep[0];
  onSelection: () => void;
  position?: -1 | 0 | 1;
  hoveredOn: boolean;
}> = ({ step, onSelection, hoveredOn, position }) => {
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const stepRef = React.useRef<HTMLDivElement>(null);
  const [stepY, setStepY] = React.useState(0);

  const handleScroll = () => {
    setScrollY(window.pageYOffset);
    setStepY(stepRef.current && stepRef.current.getBoundingClientRect().y);
    if (stepY < 450) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  React.useEffect(() => {
    setStepY(stepRef.current && stepRef.current.getBoundingClientRect().y);
    window.addEventListener('scroll', () => handleScroll());

    return window.removeEventListener('scroll', () => handleScroll());
  }, [scrollY]);

  return (
    <StepContainer {...{ position, onMouseEnter: onSelection, ref: stepRef }}>
      <StepIcon {...{ hoveredOn, isExpanded }}>{mapIconKeyToComponent(step.icon as string)}</StepIcon>
      <StepTitle {...{ variant: 'p1', hoveredOn, position, isExpanded }}>{step.title}</StepTitle>
      <AnimatePresence>
        {isExpanded && step.description && (
          <motion.div
            {...{
              key: 'content',
              initial: 'collapsed',
              animate: 'open',
              exit: 'collapsed',
              variants: {
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              },
              transition: { duration: 2.5, ease: [0.04, 0.62, 0.23, 0.98] },
            }}
          >
            <StepBodyText>{step.description}</StepBodyText>
          </motion.div>
        )}
      </AnimatePresence>
    </StepContainer>
  );
};

export const MultiStepContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  border: `2px solid ${theme.palette.main.secondary2}`,
  zIndex: 1,
  paddingRight: 98,
  paddingLeft: 73,
  backgroundColor: 'white',
  width: 0,
  minHeight: 328,
  position: 'relative',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'grid',
    gap: 100,
    gridTemplateColumns: '1fr',
    paddingRight: 112,
    paddingLeft: 113,
    width: 'initial',
    minHeight: 'initial',
    height: 222,
  },
}));

export const OrWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.main.secondary2}`,
  backgroundColor: 'white',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 101,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    left: 0,
    transform: 'translateX(-54%)',
    top: 93,
  },
}));

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 320,
  margin: '0 auto',
  marginTop: theme.gutters.base * 6,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const MultiStep: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <MultiStepContainer>
      <div
        {...{
          style: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 100,
          },
        }}
      >
        {React.Children.map(children, (child, ind) => {
          return React.cloneElement(child as React.ReactElement, {
            position: ind % 2 === 0 ? -1 : 1,
          });
        })}
      </div>
      <OrWrap>
        <Typography {...{ variant: 'p1', color: 'secondary1', center: true, marginTop: 2 }}>or</Typography>
      </OrWrap>
    </MultiStepContainer>
  );
};

export const FunnelDescription: React.FunctionComponent<FunnelDescriptionSingleThreadProps> = ({
  title,
  intro,
  steps,
  buttonText,
  buttonLink,
}) => {
  const [itemToShow, setItemToShow] = React.useState<[number, number]>([0, 0]);
  const onSelection = (first: number) => (second: number) => () => {
    setItemToShow([first, second]);
  };

  return (
    <FunnelDescriptionContainer>
      <Title>{title}</Title>
      <Intro>{intro}</Intro>
      <Grid>
        <Container>
          <StepsContainer>
            {steps.map((step, ind) => {
              const isMulti = step.length > 1;

              return (
                <>
                  {isMulti ? (
                    <MultiStep {...{ key: ind }}>
                      {step.map((stp, jnd) => {
                        return (
                          <Step
                            {...{
                              key: stp.id,
                              step: stp,
                              onSelection: onSelection(ind)(jnd),
                              hoveredOn: itemToShow[0] === ind && itemToShow[1] === jnd,
                            }}
                          />
                        );
                      })}
                    </MultiStep>
                  ) : (
                    <Step
                      {...{
                        key: ind,
                        step: step[0],
                        onSelection: onSelection(ind)(0),
                        hoveredOn: itemToShow[0] === ind,
                      }}
                    />
                  )}
                </>
              );
            })}
          </StepsContainer>
        </Container>
        <InfoContainer>
          <Typography {...{ variant: 'h4', component: 'h4', marginBottom: theme.gutters.base * 2 }}>
            {steps?.[itemToShow?.[0]]?.[itemToShow?.[1]].title}
          </Typography>
          <Typography {...{ variant: 'p1SemiBold' }}>
            {steps?.[itemToShow?.[0]]?.[itemToShow?.[1]]?.description}
          </Typography>
        </InfoContainer>
      </Grid>
      {buttonLink && buttonText && (
        <ButtonWrap>
          <Link {...{ to: buttonLink }}>
            <Button {...{ size: 'large' }}>{buttonText}</Button>
          </Link>
        </ButtonWrap>
      )}
    </FunnelDescriptionContainer>
  );
};

export default FunnelDescription;
