import React from 'react';
import { Link } from 'gatsby';
import { RenderIfDesktop, RenderIfTablet } from '@marbletech/components';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { MovesYouForwardProps } from './MovesYouForward.types';
import { ContentContainer } from '../ContentContainer';
import { Container, Title, TitleMobile } from './MovesYouForward.styled';
import { Button } from '../Button';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const MovesYouForward = ({ pageContent, isDesktop }: MovesYouForwardProps) => {
  const { h1: title, fold7_cta: buttonText, cta_link: link } = pageContent;

  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Moves You Forward' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container background={'primary100'}>
      <ContentContainer>
        <RenderIfDesktop>
          <Title>{title}</Title>
        </RenderIfDesktop>
        <RenderIfTablet>
          <TitleMobile>{title}</TitleMobile>
        </RenderIfTablet>
        <Link to={getLocalizedLink({ pathname: link })}>
          <Button
            text={buttonText}
            variant={'secondary'}
            onClick={() => {
              handleCtaClick();
              triggerNewBiEvent({
                eventName: NewBiEventsOptions.WebPageCtaClick,
                eventData: {
                  location: 'Moves You Forward',
                  cta_text: buttonText,
                  click_url: link,
                },
              });
            }}
          />
        </Link>
      </ContentContainer>
    </Container>
  );
};
