import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { navigate } from 'gatsby';
import { ComplexSubItem, NavItem, SubItem } from '../MainNavigation/MainNavigation';
import MaxWidthContainer from '../../MaxWidthContainer/MaxWidthContainer';
import { motion, AnimateSharedLayout } from 'framer-motion';
import DesktopMenuItems from '../DesktopMenuItems/DesktopMenuItems';
import DesktopMenuDetails from '../DesktopMenuDetails/DesktopMenuDetails';
import { StoryblokImage } from '../../../storyblok-defs';
import { useNavigation } from '../../../context/Context';

export interface DesktopMenuContainerProps {
  title: string;
  subItems: Array<ComplexSubItem | SubItem>;
  setIsExpanded: (flag: boolean) => void;
  setExpandedMenu: (flag: NavItem) => void;
  isPickedComplex: boolean;
  setIsPickedComplex: (flag: boolean) => void;

  menuDetailsDesktop_box1_image: StoryblokImage;
  menuDetailsDesktop_box1_title: string;
  menuDetailsDesktop_box1_subtitle: string;
  menuDetailsDesktop_box1_buttonText: string;
  menuDetailsDesktop_box2_title: string;
  menuDetailsDesktop_box2_subtitle: string;
}

export const Container = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    position: 'absolute',
    top: 78,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.main.bright,
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0px ${theme.gutters.base * 2.5}px`,
  },
}));

export const MenuGrid = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridGap: '10%',
}));

export const BackgroundImage = styled('img')<{ theme: Theme; isPickedComplex: boolean }>(
  ({ theme, isPickedComplex }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: isPickedComplex ? 'block' : 'none',
    width: '100%',
  }),
);

const containerAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const menuGridAnimation = {
  open: {
    gridTemplateColumns: '24% 70%',
    transition: {
      duration: 1.2,
    },
  },
  closed: {
    gridTemplateColumns: '100% 0%',
  },
};

export const DesktopMenuContainer: React.FunctionComponent<DesktopMenuContainerProps> = ({
  title,
  subItems,
  setIsExpanded,
  setExpandedMenu,
  isPickedComplex,
  setIsPickedComplex,

  menuDetailsDesktop_box1_image,
  menuDetailsDesktop_box1_title,
  menuDetailsDesktop_box1_subtitle,
  menuDetailsDesktop_box1_buttonText,
  menuDetailsDesktop_box2_title,
  menuDetailsDesktop_box2_subtitle,
}) => {
  const { linkPrefix } = useNavigation();
  const [pickedPracticeArea, setPickedPracticeArea] = React.useState<ComplexSubItem>({
    text: '',
    icon: { filename: '', alt: '' },
    thumbIcon: { filename: '', alt: '' },
    servicePageLink: '',
    funnelLink: '',
    testimonials: [],
    backgroundImage: {
      filename: '',
      alt: '',
    },
  });
  const [isNavigatingBetweenPracticeAreas, setIsNavigatingBetweenPracticeAreas] = React.useState(false);

  const noFirstSelection = pickedPracticeArea.text !== '';

  const handleSimpleClick = (link?: string) => {
    if (link) {
      navigate(linkPrefix + link);
    }
    setIsExpanded(false);
    setExpandedMenu({ title: '', subItems: [] });
    window.scrollTo(0, 0);
  };

  return (
    <Container {...{ variants: containerAnimation, initial: 'hidden', animate: 'show' }}>
      <MaxWidthContainer>
        <AnimateSharedLayout>
          <MenuGrid
            {...{
              layout: true,
              variants: menuGridAnimation,
              initial: 'closed',
              animate: isPickedComplex ? 'open' : 'closed',
            }}
          >
            <DesktopMenuItems
              {...{
                title,
                subItems,
                isPickedComplex,
                setIsPickedComplex,
                setPickedPracticeArea,
                handleSimpleClick,
                pickedPracticeArea,
                setIsNavigatingBetweenPracticeAreas,
              }}
            />
            {noFirstSelection && (
              <DesktopMenuDetails
                {...{
                  handleSimpleClick,
                  pickedPracticeArea,
                  isNavigatingBetweenPracticeAreas,
                  menuDetailsDesktop_box1_image,
                  menuDetailsDesktop_box1_title,
                  menuDetailsDesktop_box1_subtitle,
                  menuDetailsDesktop_box1_buttonText,
                  menuDetailsDesktop_box2_title,
                  menuDetailsDesktop_box2_subtitle,
                }}
              />
            )}
          </MenuGrid>
        </AnimateSharedLayout>
      </MaxWidthContainer>
      {pickedPracticeArea.backgroundImage?.filename && (
        <BackgroundImage
          {...{
            src: pickedPracticeArea.backgroundImage.filename,
            alt: pickedPracticeArea.backgroundImage.alt,
            isPickedComplex,
          }}
        />
      )}
    </Container>
  );
};

export default DesktopMenuContainer;
