import React from 'react';
import { ImageSectionProps } from './ImageSection.types';
import { Container, Image } from './ImageSection.styled';

export const ImageSection: React.FC<ImageSectionProps> = ({ image }) => {
  const { filename: imageUrl, alt: imageAlt } = image;
  return (
    <Container>
      <Image src={imageUrl} alt={imageAlt} maxWidth={550} />
    </Container>
  );
};
