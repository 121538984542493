import React from 'react';
import { Link } from 'gatsby';
import { H2, RebrandedButton, P1 } from '@marbletech/components';
import { VIcon } from '@marble/icons';
import { WeCanHelpProps } from './WeCanHelp.types';
import { Container, Item, IconContainer, ItemsWrapper } from './WeCanHelp.styled';

export const WeCanHelp: React.FC<WeCanHelpProps> = (props) => {
  const { title, topics: items, btnText, ctaLink: link, isDesktop } = props;

  return (
    <Container>
      <H2>{title}</H2>
      <ItemsWrapper>
        {items.map(({ list_item }, index) => {
          const isTopElement = isDesktop ? index === 0 || index === 1 : index === 0;
          return (
            <Item topBorder={isTopElement} key={`${list_item} ${index}`}>
              <IconContainer>
                <VIcon color={'bright'} />
              </IconContainer>
              <P1>{list_item}</P1>
            </Item>
          );
        })}
      </ItemsWrapper>
      <Link to={link}>
        <RebrandedButton variant={'secondary'} size={'xlarge'}>
          {btnText}
        </RebrandedButton>
      </Link>
    </Container>
  );
};
