import React from 'react';
import styled from 'styled-components';
import { MARK_BOLD, NODE_PARAGRAPH, render } from 'storyblok-rich-text-react-renderer';
import { Testimonials as TestimonialsComponent, PageWidthContainer, Page } from '@marbletech/components';
import { colors, devices, spacings } from '@marbletech/theme';
import { QuotationMarkIcon } from '@marbletech/icons';
import { TestimonialsProps, HighlightTextProps } from './Testimonials.types';

const HighlightText = styled.span<HighlightTextProps>`
  color: ${({ highlightColor }) => colors[highlightColor] || colors.green100};
`;

export const Container = styled(PageWidthContainer)`
  position: relative;
  margin-top: 152px;

  ${devices.mobile} {
    margin-top: ${(props) => props.theme.gutters.base * 8}px;
  }

  & .swiper-slide {
    height: initial;
  }
`;

export const QuotationMark = styled(QuotationMarkIcon)`
  position: absolute;
  left: ${spacings[10]};
  top: ${spacings[14]};

  width: 108px;
  height: auto;
  z-index: 2;

  ${devices.desktop} {
    width: 220px;
    left: 164px;
    top: ${spacings[18]};
  }
`;

export const Testimonials: React.FC<TestimonialsProps> = ({ carousel }) => {
  return (
    <Page.Content>
      <TestimonialsComponent arrowColor={colors.dark100}>
        {carousel?.map((testimonial, index) => (
          <TestimonialsComponent.Item name={testimonial?.p2} color={'dark100'} key={`${testimonial.p2}${index}`}>
            {render(testimonial?.h4, {
              markResolvers: {
                [MARK_BOLD]: (children) => <HighlightText highlightColor={'primary100'}>{children}</HighlightText>,
              },
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children) => <>{children}</>,
              },
            })}
          </TestimonialsComponent.Item>
        ))}
      </TestimonialsComponent>
    </Page.Content>
  );
};

export default Testimonials;
