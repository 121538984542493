import * as React from 'react';
import styled from 'styled-components';
import { theme, Theme } from '@marble/theme';
import { Typography, OptimizedAssetImage } from '@marbletech/components';

export interface NavigationBoxProps {
  imgSrc: string;
  imgAlt?: string;
  title: string;
  subtitle: string;
  component: React.ReactNode;
  handleSimpleClick: (link?: string) => void;
}

export const Box = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content max-content auto',
  gridGap: theme.gutters.base * 4,
  alignItems: 'center',
  backgroundImage: `linear-gradient(81deg, #eef5f7 32%, rgba(238, 245, 247, 0.3))`,
  cursor: 'pointer',
  borderRadius: 4,
  '&:hover': {
    backgroundImage: 'none',
    backgroundColor: theme.palette.main.bright,
    boxShadow: '0 12px 32px 0 rgb(162 193 201 / 44%)',
  },
}));

const NavigationImage = styled(OptimizedAssetImage)`
  height: 120px;
  display: block;
`;

export const NavigationBox: React.FunctionComponent<NavigationBoxProps> = ({
  imgSrc,
  imgAlt,
  title,
  subtitle,
  component,
  handleSimpleClick,
}) => {
  return (
    <Box
      {...{
        onClick: () => {
          handleSimpleClick();
        },
      }}
    >
      <NavigationImage src={imgSrc} alt={imgAlt} maxWidth={120} />
      <div>
        <Typography {...{ variant: 'p2Bold', marginBottom: theme.gutters.base * 1 }}>{title}</Typography>
        <Typography {...{ variant: 'p1SemiBold' }}>{subtitle}</Typography>
      </div>
      {component}
    </Box>
  );
};

export default NavigationBox;
