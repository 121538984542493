import React from 'react';
import { Link } from 'gatsby';
import { P2, Subtitle2 } from '@marbletech/components';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { Container, ContentWrapper, Reason, ReasonsWrapper } from './ChooseMarble.styled';
import { ChooseMarbleProps } from './ChooseMarble.types';
import { Button } from '../Button';
import { Title } from '../Title';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const ChooseMarble = ({
  pageContent,
  isDesktop,
  background = 'neutral100',
  color = 'dark100',
}: ChooseMarbleProps) => {
  const { fold2_h2: title, reasons, fold2_cta: buttonText, cta_link: link } = pageContent;
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Choose Marble' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container justify={'center'} align={'center'} background={background}>
      <ContentWrapper>
        <Title color={color}>{title}</Title>
        <ReasonsWrapper desktopPadding={!!buttonText ? '48px' : '0px'}>
          {reasons?.map((reason, index) => (
            <Reason
              key={index}
              isLast={index === reasons.length - 1}
              align={'start'}
              justify={'start'}
              direction={'column'}
              pad={{ right: isDesktop ? '40px' : '0px' }}
              color={color}
            >
              {reason?.subtitle2 && (
                <Subtitle2 margin={{ bottom: isDesktop ? '24px' : '16px', top: '0px' }} color={color}>
                  {reason?.subtitle2}
                </Subtitle2>
              )}
              {reason?.p2 && <P2 color={color}>{reason?.p2}</P2>}
            </Reason>
          ))}
        </ReasonsWrapper>
        {buttonText && (
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button
              text={buttonText}
              variant={'secondary'}
              onClick={() => {
                handleCtaClick();
                triggerNewBiEvent({
                  eventName: NewBiEventsOptions.WebPageCtaClick,
                  eventData: {
                    location: 'Choose Marble',
                    cta_text: buttonText,
                    click_url: link,
                  },
                });
              }}
            />
          </Link>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ChooseMarble;
