import React from 'react';
import { Flex, PaginationCarousel } from '@marbletech/components';
import { colors } from '@marbletech/theme';
import { Container, JumboTitle, Content } from './ComparisonTableSection.styled';
import { ComparisonTableSectionProps } from './ComparisonTableSection.types';
import { ComparisonTablePrimaryCard } from './components/ComparisonTablePrimaryCard/ComparisonTablePrimaryCard';
import { ComparisonTableSecondaryCard } from './components/ComparisonTableSecondaryCard/ComparisonTableSecondaryCard';

export const ComparisonTable: React.FC<ComparisonTableSectionProps> = ({ pageContent, isDesktop }) => {
  const { ComparisonTable } = pageContent;
  const { title, cards, backgroundColor = 'dark100' } = ComparisonTable[0];
  const [primaryCard, secondaryCard] = cards;
  const {
    title: primaryCardTitle,
    items: primaryCardItems,
    color: primaryCardColor,
    image: primaryCardImage,
  } = primaryCard;
  const {
    title: secondaryCardTitle,
    items: secondaryCardItems,
    color: secondaryCardColor,
    image: secondaryCardImage,
  } = secondaryCard;

  return (
    <Container background={colors[backgroundColor]}>
      <Content>
        <JumboTitle>{title}</JumboTitle>
        {!isDesktop && (
          <PaginationCarousel breakpoints={{ 0: { slidesPerView: 1.05, spaceBetween: 8 } }}>
            <ComparisonTablePrimaryCard
              title={primaryCardTitle}
              items={primaryCardItems}
              color={primaryCardColor}
              image={primaryCardImage}
              isDesktop={isDesktop}
            />
            <ComparisonTableSecondaryCard
              title={secondaryCardTitle}
              items={secondaryCardItems}
              color={secondaryCardColor}
              image={secondaryCardImage}
              isDesktop={isDesktop}
            />
          </PaginationCarousel>
        )}
        {isDesktop && (
          <Flex>
            <ComparisonTablePrimaryCard
              title={primaryCardTitle}
              items={primaryCardItems}
              color={primaryCardColor}
              image={primaryCardImage}
              isDesktop={isDesktop}
            />
            <ComparisonTableSecondaryCard
              title={secondaryCardTitle}
              items={secondaryCardItems}
              color={secondaryCardColor}
              image={secondaryCardImage}
              isDesktop={isDesktop}
            />
          </Flex>
        )}
      </Content>
    </Container>
  );
};
