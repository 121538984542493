import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme, Theme } from '@marble/theme';
import { Button, Typography } from '@marbletech/components';
import { ComplexSubItem } from '../MainNavigation/MainNavigation';
import { ChevronSmallIcon } from '@marble/icons';
import MenuTestimonials from '../MenuTestimonials/MenuTestimonials';
import NavigationBox from '../NavigationBox/NavigationBox';
import { StoryblokImage } from '../../../storyblok-defs';
import { Link } from 'gatsby';
import { useNavigation } from '../../../context/Context';

export interface DesktopMenuDetailsProps {
  handleSimpleClick: (link?: string) => void;
  pickedPracticeArea?: ComplexSubItem;
  isNavigatingBetweenPracticeAreas: boolean;

  menuDetailsDesktop_box1_image: StoryblokImage;
  menuDetailsDesktop_box1_title: string;
  menuDetailsDesktop_box1_subtitle: string;
  menuDetailsDesktop_box1_buttonText: string;
  menuDetailsDesktop_box2_title: string;
  menuDetailsDesktop_box2_subtitle: string;
}

export const Container = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 14 + 52,
  zIndex: 10,
}));

export const Grid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(2, max-content)',
  gridGap: theme.gutters.base * 3,
  marginBottom: theme.gutters.base * 9,
  maxWidth: 655,
}));

export const ButtonInnerContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content min-content',
  gridGap: theme.gutters.base * 2,
  alignItems: 'center',
}));

export const ButtonOuterContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginRight: theme.gutters.base * 5,
  maxWidth: theme.gutters.base * 20,
  justifySelf: 'end',
}));

export const DesktopMenuDetails: React.FunctionComponent<DesktopMenuDetailsProps> = ({
  handleSimpleClick,
  pickedPracticeArea,
  isNavigatingBetweenPracticeAreas,

  menuDetailsDesktop_box1_image,
  menuDetailsDesktop_box1_title,
  menuDetailsDesktop_box1_subtitle,
  menuDetailsDesktop_box1_buttonText,
  menuDetailsDesktop_box2_title,
  menuDetailsDesktop_box2_subtitle,
}) => {
  const { linkPrefix } = useNavigation();
  const containerAnimation1 = {
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: isNavigatingBetweenPracticeAreas ? 0.3 : 1.3,
      },
    },
  };

  const containerAnimation2 = {
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: isNavigatingBetweenPracticeAreas ? 0.5 : 1.5,
      },
    },
  };

  const containerAnimation3 = {
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: isNavigatingBetweenPracticeAreas ? 0.7 : 1.7,
      },
    },
  };

  return (
    <Container>
      <Grid>
        <motion.div
          {...{
            variants: containerAnimation1,
            initial: 'exit',
            animate: 'enter',
          }}
        >
          <Link {...{ to: linkPrefix + pickedPracticeArea?.funnelLink ?? '' }}>
            <NavigationBox
              {...{
                handleSimpleClick,
                imgSrc: menuDetailsDesktop_box1_image?.filename ?? '',
                imgAlt: menuDetailsDesktop_box1_image?.alt ?? '',
                title: menuDetailsDesktop_box1_title,
                subtitle: menuDetailsDesktop_box1_subtitle,
                component: (
                  <ButtonOuterContainer>
                    <Button {...{ size: 'small' }}>
                      <ButtonInnerContainer>
                        <Typography {...{ variant: 'p1SemiBold', color: 'bright' }}>
                          {menuDetailsDesktop_box1_buttonText}
                        </Typography>
                        <ChevronSmallIcon {...{ color: 'bright' }} />
                      </ButtonInnerContainer>
                    </Button>
                  </ButtonOuterContainer>
                ),
              }}
            />
          </Link>
        </motion.div>
        <motion.div
          {...{
            variants: containerAnimation2,
            initial: 'exit',
            animate: 'enter',
          }}
        >
          <Link {...{ to: linkPrefix + pickedPracticeArea?.servicePageLink ?? '' }}>
            <NavigationBox
              {...{
                handleSimpleClick,
                imgSrc: pickedPracticeArea?.thumbIcon.filename ?? '',
                imgAlt: pickedPracticeArea?.thumbIcon.alt ?? '',
                title: menuDetailsDesktop_box2_title,
                subtitle: menuDetailsDesktop_box2_subtitle,
                component: <ChevronSmallIcon />,
              }}
            />
          </Link>
        </motion.div>
      </Grid>
      <motion.div
        {...{
          variants: containerAnimation3,
          initial: 'exit',
          animate: 'enter',
          style: {
            marginLeft: theme.gutters.base * 3,
          },
        }}
      >
        <MenuTestimonials
          {...{
            testimonials: pickedPracticeArea?.testimonials ?? [],
          }}
        />
      </motion.div>
    </Container>
  );
};

export default DesktopMenuDetails;
