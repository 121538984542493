import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, devices, spacings, Theme } from '@marbletech/theme';
import { PageWidthContainer, Panel } from '@marbletech/components';
import { StarIcon } from '@marbletech/icons';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../consts';

const HEIGHT_BREAKPOINT = '840px';

export const MainContainer = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: `calc(${HEADER_HEIGHT_MOBILE + 48}px)`,
  paddingBottom: `${spacings[3]}`,

  [devices.desktop]: {
    height: '100vh',
    alignItems: 'flex-start',
    paddingTop: `calc(${HEADER_HEIGHT + 128}px)`,
  },

  [`@media (max-height: ${HEIGHT_BREAKPOINT})`]: {
    height: 'auto',
  },
}));

export const ContentContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
}));

export const ReviewsContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flex: '0 1 350px',

  [devices.mobile]: {
    paddingTop: '40px',
    paddingBottom: '40px',
    height: 'auto',
    minHeight: 'auto',
  },

  [`@media (max-height: ${HEIGHT_BREAKPOINT})`]: {
    height: 'auto',
    flexBasis: '175px',
  },
}));

export const Spacer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: '188px',
  width: '1px',
  height: '128px',
  backgroundColor: colors.primary400,
}));

export const ReviewPanel = styled(Panel).attrs({
  variant: 'custom',
  background: colors.neutral100,
  round: spacings[1],
})`
  padding: ${spacings[10]} ${spacings[13]} ${spacings[9]} ${spacings[13]};

  ${devices.mobile} {
    padding: ${spacings[5]} ${spacings[6]} ${spacings[4]} ${spacings[4]};
  }
`;

export const StyledStar = styled(StarIcon)`
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;
