import styled from 'styled-components';
import { H2, H5 } from '@marbletech/components';
import { theme } from '@marbletech/theme';
import { Box } from 'grommet';
import { MAX_WIDTH } from '../../../../consts';

export const sectionHeight = 765;

export const Container = styled(Box)`
  justify-content: center;
  flex-direction: row;
  height: ${sectionHeight}px;
  background: ${(props) => props.theme.global.colors.neutral100};

  @media (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column-reverse;
    height: 100%;
  }
`;

export const ContentLayout = styled.div`
  max-width: ${MAX_WIDTH / 2}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-right: 85px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-right: 0;
    align-items: stretch;
  }
`;

export const ImgSection = styled.div`
  width: 50%;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    height: 396px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled(Box)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  padding: 0 20px;

  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: stretch;
    width: 100%;
    flex-grow: 1;
    padding: 64px 20px;
  }

  @media (min-width: 1170px) {
    padding: 0;
  }
`;

export const Title = styled(H2).attrs({
  color: 'bright',
})`
  white-space: pre-wrap;
  padding: 72px 0 80px 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 24px 0 32px 0;
    flex-grow: 1;
  }
`;

export const ListItem = styled(H5)`
  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(25, 35, 72, 0.2);
    margin-bottom: 27px;
  }
`;
