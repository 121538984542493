import { Theme } from '@marble/theme';
import * as React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { flexCenterCenter } from '@marble/style-utils/flex/flex';
import cookie from 'cookie';
import { useStore } from '../../../context/Context';

export interface PortalButtonProps {
  signInText: string;
}

export const SignInButton = styled('button')<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  background: 'transparent',
  border: 'none',
  outline: 0,
  fontFamily: theme.typography.fontFamily[0],
  cursor: 'pointer',
}));

const CUSTOMER_PORTAL_URL = process.env.GATSBY_CUSTOMER_PORTAL_URL ?? '';

export const Initials = styled('button')<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1Bold,
  backgroundColor: theme.palette.main.primary,
  width: theme.gutters.base * 5,
  height: theme.gutters.base * 5,
  color: theme.palette.text.contrast,
  borderRadius: '50%',
  border: 'none',
  cursor: 'pointer',
  outline: 0,
  ...flexCenterCenter,
}));

export const PortalButton: React.FunctionComponent<PortalButtonProps> = ({ signInText }) => {
  const { userDetails, setUserDetails } = useStore();

  React.useEffect(() => {
    const cookies = cookie.parse((typeof window !== 'undefined' && document.cookie) || '');

    const sharedCustomer = cookies['shared-customer'];

    if (!sharedCustomer) {
      return;
    }
    setUserDetails(JSON.parse(sharedCustomer));
  }, []);

  const isSignedIn = Boolean(userDetails);

  return (
    <div {...{ style: { cursor: 'pointer' } }}>
      {isSignedIn && userDetails ? (
        <Initials {...{ onClick: () => navigate(CUSTOMER_PORTAL_URL) }}>
          {userDetails.name.first.slice(0, 1) + userDetails.name.last.slice(0, 1)}
        </Initials>
      ) : (
        <SignInButton {...{ onClick: () => navigate(CUSTOMER_PORTAL_URL) }}>{signInText}</SignInButton>
      )}
    </div>
  );
};

export default PortalButton;
