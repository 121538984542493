import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import { motion } from 'framer-motion';
import { containerStaggerChildrenAnimation, staggeredItemAnimation } from './YourLegalNeeds';

export interface YourLegalNeedsCardMobileProps {
  number: string;
  title: string;
  text: string;
  imgSrc: string;
  imgPosition: 'left' | 'right';
}

export const Container = styled(motion.div)<{
  theme: Theme;
}>(({ theme }) => ({
  display: 'grid',
  alignItems: 'start',
  width: 278,
}));

export const ContentContainer = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'content',
  display: 'grid',
  gridTemplateRows: '32px 90px min-content 241px',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: `
  'number'
  'title'
  'text'
  `,
}));

export const Number = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2Bold,
  color: theme.palette.main.primary,
  gridArea: 'number',
  alignSelf: 'center',
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h4,
  marginTop: theme.gutters.base * 1,
  marginBottom: theme.gutters.base * 5,
}));

export const Text = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2SemiBold,
}));

export const Img = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 235,
  height: 241,
  gridArea: 'image',
  marginTop: theme.gutters.base * 5,
}));

export const YourLegalNeedsCardMobile: React.FunctionComponent<YourLegalNeedsCardMobileProps> = ({
  number,
  title,
  text,
  imgSrc,
  imgPosition,
}) => {
  const cardContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isShownCardContainer, setIsShownCardContainer] = React.useState(false);
  const [cardContainerY, setCardContainerY] = React.useState(1000);

  const handleScroll = () => {
    setScrollY(window.pageYOffset);
    cardContainerRef.current && setCardContainerY(cardContainerRef.current.getBoundingClientRect().y);

    if (typeof window !== 'undefined') {
      if (cardContainerY < window.innerHeight * 0.865) {
        setIsShownCardContainer(true);
      }
    }
  };

  React.useEffect(() => {
    cardContainerRef.current && setCardContainerY(cardContainerRef.current.getBoundingClientRect().y);
    window.addEventListener('scroll', () => handleScroll());

    if (isShownCardContainer) {
      return window.removeEventListener('scroll', () => handleScroll());
    }
    return window.removeEventListener('scroll', () => handleScroll());
  }, [scrollY]);

  return (
    <Container
      {...{
        imgPosition,
      }}
    >
      <ContentContainer
        {...{
          ref: cardContainerRef,
          variants: containerStaggerChildrenAnimation,
          initial: 'hidden',
          animate: isShownCardContainer ? 'show' : 'hidden',
        }}
      >
        <motion.div
          {...{
            variants: staggeredItemAnimation,
          }}
        >
          <Number>{number}</Number>
        </motion.div>
        <motion.div
          {...{
            variants: staggeredItemAnimation,
          }}
        >
          <Title>{title}</Title>
        </motion.div>
        <motion.div
          {...{
            variants: staggeredItemAnimation,
          }}
        >
          <Text>{text}</Text>
        </motion.div>
        <motion.div
          {...{
            variants: staggeredItemAnimation,
          }}
        >
          <Img {...{ src: imgSrc }} />
        </motion.div>
      </ContentContainer>
    </Container>
  );
};

export default YourLegalNeedsCardMobile;
