import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, spacings } from '@marbletech/theme';
import { Hero } from '../components/Hero';
import { HorizontalPanelItems } from '../components/HorizontalPanelItems';
import { Testimonials } from '../components/Testimonials';
import { Faq } from '../components/Faq';
import { MovesYouForward } from '../components/MovesYouForward';
import { SplitViewBanner } from '../components/SplitViewBanner';
import { OptionsStackWithImage } from '../components/OptionsStackWithImage';
import { ComparisonTable } from '../components/ComparisonTableSection';
import { PageProps } from './page.types';
import { eventService, EventName } from '../../../services/eventService';

const SpliViewBannerContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.primary300};
  padding-top: ${spacings[8]};
  width: 100%;
`;

export const Payments: React.FC<PageProps> = ({ pageContent: content, isDesktop }) => {
  useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPricingPageView,
    });
  }, []);

  return (
    <Box background={colors.neutral100}>
      <Hero pageContent={content} isDesktop={isDesktop} />
      <ComparisonTable pageContent={content} isDesktop={isDesktop} />

      <SpliViewBannerContainer>
        <Box width={{ max: '1440px' }}>
          <SplitViewBanner pageContent={content} isDesktop={isDesktop} />
        </Box>
      </SpliViewBannerContainer>

      <OptionsStackWithImage pageContent={content} isDesktop={isDesktop} />
      <HorizontalPanelItems pageContent={content} isDesktop={isDesktop} />
      <Testimonials
        color={'dark100'}
        arrowColor={'dark100'}
        highlightColor={'primary100'}
        pageContent={content}
        isDesktop={isDesktop}
      />
      <Faq pageContent={content} isDesktop={isDesktop} />
      <MovesYouForward pageContent={content} isDesktop={isDesktop} />
    </Box>
  );
};
