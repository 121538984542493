import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface LongArrowDownProps extends SVGIconProps {}

export const LongArrowDownIcon: React.FunctionComponent<LongArrowDownProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 36, viewBox: '0 0 24 36', ...props }}>
      <path
        fillRule="evenodd"
        d="M187.75 512v33.007L197.984 534l1.016 1.093L187 548l-12-12.907 1.016-1.093 10.234 11.007V512h1.5z"
        transform="translate(-175 -512)"
      />
    </SVGIcon>
  );
};
