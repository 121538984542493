import { useStore } from '@marble/website';
import { StateInfo } from '../LandingPage.types';
import useInjectTextContextVariables from '../../../hooks/useInjectTextContextVariables';

const replaceStateName = (stateInfo: StateInfo, replacedValue: string): string => {
  return replacedValue.replace(new RegExp(`\\[\\[(state_name)\\]\\]`, 'g'), stateInfo.state_name);
};
export const useDynamicTextReplacement = () => {
  const { stateInfo } = useStore();
  const { getDynamicText } = useInjectTextContextVariables();

  const replaceDynamicText = (dynamicText: string) => {
    if (typeof dynamicText !== 'string') {
      return dynamicText;
    }
    const replacedStateName = replaceStateName(stateInfo, dynamicText);
    return getDynamicText({ text: replacedStateName });
  };
  return { replaceDynamicText };
};
