import React from 'react';
import { Footer, FooterContent, FooterPadding } from '@marbletech/ca-footer';
import { eventService } from '@marble/website/src/utils';
import { FooterContainerProps } from '../../footer.types';
import { triggerNewBiEvent } from '../../../../utils/newEvents';

export const DefaultFooter = ({ language, LinkComponent }: FooterContainerProps) => {
  return (
    <Footer
      language={language}
      LinkComponent={LinkComponent}
      triggerEvent={eventService.triggerEvent}
      triggerNewEvent={triggerNewBiEvent}
    >
      <FooterPadding>
        <FooterContent />
      </FooterPadding>
    </Footer>
  );
};
