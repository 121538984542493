import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, devices, spacings } from '@marbletech/theme';

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 112px;
  padding-bottom: 168px;

  ${devices.mobile} {
    padding-top: ${spacings[7]};
    padding-bottom: 152px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: ${spacings[10]};

  ${devices.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    row-gap: ${spacings[5]};
    margin-bottom: ${spacings[6]};
  }
`;

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  max-width: 488px;
  border: 1px solid ${colors.disabled};
  padding: ${spacings[3]};
  row-gap: ${spacings[3]};
  margin-bottom: ${spacings[10]};

  ${devices.mobile} {
    flex-direction: row;
    width: 100%;
    column-gap: ${spacings[2]};
    margin-bottom: ${spacings[7]};
  }
`;
