import React from 'react';

import { H2 } from '@marbletech/components';

import { AttorneysSectionProps } from './AttorneysSection.types';
import { Container, CardsContainer } from './AttorneysSection.styled';
import { LawyerInfo } from '../../shared/components/LawyerInfo';

export const AttorneysSection: React.FC<AttorneysSectionProps> = ({ data }) => {
  const { fold3_cards: lawyersInfo, fold3_h2_title: title, practice_areas_map: practiceAreasMap } = data || {};
  return (
    <Container>
      <H2 textAlign={'center'}>{title}</H2>
      <CardsContainer>
        {lawyersInfo.map(
          (
            {
              lawyer_image,
              name_h5,
              p1_attorney_position,
              p1_attorney_state,
              p2_address,
              p2bold,
              p2bold_phone,
              practice_areas,
              p1_attorney_position_richtext,
            },
            index,
          ) => (
            <LawyerInfo
              image={lawyer_image}
              name={name_h5}
              field={p1_attorney_position}
              fieldState={p1_attorney_state}
              address={p2_address}
              phone={p2bold_phone}
              practiceAreas={practice_areas}
              practiceAreaTitle={p2bold}
              key={`${name_h5}${index}`}
              practiceAreasMap={practiceAreasMap}
              attorneyPosition={p1_attorney_position_richtext}
            />
          ),
        )}
      </CardsContainer>
    </Container>
  );
};
