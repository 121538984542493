import { FEATURE_FLAG_COLOR_PALETTE_TEST, useFeatureReadyFlag } from '@marble/website';
import { useLocation } from '@reach/router';
import { isLPTestPage } from './isPageIncludesInBrandAbTest';

const useDummyFeatureFlag = () => [false, true];

export const useBrandAbTest = () => {
  const { pathname } = useLocation();
  const isTestPage = isLPTestPage(pathname);
  const [isABTest, isABTestReady] = isTestPage
    ? useFeatureReadyFlag(FEATURE_FLAG_COLOR_PALETTE_TEST)
    : useDummyFeatureFlag();
  return [isABTest && isTestPage, isABTestReady];
};
