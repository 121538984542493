import * as React from 'react';
import styled from 'styled-components';
import { CallLineIcon } from '@marble/icons';
import { Typography } from '@marbletech/components';
import { Theme } from '@marble/theme';
import { useLandingPagePhoneFlag } from '../../../context/split';
import { eventService, EventName } from '../../../utils';

export interface PhoneNumberProps {
  phoneLink: string;
  prettyPhone: string;
}

export const CallLineContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content max-content',
  gridGap: theme.gutters.base * 1,
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: 'auto',
}));

export const PhoneNumberText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'inline',
    ...theme.typography.meta.p1SemiBold,
  },
}));

export const PhoneNumber: React.FunctionComponent<PhoneNumberProps> = ({ phoneLink, prettyPhone }) => {
  const shouldShowPhoneNumberOnLandingPageAndFunnel = useLandingPagePhoneFlag();

  function handlePhoneClick() {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPhoneCtaClick,
      eventData: { origin: 'header', number: prettyPhone },
    });
  }

  if (!shouldShowPhoneNumberOnLandingPageAndFunnel) {
    return null;
  }
  return (
    <a
      {...{ href: `tel:${phoneLink}`, style: { marginLeft: 'auto', textDecoration: 'none' } }}
      onClick={handlePhoneClick}
    >
      <CallLineContainer>
        <CallLineIcon />
        <PhoneNumberText {...{ variant: 'p1SemiBold' }}>{prettyPhone}</PhoneNumberText>
      </CallLineContainer>
    </a>
  );
};

export default PhoneNumber;
