import * as React from 'react';
import { ThankYouPage } from '../../../website/src/components/ThankYouPage/ThankYouPage';

export interface WebinarThankYouProps {
  data: {
    pageContent: {
      title: string;
      subtitle: string;
      meetingDetails: Date | undefined;
    };
  };
}

const WebinarThankYou: React.FunctionComponent<WebinarThankYouProps> = ({ data }) => {
  const [webinarDate, setWebinarDate] = React.useState<string>('');
  const { title, subtitle } = data.pageContent;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const date = localStorage.getItem('webinarTime');
      setWebinarDate(date);

      window.setTimeout(() => {
        window.location.replace('/');
      }, 5000);
    }
  }, []);

  return (
    <div>
      <ThankYouPage {...{ title, subtitle, meetingDetails: webinarDate }} />
    </div>
  );
};

export default WebinarThankYou;
