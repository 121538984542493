import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marbletech/theme';
import { flex, flexBetweenCenter } from '@marble/style-utils';
import { Typography } from '@marbletech/components';
import { ChevronSmallIcon } from '@marble/icons';

export interface GradientButtonProps {
  title: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundImage: 'linear-gradient(90deg,#e2eef1 50%,#dce2ff 94%)',
  backgroundPosition: '95% 50%,0 0',
  borderRadius: 40,
  ...flexBetweenCenter,
  width: '100%',
  padding: `${theme.gutters.base * 3}px ${theme.gutters.base * 2}px ${theme.gutters.base * 3}px ${
    theme.gutters.base * 3
  }px`,
  border: `1px solid ${theme.palette.main.bright}`,
  cursor: 'pointer',
  boxSizing: 'border-box',
  '&:hover': {
    border: `1px solid ${theme.palette.main.primary}`,
    boxShadow: '0 0 16px 4px rgb(142 160 251 / 59%)',
  },
  '&:hover p': {
    color: theme.palette.main.primary,
  },
}));

export const GradientButton: React.FunctionComponent<GradientButtonProps> = ({ title }) => {
  return (
    <Container>
      <Typography {...{ variant: 'p2Bold' }}>{title}</Typography>
      <ChevronSmallIcon {...{ color: 'secondary1' }} />
    </Container>
  );
};

export default GradientButton;
