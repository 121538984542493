import * as React from 'react';
import { Button } from '@marbletech/components';
import { ChevronSmallIcon } from '@marble/icons';
import { theme } from '@marble/theme';

export interface ButtonWithChevronProps {
  text: string;
  disabled: boolean;
  onSubmit?: (e: React.FormEvent) => void;
  onClick?: () => void;
}

export const ButtonWithChevron: React.FunctionComponent<ButtonWithChevronProps> = ({
  text,
  disabled,
  onSubmit,
  onClick,
}) => {
  return (
    <Button
      {...{
        disabled,
        variant: 'dark',
        type: 'submit',
        onSubmit,
        onClick,
      }}
    >
      {text}
      <span {...{ style: { marginLeft: theme.gutters.base * 2 } }}>
        <ChevronSmallIcon {...{ color: 'bright' }} />
      </span>
    </Button>
  );
};

export default ButtonWithChevron;
