import styled from 'styled-components';
import { Box } from 'grommet';

import { colors, breakpoints } from '@marbletech/theme';

export const Container = styled(Box).attrs({ justify: 'center', direction: 'row' })`
  width: 100%;
  height: 631px;
  background-color: ${colors.primary100};
  @media (max-width: ${breakpoints.md}px) {
    height: 400px;
  }
`;
