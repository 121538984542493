import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface InterviewProps extends SVGIconProps {}

export const InterviewIcon: React.FunctionComponent<InterviewProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M13 0c3.866 0 7 3.134 7 7v6c0 3.866-3.134 7-7 7v2.5h7V24H4v-1.5h7.5V20H11c-3.866 0-7-3.134-7-7V7c0-3.866 3.134-7 7-7h2zm0 1.5h-2c-2.963 0-5.38 2.344-5.496 5.279L5.5 7v6c0 2.963 2.344 5.38 5.279 5.496L11 18.5h2c2.963 0 5.38-2.344 5.496-5.279L18.5 13V7c0-2.963-2.344-5.38-5.279-5.496L13 1.5zm3 9.5v1.5H8V11h8zm0-4v1.5H8V7h8z"
      />
    </SVGIcon>
  );
};
