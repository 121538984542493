import React from 'react';
import { Box } from 'grommet';
import { colors } from '@marbletech/theme';
import { Hero } from '../components/Hero';
import { HorizontalPanelItems } from '../components/HorizontalPanelItems';
import { Testimonials } from '../components/Testimonials';
import { Faq } from '../components/Faq';
import { MovesYouForward } from '../components/MovesYouForward';
import { SplitViewBanner } from '../components/SplitViewBanner';
import { PageProps } from './page.types';

export const FreeTaxPage: React.FC<PageProps> = ({ pageContent: content, isDesktop }) => (
  <Box background={colors.neutral100}>
    <Hero pageContent={content} isDesktop={isDesktop} />
    <SplitViewBanner pageContent={content} isDesktop={isDesktop} />
    <HorizontalPanelItems pageContent={content} isDesktop={isDesktop} />
    <Testimonials
      color={'dark100'}
      arrowColor={'dark100'}
      highlightColor={'primary100'}
      pageContent={content}
      isDesktop={isDesktop}
    />
    <Faq pageContent={content} isDesktop={isDesktop} />
    <MovesYouForward pageContent={content} isDesktop={isDesktop} />
  </Box>
);
