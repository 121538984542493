import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface CheckedIconProps extends SVGIconProps {
  small?: boolean;
}

export const CheckedIcon: React.FunctionComponent<CheckedIconProps> = ({ small = false, ...props }) => {
  const size = small ? 56 : 144;

  return (
    <SVGIcon
      {...{
        width: size,
        height: size,
        viewBox: `0 0 ${size} ${size}`,
        ...props,
        fill: 'none',
      }}
    >
      <rect width={size} height={size} rx="72" fill="#626DD0" />
      <path
        d="M43 69L64.4396 92.3039C65.2633 93.1993 66.689 93.1579 67.4594 92.2163L102 50"
        stroke="#F7F1E8"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVGIcon>
  );
};
