import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface UpdatesProps extends SVGIconProps {}

export const UpdatesIcon: React.FunctionComponent<UpdatesProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M24 0v16.552h-8.36L12.4 24l-3.24-7.448H0V0h24zm-1.5 1.5h-21v13.551h8.642L12.4 20.24l2.258-5.188 7.843-.001V1.5zM12 10c.828 0 1.5.672 1.5 1.5S12.828 13 12 13s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm1-7v5h-2V3h2z"
      />
    </SVGIcon>
  );
};
