import React, { FC } from 'react';
import { H2, P1, Page, RebrandedButton, OptimizedAssetImage, Dialog } from '@marbletech/components';
import { useBreakpoint } from '@marbletech/hooks';
import { PageProps } from 'gatsby';
import { Box } from 'grommet';
import { ContentContainer, HeaderContainer, CardContainer } from './ContactIrs.styled';
import { ContactIrsProps } from './ContactIrs.types';
import { useNavigation } from '@marble/website/src/context/Context';
import { CallLineFilledIcon, CallLineIcon } from '@marbletech/icons';

const ContactIrs: FC<PageProps<ContactIrsProps>> = ({ data: { pageContent } }) => {
  const { title, image, buttonText, iconTitle, redirectLink } = pageContent;
  const { desktop } = useBreakpoint();
  const { navigate } = useNavigation();

  const onClick = () => {
    navigate(redirectLink);
  };

  return (
    <Page>
      <ContentContainer>
        <HeaderContainer>
          <H2 textAlign={desktop ? 'start' : 'center'} whiteSpace={'pre-wrap'}>
            {title}
          </H2>
          <OptimizedAssetImage src={image.filename} alt={image.alt} maxWidth={248} />
        </HeaderContainer>
        <CardContainer>
          <Box flex={'grow'}>
            <CallLineFilledIcon />
          </Box>
          <P1 whiteSpace={'pre-wrap'}>{iconTitle}</P1>
        </CardContainer>
        <Box width={desktop ? '248px' : '100%'}>
          <RebrandedButton size={'large'} onClick={onClick} type={'submit'}>
            {buttonText}
          </RebrandedButton>
        </Box>
      </ContentContainer>
    </Page>
  );
};
export default ContactIrs;
