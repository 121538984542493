import { useStore } from '@marble/website';
import _get from 'lodash/get';
import { dynamicVariablesRegex } from '@marble/website/src/utils/regex/regex';

interface DynamicTextProps {
  text: string;
}

const useInjectTextContextVariables = () => {
  const { textContext } = useStore();

  const getDynamicText = ({ text }: DynamicTextProps) => {
    if (typeof text !== 'string') {
      return text;
    }

    return text.replace(dynamicVariablesRegex, (m, prefix, varName, suffix, defaultValue) => {
      const textContextVar = _get(textContext, [varName], '');
      return textContextVar ? `${prefix}${textContextVar}${suffix}` : defaultValue;
    });
  };

  return { getDynamicText };
};

export default useInjectTextContextVariables;
