import React from 'react';
import { useMatch } from '@reach/router';
import '@marbletech/core/dist/style.css';

import { createGlobalStyle } from 'styled-components';

const GlobalCss = createGlobalStyle`
  
  body {
    background-color: #F7F1E8;
  }
  
  nav {
    background: #FCFBF7;
  }

  h1 {
    font-family: BogueLight !important;
    font-weight: 300 !important;
    font-size: 40px !important;
    line-height: 56px !important;
  }
  
  h2 {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 56px !important;
    line-height: 56px !important;
    font-feature-settings: 'salt' on, 'liga' off !important;
  }
  
  h4 {
    font-family: Bogue !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 32px !important;
    line-height: 40px !important;
    font-feature-settings: 'salt' on, 'liga' off !important;
    color: #192348 !important;
  }
  
  p:not(footer *) {
    font-family: Roobert !important;
    font-feature-settings: 'tnum' on,'lnum' on,'ss02' on,'salt' on,'liga' off !important;
    font-size: 20px !important;
    line-height: 32px !important;
  }
  
  [class*="FunnelQuestionBaseRebrandstyled"] {
    border: #2C816E !important;
    color: #192348 !important;
    background: #FCFBF7;
  }
  
  
  [class*="PersonalDetailsFormRebrand"], [class*="AnythingElseRebrand"] {
    input, textarea {
      background: #FCFBF7 !important;
      
      &::placeholder {
        font-family: CentraNo1 !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 24px !important;
        font-feature-settings: 'ss04' on !important;
        color: #A0A4C3 !important;
      }
      
      &:focus {
        border: 1px solid #2C816E !important;
        border-radius: 8px !important;
      }
    }
  }
  
  footer {
    background-color: #2C816E !important;
    color: #F7F1E8 !important;
    
    img {
      fill: #2C816E !important;
    }
  }

  [class*="FunnelQuestionBaseRebrandstyled"]:hover {
    background: #2C816E !important;
  }

  [class*="FunnelStepDescriptionRebrand"] {

    #bullet-0 path {
      fill: #2C816E !important;
    }

    #bullet-1, #bullet-2, path {
      fill: #FCFBF7 !important;
    }

    #bullet-0, #bullet-1, #bullet-2 {
      > div span {
        font-family: RecifeText !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        text-align: center !important;
        font-feature-settings: 'tnum' on, 'lnum' on !important;
      }
    }
    
    #bullet-1, #bullet-2 {
      > div span {
        color: #192348 !important;
      }
    }
  }
  
  
  button[type="submit"] {
    background: #2C816E !important;
  }
`;

export const GreenFunnelABTestOverrides = () => {
  const shouldShowGreenFunnel = useMatch('/family/funnel-new');

  return shouldShowGreenFunnel ? <GlobalCss /> : null;
};
