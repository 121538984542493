import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface TrialProps extends SVGIconProps {}

export const TrialIcon: React.FunctionComponent<TrialProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M19.947.342c.194.096.35.253.447.447l2.976 5.95c1.068 2.137.202 4.736-1.935 5.804-.601.3-1.263.457-1.935.457-2.39 0-4.326-1.937-4.326-4.326 0-.672.156-1.334.456-1.935l2.115-4.23-5.03 2.013c.023.072.035.148.035.228V22.5h8.5c.414 0 .75.336.75.75s-.336.75-.75.75H2.75c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h8.5V5.107L5.669 7.34l2.7 5.399c1.069 2.137.203 4.736-1.934 5.804-.601.3-1.263.457-1.935.457-2.39 0-4.326-1.937-4.326-4.326 0-.672.156-1.334.456-1.935l2.976-5.95c.153-.306.441-.5.756-.543L18.917.424c.292-.21.687-.254 1.03-.082zM7.202 15.5H1.797C2.15 16.657 3.227 17.5 4.5 17.5c.439 0 .871-.102 1.264-.298.72-.36 1.22-.987 1.438-1.701zM4.5 8.354L1.972 13.41c-.094.189-.167.387-.217.59h5.49c-.035-.144-.083-.287-.142-.428l-.075-.162L4.5 8.354zm17.702 1.147h-5.405c.353 1.157 1.43 1.999 2.703 1.999.439 0 .871-.102 1.264-.298.72-.36 1.22-.987 1.438-1.701zM19.5 2.354L16.972 7.41c-.094.189-.167.387-.217.59h5.49c-.035-.144-.083-.287-.142-.428l-.075-.162L19.5 2.354z"
      />
    </SVGIcon>
  );
};
