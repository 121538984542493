import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface MeetingProps extends SVGIconProps {}

export const MeetingIcon: React.FunctionComponent<MeetingProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M19 11c2.761 0 5 2.239 5 5v8H14v-3.385c-.656.09-1.324.135-2 .135-.675 0-1.343-.046-2-.135V24H0v-8c0-2.761 2.239-5 5-5s5 2.239 5 5v3.1c.655.099 1.323.15 2 .15s1.345-.051 2-.15V16c0-2.761 2.239-5 5-5zM1.5 16.36v6.14h7v-2.168c-2.659-.648-5.079-2.023-7-3.972zm14 3.971V22.5h7l.002-6.142c-1.921 1.949-4.341 3.326-7.001 3.973zM5 12.5c-1.397 0-2.602.818-3.164 2.001l-.178-.218c1.763 2.199 4.156 3.766 6.842 4.5V16c0-1.869-1.464-3.395-3.308-3.495L5 12.5zm14 0c-1.869 0-3.395 1.464-3.495 3.308L15.5 16v2.782c2.597-.709 4.92-2.197 6.663-4.28-.534-1.129-1.656-1.926-2.971-1.997L19 12.5zM5 0c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm14 0c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zM5 1.5C3.067 1.5 1.5 3.067 1.5 5S3.067 8.5 5 8.5 8.5 6.933 8.5 5 6.933 1.5 5 1.5zm14 0c-1.933 0-3.5 1.567-3.5 3.5s1.567 3.5 3.5 3.5 3.5-1.567 3.5-3.5-1.567-3.5-3.5-3.5z"
      />
    </SVGIcon>
  );
};
