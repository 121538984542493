import React from 'react';
import { H2, H3 } from '@marbletech/components';
import { useWindowSize } from '@marbletech/hooks';
import { theme } from '@marbletech/theme';

export const Title = ({ children, ...props }: any) => {
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.md;
  const Title = isDesktop ? H2 : H3;

  return <Title {...props}>{children}</Title>;
};
