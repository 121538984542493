import React from 'react';
import styled from 'styled-components';
import { RebrandedButton } from '@marbletech/components';
import { breakpoints } from '@marbletech/theme';
import { useBrandAbTest } from '../DELETE_WHEN_BRAND_AB_TEST_DONE/useBrandAbTest';
import { BrandAbTestButton } from '../DELETE_WHEN_BRAND_AB_TEST_DONE/BrandAbTestButton';

type ButtonSize = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
type ButtonVariant = 'primary' | 'secondary';
interface ButtonProps {
  text: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: () => void;
}

const CustomButton = styled(RebrandedButton)`
  min-width: 210px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Button = ({ text, variant, size, onClick }: ButtonProps) => {
  const [isTestOn] = useBrandAbTest();

  if (isTestOn) {
    return (
      <BrandAbTestButton size={size || 'xlarge'} variant={variant || 'primary'} onClick={onClick}>
        {text}
      </BrandAbTestButton>
    );
  }

  return (
    <CustomButton size={size || 'xlarge'} variant={variant || 'primary'} onClick={onClick}>
      {text}
    </CustomButton>
  );
};
