import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'grommet';
import { useNavigation } from '@marble/website/src/context/Context';
import {
  Container,
  ContentContainer,
  ImgSection,
  Image,
  ContentLayout,
  ListItem,
  sectionHeight,
} from './WeCanHelp.styled';
import { WeCanHelpProps } from './WeCanHelp.types';
import { Button } from '../Button';
import { Title } from '../Title';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const WeCanHelp = ({ pageContent, isDesktop }: WeCanHelpProps) => {
  const {
    fold2_employment_h2: title,
    list,
    fold2_employment_image: image,
    fold2_employment_cta: buttonText,
    cta_link: link,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();

  return (
    <Container>
      <ContentContainer>
        <ContentLayout>
          <Title>{title}</Title>
          <Box
            justify={'start'}
            width={'100%'}
            pad={{ top: isDesktop ? '64px' : '32px', bottom: isDesktop ? '64px' : '40px' }}
          >
            {list?.map(({ list_item: text }, index) => (
              <ListItem key={`${index}-${text}`}>{text}</ListItem>
            ))}
          </Box>
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button
              text={buttonText}
              variant={'secondary'}
              onClick={() => {
                triggerNewBiEvent({
                  eventName: NewBiEventsOptions.WebPageCtaClick,
                  eventData: {
                    location: 'We Can Help',
                    cta_text: buttonText,
                    click_url: link,
                  },
                });
              }}
            />
          </Link>
        </ContentLayout>
      </ContentContainer>
      <ImgSection>
        <Image src={`${image?.filename}/m/0x${sectionHeight}`} alt={image?.alt} />
      </ImgSection>
    </Container>
  );
};
