import React from 'react';
import { PageProps } from '../pages/page.types';
import { createGlobalStyle } from 'styled-components';
import { DefaultPage } from '../pages';
import { useBrandAbTest } from './useBrandAbTest';
import { Box } from 'grommet';
import { LoadingAbsolute } from '@marble/ui-lib';

const PaletteAbTestGlobalStyle = createGlobalStyle`
  [class*="Bannerstyled__Container"] {
    background: #F8EECE !important;
  }

  [class*="MovesYouForwardstyled"] {
    color: #192348;
    background: #DFEAE3;
  }

  [class*="Herostyled__HighlightText"] {
    color: #A8C1B2;
  }
  
  [class*="Timelinestyled"] {
    h4, p {
      color: #192348;
    }

    .step-bullet {
      background-color: white;
      border: 1px solid #2C816E;
    }

    .progress-line {
      background-color: #2C816E;
    }
  }
  
  nav {
    button[data-testid='navbar-get-started-button'] {
      background: #2c816e;
      &:hover {
        background: #226456;
        color: #ffffffb3;
      }
    }
  }
`;

export const DefaultPageAbTest = ({ pageContent: content, isDesktop }: PageProps) => {
  const [isBrandTestOn, isBrandTestReady] = useBrandAbTest();

  if (!isBrandTestReady) {
    return (
      <Box height={'100vh'}>
        <LoadingAbsolute size={'medium'} />
      </Box>
    );
  }

  return (
    <>
      {isBrandTestOn && <PaletteAbTestGlobalStyle />}
      <DefaultPage pageContent={content} isDesktop={isDesktop} />
    </>
  );
};
