import type { OperationalState, OurLawyersStoryblok } from '@marble/website';
import {
  AA_TEST,
  FAQ,
  HeroHomePage,
  HeroWebinar,
  Image,
  KnowYourPrice,
  MaxWidthContainer,
  OnTheCase,
  PaddingContainer,
  PayNothingUpfront,
  ProvidingHope,
  SectionSeparator,
  TeamBehindCare,
  Testimonials,
  useFeatureFlag,
  useStore,
  WebinarForm,
  WeHandleStrip,
} from '@marble/website';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';

import FunnelDescription from '../components/FunnelDescription/FunnelDescription';
import FunnelDescriptionSingleThread, {
  FunnelDescriptionSingleThreadStep,
} from '../components/FunnelDescription/FunnelDescriptionSingleThread';
import { ContentItem, StoryblokImage } from '../storyblok-defs';
import { addRegistrant, getWebinarDetails, PracticeArea } from '../api/api';
import { UTMsToPass } from '@marble/website/src/context/Context';
import useInjectTextContextVariables from '../hooks/useInjectTextContextVariables';
import { getLocationFromUrlParam } from '../services/geo-location-service';
import { LandingPageAbTest } from './LandingPage/components/LandingPageAbTest';
import { LandingPageMap } from './LandingPage/components/LandingPageAbTest/LandingPageAbTest.types';
import { useLocation } from '@reach/router';

export interface EmploymentLPProps {
  page: {
    id: string;
    content: string;
    lang: string;
    slug: string;
    uuid: string;
  };
}

interface Content extends ContentItem, OurLawyersStoryblok {
  title: string;
  subtitle: string;
  heroButtonText: string;
  heroButtonHelperText: string;

  webinarHero_title: string;
  webinarHero_subtitle: string;
  webinarHero_tagline: string;
  webinarHero_image: StoryblokImage;
  webinarHero_buttonText: string;
  webinarHero_buttonLink: string;

  webinarForm_title: string;
  webinarForm_subtitle: string;
  webinarForm_firstName: string;
  webinarForm_lastName: string;
  webinarForm_phone: string;
  webinarForm_email: string;
  webinarForm_buttonText: string;
  webinarForm_buttonLink: string;
  webinarForm_question: string;
  webinarForm_isClientQuestion: string;
  webinarForm_isClientAnswers: string[];
  webinarForm_webinarId: string;

  webinarMeetingStrip_image: Image;
  webinarMeetingStrip_text: string;
  webinarMeetingStrip_time: string;

  weHandleStrip_title: string;
  weHandleStrip_items: Array<{ text: string }>;
  weHandleStrip_buttonText: string;

  funnelDescription_title: string;
  funnelDescription_intro: string;
  funnelDescriptionSteps: Array<
    {
      item: Array<Omit<FunnelDescriptionSingleThreadStep[0], 'id'> & ContentItem>;
    } & ContentItem
  >;

  payNothingUpfront_titleMobile: string;
  payNothingUpfront_titleDesktop: string;
  payNothingUpfront_items: Array<{ title: string; text: string }>;
  payNothingUpfront_buttonText: string;

  onTheCase_title: string;
  onTheCase_items: Array<{ image: StoryblokImage; text: string } & ContentItem>;

  allowedCities: Array<{ city: string } & ContentItem>;

  testimonials_title: string;
  testimonials_funnelLink: string;
  testimonials_buttonText: string;
  testimonials_items: Array<{ name: string; location: string; text: string; image: StoryblokImage } & ContentItem>;

  faq_title: string;
  faq_items: Array<{ summary: string; details: string }>;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;

  buildingBlocks: Array<{ key: BuildingBlockKey } & ContentItem>;

  practiceArea: PracticeArea;

  /**
   * Two letter representation
   */
  state: OperationalState;
}

type BuildingBlockKey =
  | 'hero'
  | 'weHandleStrip'
  | 'sectionSeparator'
  | 'funnelDescription'
  | 'payNothingUpfront'
  | 'onTheCase'
  | 'testimonials'
  | 'faq'
  | 'pageIntro'
  | 'knowYourPrice'
  | 'webinarForm';

const componentMap: (
  content: Content,
  funnelDescription: {
    type: FunnelDescriptionType;
    steps: { id: string; icon: string; title: string; description: string }[];
  },
  utm?: UTMsToPass,
) => Record<BuildingBlockKey, React.ReactNode> = (content, funnelDescription, utm) => {
  const { getDynamicText } = useInjectTextContextVariables();
  useFeatureFlag(AA_TEST);

  const {
    title,
    subtitle,
    heroButtonText,
    heroButtonHelperText,
    funnelLink,
    weHandleStrip_title,
    weHandleStrip_items,
    weHandleStrip_buttonText,
    payNothingUpfront_buttonText,
  } = content;

  return {
    hero: (
      <PaddingContainer>
        <MaxWidthContainer>
          <HeroHomePage
            {...{
              title: title,
              subtitle: getDynamicText({ text: subtitle }),
              buttonText: heroButtonText,
              buttonLink: funnelLink,
              caption: heroButtonHelperText,
              navigate,
            }}
          />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    webinarHero: (
      <PaddingContainer>
        <MaxWidthContainer>
          <HeroWebinar
            {...{
              title: content.webinarHero_title,
              subtitle: content.webinarHero_subtitle,
              buttonText: content.webinarHero_buttonText,
              buttonLink: content.webinarHero_buttonLink,
              tagline: content.webinarHero_tagline,
              image: content.webinarHero_image,
              navigate,
            }}
          />
          {/*<SectionSeparator />*/}
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    weHandleStrip: (
      <PaddingContainer>
        <MaxWidthContainer>
          <WeHandleStrip
            {...{
              title: getDynamicText({ text: weHandleStrip_title }),
              items: weHandleStrip_items,
              buttonText: weHandleStrip_buttonText,
              buttonLink: funnelLink,
              numberOfColumns: 3,
            }}
          />
          <SectionSeparator />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    pageIntro: (
      <MaxWidthContainer>
        <ProvidingHope
          {...{
            title: content.pageIntro_title,
            intro: content.pageIntro_intro,
            list: content.pageIntro_items,
            imageMobile: content.pageIntro_imageMobile,
            imageDesktop: content.pageIntro_imageDesktop,
          }}
        />
      </MaxWidthContainer>
    ),
    sectionSeparator: <SectionSeparator />,
    funnelDescription:
      funnelDescription.type === 'single' ? (
        <PaddingContainer>
          <MaxWidthContainer>
            <FunnelDescriptionSingleThread
              {...{
                title: content.funnelDescription_title,
                intro: content.funnelDescription_intro,
                steps: funnelDescription.steps,
              }}
            />
            <SectionSeparator />
          </MaxWidthContainer>
        </PaddingContainer>
      ) : funnelDescription.type === 'double' ? (
        <PaddingContainer>
          <MaxWidthContainer>
            <FunnelDescription
              {...{
                title: content.funnelDescription_title,
                intro: content.funnelDescription_intro,
                steps: funnelDescription.steps,
                buttonText: content.funnelDescription_buttonText,
                buttonLink: funnelLink,
              }}
            />
            <SectionSeparator />
          </MaxWidthContainer>
        </PaddingContainer>
      ) : null,
    knowYourPrice: (
      <PaddingContainer>
        <MaxWidthContainer>
          <KnowYourPrice
            {...{
              title: content.howToPay_title,
              intro: content.howToPay_intro,
              cards: content.howToPay_cards,
              buttonText: content.howToPay_buttonText,
              buttonLink: funnelLink,
            }}
          />
          <SectionSeparator />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    onTheCase: (
      <MaxWidthContainer>
        <OnTheCase {...{ title: content.onTheCase_title, items: content.onTheCase_items }} />
        <SectionSeparator />
      </MaxWidthContainer>
    ),
    testimonials: (
      <>
        <Testimonials
          {...{
            title: getDynamicText({ text: content.testimonials_title }),
            testimonials: content.testimonials_items,
            buttonText: content.testimonials_buttonText,
            funnelLink,
          }}
        />
        <SectionSeparator />
      </>
    ),
    faq: (
      <>
        <PaddingContainer>
          <FAQ {...{ title: content.faq_title, faqs: content.faq_items }} />
        </PaddingContainer>
        <SectionSeparator />
      </>
    ),
    payNothingUpfront: (
      <PaddingContainer>
        <MaxWidthContainer>
          <PayNothingUpfront
            {...{
              titleMobile: content.payNothingUpfront_titleMobile,
              titleDesktop: content.payNothingUpfront_titleDesktop,
              items: content.payNothingUpfront_items,
              buttonText: payNothingUpfront_buttonText,
              buttonLink: funnelLink,
            }}
          />
          <SectionSeparator />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    ourLawyers: (
      <PaddingContainer>
        <MaxWidthContainer>
          <TeamBehindCare
            {...{
              title: content.ourLawyers_title,
              info: content.ourLawyers_info,
              buttonText: content.ourLawyers_button,
              buttonLink: content.ourLawyers_buttonLink,
              aboutButtonText: content.ourLawyers_about,
              slidesData: content.ourLawyers_items ?? [],
            }}
          />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
    webinarForm: (
      <PaddingContainer>
        <MaxWidthContainer>
          <WebinarForm
            {...{
              title: content.webinarForm_title,
              subtitle: content.webinarForm_subtitle,
              buttonText: content.webinarForm_buttonText,
              buttonLink: content.webinarForm_buttonLink,
              emailLabel: content.webinarForm_email,
              phoneLabel: content.webinarForm_phone,
              openQuestion: content.webinarForm_question,
              isClientQuestion: content.webinarForm_isClientQuestion,
              isClientAnswers: content.webinarForm_isClientAnswers,
              firstNameLabel: content.webinarForm_firstName,
              lastNameLabel: content.webinarForm_lastName,
              webinarMeetingStrip_image: content.webinarMeetingStrip_image,
              webinarMeetingStrip_text: content.webinarMeetingStrip_text,
              webinarMeetingStrip_data: content.webinarMeetingStrip_time,
              webinarId: Number(content.webinarForm_webinarId),
              getWebinarDetails: async (webinarId) => {
                const { data } = await getWebinarDetails({ webinarId });
                return data;
              },
              onSubmit: addRegistrant,
              utm,
            }}
          />
        </MaxWidthContainer>
      </PaddingContainer>
    ),
  };
};

type FunnelDescriptionType = 'empty' | 'single' | 'double';

const removeTrailingSlash = (pathname: string): string => {
  return pathname.replace(/\/+$/, '');
};

export const LandingPage: React.FunctionComponent<PageProps<EmploymentLPProps, { landingPageMap: LandingPageMap }>> = ({
  data,
  pageContext,
}) => {
  const content: Content = data.pageContent;

  const { funnelDescriptionSteps, buildingBlocks = [], practiceArea, state = 'CA', allowedCities = [] } = content;
  const { setFunnelMeta, setStateCode, utm, setGeoLocation, geoLocation } = useStore();
  const location = useLocation();

  React.useEffect(() => {
    if (!geoLocation?.city) return;
    const locationFromUrl = getLocationFromUrlParam();
    const isIpCity = !!allowedCities.find(({ city }) => city === geoLocation.city);

    if (!isIpCity && !locationFromUrl) {
      // if there is location param, we don't care about the white list.
      setGeoLocation({ city: '' });
    }
  }, [allowedCities, geoLocation?.city]);

  React.useEffect(() => {
    setFunnelMeta({
      practiceArea,
    });
    setStateCode(state);
  }, [practiceArea]);

  const steps = funnelDescriptionSteps?.map(({ item }) => {
    return item.map((step) => {
      return {
        id: step._uid,
        icon: step.icon,
        title: step.title,
        description: step.description,
      };
    });
  });

  const funnelDescriptionType: FunnelDescriptionType =
    steps?.length === 0 ? 'empty' : steps?.some((step) => step.length > 1) ? 'double' : 'single';

  const pathname = location.pathname;
  const rebrandedLandingPagePathname = `${removeTrailingSlash(pathname)}-2022`;
  const isRebrandedPageExist = !!pageContext.landingPageMap?.[rebrandedLandingPagePathname];

  const children = buildingBlocks.map((block) => {
    return (
      <React.Fragment key={block._uid}>
        {componentMap(content, { type: funnelDescriptionType, steps }, utm)[block.key]}
      </React.Fragment>
    );
  });

  return isRebrandedPageExist ? (
    <LandingPageAbTest
      isRebrandedPageExist={isRebrandedPageExist}
      rebrandedLandingPagePathname={rebrandedLandingPagePathname}
      state={state}
    >
      {children}
    </LandingPageAbTest>
  ) : (
    children
  );
};

export default LandingPage;
