import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Theme } from '@marbletech/theme';
import { absoluteBlow } from '@marble/style-utils';
import PageTitle from '../PageTitle/PageTitle';
import PageTitleSupportingText from '../PageTitleSupportingText/PageTitleSupportingText';
import { Button, Typography } from '@marbletech/components';
import { LongArrowDownIcon } from '@marble/icons';
import { Image } from '@marble/website/src/context/Context';
import { motion } from 'framer-motion';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';

export interface HeroGeneralProps {
  title: string;
  subtitle: string;
  buttonText: string;
  bankruptcyTitle?: string;
  bgSrcMobile?: Image;
  bgSrcDesktop?: Image;
  isBgCover?: boolean;
  onStartHere?: () => void;
}

export const Container = styled(motion.section)<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  zIndex: 10,
  padding: `0 ${theme.gutters.base * 2.5}px`,
  backgroundColor: 'grey',
  backgroundImage: `linear-gradient(to bottom, ${theme.palette.main.secondary3}, #fff)`,
  backgroundSize: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TopContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const HeroImage = styled('img')<{ theme: Theme; isBgCover?: boolean }>(({ theme, isBgCover }) => ({
  display: 'block',
  ...absoluteBlow,
  objectFit: isBgCover ? 'cover' : 'contain',
  width: '100%',
  maxHeight: '100%',
  zIndex: -1,
}));

export const BankruptcyTitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.subtitle2,
  color: theme.palette.main.dark,
  fontFamily: theme.typography.fontFamily[1],
  fontStyle: 'italic',
  fontWeight: 700,
  marginTop: theme.gutters.base * 14,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 35,
  },
}));

export const PageTitleWrap = styled('div')<{ theme: Theme; bankruptcyTitle?: string }>(
  ({ theme, bankruptcyTitle }) => ({
    marginTop: bankruptcyTitle ? theme.gutters.base * 2 : theme.gutters.base * 14,
    marginBottom: theme.gutters.base * 6,
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      marginTop: bankruptcyTitle ? theme.gutters.base * 2 : theme.gutters.base * 40,
      marginBottom: theme.gutters.base * 5,
    },
  }),
);

const floatingArrow = keyframes`
0% {transform: translate(0, 0px);}
50% {transform: translate(0, 15px);}
100% {transform: translate(0, -0px);}
`;

export const ArrowWrap = styled('div')<{ theme: Theme; ofBankruptcy: boolean }>(({ theme, ofBankruptcy }) => ({
  marginBottom: theme.gutters.base * 10,
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    ...(ofBankruptcy && {
      display: 'none',
    }),
  },
}));

export const FloatingArrow = styled(LongArrowDownIcon)<{ theme: Theme }>`
  animation: ${css`
    ${floatingArrow} 1.5s ease-in infinite
  `};
`;

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 335,
}));

const AnimatedContent = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const containerAnimation = {
  exit: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      type: 'tween',
      stifness: 300,
    },
  },
};

export const HeroGeneral: React.FunctionComponent<HeroGeneralProps> = ({
  title,
  subtitle,
  buttonText,
  bankruptcyTitle,
  bgSrcMobile,
  bgSrcDesktop,
  isBgCover,
  onStartHere,
}) => {
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Hero Section', callback: onStartHere });

  return (
    <Container>
      <TopContainer>
        <HeroImage {...{ src: bgSrcDesktop?.filename, alt: bgSrcMobile?.alt, isBgCover }} />
        {bankruptcyTitle && <BankruptcyTitle>{bankruptcyTitle}</BankruptcyTitle>}
        <AnimatedContent
          {...{
            variants: containerAnimation,
            initial: 'exit',
            animate: 'enter',
          }}
        >
          <PageTitleWrap {...{ bankruptcyTitle }}>
            <PageTitle>{title}</PageTitle>
          </PageTitleWrap>
          <PageTitleSupportingText>{subtitle}</PageTitleSupportingText>
          {onStartHere && (
            <ButtonWrap>
              <Button size={'large'} variant={'primary'} onClick={handleCtaClick}>
                {buttonText}
              </Button>
            </ButtonWrap>
          )}
        </AnimatedContent>
      </TopContainer>
      <ArrowWrap {...{ ofBankruptcy: Boolean(bankruptcyTitle) }}>
        <FloatingArrow {...{ color: 'support1' }} />
      </ArrowWrap>
    </Container>
  );
};

export default HeroGeneral;
