import React from 'react';
import { Box } from 'grommet';
import { H6, H5, H4, P3, P2 } from '@marbletech/components';
import { colors, spacings } from '@marbletech/theme';
import { VIcon } from '@marbletech/icons';
import { PrimaryCardListItemIcon, PrimaryCardContainer, PrimaryCarditem } from './ComparisonTablePrimaryCard.styled';
import { ComparisonTablePrimaryCardProps } from './ComparisonTablePrimaryCard.types';
import { PrimaryCard } from './components/PrimaryCard';
import { ImageComponent } from '../shared/ImageComponent';
export const ComparisonTablePrimaryCard: React.FC<ComparisonTablePrimaryCardProps> = (props) => {
  const { title, items, image, color, isDesktop } = props;
  const iconComponent = (
    <PrimaryCardListItemIcon background={colors[color]}>
      <VIcon />
    </PrimaryCardListItemIcon>
  );

  return (
    <PrimaryCardContainer>
      {isDesktop && (
        <PrimaryCard title={<H4 whiteSpace={'pre-wrap'}>{title}</H4>} image={image}>
          {items.map(({ subtitle, title, image: { filename: itemimage, alt: itemAlt } }, index) => (
            <PrimaryCarditem key={`${subtitle}${index}`}>
              <ImageComponent image={itemimage} alt={itemAlt} iconComponent={iconComponent} />
              <Box gap={spacings[0.5]}>
                <H5>{title}</H5>
                <P2 whiteSpace={'pre-wrap'}>{subtitle}</P2>
              </Box>
            </PrimaryCarditem>
          ))}
        </PrimaryCard>
      )}
      {!isDesktop && (
        <PrimaryCard title={<H5 whiteSpace={'pre-wrap'}>{title}</H5>} image={image}>
          {items.map(({ subtitle, title, image: { filename: itemimage, alt: itemAlt } }, index) => (
            <PrimaryCarditem key={`${subtitle}${index}`}>
              <ImageComponent image={itemimage} alt={itemAlt} iconComponent={iconComponent} />
              <Box gap={spacings[0.5]}>
                <H6>{title}</H6>
                <P3 whiteSpace={'normal'}>{subtitle}</P3>
              </Box>
            </PrimaryCarditem>
          ))}
        </PrimaryCard>
      )}
    </PrimaryCardContainer>
  );
};
