import { EventService } from '@marbletech/services';
import { isBrowser } from '@marbletech/utils';
import { captureSentryException } from '@marble/website/src/context/sentry';
export { EventName } from '@marbletech/services';

declare global {
  interface Window {
    eventService: EventService;
    analytics: any;
  }
}

export const eventService = new EventService({
  onError: (message, error) => captureSentryException(message, { extra: { err: error } }),
  // In order to prevent the event service from triggering events before the segment context is updated,
  // it's a gatsby bug 50 is set on purpose DON'T CHANGE.
  delay: 50,
});

if (isBrowser()) window.eventService = eventService;
