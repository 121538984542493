import styled from 'styled-components';
import { Link } from 'gatsby';
import { Box } from 'grommet';

import { colors, spacings } from '@marbletech/theme';
import { breakpoints } from '@marbletech/theme';

export const PhoneNumberLabelContainer = styled.a`
  text-decoration: none;
  display: flex;
  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
`;

export const JobTitle = styled.div`
  width: 100%;
  padding-bottom: ${spacings[2]};
  border-bottom: 1px solid ${colors.disabled};
  margin-bottom: ${spacings[2]};
`;
export const CallLineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacings[0.5]};
`;

export const ImageContainer = styled(Box)`
  & img {
    display: flex;
    max-height: 213px;
  }
  @media (max-width: ${breakpoints.md}px) {
    height: auto;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.primary100};
`;
