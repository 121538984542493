import React from 'react';
import { PageProps } from 'gatsby';
// @ts-ignore
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';
import { StoryblokImage } from '../storyblok-defs';
import { LegalPage as LegalPageComponent, ContactForm, ContactFormProps } from '@marble/website/src/components';
import { Typography } from '@marbletech/components';
import { Box } from 'grommet';
import { spacings } from '@marbletech/theme';

const resolver = new RichTextResolver();

export interface PrivacyPageProps {
  page: {
    id: string;
    content: string;
    lang: string;
    slug: string;
    uuid: string;
    name: string;
    full_slug: string;
  };
}

interface Content {
  title: string;
  effectiveDate: string;
  body: string;

  form_title: string;
  form_fields: Array<{
    key: 'select' | 'name' | 'email' | 'phoneNumber' | 'message';
    placeholder: string;
    selectOptions:
      | Array<{
          key: string;
          label: string;
          value: string;
        }>
      | [];
  }>;
  form_buttonText: string;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;
}

const sourceMap: Record<string, ContactFormProps['source']> = {
  '/do-not-sell-my-info': 'dont-sell-info',
  '/privacy': 'info-deletion',
  '/disability-contact-form': 'disability',
};

export const LegalPage: React.FunctionComponent<PageProps<PrivacyPageProps>> = ({ data, path }) => {
  const content: Content = data.pageContent;
  const hasForm = content?.form_fields?.length > 0;

  return (
    <>
      <LegalPageComponent
        {...{
          title: content.title,
          subtitle: content.effectiveDate,
          body: resolver.render(content.body),
          form: hasForm ? (
            <Box margin={{ bottom: spacings[8] }}>
              <ContactForm
                {...{
                  title: content.form_title ? (
                    <Typography {...{ variant: 'h3', marginBottom: 48 }}>{content.form_title}</Typography>
                  ) : (
                    ''
                  ),

                  source: sourceMap[path],
                  buttonText: content.form_buttonText ?? 'Submit',
                  formFields: content.form_fields,
                }}
              />
            </Box>
          ) : null,
        }}
      />
    </>
  );
};

export default LegalPage;
