export enum PageComponent {
  home = 'home',
  funnel = 'funnel',
  conditionalFunnel = 'conditionalFunnel',
  bookacall = 'bookacall',
  notFound = 'notFound',
  about = 'about',
  landingPage = 'landingPage',
  referral = 'referral',
  webinarThankYou = 'webinarThankYou',
}
