import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface DetermineProps extends SVGIconProps {}

export const DetermineIcon: React.FunctionComponent<DetermineProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M11 13v11H0V13h11zm13 0v11H13V13h11zM9.5 14.5h-8v8h8v-8zm13 0h-8v8h8v-8zM11 0v11H0V0h11zm13 0v11H13V0h11zm-1.5 1.5h-8v8h8v-8z"
      />
    </SVGIcon>
  );
};
