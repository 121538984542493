import styled from 'styled-components';
import { Box } from 'grommet';
import { Flex, OptimizedAssetImage, RebrandedButton } from '@marbletech/components';
import { colors, devices, spacings } from '@marbletech/theme';

export const Container: React.FC<any> = styled(Flex).attrs({
  background: { color: colors.primary300 },
})`
  height: 720px;
  justify-content: space-between;

  ${devices.mobile} {
    flex-direction: column;
    gap: ${spacings[7]};
    padding-bottom: ${spacings[10]};
    height: auto;
    padding-right: 0;
  }
`;

export const Content = styled(Box).attrs({ basis: '1/2', flex: { shrink: 0, grow: 1 } })`
  justify-content: center;
  gap: ${spacings[6]};
  padding-right: 136px;
  padding-left: ${spacings[8]};

  ${devices.mobile} {
    gap: ${spacings[5]};
    padding: 0 ${spacings[2.5]};
  }
`;

export const StyledButton = styled(RebrandedButton)`
  ${devices.mobile} {
    width: 100%;
  }
`;

export const ImageSection: React.ComponentType<any> = styled(Flex).attrs({
  basis: '1/2',
  flex: { shrink: 1 },
  justify: 'start',
})`
  align-self: flex-end;

  div[role='button'] {
    display: flex;

    ${devices.mobile} {
      padding-right: ${spacings[2]};
      padding-top: ${spacings[9]};
    }

    svg {
      max-width: 750px;
    }
  }

  ${devices.mobile} {
    align-self: center;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    display: flex;
  }
`;

export const OptimizedAsset = styled(OptimizedAssetImage)`
  object-fit: cover;
`;
