import { PageWidthContainer } from '@marbletech/components';
import { devices, spacings, Theme } from '@marbletech/theme';
import { Box } from 'grommet';
import styled from 'styled-components';
import { HEADER_HEIGHT_MOBILE } from '../../consts';

export const ContentContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
}));

export const MainContainer = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: `calc(${HEADER_HEIGHT_MOBILE + 48}px)`,
  paddingBottom: `${spacings[3]}`,

  [devices.desktop]: {
    height: '100vh',
    alignItems: 'flex-start',
  },
}));

export const ScheduleDesktopIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [devices.desktop]: {
    display: 'block',
    width: 200,
    height: 200,
    marginBottom: `${spacings[1]}`,
  },
}));

export const ScheduleMobileIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 112,
  height: 112,
  marginBottom: `${spacings[2]}`,
  [devices.desktop]: {
    display: 'none',
  },
}));

export const LearnMoreContainer = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  marginTop: `${spacings[14]}`,
}));

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
