import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import React, { useContext } from 'react';
import { useStore } from './Context';
import { DUMMY_KEY } from '@marble/g-website/src/services/split/get-unique-user-key';
import { SplitUserDataContext } from '@marble/g-website/src/services/split';
import { isBrowser } from '@marbletech/utils';
import { useBreakpoint } from '@marbletech/hooks';
import * as Sentry from '@sentry/gatsby';

export type BASIC_TREATMENTS = 'on' | 'off' | 'control';

export const FEATURE_FLAG_FUNNEL_SMS = 'funnel-sms';
export const FEATURE_FLAG_FUNNEL_PERSONAL_DETAILS_SHOW_PHONE_FIELD = 'FUNNEL_PERSONAL_DETAILS_SHOW_PHONE_FIELD';
export const FEATURE_FLAG_FUNNEL_PERSONAL_DETAILS_SHOW_CONSENT = 'FUNNEL_PERSONAL_DETAILS_SHOW_CONSENT';
export const FEATURE_FLAG_SMS_CREATE_LEAD = 'SMS_CREATE_LEAD';
export const FEATURE_FLAG_PCT_TEST = 'test-pct';
export const FEATURE_FLAG_FAMILY_FUNNEL = 'FAMILY_FUNNEL';
export const FEATURE_FLAG_BANKRUPTCY_FUNNEL = 'BANKRUPTCY_FUUNEL';
export const FEATURE_FLAG_CRIMINAL_FUNNEL = 'FEATURE_FLAG_CRIMINAL_FUNNEL';
export const FEATURE_FLAG_DUI_FUNNEL = 'DUI_FUNNEL';
export const FEATURE_FLAG_SALESFORCE_LEAD = 'SALESFORCE_LEAD';
export const FEATURE_FLAG_EMPLOYMENT_FUNNEL = 'EMPLOYMENT_FUNNEL';
export const FEATURE_FLAG_IMMIGRATION_FUNNEL = 'IMMIGRATION_FUNNEL';
export const FEATURE_FLAG_LANDING_PAGE_PHONE_NUMBER = 'SHOW_PHONE_ON_LANDING_PAGE';
export const FEATURE_FLAG_DEMO_DOWNLOAD_APP = 'SHOW_DEMO_DOWNLOAD_APP';
export const FEATURE_FLAG_NEW_NAVBAR = 'SHOW_NEW_NAVBAR';
export const FEATURE_FLAG_NEW_NAVBAR_LP = 'FEATURE_FLAG_NEW_NAVBAR_LP';
export const FEATURE_FLAG_ADA_BOT = 'FEATURE_FLAG_ADA_BOT';
export const AA_TEST = 'AA_TEST';
export const FEATURE_FLAG_NEW_HOMEPAGE = 'FEATURE_FLAG_NEW_HOMEPAGE';
export const FEATURE_FLAG_NEW_LANDING_PAGE = 'FEATURE_FLAG_NEW_LANDING_PAGE';
export const FEATURE_FLAG_FULL_FUNNEL = 'FEATURE_FLAG_FULL_FUNNEL';
export const FEATURE_FLAG_FUNNELS_AA_TEST = 'FEATURE_FLAG_FUNNELS_AA_TEST';
export const FEATURE_FLAG_TRUSTPILOT_REVIEWS = 'FEATURE_FLAG_TRUSTPILOT_REVIEWS';
export const FEATURE_FLAG_TRUSTPILOT_REVIEWS_OLD = 'FEATURE_FLAG_TRUSTPILOT_REVIEWS_OLD';
export const FEATURE_FLAG_COLOR_PALETTE_TEST = 'ONLINE_LP_TEXAS_AB';
export const FEATURE_FLAG_FOOTER_ON_FUNNEL = 'FUNNEL_NO_FOOTER';

const PST_TZ = 'America/Los_Angeles';

function getDayAndTime(timezone: string = PST_TZ) {
  const defaultRes = {
    day: -1,
    time: -1,
  };
  if (!isBrowser()) {
    return defaultRes;
  }
  const now = new Date();
  try {
    const pstString = now.toLocaleString('en-US', {
      timeZone: timezone,
      hour12: false,
    });
    const pstDate = new Date(pstString);
    return {
      day: pstDate.getDay(),
      time: Number(`${pstDate.getHours()}${pstDate.getMinutes()}`),
    };
  } catch (err) {
    console.error(err);
    Sentry.captureException(new Error('getDayAndTime failed'), {
      extra: {
        date: now.toISOString(),
      },
    });
  }
  return defaultRes;
}

function useSplitAttributes(attributes: SplitIO.Attributes): SplitIO.Attributes {
  const { stateCode, funnelMeta, isRebrand, utm } = useStore();
  const { mobile, desktop } = useBreakpoint();
  const { day: dayPST, time: timePST } = getDayAndTime();

  return {
    ...attributes,
    stateCode,
    practiceArea: funnelMeta?.practiceArea ?? 'NA',
    isRebrand,
    dayPST,
    timePST,
    mobile,
    desktop,
    campaignId: utm?.campaignId ?? '',
  };
}

export function useSplit<T, C>(splitName: string): { treatment: T; config: null | C } {
  const result = useTreatments([splitName])[splitName];
  let treatment: T;
  let config: C | null = null;

  treatment = (result.treatment as unknown) as T;

  try {
    if (result.config) {
      config = JSON.parse(result.config);
    }
  } catch (e) {
    console.error(`Error decoding split config`, e);
  }
  return {
    treatment,
    config,
  };
}

export function useSplitTreatment<T>(splitName: string, attributes: SplitIO.Attributes = {}): T {
  const mergedAttributes = useSplitAttributes(attributes);
  const result = useTreatments([splitName], mergedAttributes);
  return (result[splitName]?.treatment as unknown) as T;
}

export const useFeatureFlag = (splitName: string, attributes: SplitIO.Attributes = {}): boolean => {
  return useSplitTreatment<BASIC_TREATMENTS>(splitName, attributes) === 'on';
};

export const useFeatureReadyFlag = (splitName: string, attributes: SplitIO.Attributes = {}): [boolean, boolean] => {
  const { isReady: isSDKReady } = useContext(SplitContext);
  const { splitKey } = useContext(SplitUserDataContext);
  const result = useSplitTreatment<BASIC_TREATMENTS>(splitName, attributes);

  return [result === 'on', isSDKReady && splitKey !== DUMMY_KEY];
};

export const useLandingPagePhoneFlag = (): boolean => {
  const store = useStore();
  const lpPhoneNumber = useFeatureFlag(FEATURE_FLAG_LANDING_PAGE_PHONE_NUMBER);
  const {
    pageComponent,
    isFromLandingPage,
    funnelMeta: { practiceArea },
    stateCode,
  } = store;

  if (!isFromLandingPage) {
    return true;
  }

  return (isFromLandingPage && practiceArea === 'Criminal' && stateCode === 'CA') ||
    (pageComponent === 'landingPage' && stateCode === 'CA' && practiceArea === 'Criminal')
    ? lpPhoneNumber
    : false;
};
