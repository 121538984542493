import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface BurgerProps extends SVGIconProps {}

export const BurgerIcon: React.FunctionComponent<BurgerProps> = ({ width = 16, height = 16, ...props }) => {
  return (
    <SVGIcon
      {...{
        width,
        height,
        viewBox: `0 0 ${width} ${height}`,
        ...props,
      }}
    >
      <path
        fillRule="evenodd"
        d="M16 14v2H0v-2h16zm0-7v2H0V7h16zm0-7v2H0V0h16z"
        transform="translate(-339 -20) translate(339 20)"
      />
    </SVGIcon>
  );
};
