export enum Variant {
  primary = 'primary',
}

export interface StyleProps {
  variant?: Variant;
}

export interface LinkProps extends StyleProps {
  to: string;
  onClick?: () => void;
  component?: string | JSX.Element;
  children: string | JSX.Element;
  target?: string;
  className?: string;
}
