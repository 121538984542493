import React from 'react';
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_BR,
  MARK_BOLD,
  render,
  StoryblokRichtext,
} from 'storyblok-rich-text-react-renderer';
import styled from 'styled-components';
import { Box } from 'grommet';
import { devices } from '@marbletech/theme';
import { H1, H2, H3, H4, P2 } from '@marbletech/components';

interface RichTextResolverProps {
  document: StoryblokRichtext;
}

const Container = styled(Box).attrs({ focusIndicator: false })`
  cursor: initial;
`;

const StyledBold = styled.span`
  color: ${({ theme }) => theme.global.colors.primary};
`;

const NodeHeading = (
  children: React.ReactNode,
  { level }: { level: number },
) => {
  switch (level) {
    case 1:
      return <H1>{children}</H1>;
    case 2:
      return <H2>{children}</H2>;
    case 3:
      return <H3 textAlign={'center'}>{children}</H3>;
    case 4:
      return (
        <H4>
          {children}
        </H4>
      );
    default:
      return <H3>{children}</H3>;
  }
};

const CustomBr = styled.br`
  ${devices.mobile} {
    display: none;
  }
`;

export const RichTextResolver = ({
  document,
}: RichTextResolverProps) => {
  return (
    <Container>
      {render(document, {
        nodeResolvers: {
          [NODE_HEADING]: NodeHeading,
          [NODE_PARAGRAPH]: (children) => <P2>{children}</P2>,
          [NODE_BR]: () => <CustomBr />
        },
        markResolvers: {
          [MARK_BOLD]: (children) => <StyledBold>{children}</StyledBold>
        }
      })}
    </Container>
  );
};
