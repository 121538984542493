import { useEffect, useLayoutEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';

export const sbClient = new StoryblokClient({
  accessToken: 'fRCjhAxoGSjJG243whAyfwtt',
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export function useStoryblok(originalStoryContent: any, location: Location, path: string) {
  let [storyContent, setStoryContent] = useState(originalStoryContent);
  let [storyPath, setStoryPath] = useState<string>(path);
  let [isEditMode, setIsEditMode] = useState<boolean>(false);

  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  function initEventListeners() {
    const { StoryblokBridge } = window;

    if (typeof StoryblokBridge !== 'undefined') {
      setIsEditMode(true);
      const storyblokInstance = new StoryblokBridge();

      storyblokInstance.on(['published', 'change'], (event: StoryblokEventPayload) => {
        // reload project on save an publish
        window.location.reload();
      });

      storyblokInstance.on('input', (event: StoryblokEventPayload) => {
        // live updates when editing
        setStoryContent(event.story?.content);
      });

      storyblokInstance.on('enterEditmode', (event: StoryblokEventPayload) => {
        // loading the draft version on initial view of the page
        sbClient
          .get(`cdn/stories/${event.storyId}`, {
            version: 'draft',
          })
          .then(({ data }) => {
            console.log(data);
            if (data?.story?.content) {
              setStoryContent(data.story?.content);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  function addBridge(callback: any) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // call a function once the bridge is loaded
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // load bridge only inside the storyblok editor

    if (
      location?.search.includes('_storyblok') ||
      location?.href?.includes('_storyblok') ||
      document?.referrer?.includes('app.storyblok.com')
    ) {
      // first load the bridge and then attach the events
      addBridge(initEventListeners);
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  useEffect(() => {
    setStoryPath(path);
    setStoryContent(originalStoryContent);
  }, [path, originalStoryContent]);

  return { storyContent, storyPath, isEditMode };
}
