import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ApprovedProps extends SVGIconProps {}

export const ApprovedIcon: React.FunctionComponent<ApprovedProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zM16.999 5l1.45 1.151-8.275 11.96L6 12.47l1.406-.916 2.768 3.446L16.999 5z"
      />
    </SVGIcon>
  );
};
