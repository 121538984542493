import React from 'react';
import { Link } from 'gatsby';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { Container, ImgSection, Image, ContentContainer, Text, sectionHeight } from './Banner.styled';
import { BannerProps } from './Banner.types';
import { Button } from '../../../layouts/LandingPage/components/Button';
import { Title } from '../../../layouts/LandingPage/components/Title';
import { useDynamicTextReplacement } from '../../../layouts/LandingPage/hooks/useDynamicTextReplacement';

export const Banner: React.FC<BannerProps> = (props) => {
  const { title, text, cta: buttonText, image, backgroundColor = 'green100', ctaLink: link, isDesktop } = props;
  const { replaceDynamicText } = useDynamicTextReplacement();
  const deynamicAlt = replaceDynamicText(image?.alt);
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Banner' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container background={backgroundColor}>
      <ImgSection>
        <Image src={`${image?.filename}/m/0x${sectionHeight}`} alt={deynamicAlt} />
      </ImgSection>
      <ContentContainer>
        <Title whiteSpace={isDesktop ? 'pre-wrap' : 'normal'}>{title}</Title>
        <Text>{text}</Text>
        {buttonText && (
          <Link to={getLocalizedLink({ pathname: link })}>
            <Button text={buttonText} variant={'secondary'} onClick={handleCtaClick} />
          </Link>
        )}
      </ContentContainer>
    </Container>
  );
};
