import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface XProps extends SVGIconProps {}

export const XIcon: React.FunctionComponent<XProps> = ({ width = 12, height = 12, ...props }) => {
  return (
    <SVGIcon
      {...{
        width,
        height,
        viewBox: `0 0 ${width} ${height}`,
        ...props,
      }}
    >
      <path
        fillRule="evenodd"
        d="M11.025 0L12 .975 6.974 6 12 11.025l-.975.975L6 6.974.975 12 0 11.025 5.026 6 0 .975.975 0 6 5.025 11.025 0z"
        transform="translate(-912 -236) translate(912 236)"
      />
    </SVGIcon>
  );
};
