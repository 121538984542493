import * as _ from 'lodash';
import { UTMsToPass } from '../../context/Context';
import { getItem, setItem } from '../local-storage/local-storage';
import { getReferrer } from '@marble/g-website/src/utils/newEvents/utils';

export const emptyUTMs = {
  source: null,
  campaignId: null,
  adgroupId: null,
  keyword: null,
  content: null,
  medium: null,
  marbleCategory: null,
  gclid: null,
  fbclid: null,
  ttclid: null,
  msclkid: null,
  affiliateId: null,
  matchLocation: null,
  matchType: null,
  searchNetwork: null,
};

export const SEARCH_ENGINE_LIST = [
  'google',
  'baidu',
  'bing',
  'yahoo',
  'duckduckgo',
  'yandex',
  'naver',
  'ecosia.org',
  'so.com',
  'sogou',
  'daum',
  'jobs_on_facebook',
  'go.mail.ru',
  'qwant.com',
  'ask',
  'aol',
  'seznam',
  'yippy',
  'amazon',
  'openverse',
  'gibiru',
  'searchencrypt',
  'searx',
  'startpage',
  'swisscows',
  'ecosia',
  'gigablast',
  'lycos',
  'mojeek',
  'neeva',
  'qmamu',
  'wolframalpha',
  'webcrawler',
  'metager',
  'qwant',
  'you',
  'oscobo',
  'infinity Search',
  'YEP',
  'Anoox',
];

export const extractUTM = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const params = new URLSearchParams(location.search);
  const referrer = getReferrer();

  const utmsFromURL: UTMsToPass = {
    source: params.get('utm_source') || params.get('source') || null,
    campaignId: params.get('utm_campaign') || params.get('campaignId') || null,
    adgroupId: params.get('utm_adset') || params.get('utm_adgroup') || null,
    keyword: params.get('utm_term') || params.get('utm_keyword') || params.get('keyword') || params.get('term') || null,
    content: params.get('utm_content') || null,
    medium: params.get('utm_medium') || params.get('medium') || null,
    marbleCategory: params.get('marble_cat') || params.get('utm_marble_cat') || null,
    gclid: params.get('gclid') || null,
    fbclid: params.get('fbclid') || null,
    ttclid: params.get('ttclid') || null,
    msclkid: params.get('msclkid') || null,
    referrer,
    affiliateId: params.get('affiliate_id') || null,
    matchLocation: params.get('match_location') || null,
    matchType: params.get('match_type') || null,
    searchNetwork: params.get('network') || null,
  };

  const utmItem = getItem('utm');
  const utmsFromStorage: UTMsToPass = utmItem ? JSON.parse(utmItem) : emptyUTMs;
  const utms = handleUTM({ utmsFromURL, referrer, utmsFromStorage });

  return utms;
};

const overrideLocalStorageUTMs = (utm: Partial<UTMsToPass>): UTMsToPass => {
  const currentsUTM = getItem('utm') ?? JSON.stringify(emptyUTMs);
  const updatedUTM: UTMsToPass = { ...JSON.parse(currentsUTM), ...utm };
  setItem('utm', JSON.stringify(updatedUTM));
  return updatedUTM;
};

interface HandleUTMParams {
  utmsFromURL: UTMsToPass;
  utmsFromStorage: UTMsToPass;
  referrer: string;
}

const hasUTM = (utm: UTMsToPass) =>
  Object.keys(utm).filter((key) => key !== 'referrer' && utm[key as keyof UTMsToPass] !== null).length > 0;

export const handleUTM = ({ utmsFromURL, referrer, utmsFromStorage }: HandleUTMParams) => {
  const hasUTMFromStorage = Boolean(utmsFromStorage && hasUTM(utmsFromStorage));
  const searchEngine = SEARCH_ENGINE_LIST.find((item) => {
    return referrer?.toLowerCase().includes(item.toLowerCase());
  });
  const isFromSearchEngine = Boolean(searchEngine);
  const utmsFromURLWithoutNull = _.omitBy(utmsFromURL, (v) => v === null);

  const isPaidTraffic = utmsFromURL?.medium === 'cpc';
  if (isPaidTraffic) {
    const overrideUTMData = {
      ...utmsFromURLWithoutNull,
      source: !!utmsFromURL.source ? utmsFromURL.source : referrer,
    };
    const updatedUTM = overrideLocalStorageUTMs(overrideUTMData);
    return updatedUTM;
  }

  const isAffiliateTraffic = utmsFromURL?.source === 'affiliate';
  if (isAffiliateTraffic) {
    const overrideUTMData = {
      ...utmsFromURLWithoutNull,
      source: 'affiliate',
    };
    const updatedUTM = overrideLocalStorageUTMs(overrideUTMData);
    return updatedUTM;
  }

  const isOrganicTraffic = isFromSearchEngine && !utmsFromURL?.medium;
  if (isOrganicTraffic) {
    const overrideUTMData = {
      ...utmsFromURLWithoutNull,
      source: referrer,
      medium: 'organic',
    };
    const updatedUTM = overrideLocalStorageUTMs(overrideUTMData);
    return updatedUTM;
  }

  const isReferralTraffic =
    !!referrer && !isFromSearchEngine && (!utmsFromURL?.medium || utmsFromURL?.medium !== 'cpc');
  if (isReferralTraffic) {
    const overrideUTMData = {
      ...utmsFromURLWithoutNull,
      source: !!utmsFromURL.source ? utmsFromURL.source : referrer,
      medium: !!utmsFromURL.medium ? utmsFromURL.medium : 'referral',
    };
    const updatedUTM = overrideLocalStorageUTMs(overrideUTMData);
    return updatedUTM;
  }

  const isDirectTraffic = !referrer && !utmsFromURL?.medium && !utmsFromURL?.source;
  if (isDirectTraffic) {
    if (hasUTMFromStorage) return utmsFromStorage;

    const overrideUTMData = {
      source: 'direct',
      medium: 'direct',
    };
    const updatedUTM = overrideLocalStorageUTMs(overrideUTMData);
    return updatedUTM;
  }

  if (hasUTMFromStorage) {
    return utmsFromStorage;
  }

  const updatedUTM = overrideLocalStorageUTMs(utmsFromURLWithoutNull);
  return updatedUTM;
};

/**
 ** https://www.themarbleway.com/dui-lp?
 ** utm_source=facebook&
 ** utm_meduim=cpc&
 ** utm_campaign=23844255822490334
 ** &utm_adset=23844256192560334&
 ** utm_content=23844256192570334&
 ** utm_marble_cat=dui&
 ** fbclid=IwAR2fLpWkzEeefpSr3YOo9xhVk-Y43wVNbTfDEGSWrKYJ1Iq17vZkHPo0kLQ
 */
