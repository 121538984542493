import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, spacings, devices } from '@marbletech/theme';
import { Flex } from '@marbletech/components';

export const SecondaryCard = styled(Box).attrs({ round: spacings[1], background: colors.neutral200 })`
  border: 2px solid ${colors.primary400};

  ${devices.mobile} {
    border-radius: ${spacings[1]};
    border: none;
    min-width: 339px;
    margin-bottom: ${spacings[4.5]};
    margin-right: ${spacings[2.5]};
  }
`;

export const SecondaryCardHeader = styled(Flex).attrs({
  textAlign: 'start',
  justify: 'start',
  border: { size: '2px', side: 'bottom', color: colors.primary400 },
})`
  padding: ${spacings[6]};
  gap: ${spacings[3]};

  ${devices.mobile} {
    padding: ${spacings[3]};
    gap: ${spacings[1]};
  }
`;

export const SecondaryCarditem = styled(Flex).attrs({
  justify: 'start',
  border: { size: '1px', color: colors.disabled, side: 'bottom' },
})`
  padding: ${spacings[2]} ${spacings[5]};
  gap: ${spacings[3]};

  ${devices.mobile} {
    padding: ${spacings[3]} ${spacings[1.5]};
    padding-right: ${spacings[3]};
    gap: ${spacings[2.5]};

    :last-child {
      border-radius: 0px 0px ${spacings[1]} ${spacings[1]};
      border-bottom: unset;
    }
  }
`;

export const SecondaryCardListItemIcon = styled(Box).attrs({
  round: '50%',
  align: 'center',
  justify: 'center',
  flex: { grow: 1, shrink: 0 },
  border: { size: '1.5px', color: colors.primary400 },
})`
  width: ${spacings[4.5]};
  height: ${spacings[4.5]};
  max-width: ${spacings[4.5]};

  ${devices.mobile} {
    width: ${spacings[2.5]};
    height: ${spacings[2.5]};
    max-width: ${spacings[2.5]};

    svg {
      height: ${spacings[1]};
      width: ${spacings[1]};
    }
  }
`;
