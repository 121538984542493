import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { Link } from 'gatsby';
import { Flex, H2, H3, Hero, P2, Page } from '@marbletech/components';
import { devices, spacings } from '@marbletech/theme';
import { useNavigation } from '@marble/website/src/context/Context';
import { TrustBox } from '../LandingPage/components/Trustbox';
import { MovesYouForward } from '../LandingPage/components/MovesYouForward';
import { AboutRebrandProps, TrustboxContainerProps } from './AboutRebrand.types';
import { PracticeAreas } from '../Homepage/components/PracticeAreas';
import { StepperSection } from '../Homepage/components/StepperSection';
import { Banner } from '../LandingPage/components/Banner';
import { BannerText } from './components/BannerText/BannerText';
import { Section } from '../Homepage/shared';
import { EventName, eventService } from '../../services/eventService';

export const TrustboxContainer = styled(Flex)<TrustboxContainerProps>`
  padding-top: ${(props) => (props.isLast ? '0' : '160px')};
  padding-bottom: 192px;
  flex-direction: column;

  ${devices.mobile} {
    padding-top: ${(props) => (props.isLast ? '0' : spacings[10])};
    padding-bottom: ${spacings[10]};
  }
`;

const HeroContent = styled(Hero.Content)`
  ${devices.mobile} {
    margin-top: 168px;
  }
`;

const MapSection = styled(Section)`
  padding: 8px 0 152px 0;

  ${devices.mobile} {
    padding: ${spacings[1]} 0 ${spacings[8]} 0;
  }
`;

const MapTitle = styled(H3)`
  text-align: center;

  ${devices.desktop} {
    white-space: pre-wrap;
  }
`;

const MapLinkWrapper = styled(Flex)`
  margin: ${spacings[3]} 0 ${spacings[11]} 0;

  ${devices.mobile} {
    margin: ${spacings[7]} 0 ${spacings[6]} 0;
  }
`;

const MapLink = styled(P2)`
  text-decoration: underline;
  padding-right: ${spacings[1]};
`;

const Mobile = styled.div`
  display: none;

  ${devices.mobile} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const Desktop = styled.div`
  display: none;

  ${devices.desktop} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const AboutRebrand = ({ data: { pageContent: content } }: AboutRebrandProps) => {
  const {
    heroTitle,
    heroImage,
    heroMobileImage,
    fold7Text,
    fold7ButtonText,
    fold7ButtonLink,
    fold3Title,
    practices,
    fold4Title,
    Stepper,
    fold4ButtonText,
    fold4ButtonLink,
    fold4Image,
    fold6Image,
    fold6Title,
    fold6Text,
    content: fold2Content,
    fold5Image,
    fold5MobileImage,
    fold5ArrowImage,
    fold5Title,
    fold5LinkText,
    fold5Link,
  } = content;
  const { getLocalizedLink } = useNavigation();

  React.useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelAboutUsPageView,
    });
  }, []);

  return (
    <>
      <Box height={'100vh'}>
        <Mobile>
          <Hero bgImage={heroMobileImage.filename} bgPosition={'center top'}>
            <Page.Content flex>
              <HeroContent>
                <H2 margin={{ top: spacings[1], bottom: spacings[3] }} color={'white'}>
                  {heroTitle}
                </H2>

                <TrustBox
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-style-height="150px"
                  data-theme="dark"
                  data-text-color="#FFFFFF"
                  style={{ width: '255px', minHeight: '150px' }}
                />
              </HeroContent>
            </Page.Content>
          </Hero>
        </Mobile>

        <Desktop>
          <Hero bgImage={heroImage.filename} bgPosition={'center top'}>
            <Page.Content flex>
              <HeroContent>
                <H2 whiteSpace={'pre-wrap'} margin={{ top: spacings[5], bottom: spacings[7] }} color={'white'}>
                  {heroTitle}
                </H2>

                <TrustBox
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-style-height="150px"
                  data-theme="dark"
                  data-text-color="#FFFFFF"
                  style={{ width: '255px', minHeight: '150px' }}
                />
              </HeroContent>
            </Page.Content>
          </Hero>
        </Desktop>
      </Box>
      <BannerText text={fold2Content} />
      <PracticeAreas pageContent={{ fold3_h3: fold3Title, cards: practices }} />
      <StepperSection
        pageContent={{
          fold4_h3: fold4Title,
          fold4_image: fold4Image,
          fold4_cta: fold4ButtonText,
          fold4_cta_link: fold4ButtonLink,
          fold4_alt_text: '',
          stepper: Stepper,
        }}
        bgColor={'bright'}
      />

      <MapSection>
        <MapTitle>{fold5Title}</MapTitle>
        <Link to={getLocalizedLink({ pathname: fold5Link })}>
          <MapLinkWrapper>
            <MapLink>{fold5LinkText?.toUpperCase()}</MapLink>
            <img src={fold5ArrowImage?.filename} alt={fold5ArrowImage?.alt} />
          </MapLinkWrapper>
        </Link>
        <Desktop>
          <img src={fold5Image?.filename} alt={fold5Image.alt} />
        </Desktop>
        <Mobile>
          <img src={fold5MobileImage?.filename} alt={fold5MobileImage.alt} />
        </Mobile>
      </MapSection>

      <Banner
        pageContent={{
          fold5_h2: fold6Title,
          p1: fold6Text,
          fold5_cta: '',
          fold5_image: fold6Image,
          cta_link: '',
        }}
      />
      <MovesYouForward pageContent={{ h1: fold7Text, fold7_cta: fold7ButtonText, cta_link: fold7ButtonLink }} />
    </>
  );
};

export default AboutRebrand;
