import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box } from 'grommet';
import { LoadingAbsolute } from '@marble/ui-lib';
import { StateCode } from '@marble/utils';
import { FEATURE_FLAG_NEW_LANDING_PAGE, useFeatureReadyFlag } from '@marble/website';
import { LandingPageAbTestProps } from './LandingPageAbTest.types';

const getFlagAccordingToState = (state: StateCode, hasAAFlag?: boolean) => {
  if (state === 'GA') return FEATURE_FLAG_NEW_LANDING_PAGE;
  return `${FEATURE_FLAG_NEW_LANDING_PAGE}_${state}${hasAAFlag ? '_AA' : ''}`.toUpperCase();
};

export const LandingPageAbTest = (props: LandingPageAbTestProps) => {
  const { children, isRebrandedPageExist, rebrandedLandingPagePathname, state } = props;
  const [isOn, isReady] = useFeatureReadyFlag(getFlagAccordingToState(state));
  const [isAAOn, isAAReady] = useFeatureReadyFlag(getFlagAccordingToState(state, true));

  const shouldNavigateToRebrandedPage = isReady && isOn && isRebrandedPageExist;

  useEffect(() => {
    if (shouldNavigateToRebrandedPage) {
      navigate(rebrandedLandingPagePathname);
      return;
    }
  }, [shouldNavigateToRebrandedPage]);

  if (!isRebrandedPageExist) {
    return <>{children}</>;
  }

  // we want to display a loading spinner in 2 cases:
  // 1 - when split is not ready
  // 2 - when "shouldNavigateToRebrandedPage" is true, to prevent flickering until navigation occurs
  if (!isReady || shouldNavigateToRebrandedPage) {
    return (
      <Box height={'100vh'}>
        <LoadingAbsolute size={'medium'} />
      </Box>
    );
  }

  return <>{children}</>;
};
