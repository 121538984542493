import styled from 'styled-components';
import { Box } from 'grommet';
import { devices, spacings, colors } from '@marbletech/theme';

export const Item = styled(Box)`
  gap: ${spacings[4]};

  ${devices.mobile} {
    gap: ${spacings[3]};
  }
`;

export const Line = styled(Box)`
  border-bottom: 1px solid ${colors.primary400};
  width: 100%;
`;

export const SeparatorItem = styled(Box).attrs({ align: 'center', justify: 'center' })`
  padding: ${spacings[0.5]} ${spacings[1.5]};
  border-radius: 30px;
`;
