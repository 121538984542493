import React from 'react';
import { ContinuePanelProps } from './ContinuePanel.types';
import { ClickableRebrandedButton, PanelLayout } from './ContinuePanel.styled';

export const ContinuePanel: React.FC<ContinuePanelProps> = (props) => {
  const { onClick } = props;
  return (
    <PanelLayout>
      <ClickableRebrandedButton onClick={onClick} variant={'secondary'} size={'large'}>
        Continue
      </ClickableRebrandedButton>
    </PanelLayout>
  );
};
