import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Typography } from '@marbletech/components';
import { theme, Theme } from '@marble/theme';
import { ComplexSubItem, SubItem } from '../MainNavigation/MainNavigation';
import { navigate } from 'gatsby';
import { useNavigation } from '../../../context/Context';

export interface DesktopMenuItemsProps {
  title: string;
  subItems: Array<ComplexSubItem | SubItem>;
  isPickedComplex: boolean;
  setIsPickedComplex: (flag: boolean) => void;
  setPickedPracticeArea: (practiceArea: ComplexSubItem) => void;
  pickedPracticeArea: ComplexSubItem;
  setIsNavigatingBetweenPracticeAreas: (flag: boolean) => void;
}

export const Container = styled(motion.ul)<{ theme: Theme; isPickedComplex: boolean }>(
  ({ theme, isPickedComplex }) => ({
    padding: 0,
    margin: 0,
    listStyle: 'none',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: isPickedComplex ? 'start' : 'center',
  }),
);

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2,
  fontWeight: 600,
  marginTop: theme.gutters.base * 14,
  marginBottom: theme.gutters.base * 3.5,
}));

export const Li = styled(motion.li)<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 36,
}));

export const LiText = styled(Typography)<{ theme: Theme; isPicked: boolean }>(({ theme, isPicked }) => ({
  textAlign: 'center',
  ...theme.typography.meta.h4,
  color: isPicked ? theme.palette.main.primary : theme.palette.main.dark,
  '&:hover': {
    color: theme.palette.main.primary,
  },
  [`@media (min-width: 1100px)`]: {
    ...theme.typography.meta.h3,
  },
}));

const containerAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const practiceAreaAnimation = {
  hidden: {
    opacity: 0,
    y: 80,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

export const DesktopMenuItems: React.FunctionComponent<DesktopMenuItemsProps> = ({
  title,
  subItems,
  isPickedComplex,
  setIsPickedComplex,
  setPickedPracticeArea,
  pickedPracticeArea,
  setIsNavigatingBetweenPracticeAreas,
}) => {
  const { linkPrefix } = useNavigation();
  const handleClick = ({
    funnelLink,
    text,
    icon,
    thumbIcon,
    servicePageLink,
    testimonials,
    backgroundImage,
  }: ComplexSubItem) => {
    const notFirstSelection = pickedPracticeArea.text !== '';

    if (funnelLink) {
      if (notFirstSelection) {
        setIsNavigatingBetweenPracticeAreas(true);
      }
      setPickedPracticeArea({
        text: '',
        icon: { filename: '', alt: '' },
        thumbIcon: { filename: '', alt: '' },
        servicePageLink: '',
        funnelLink: '',
        testimonials: [],
        backgroundImage: {
          filename: '',
          alt: '',
        },
      });
      typeof window !== 'undefined' &&
        window.setTimeout(() => {
          setIsPickedComplex(true);
          setPickedPracticeArea({
            text,
            icon: icon ?? { filename: '', alt: '' },
            thumbIcon: { filename: thumbIcon?.filename, alt: thumbIcon?.alt },
            servicePageLink,
            funnelLink,
            testimonials,
            backgroundImage,
          });
        }, 1);
    } else {
      const isRelativeLink = !servicePageLink.includes('http');
      navigate(isRelativeLink ? linkPrefix + servicePageLink : servicePageLink);
    }
  };

  return (
    <Container
      {...{
        isPickedComplex,
        variants: containerAnimation,
        initial: 'hidden',
        animate: 'show',
      }}
    >
      <motion.div
        {...{
          layout: true,
          transition: {
            duration: 1,
          },
        }}
      >
        <Title>{title}</Title>
      </motion.div>
      {subItems?.map(({ text, icon, thumbIcon, servicePageLink, funnelLink, testimonials, backgroundImage }) => (
        <motion.div
          {...{
            key: text,
            style: {
              marginBottom: theme.gutters.base * 3.5,
            },
            variants: practiceAreaAnimation,
            layout: true,
            transition: {
              duration: 1,
            },
          }}
        >
          <Li
            {...{
              onClick: () =>
                handleClick({ funnelLink, text, icon, thumbIcon, servicePageLink, testimonials, backgroundImage }),
              isPickedComplex,
            }}
          >
            <LiText {...{ isPickedComplex, isPicked: Boolean(text === pickedPracticeArea.text) }}>{text}</LiText>
          </Li>
        </motion.div>
      ))}
    </Container>
  );
};

export default DesktopMenuItems;
