import React, { useState, useEffect, useRef } from 'react';
import { TempLoader } from '@marble/ui-lib/src/components/TempLoader';
import { PdfViewerProps } from './PdfViewr.types';
import { StyledIframe, LoaderContainer } from './PdfViewer.styled';
import { GOOGLE_DRIVE_VIEWER } from '../../../consts';

export const PdfViewer: React.FC<PdfViewerProps> = ({ documentLink: link }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadIframe, setReloadIframe] = useState<number>(0);
  const iframeElem = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!iframeElem?.current?.contentDocument) setIsLoading(false);
      else setReloadIframe((prev) => prev + 1);
    }, 500);
    return () => clearTimeout(timer);
  }, [reloadIframe]);

  return (
    <>
      {isLoading && (
        <LoaderContainer height={'100vh'}>
          <TempLoader />
        </LoaderContainer>
      )}
      <StyledIframe
        ref={iframeElem}
        key={reloadIframe}
        loader={isLoading}
        onLoad={() => setIsLoading(false)}
        src={`${GOOGLE_DRIVE_VIEWER}${link}`}
      />
    </>
  );
};
