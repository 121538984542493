import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marbletech/theme';
import { Typography } from '@marbletech/components';

export interface PageTitleSupportingTextProps {}

export const StyledTypography = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.subtitle1,
  textAlign: 'center',
  marginBottom: theme.gutters.base * 6,
  maxWidth: 470,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: theme.gutters.base * 8,
    maxWidth: 820,
  },
}));

export const PageTitleSupportingText: React.FunctionComponent<PageTitleSupportingTextProps> = ({ children }) => {
  return <StyledTypography {...{ component: 'h3' }}>{children}</StyledTypography>;
};

export default PageTitleSupportingText;
