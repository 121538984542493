import * as React from 'react';
import styled from 'styled-components';
import { navigate, PageProps } from 'gatsby';
import { PaddingContainer } from '@marble/website';
import { ContentItem, StoryblokImage } from '../storyblok-defs';
import { theme, Theme } from '@marbletech/theme';
import { Button, Typography } from '@marbletech/components';
import { useNavigation } from '@marble/website/src/context/Context';

export interface NotFoundPageProps {
  page: {
    id: string;
    content: string;
    lang: string;
    slug: string;
    uuid: string;
  };
}

interface Content extends ContentItem {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonLink: string;
  backgroundImage: StoryblokImage;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  backgroundImage: `linear-gradient(to right, ${theme.palette.main.secondary3} 50%, ${theme.palette.main.support2} 94%)`,
}));

export const FlexContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const TitleWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 9,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 28,
  },
}));

export const StyledButton = styled(Button)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1Bold,
}));

export const Img = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 335,
  marginTop: theme.gutters.base * 4,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: 638,
  },
}));

export const NotFoundPage: React.FunctionComponent<PageProps<NotFoundPageProps>> = ({ data }) => {
  const content: Content = data.pageContent;
  const { title, subtitle, buttonText, buttonLink, backgroundImage } = content;
  const { linkPrefix } = useNavigation();

  return (
    <Container>
      <PaddingContainer>
        <FlexContainer>
          <TitleWrap>
            <Typography {...{ variant: 'h4', marginBottom: theme.gutters.base * 1, center: true }}>{title}</Typography>
          </TitleWrap>
          <Typography {...{ variant: 'p2SemiBold', marginBottom: theme.gutters.base * 3, center: true }}>
            {subtitle}
          </Typography>
          <StyledButton {...{ onClick: () => navigate(linkPrefix + buttonLink), variant: 'dark', size: 'small' }}>
            {buttonText}
          </StyledButton>
          <Img {...{ src: backgroundImage?.filename, alt: backgroundImage?.alt }} />
        </FlexContainer>
      </PaddingContainer>
    </Container>
  );
};

export default NotFoundPage;
