import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Lottie from 'react-lottie';
import { H2, P1 } from '@marbletech/components';
import { isBrowser } from '@marbletech/utils';
import { useNavigation } from '@marble/website/src/context/Context';
import {
  Container,
  Content,
  ImageContainer,
  ImageSection,
  OptimizedAsset,
  StyledButton,
} from './SplitViewBanner.styled';
import { SplitViewBannerProps, FetchLottie } from './SplitViewBanner.types';

export const SplitViewBanner: React.FC<SplitViewBannerProps> = ({ pageContent, isDesktop }) => {
  const { SplitViewBanner, cta_link: link } = pageContent;
  const {
    title,
    description,
    btnText,
    image: { filename: imageLink, alt } = {},
    mobileImage: { filename: mobileImageLink, alt: mobileImageAlt } = {},
    lottie: { filename: lottieLink },
    mobileLottie: { filename: mobileLottieLink },
  } = SplitViewBanner[0];

  const [lottie, setLottie] = useState({ desktop: undefined, mobile: undefined });
  const { getLocalizedLink } = useNavigation();

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const fetchLottie: FetchLottie = async ({ desktopLink, mobileLink, tries }) => {
    try {
      const fetchArray = [fetch(desktopLink), fetch(mobileLink)];
      const results = await Promise.all(fetchArray);
      const [desktopLottie, mobileLottie] = await Promise.all(results.map((result) => result.json()));
      setLottie({ desktop: desktopLottie, mobile: mobileLottie });
    } catch (error) {
      if (tries === 0) console.log('error on fetch');
      else {
        return await fetchLottie({ desktopLink, mobileLink, tries: tries - 1 });
      }
    }
  };

  useEffect(() => {
    if (!isBrowser() || !lottieLink) return;
    const mobileLink = mobileLottieLink || lottieLink;
    fetchLottie({ desktopLink: lottieLink, mobileLink, tries: 3 });
  }, []);

  return (
    <Container>
      <ImageSection>
        {isDesktop && (
          <>
            {imageLink && (
              <ImageContainer>
                <OptimizedAsset src={imageLink} alt={alt} maxWidth={720} height={720} />
              </ImageContainer>
            )}
            {lottieLink && <Lottie options={{ ...defaultLottieOptions, animationData: lottie.desktop }} />}
          </>
        )}
        {!isDesktop && (
          <>
            {imageLink && (
              <ImageContainer>
                <OptimizedAsset
                  src={mobileImageLink ? mobileImageLink : imageLink}
                  alt={mobileImageAlt ? mobileImageAlt : alt}
                  maxWidth={0}
                />
              </ImageContainer>
            )}
            {lottieLink && (
              <Lottie
                options={{ ...defaultLottieOptions, animationData: mobileLottieLink ? lottie.mobile : lottie.desktop }}
              />
            )}
          </>
        )}
      </ImageSection>
      <Content>
        <H2>{title}</H2>
        <P1>{description}</P1>
        <Link to={getLocalizedLink({ pathname: link })}>
          <StyledButton variant={'secondary'} size={'xlarge'}>
            {btnText}
          </StyledButton>
        </Link>
      </Content>
    </Container>
  );
};
