import * as React from 'react';
import { PageProps } from 'gatsby';
import {
  MaxWidthContainer,
  OnTheCase,
  OurDifference,
  PaddingContainer,
  SectionSeparator,
  TeamBehindCareStatic,
} from '@marble/website';
import { ContentItem, StoryblokImage } from '../storyblok-defs';
import { HeroAbout } from '@marble/website/src/components';

export interface AboutProps {
  pageContent: Content;
}

interface Content extends ContentItem {
  title: string;
  subtitle: string;
  ourDifference_title: string;
  ourDifference_items: Array<
    {
      title: string;
      text: string;
      imageMobile: StoryblokImage;
      imageDesktop: StoryblokImage;
    } & ContentItem
  >;

  ourTeam_title: string;
  ourTeam_text: string;
  ourTeam_image: StoryblokImage;
  ourTeam_buttonText: string;
  ourTeam_buttonLink: string;

  onTheCase_title: string;
  onTheCase_items: Array<{ image: StoryblokImage; text: string } & ContentItem>;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;
}

export const About: React.FunctionComponent<PageProps<AboutProps>> = ({ data }) => {
  const content = data.pageContent;
  const {
    title,
    subtitle,
    ourDifference_title,
    ourDifference_items,
    ourTeam_buttonText,
    ourTeam_buttonLink,
    ourTeam_image,
    ourTeam_text,
    ourTeam_title,
    onTheCase_title,
    onTheCase_items,
  } = content;

  return (
    <>
      <PaddingContainer>
        <HeroAbout
          {...{
            title,
            subtitle,
          }}
        />
        <MaxWidthContainer>
          <OurDifference {...{ title: ourDifference_title, items: ourDifference_items }} />
          <SectionSeparator />
        </MaxWidthContainer>
      </PaddingContainer>
      <TeamBehindCareStatic
        {...{
          title: ourTeam_title,
          text: ourTeam_text,
          buttonText: ourTeam_buttonText,
          buttonLink: ourTeam_buttonLink,
          image: ourTeam_image,
        }}
      />
      <SectionSeparator />
      <OnTheCase {...{ title: onTheCase_title, items: onTheCase_items }} />
      <SectionSeparator />
    </>
  );
};

export default About;
