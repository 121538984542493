import axios from 'axios';
import { string } from 'yup';
// import { apiBaseURL } from './../config/config';

// * https://production-lambda.themarbleway.com/leads/log
// * https://staging-lambda.themarbleway.com/leads/log

const apiBaseURL = process.env.GATSBY_API_BASE_URL ?? 'https://staging-lambda.themarbleway.com';

export const api = axios.create({
  baseURL: `${apiBaseURL}`,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface UTM {
  utm: {
    source: string | null;
    medium: string | null;
    campaignId: string | null;
    adgroupId: string | null;
    keyword: string | null;
    content: string | null;
    marbleCategory: string | null;
  };
}

export interface Person {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  email: string;
  phone: string;
  location: string;
}

export interface ABTestData {
  testName: string;
  testGroup: string;
}

export interface MeetingDetails {
  firstName: string;
  lastName: string;
  assign_to: string;
  meetingStart: Date | string;
  meetingEnd: Date | string;
  isApproved: boolean;
  meetingName: string;
  email: string;
}

export interface SharedFunnelPayload extends Person, MeetingDetails {
  utm: UTM;
}

export type PracticeArea =
  | 'Employment'
  | 'DUI'
  | 'Immigration'
  | 'Bankruptcy'
  | 'Family'
  | 'Criminal'
  | 'Estate_Planning'
  | 'Tax'
  | 'FreeTax';

export interface FunnelMeta {
  entry_url: string;
  practiceArea: PracticeArea;
  category: 'Employment' | 'DUI' | 'US Visas' | 'US Citizenship' | 'Green Card' | 'Chapter 7' | 'Family' | 'Criminal';
}

type SharedPayload = FunnelMeta & Person & MeetingDetails & UTM;

interface FunnelResponse {
  message: 'Lead upserted & sent to Zapier successfully ';
}

export const getWebinarDetails = ({ webinarId }: { webinarId: number }) => {
  return api.post(`/zoom/get-webinar-details`, {
    webinarId,
  });
};

interface AddRegistrantPayload {
  webinarId: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  custom_questions: Array<{
    title: string;
    value: string;
  }>;
}

export const addRegistrant = (payload: AddRegistrantPayload) => {
  return api.post(`/zoom/add-registrant`, payload);
};
