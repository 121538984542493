import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography, Stepper, StepMeta, Button } from '@marbletech/components';
import { FunnelTitle } from '../FunnelTitle/FunnelTitle';
import { FunnelSubtitle } from '../FunnelSubtitle/FunnelSubtitle';
import { successWhite } from '@marble/icons';

export interface FunnelStepDescriptionProps {
  title: string;
  subtitle?: string;
  steps?: StepMeta[];
  currentStep?: string;
  list?: string[];
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 auto',
  maxWidth: 480,
}));

export const Img = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  maxWidth: 72,
  marginBottom: theme.gutters.base * 3,
}));

export const StepperWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  margin: '0 30px',
  marginTop: theme.gutters.base * 5,
  width: '100%',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: theme.gutters.base * 5,
  },
}));

export const ListContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 5,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 4,
    marginBottom: theme.gutters.base * 6,
  },
}));

export const ListItem = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  alignItems: 'center',
  gridGap: theme.gutters.base * 2,
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 3,
  },
}));

export const ListItemText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1SemiBold,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
  },
}));

export const StyledButton = styled(Button)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  maxWidth: 335,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ButtonInnerContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content min-content',
  gridGap: theme.gutters.base * 2,
  alignItems: 'center',
}));

export const FunnelStepDescription: React.FunctionComponent<FunnelStepDescriptionProps> = ({
  title,
  subtitle,
  steps,
  currentStep,
  list,
}) => {
  return (
    <Container>
      <FunnelTitle>{title}</FunnelTitle>
      {subtitle && <FunnelSubtitle>{subtitle}</FunnelSubtitle>}
      {steps && currentStep && (
        <StepperWrap>
          <Stepper {...{ steps, currentStep }} />
        </StepperWrap>
      )}
      {list && (
        <ListContainer>
          {list.map((item, ind) => (
            <ListItem {...{ key: ind }}>
              <img {...{ src: successWhite }} />
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </ListContainer>
      )}
    </Container>
  );
};

export default FunnelStepDescription;
