import React from 'react';

import { FeaturedAttorneySectionProps } from './FeaturedAttorneySection.types';
import { Container, InfoContainer } from './FeaturedAttorneySection.styled';
import { ImageSection } from './componnents/ImageSection/ImageSection';
import { LawyerInfo } from '../../shared/components/LawyerInfo';

export const FeaturedAttorneySection: React.FC<FeaturedAttorneySectionProps> = ({ data }) => {
  const {
    fold2_image: img,
    fold2_h5: practiceAreaTitle,
    fold2_p1_subtitle: field,
    fold2_p2_address: address,
    fold2_p2_phone: phone,
    fold2_circle_image: backgroundImg,
    fold2_h4_name: name,
    fold2_practiceAreas: practiceAreas,
    practice_areas_map: practiceAreasMap,
  } = data || {};

  return (
    <Container>
      <InfoContainer>
        <LawyerInfo
          name={name}
          field={field}
          address={address}
          phone={phone}
          practiceAreaTitle={practiceAreaTitle}
          practiceAreas={practiceAreas}
          practiceAreasMap={practiceAreasMap}
        />
      </InfoContainer>
      <ImageSection background={backgroundImg} image={img} />
    </Container>
  );
};
