import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'grommet';
import { RebrandedButton } from '@marbletech/components';
import { isBrowser } from '@marbletech/utils';
import { useNavigation } from '@marble/website/src/context/Context';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { SectionContainer, Subtitle, Title } from './Hero.styled';
import { HeroProps } from './Hero.types';
import TextAnimation from '../../../../components/TextAnimation';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const Hero = ({ pageContent, isDesktop }: HeroProps) => {
  const {
    h4: subtitle,
    h1: title,
    cta: buttonText,
    hero_image: { filename: image } = {},
    hero_image_mobile: { filename: mobileImg } = {},
    cta_link: link,
    hero_alt_text: alt,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Hero Section' });

  //isBrowser: true - run time, false - build time
  let heroImage = image;
  if (isBrowser() && !isDesktop) {
    heroImage = mobileImg;
  }

  return (
    <SectionContainer image={`${heroImage}/m/0x0`} title={alt}>
      <TextAnimation>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <Link to={getLocalizedLink({ pathname: link })}>
          <Box width={{ min: isDesktop ? '280px' : '212px' }}>
            <RebrandedButton
              size={'xlarge'}
              onClick={() => {
                handleCtaClick();
                triggerNewBiEvent({
                  eventName: NewBiEventsOptions.WebPageCtaClick,
                  eventData: {
                    location: 'Hero Section',
                    cta_text: buttonText,
                    click_url: link,
                  },
                });
              }}
            >
              {buttonText}
            </RebrandedButton>
          </Box>
        </Link>
      </TextAnimation>
    </SectionContainer>
  );
};

export default Hero;
