import { useEffect, useRef } from 'react';

export const useScrollIntoView = (value?: any) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const scrollIntoView = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ inline: 'start', behavior: 'smooth' });
    }
  };

  useEffect(scrollIntoView, [value]);

  return { ref, scrollIntoView };
};
