import styled from 'styled-components';

import { breakpoints } from '@marbletech/theme';
import { OptimizedAssetImage } from '@marbletech/components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-basis: 50%;
  justify-content: flex-end;
`;

export const Image = styled(OptimizedAssetImage)`
  display: flex;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;
