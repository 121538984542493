import { Calendly, useStore } from '@marble/website';
import * as Sentry from '@sentry/gatsby';
import { Box } from 'grommet';
import styled from 'styled-components';
import { theme } from '@marbletech/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loading } from '@marble/ui-lib'; // TODO: Move to shared ui lib
import { PageProps } from 'gatsby';
import { eventService, EventName } from '../services/eventService';
import { joinWithPipe, useHash, extractRootDomain } from './ThankYou/utils';
import { useScrollIntoView } from '../hooks/useScrollIntoView';
import { statesObject } from '@marble/utils';
import { isBrowser } from '@marbletech/utils';

export interface BookACallProps {
  pageContent: any;
}

const Container = styled(Box)`
  margin-top: ${theme.gutters.base * 9}px;

  @media (max-width: ${theme.breakpoints.md}px) {
    margin-top: ${theme.gutters.base * 8 + theme.gutters.base * 5}px;
  }
`;

export const BookACall: FunctionComponent<PageProps<BookACallProps>> = ({ data }) => {
  const { utm, person, funnelMeta, externalStateCode } = useStore();
  const state = externalStateCode ? statesObject[externalStateCode] : null;
  const practiceArea = funnelMeta?.practiceArea?.toUpperCase();
  const hashUser = useHash();
  const { ref, scrollIntoView } = useScrollIntoView();
  const [loading, setLoading] = useState<boolean>(true);
  const [rootDomain, setRootDomain] = useState<string>('');
  const anonymousId = isBrowser() && window.eventService.anonymousUserId;

  // we extract the root domain name and send it as part of utm_term to calendly
  // Whenever calendly redirects after confirmation, cloudfront will handle it according to the root domain
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRootDomain(extractRootDomain());
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      eventService.triggerEvent({ eventName: EventName.OnlineFunnelSchedulePageView });
    }
  }, [loading]);

  const handleEventSchedule = async () => {
    const { phone, email, firstName, lastName } = person;
    const userDetails = { phone, email, firstName, lastName, fullName: `${firstName} ${lastName || ''}` };
    try {
      const hashResult = await hashUser(userDetails);
      const { hashedSaltedUser, hashedUnsaltedUser } = hashResult;

      eventService.setDataLayerVariables({
        hashed_user_details: hashedUnsaltedUser,
      });

      eventService.triggerEvent({
        eventName: EventName.OnlineFunnelCalendlyFlowSuccess,
        eventData: { personal_details: hashedSaltedUser, raw_personal_details: userDetails },
      });
    } catch (err) {
      Sentry.captureException(err, { user: userDetails });
    }
  };

  return (
    <Container ref={ref}>
      <Calendly
        {...{
          utm: {
            utmCampaign: utm.campaignId,
            utmContent: utm.content,
            utmMedium: utm.medium,
            utmSource: utm.source,
            utmTerm: joinWithPipe(utm.keyword, practiceArea, state, rootDomain, anonymousId),
          },
          onEventScheduled: handleEventSchedule,
          onDateAndTimeSelected: scrollIntoView,
          onLoad: () => {
            setLoading(false);
          },
          calendlyLinksMap: data?.pageContent?.calendlyLinksMap ?? {},
        }}
      />
      {loading && <Loading />}
    </Container>
  );
};

export default BookACall;
