import React, { MouseEventHandler } from 'react';
import { RebrandedButton } from '@marbletech/components';

interface ButtonProps {
  text: string;
  variant?: string;
  onClick?: MouseEventHandler;
}

export const Button = ({ text, variant = 'primary', onClick }: ButtonProps) => {
  return (
    <RebrandedButton size={'xlarge'} variant={variant} width={{ min: '212px' }} onClick={onClick}>
      {text}
    </RebrandedButton>
  );
};

export default Button;
