import { ThankYouProps } from '../ThankYouPage.types';
import * as React from 'react';
import {
  ScheduleMobileIcon,
  MainContainer,
  ContentContainer,
  ScheduleDesktopIcon,
  ReviewsContainer,
  Spacer,
  ReviewItem,
} from './ThankYou.styled';
import { H2, Subtitle2, P1, RebrandedButton, H4, P3, P2 } from '@marbletech/components';
import { format } from 'date-fns';
import { useWindowSize } from '@marbletech/hooks';
import { spacings, theme } from '@marbletech/theme';
import { useNavigation } from '@marble/website/src/context/Context';
import { Box } from 'grommet';
// @ts-ignore
import TrustpilotReviews from '@pittica/gatsby-plugin-trustpilot-widget';
import { FEATURE_FLAG_TRUSTPILOT_REVIEWS, useFeatureReadyFlag } from '@marble/website';
import { ThankYouLoader } from '../ThankYouLoader';
import { EventName, eventService } from '../../../services/eventService';
import { EXTERNAL_WEBSITE_URL } from '../../../consts';

const ThankYou = ({ person, meetingDetails, data, review }: ThankYouProps) => {
  const { title, subtitle, buttonText, scheduleIconDesktop, scheduleIconMobile } = data;
  const [isReviewsWidgetEnabled, isReviewsWidgetEnabledReady] = useFeatureReadyFlag(FEATURE_FLAG_TRUSTPILOT_REVIEWS);
  const { width } = useWindowSize();
  const { navigate } = useNavigation();

  const isSmallWindow = width <= theme.breakpoints.md;

  const HeadingComp = isSmallWindow ? H4 : H2;
  const ParagraphComp = isSmallWindow ? P3 : P1;

  const navigateHome = () => {
    window.location.href = EXTERNAL_WEBSITE_URL;
  };

  if (!isReviewsWidgetEnabledReady) {
    return <ThankYouLoader />;
  }

  return (
    <MainContainer>
      <ContentContainer background={'neutral200'}>
        <Box align={isSmallWindow ? 'center' : 'flex-start'} direction={'column'} pad={{ bottom: '64px' }}>
          <ScheduleMobileIcon src={scheduleIconMobile?.filename} />
          <ScheduleDesktopIcon src={scheduleIconDesktop?.filename} />
          <HeadingComp
            textAlign={isSmallWindow ? 'center' : 'left'}
            margin={{ bottom: isSmallWindow ? '16px' : '40px', top: '0' }}
          >
            {`${meetingDetails?.firstName ?? person.firstName}${title}`}
          </HeadingComp>
          <Subtitle2 margin={{ bottom: '0', top: '0' }} color={'denim100'}>
            {meetingDetails?.meetingStart ? format(new Date(meetingDetails?.meetingStart), 'MMMM d, yyyy') : ''}
          </Subtitle2>
          <Subtitle2 margin={{ bottom: isSmallWindow ? '32px' : '40px', top: '0' }} color={'denim100'}>
            {meetingDetails?.meetingStart ? format(new Date(meetingDetails?.meetingStart), 'h:mm aaa') : ''}
          </Subtitle2>
          <ParagraphComp
            textAlign={isSmallWindow ? 'center' : 'left'}
            margin={{ bottom: '48px', top: '0' }}
            color={'denim100'}
          >
            {`${subtitle} ${meetingDetails?.email ?? person.email}`}
          </ParagraphComp>
          <RebrandedButton onClick={navigateHome} size={'large'}>
            {buttonText}
          </RebrandedButton>
        </Box>
      </ContentContainer>
      {isReviewsWidgetEnabled && (
        <ReviewsContainer background={'neutral100'} align={'center'} direction={'row'}>
          <Box align={isSmallWindow ? 'flex-start' : 'center'} direction={isSmallWindow ? 'column' : 'row'}>
            <div style={{ position: 'relative' }}>
              <TrustpilotReviews
                language="en"
                culture="US"
                theme="light"
                width={isSmallWindow ? '160px' : '235px'}
                height={isSmallWindow ? '80px' : '110px'}
                template="53aa8807dec7e10d38f59f32"
                business="5ee9bc358adfce0001e91218"
                username="themarbleway.com"
              />
              <a
                style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                onClick={(e) => {
                  eventService.triggerEvent({ eventName: EventName.OnlineFunnelTrustpilotLinkClick });
                }}
                href={'https://www.trustpilot.com/review/themarbleway.com'}
                target={'_blank'}
              />
            </div>

            {!isSmallWindow && <Spacer />}
            <ReviewItem margin={{ top: isSmallWindow ? spacings[5] : '0px' }}>
              <Subtitle2
                whiteSpace={'pre-wrap'}
                margin={{ bottom: isSmallWindow ? spacings[2] : spacings[3], top: '0px' }}
              >{`“${review?.quote ?? ''}”`}</Subtitle2>
              <P2>{review?.name ?? ''}</P2>
            </ReviewItem>
          </Box>
        </ReviewsContainer>
      )}
    </MainContainer>
  );
};

export default ThankYou;
