import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import FunnelTitle from '../FunnelTitle/FunnelTitle';
import { string } from 'yup';

export interface FunnelQuestionProps {
  question: string;
  answers: Answer[];
  isSelected?: boolean;
  onAnswerSelection: (ind: number) => void;
  selectedAnswer?: number | null | undefined;
}

export type Answer =
  | {
      nextStepKey: string;
      text: string;
    }
  | string;

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  maxWidth: 335,
  margin: '0 auto',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    maxWidth: 388,
  },
}));

export const Answers = styled('ul')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 7,
  marginBottom: 0,
  padding: 0,
  width: '100%',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 6,
  },
}));

export const Answer = styled('li')<{ theme: Theme; isSelected: boolean }>(({ theme, isSelected }) => ({
  border: isSelected ? `1px solid ${theme.palette.main.secondary3}` : `1px solid ${theme.palette.main.secondary0}`,
  backgroundColor: isSelected ? theme.palette.main.secondary3 : theme.palette.main.bright,
  borderRadius: theme.gutters.base * 3,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: `${theme.gutters.base * 1.5}px ${theme.gutters.base * 2}px`,
  cursor: 'pointer',
  position: 'relative',
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 3,
  },
}));

export const SpinnerWrap = styled('div')<{ theme: Theme; isSelected: boolean }>(({ theme, isSelected }) => ({
  display: isSelected ? 'block' : 'none',
  position: 'absolute',
  left: theme.gutters.base * 0.5,
  top: theme.gutters.base * 0.5,
}));

export const OptionMark = styled('div')<{ theme: Theme; isSelected?: boolean }>(({ theme, isSelected = false }) => ({
  border: isSelected ? 'none' : `1px solid ${theme.palette.main.secondary1}`,
  backgroundColor: isSelected ? theme.palette.main.primary : 'transparent',
  borderRadius: '50%',
  marginRight: theme.gutters.base * 2,
  width: theme.gutters.base * 2,
  height: theme.gutters.base * 2,
}));

export const Avatar = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: theme.gutters.base * 7,
  height: theme.gutters.base * 7,
  marginBottom: theme.gutters.base,
}));

export const FunnelQuestion: React.FunctionComponent<FunnelQuestionProps> = ({
  question,
  answers,
  onAnswerSelection,
  selectedAnswer,
}) => {
  const [selectedInd, setSelectedInd] = React.useState<number | null | undefined>(null);

  React.useEffect(() => {
    setSelectedInd(selectedAnswer);
  }, [question]);

  return (
    <Container>
      <FunnelTitle>{question}</FunnelTitle>
      <Answers>
        {answers.map((answer, ind) => {
          const isSelected = selectedInd === ind;
          const actualAnswer = typeof answer === 'string' ? answer : answer.text;
          return (
            <Answer
              {...{
                key: ind,
                onClick: () => {
                  setSelectedInd(ind);
                  onAnswerSelection(ind);
                },
                isSelected,
                'data-test-answer': actualAnswer,
              }}
            >
              <SpinnerWrap {...{ isSelected }}>
                <img
                  {...{
                    src: 'https://uploads-ssl.webflow.com/5f3452e289aec70d5efcf4d6/5faa36162f1243e10fa109c6_Glow%20Ring-0.9s-40px.svg',
                  }}
                />
              </SpinnerWrap>
              <OptionMark {...{ isSelected }} />
              <Typography {...{ variant: 'p1SemiBold' }}>{actualAnswer}</Typography>
            </Answer>
          );
        })}
      </Answers>
    </Container>
  );
};

export default FunnelQuestion;
