import * as React from 'react';
import { FunnelTitle } from '../FunnelTitle/FunnelTitle';
import { FunnelSubtitle } from '../FunnelSubtitle/FunnelSubtitle';
import { TextField } from '@marbletech/components';
import { TextFieldGrid } from '../TextFieldGrid';
import { object, string } from 'yup';
import { Container } from '../shared-funnel/Container';
import { ButtonWrap } from '../shared-funnel/ButtonWrap';
import { StyledForm } from '../shared-funnel/StyledForm';
import { ButtonWithChevron } from '../shared-funnel/ButtonWithChevron';

export interface StateUnavailableProps {
  title: string;
  subtitle?: string;
  fields: Array<{
    key: 'state' | 'email';
    placeholder: string;
  }>;
  buttonText: string;
  handleSubmit: (data: { email: string; state: string }) => void;
}

const validationSchema = object().shape({
  state: string().required().min(3),
  email: string().email().required(),
});

export const StateUnavailable: React.FunctionComponent<StateUnavailableProps> = ({
  title,
  subtitle,
  fields,
  buttonText,
  handleSubmit,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = React.useState<boolean>(false);
  const [fieldsData, setFieldsData] = React.useState({
    state: '',
    email: '',
  });

  React.useEffect(() => {
    validationSchema.isValid(fieldsData).then((valid) => {
      setIsButtonEnabled(valid);
    });
  }, [fieldsData]);

  const updateField = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldsData({
      ...fieldsData,
      [key]: e.target.value,
    });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    handleSubmit({
      email: fieldsData.email,
      state: fieldsData.state,
    });
  };

  return (
    <Container>
      <FunnelTitle>{title}</FunnelTitle>
      {subtitle && <FunnelSubtitle>{subtitle}</FunnelSubtitle>}
      <StyledForm
        {...{
          isContentCenteredDesktop: true,
          onSubmit,
        }}
      >
        <TextFieldGrid {...{ isDesktopFieldsColumn: true }}>
          {fields.map((field) => {
            return (
              <TextField
                {...{
                  value: fieldsData[field.key],
                  onChange: updateField(field.key),
                  placeholder: field.placeholder,
                }}
              />
            );
          })}
        </TextFieldGrid>
        <ButtonWrap>
          <ButtonWithChevron
            {...{
              text: buttonText,
              disabled: !isButtonEnabled,
              onSubmit,
            }}
          />
        </ButtonWrap>
      </StyledForm>
    </Container>
  );
};

export default StateUnavailable;
