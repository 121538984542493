import styled from 'styled-components';
import { colors, devices, Theme } from '@marbletech/theme';
import { PageWidthContainer, Subtitle2 } from '@marbletech/components';
import { spacings } from '@marbletech/theme';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../../consts';
import { Box } from 'grommet';

const HEIGHT_BREAKPOINT = '840px';

export const MainContainer = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: `calc(${HEADER_HEIGHT_MOBILE}px)`,

  [devices.desktop]: {
    height: '100vh',
    alignItems: 'flex-start',
    paddingTop: `calc(${HEADER_HEIGHT}px)`,
  },

  [`@media (max-height: ${HEIGHT_BREAKPOINT})`]: {
    height: 'auto',
  },
}));

export const ContentContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flexGrow: 1,

  [devices.desktop]: {
    minHeight: '600px',
  },
}));

export const TempContainer = styled(PageWidthContainer)<{ theme: Theme }>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const ScheduleMobileIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 112,
  height: 112,
  marginBottom: `${spacings[2]}`,
  [devices.desktop]: {
    display: 'none',
  },
}));

export const ScheduleDesktopIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [devices.desktop]: {
    display: 'block',
    width: 200,
    height: 200,
    marginBottom: `${spacings[1]}`,
  },
}));

export const ReviewsContainer = styled(PageWidthContainer)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  flex: '0 1 350px',

  [devices.mobile]: {
    paddingTop: '40px',
    paddingBottom: '40px',
    height: 'auto',
    minHeight: 'auto',
  },

  [`@media (max-height: ${HEIGHT_BREAKPOINT})`]: {
    height: 'auto',
    flexBasis: '175px',
  },
}));

export const Spacer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: '188px',
  width: '1px',
  height: '128px',
  backgroundColor: colors.primary400,
}));

export const ReviewItem = styled(Box)<{ theme: Theme }>(({ theme }) => ({
  marginLeft: 0,

  [devices.desktop]: {
    marginLeft: `${spacings[8]}`,
  },
}));
