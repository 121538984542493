import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box } from 'grommet';
// @ts-ignore
import TrustpilotReviews from '@pittica/gatsby-plugin-trustpilot-widget';
import { CheckedIcon } from '@marble/icons';
import { H2, H4, H5, P1, P2, P3 } from '@marbletech/components';
import { useBreakpoint } from '@marbletech/hooks';
import { colors, spacings } from '@marbletech/theme';
import { isBrowser } from '@marbletech/utils';
import { ThankYouScheduledPageProps } from './ThankYouScheduled.types';
import { ContentContainer, MainContainer, ReviewPanel, StyledStar } from './ThankYouScheduled.styled';
import { EventName, eventService } from '../../services/eventService';

export const ThankYouScheduledPage: FunctionComponent<ThankYouScheduledPageProps> = ({ data: { pageContent } }) => {
  const { mobile } = useBreakpoint();
  const { title, subtitle, reviews } = pageContent;

  const HeadingComp = mobile ? H4 : H2;
  const SubtitleComp = mobile ? P1 : H5;
  const ReviewQuoteComp = mobile ? P1 : H4;
  const ReviewNameComp = mobile ? P2 : P3;

  if (!isBrowser()) {
    return <MainContainer />;
  }

  return (
    <MainContainer>
      <ContentContainer>
        <Box
          align={mobile ? 'center' : 'flex-start'}
          direction={mobile ? 'column' : 'row'}
          pad={{ bottom: mobile ? spacings[5] : spacings[8] }}
        >
          <CheckedIcon width={mobile ? 56 : 144} height={mobile ? 56 : 144} />

          <Box direction={'column'} margin={{ left: mobile ? '0px' : spacings[7], top: `${mobile ? 16 : 0}px` }}>
            <HeadingComp
              textAlign={mobile ? 'center' : 'left'}
              margin={{ bottom: mobile ? '16px' : spacings[1], top: '0' }}
            >
              {title}
            </HeadingComp>
            <SubtitleComp
              margin={{ bottom: '0', top: '0' }}
              color={'denim100'}
              maxWidth={mobile ? `270px` : `400px`}
              textAlign={mobile ? 'center' : 'left'}
            >
              {subtitle}
            </SubtitleComp>
          </Box>
        </Box>
        {!!reviews?.length && (
          <ReviewPanel background={colors.neutral100}>
            <Box>
              <Box direction={'row'}>
                {[1, 2, 3, 4, 5].map(() => (
                  <StyledStar height={mobile ? 18 : 40} width={mobile ? 18 : 40} />
                ))}
              </Box>
              <ReviewQuoteComp
                margin={{ bottom: mobile ? spacings[3] : spacings[3], top: mobile ? spacings[2] : spacings[5] }}
              >
                “{reviews[0].quote}”
              </ReviewQuoteComp>
              <ReviewNameComp>{reviews[0].name}</ReviewNameComp>
            </Box>
          </ReviewPanel>
        )}
        <Box align={mobile ? 'flex-start' : 'flex-end'} margin={{ top: spacings[4] }}>
          <div style={{ position: 'relative', right: '-30px' }}>
            <TrustpilotReviews
              language="en"
              culture="US"
              theme="light"
              width={'100%'}
              height={'24px'}
              template="5419b637fa0340045cd0c936"
              business="5ee9bc358adfce0001e91218"
              username="themarbleway.com"
            />
            <a
              style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
              onClick={(e) => {
                eventService.triggerEvent({ eventName: EventName.OnlineFunnelTrustpilotLinkClick });
              }}
              href={'https://www.trustpilot.com/review/themarbleway.com'}
              target={'_blank'}
            />
          </div>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
};

export default ThankYouScheduledPage;
