/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import * as React from 'react';
import MainLayout from './src/layouts/MainLayout';
import { StoreProvider } from '@marble/website';
import './src/services/eventService';
import { WithGrommetTheme } from '@marbletech/components';
import { GeoLocationService } from './src/services/geo-location-service';
import './src/styles/fonts';
import { SplitContext } from './src/services/split';

export const wrapRootElement = ({ element }) => {
  return (
    <WithGrommetTheme>
      <StoreProvider>
        <SplitContext>{element}</SplitContext>
        <GeoLocationService />
      </StoreProvider>
    </WithGrommetTheme>
  );
};

export const wrapPageElement = ({ element, props, ...rest }) => {
  const { pageContext } = props;
  const {
    menu: _menuContent,
    deprecatedMenuContent: _deprecatedMenuContent,
    pageContent: _pageContent,
    pageCandidateContent: _pageCandidateContent,
    footer: _footerContent,
    pageType,
    rebrand,
  } = pageContext;

  const menuContent = _menuContent && JSON.parse(_menuContent.content);
  const deprecatedMenuContent = _deprecatedMenuContent && JSON.parse(_deprecatedMenuContent.content);

  const pageContent = JSON.parse(_pageContent.content);

  if (_pageContent.calendlyLinksMap) {
    pageContent.calendlyLinksMap = _pageContent.calendlyLinksMap;
  }
  if (_pageContent.fullFunnelsData) {
    pageContent.fullFunnelsData = _pageContent.fullFunnelsData;
  }
  if (_pageContent.campaignToPhoneData) {
    pageContent.campaignToPhoneData = _pageContent.campaignToPhoneData;
  }

  const menuTypeMapper = {
    home: 'full',
    homepage: 'full',
    funnel: 'phoneNumber',
    conditionalFunnel: 'phoneNumber',
    bookacall: 'phoneNumber',
    notFound: 'full',
    about: 'full',
    landingPage: 'phoneNumberAndCTA',
    referral: 'phoneNumber',
    webinarThankYou: 'phoneNumber',
    documentsPage: pageContent.hideCta ? '' : 'full',
  };

  const pageCandidateContent = _pageCandidateContent && JSON.parse(_pageCandidateContent.content);

  const footerContent = JSON.parse(_footerContent.content);

  const { phoneNumbers = [] } = pageContent;

  const funnelPagesNames = [
    'funnel',
    'conditionalFunnel',
    'conditionalFunnelRebrand',
    'choosePracticeAreaRebrand',
    'choosePracticeArea',
    'bookacallRebrand',
    'bookacall',
    'thankYou',
  ];
  const isFunnel = funnelPagesNames.includes(pageContent.component);

  const isBookACall = pageContent.component === 'bookacallRebrand';
  const is404 = pageContent.component === 'notFound';
  const isAboutPage = pageContent.component === 'about';
  const isLandingPage =
    pageContent.component === 'landingPage' ||
    pageContent.component === 'landingPageRebrand' ||
    pageContent.component === 'dynamicLandingPage';

  const menuType = menuTypeMapper[pageContent.component] ?? 'full';

  return (
    <MainLayout
      menuContent={menuContent}
      pageComponent={pageContent.component}
      deprecatedMenuContent={deprecatedMenuContent}
      footerContent={footerContent}
      phoneNumbers={phoneNumbers}
      funnelLink={pageContent.funnelLink}
      isBookACall={isBookACall}
      is404={is404}
      isAboutPage={isAboutPage}
      isLandingPage={isLandingPage}
      pageContent={pageContent}
      pageType={pageType}
      rebrand={rebrand}
      lang={pageContext.lang}
      pageCandidateContent={pageCandidateContent}
      menuType={menuType}
      isFunnel={isFunnel}
      seo={{
        title: pageContent.meta_title,
        description: pageContent.meta_description,
        image: { filename: pageContent.meta_image?.filename },
        noIndex: pageContent.noIndex || pageContent.no_index,
        canonicalHref: pageContent.canonicalHref,
      }}
    >
      {element}
    </MainLayout>
  );
};
