import React from 'react';
import { MobileTimelineStepper, RenderIfDesktop, RenderIfTablet, TimelineStepper } from '@marbletech/components';
import { TimelineProps } from './Timeline.types';
import { ContentContainer } from '../ContentContainer';
import { SectionContainer, Title, TitleMobile } from './Timeline.styled';

export const Timeline = ({ pageContent, isDesktop }: TimelineProps) => {
  const { fold3_h2: title, timeline } = pageContent;
  const steps = timeline?.[0]?.items?.map(({ title, content, image }) => ({
    title,
    content,
    image: image?.filename,
  }));
  const TimelineComponent = isDesktop ? TimelineStepper : MobileTimelineStepper;

  return (
    <SectionContainer>
      <ContentContainer>
        <RenderIfDesktop>
          <Title>{title}</Title>
        </RenderIfDesktop>
        <RenderIfTablet>
          <TitleMobile>{title}</TitleMobile>
        </RenderIfTablet>
        {steps && <TimelineComponent steps={steps} />}
      </ContentContainer>
    </SectionContainer>
  );
};
