import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';

export interface FunnelTitleProps {}

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2Bold,
  textAlign: 'center',
  maxWidth: 285,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h4,
    maxWidth: 410,
  },
}));

export const FunnelTitle: React.FunctionComponent<FunnelTitleProps> = ({ children }) => {
  return <Title {...{ component: 'h2' }}>{children}</Title>;
};

export default FunnelTitle;
