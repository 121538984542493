// @ts-ignore
import fetch from 'isomorphic-fetch';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { SentryLink } from 'apollo-link-sentry';

const httpLink = createHttpLink({
  uri: process.env.GATSBY_APOLLO_CLIENT,
  fetch,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: false,
        includeVariables: true,
        includeError: true,
        includeFetchResult: true,
      },
    }),
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

export default client;
