import React from 'react';
import { Link } from 'gatsby';
import { FlippingText, P1 } from '@marbletech/components';
import { isBrowser } from '@marbletech/utils';
import { colors, spacings } from '@marbletech/theme';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import {
  Container,
  TextAnimationWrapper,
  ContentContainer,
  ImgSection,
  Image,
  Eyebrow,
  Title,
  HeroContentLayout,
  HighlightText,
  sectionHeightSplitView,
} from './Hero.styled';
import { HeroProps } from './Hero.types';
import TextAnimation from '../../../../components/TextAnimation';
import { Button } from '../Button';
import { useDynamicTextReplacement } from '../../hooks/useDynamicTextReplacement';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const Hero = ({ pageContent, isDesktop }: HeroProps) => {
  const {
    h5: subtitle,
    h2: title,
    texts_for_animation: textForAnimation,
    cta: buttonText,
    cta_link: link,
    header_p1: captionText,
    image,
    image_mobile,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();
  //Dynamic text from H5 - {{[[ip_city]]}} Family Law
  const { replaceDynamicText } = useDynamicTextReplacement();
  const dynamicSubtitle = replaceDynamicText(subtitle);
  const dynamicH2 = replaceDynamicText(title);
  const dynamicImageAlt = replaceDynamicText(image?.alt);

  const words = textForAnimation?.map(({ list_item }) => list_item);
  const { filename: heroImg, alt } = image || {};
  const { filename: heroMobileImg } = image_mobile || {};
  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Hero Section' });
  //isBrowser: true - run time, false - build time
  let heroImage = { image: heroImg, alt };
  if (isBrowser() && !isDesktop) {
    heroImage = { image: heroMobileImg, alt };
  }
  const isImage = !!heroImage?.image;

  return (
    <Container isSplitView={isImage}>
      <ContentContainer
        direction={'row'}
        justify={'end'}
        align={isDesktop ? 'center' : 'stretch'}
        isSplitView={isImage}
      >
        <HeroContentLayout isSplitView={isImage}>
          <TextAnimation Component={TextAnimationWrapper}>
            <Eyebrow color={'bright'}>{dynamicSubtitle}</Eyebrow>
            <Title isSplitView={isImage}>
              {dynamicH2}
              {!!words.length && (
                <HighlightText>{words.length > 1 ? <FlippingText words={words} /> : words[0]}</HighlightText>
              )}
              {captionText && (
                <P1 margin={{ top: spacings[2], bottom: '0px' }} color={colors.bright}>
                  {captionText}
                </P1>
              )}
            </Title>

            {buttonText && (
              <Link to={getLocalizedLink({ pathname: link })}>
                <Button
                  text={buttonText}
                  size={isDesktop ? 'xxlarge' : 'xlarge'}
                  onClick={() => {
                    handleCtaClick();
                    triggerNewBiEvent({
                      eventName: NewBiEventsOptions.WebPageCtaClick,
                      eventData: {
                        location: 'Hero Section',
                        cta_text: buttonText,
                        click_url: link,
                      },
                    });
                  }}
                />
              </Link>
            )}
          </TextAnimation>
        </HeroContentLayout>
      </ContentContainer>
      {isImage && (
        <ImgSection>
          <Image src={`${heroImage.image}/m/0x${sectionHeightSplitView}`} alt={dynamicImageAlt} />
        </ImgSection>
      )}
    </Container>
  );
};
