import styled from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoints, spacings } from '@marbletech/theme';
import { Flex } from '@marbletech/components';

import { ContainerProps } from './HeaderSection.types';
import { HEADER_HEIGHT_MOBILE } from '../../../../consts';

export const Container = styled(Flex)<ContainerProps>`
  flex: 0 0 592px;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin-bottom: ${spacings[10]};
  justify-content: center;
  transition: flex-basis 300ms ease-in;
  @media (max-width: ${breakpoints.md}px) {
    background-image: url(${(props) => props.backgroundMobile});
    flex: 0 0 calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
    margin-bottom: ${spacings[4]};
  }
  @media (min-width: ${breakpoints.xl}px) {
    flex: 0 0 790px;
  }
`;

export const TextContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
`;
