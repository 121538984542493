import React from 'react';
import { Box } from 'grommet';
import { Flex } from '@marbletech/components';
import { spacings, colors } from '@marbletech/theme';
import { OptionsListProps } from './OptionsList.types';
import { Item, SeparatorItem, Line } from './OptionsList.styles';

export const OptionsList: React.FC<OptionsListProps> = ({
  children,
  separatorText = 'OR',
  separatorColor = 'green100',
}) => {
  return (
    <Box gap={spacings[3]}>
      {React.Children.map(children, (child, index) => {
        const isFirst = index === 0;
        const isLast = index === children.length - 1;
        return (
          <Item key={index}>
            {isFirst && <Line />}
            {child}
            {!isLast && (
              <Flex>
                <SeparatorItem background={colors[separatorColor]}>{separatorText}</SeparatorItem>
                <Box align="center" direction={'row'} width={'100%'}>
                  <Line />
                </Box>
              </Flex>
            )}
          </Item>
        );
      })}
    </Box>
  );
};
