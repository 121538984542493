// dynamically load JavaScript files in our html with callback when finished
export const loadScript = (url: string, callback: () => void) => {
  let script = document.createElement('script'); // create script tag
  script.type = 'text/javascript';
  HTMLScriptElement;

  // when script state is ready and loaded or complete we will call callback
  // @ts-ignore
  if (script.readyState) {
    // @ts-ignore
    script.onreadystatechange = function () {
      // @ts-ignore
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        // @ts-ignore
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName('head')[0].appendChild(script); // append to head
};
