import React from 'react';
import { Link } from 'gatsby';
import { RenderIfDesktop, RenderIfTablet } from '@marbletech/components';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';
import { useNavigation } from '@marble/website/src/context/Context';
import { MovesYouForwardProps } from './MovesYouForward.types';
import { ContentContainer } from '../../../layouts/LandingPage/components/ContentContainer';
import { Container, Title, TitleMobile } from './MovesYouForward.styled';
import { Button } from '../../../layouts/LandingPage/components/Button';

export const MovesYouForward: React.FC<MovesYouForwardProps> = (props) => {
  const { title, cta: buttonText, backgroundColor = 'primary100', ctaLink: link, isDesktop } = props;

  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Moves You Forward' });
  const { getLocalizedLink } = useNavigation();

  return (
    <Container background={backgroundColor}>
      <ContentContainer>
        <RenderIfDesktop>
          <Title>{title}</Title>
        </RenderIfDesktop>
        <RenderIfTablet>
          <TitleMobile>{title}</TitleMobile>
        </RenderIfTablet>
        <Link to={getLocalizedLink({ pathname: link })}>
          <Button text={buttonText} variant={'secondary'} onClick={handleCtaClick} />
        </Link>
      </ContentContainer>
    </Container>
  );
};
