import React, { useEffect, useState } from 'react';
import { SplitFactory, SplitClient } from '@splitsoftware/splitio-react';
import { DUMMY_KEY, getUniqueUserKey, setUniqueUserKey, SplitUserData } from './get-unique-user-key';
import { eventService } from '../eventService';
import { isBrowser } from '@marbletech/utils';
import { getItem } from '@marble/website/src/utils/local-storage/local-storage';

const initialUserData = getUniqueUserKey();

const getSplitConfig = (): SplitIO.IBrowserSettings => {
  const splitConfigOverrideStr = getItem('splitConfigOverride');
  if (splitConfigOverrideStr) {
    try {
      const envSplitConfig = JSON.parse(splitConfigOverrideStr);
      return envSplitConfig as SplitIO.IBrowserSettings;
    } catch (err) {
      console.warn('Failed to parse SplitIO config');
    }
  }
  return {
    core: {
      authorizationKey: process.env.GATSBY_SPLITIO_API_KEY,
      key: initialUserData.splitKey,
      trafficType: initialUserData.trafficType,
    },
    scheduler: {
      impressionsRefreshRate: 1, // 10 sec
      eventsPushRate: 10, // 10 sec
    },
  };
};

const sdkConfig = getSplitConfig();

export const SplitUserDataContext = React.createContext<SplitUserData>(initialUserData);

export const SplitContext = ({ children }: any) => {
  const [userData, setUserData] = useState<SplitUserData>(initialUserData);
  const [tOut, setTout] = useState<NodeJS.Timeout>(null);

  // Split React SDK doesn't support SSR at the moment
  if (!isBrowser()) {
    return children;
  }

  useEffect(() => {
    const anonymousIdHandler = (anonId: string) => {
      // We want to wait for segment's anonymous id to be ready and use it as the split key.
      const data = setUniqueUserKey(anonId);
      setUserData(data);
    };

    eventService.registerAnonymousIdChanged(anonymousIdHandler);
    return () => {
      eventService.unregisterAnonymousIdChanged(anonymousIdHandler);
    };
  }, []);

  useEffect(() => {
    // We want to have some kind of timeout if segment / anonymous id was not set from some reason
    clearTimeout(tOut);
    setTout(null);
    if (userData.splitKey !== DUMMY_KEY) {
      return;
    }
    setTout(
      setTimeout(() => {
        const data = setUniqueUserKey(Math.random().toString(36).substring(10));
        setUserData(data);
      }, 5 * 1000),
    );

    return () => {
      clearTimeout(tOut);
    };
  }, [userData.splitKey]);

  return (
    <SplitUserDataContext.Provider value={userData}>
      <SplitFactory config={sdkConfig}>
        <SplitClient splitKey={userData.splitKey} trafficType={userData.trafficType}>
          {children}
        </SplitClient>
      </SplitFactory>
    </SplitUserDataContext.Provider>
  );
};
