import React from 'react';
import { Testimonials } from '@marbletech/components';
import { SectionContainer } from './TestimonialsSection.styled';
import { TestimonialsSectionProps } from './TestimonialsSection.types';
import { render, MARK_BOLD, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';
import styled from 'styled-components';
import { QuotationMark } from '../../../LandingPage/components/Testimonials';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/pagination';

const HighlightText = styled.span`
  color: ${(props) => props.theme.global.colors.green100};
`;

export const TestimonialsSection: React.FC<TestimonialsSectionProps> = (props) => {
  const { pageContent } = props;

  return (
    <SectionContainer>
      <QuotationMark />
      <Testimonials>
        {pageContent.client_stories.map((clientStory, index) => (
          <Testimonials.Item stars={5} name={clientStory.p1} key={index}>
            {render(clientStory?.p2, {
              markResolvers: {
                [MARK_BOLD]: (children) => <HighlightText>{children}</HighlightText>,
                [NODE_PARAGRAPH]: (children) => <>{children}</>,
              },
            })}
          </Testimonials.Item>
        ))}
      </Testimonials>
    </SectionContainer>
  );
};

export default TestimonialsSection;
