import loadable, { LoadableComponent } from '@loadable/component';
import { HeroAboutProps } from './components/HeroAbout/HeroAbout';
import { HeroWebinarProps } from './components/HeroWebinar/HeroWebinar';
import { TestimonialsProps } from './components/Testimonials/Testimonials';
import { TeamBehindCareProps } from './components/TeamBehindCare/TeamBehindCare';
import { KnowYourPriceProps } from './components/KnowYourPrice/KnowYourPrice';
import { MarbleGuidesProps } from './components/MarbleGuides/MarbleGuides';
import { BottomTitleProps } from './components/BottomTitle/BottomTitle';
import { WeHandleStripProps } from './components/WeHandleStrip/WeHandleStrip';
import { PayNothingUpfrontProps } from './components/PayNothingUpfront/PayNothingUpfront';
import { PageIntroProps } from './components/PageIntro/PageIntro';
import { TransparentPricingProps } from './components/TransparentPricing/TransparentPricing';
import { OurDifferenceProps } from './components/OurDifference/OurDifference';
import { TeamBehindCareStaticProps } from './components/TeamBehindCareStatic/TeamBehindCareStatic';
import { OnTheCaseProps } from './components/OnTheCase/OnTheCase';
import { LegalPageProps } from './components/LegalPage/LegalPage';
import { ContactFormProps } from './components';
import { SectionSeparatorProps } from './components/SectionSeparator/SectionSeparator';
import { CalendlyProps } from './components/Calendly/Calendly';
import { ProvidingHopeProps } from './components/ProvidingHope/ProvidingHope';
import { FunnelDescriptionProps } from './components/FunnelDescription/FunnelDescription';
import { WebinarFormProps } from './components/WebinarForm/WebinarForm';
import { FAQProps } from './components/FAQ/FAQ';
import { AvailableStatesProps } from './components/AvailableStates/AvailableStates';
import { RelatedPracticesProps } from './components/RelatedPractices/RelatedPractices';
import { VideoBlockProps } from './components/VideoBlock/VideoBlock';

export { default as MainNavigation } from './components/Menu/MainNavigation/MainNavigation';
export { default as HeroHomePage } from './components/HeroHomePage/HeroHomePage';
export const HeroWebinar: LoadableComponent<HeroWebinarProps> = loadable(() =>
  import('./components').then((module) => module.HeroWebinar),
);
export const VideoBlock: LoadableComponent<VideoBlockProps> = loadable(() =>
  import('./components').then((module) => module.VideoBlock),
);
export { default as YourLegalNeeds } from './components/YourLegalNeeds/YourLegalNeeds';
export const Testimonials: LoadableComponent<TestimonialsProps> = loadable(() =>
  import('./components').then((module) => module.Testimonials),
);
export const TeamBehindCare: LoadableComponent<TeamBehindCareProps> = loadable(() =>
  import('./components').then((module) => module.TeamBehindCare),
);
export const KnowYourPrice: LoadableComponent<KnowYourPriceProps> = loadable(() =>
  import('./components').then((module) => module.KnowYourPrice),
);
export const MarbleGuides: LoadableComponent<MarbleGuidesProps> = loadable(() =>
  import('./components').then((module) => module.MarbleGuides),
);
export const BottomTitle: LoadableComponent<BottomTitleProps> = loadable(() =>
  import('./components').then((module) => module.BottomTitle),
);
export const WeHandleStrip: LoadableComponent<WeHandleStripProps> = loadable(() =>
  import('./components').then((module) => module.WeHandleStrip),
);
export const PayNothingUpfront: LoadableComponent<PayNothingUpfrontProps> = loadable(() =>
  import('./components').then((module) => module.PayNothingUpfront),
);
export const PageIntro: LoadableComponent<PageIntroProps> = loadable(() =>
  import('./components').then((module) => module.PageIntro),
);
export const TransparentPricing: LoadableComponent<TransparentPricingProps> = loadable(() =>
  import('./components').then((module) => module.TransparentPricing),
);
export const HeroAbout: LoadableComponent<HeroAboutProps> = loadable(() =>
  import('./components').then((module) => module.HeroAbout),
);
export const OurDifference: LoadableComponent<OurDifferenceProps> = loadable(() =>
  import('./components').then((module) => module.OurDifference),
);
export const TeamBehindCareStatic: LoadableComponent<TeamBehindCareStaticProps> = loadable(() =>
  import('./components').then((module) => module.TeamBehindCareStatic),
);
export const OnTheCase: LoadableComponent<OnTheCaseProps> = loadable(() =>
  import('./components').then((module) => module.OnTheCase),
);
export const LegalPage: LoadableComponent<LegalPageProps> = loadable(() =>
  import('./components').then((module) => module.LegalPage),
);
export { default as MaxWidthContainer } from './components/MaxWidthContainer/MaxWidthContainer';
export { default as PaddingContainer } from './components/PaddingContainer/PaddingContainer';
export const ContactForm: LoadableComponent<ContactFormProps> = loadable(() =>
  import('./components').then((module) => module.ContactForm),
);
export const SectionSeparator: LoadableComponent<SectionSeparatorProps> = loadable(() =>
  import('./components').then((module) => module.SectionSeparator),
);
export const Calendly: LoadableComponent<CalendlyProps> = loadable(() =>
  import('./components').then((module) => module.Calendly),
);
export const ProvidingHope: LoadableComponent<ProvidingHopeProps> = loadable(() =>
  import('./components').then((module) => module.ProvidingHope),
);
export const FunnelDescription: LoadableComponent<FunnelDescriptionProps> = loadable(() =>
  import('./components').then((module) => module.FunnelDescription),
);
export const WebinarForm: LoadableComponent<WebinarFormProps> = loadable(() =>
  import('./components').then((module) => module.WebinarForm),
);
export const AvailableStates: LoadableComponent<AvailableStatesProps> = loadable(() =>
  import('./components').then((module) => module.AvailableStates),
);
export const RelatedPractices: LoadableComponent<RelatedPracticesProps> = loadable(() =>
  import('./components').then((module) => module.RelatedPractices),
);
export const FAQ: LoadableComponent<FAQProps> = loadable(() => import('./components').then((module) => module.FAQ));

export { useStore, usePhoneNumber, useFunnelAnswers, StoreProvider } from './context/Context';
export type { ContactFormProps, OurLawyersStoryblok } from './components';
export * from './context/split';
export * from './context/sentry';
export type { Store, PhoneNumber, PhoneNumbersArray, OperationalState, Image, GeoLocation } from './context/Context';
export { extractUTM, emptyUTMs } from './utils';
export { logFullStoryEvent } from './loggers';
