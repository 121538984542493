import React from 'react';
import { OptimizedAssetImageProps, RawImageProps } from './interfaces';

// Displays an image from Storyblok assets, but optimized through Storyblok
// optimization service.

// By default, an image is lazy-loaded after scrolling. To change that, use
// loading="eager" attribute.

const RawImage: React.FC<RawImageProps> = (props: RawImageProps) => {
  const { component: Component, ...imgProps } = props;

  return Component ? <Component {...imgProps} /> : <img {...imgProps} />;
};

const OptimizedAssetImage: React.FC<OptimizedAssetImageProps> = (props: OptimizedAssetImageProps) => {
  const { maxWidth, maxHeightPercent, component, src, ...imgProps } = props;

  if (!src || !src.includes('https://a.storyblok.com/') || src.endsWith('.svg')) {
    // not optimizable - just passthrough
    return <RawImage component={component} src={src} {...imgProps} />;
  }

  const maxHeight = Math.ceil(maxWidth * ((maxHeightPercent ?? 0) / 100));

  const srcReducedWebP = src.replace(
    'https://a.storyblok.com/',
    `https://img2.storyblok.com/${maxWidth}x${maxHeight}/filters:format(webp)/`,
  );
  const srcReduced = src.replace('https://a.storyblok.com/', `https://img2.storyblok.com/${maxWidth}x${maxHeight}/`);

  return (
    <picture>
      <source type="image/webp" srcSet={srcReducedWebP} />
      <RawImage loading="lazy" {...imgProps} src={srcReduced} component={component} />
    </picture>
  );
};

export default OptimizedAssetImage;
