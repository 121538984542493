import React, { FunctionComponent } from 'react';
import { Box } from 'grommet';
import { useWindowSize } from '@marbletech/hooks';
import { breakpoints, colors } from '@marbletech/theme';
import { Page } from '@marbletech/components';
import { DynamicLandingPageProps } from './LandingPage.types';
import { ComponentsTypes } from './LandingPage.types';
import {
  Hero,
  WeCanHelp as ListItems,
  Faq,
  MovesYouForward as MoveForward,
  Testimonials,
  ChooseMarble,
  Timeline,
  Banner,
  SplitViewBanner,
  HorizontalPanelItems,
  OptionsStackWithImage,
  ComparisonTable,
} from '../../components/Marketing';
import { useSetGeolocationState } from './hooks/useSetGeolocationState';

const componentsMap: Record<string, FunctionComponent<any>> = {
  Hero,
  SplitViewBanner,
  HorizontalPanelItems,
  ComparisonTable,
  ListItems,
  OptionsStackWithImage,
  Faq,
  MoveForward,
  Testimonials,
  ChooseMarble,
  Timeline,
  Banner,
};

export const DynamicLandingPage: React.FC<DynamicLandingPageProps> = ({ data: { pageContent: content } }) => {
  const { components, ctaLink, states_array: stateInfo } = content;
  const { width } = useWindowSize({ width: 0, height: 0 });
  const isDesktop = width > breakpoints.md;
  useSetGeolocationState(stateInfo);

  return (
    <>
      <Page.HeaderOffset />
      {components.map((data: ComponentsTypes) => {
        const Component = componentsMap[data.component];

        if (Component) {
          return (
            <Box background={colors[data.backgroundColor]}>
              <Component ctaLink={ctaLink} isDesktop={isDesktop} {...data} />
            </Box>
          );
        }
      })}
    </>
  );
};

export default DynamicLandingPage;
