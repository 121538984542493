import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Content = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
`;

// Component must be a motion element.
export const TextAnimation = ({ children, Component }: any) => {
  const CustomComponent = Component || Content;

  return (
    <CustomComponent
      variants={{
        exit: {
          opacity: 0,
          y: 20,
        },
        enter: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            type: 'tween',
          },
        },
      }}
      initial="exit"
      animate="enter"
    >
      {children}
    </CustomComponent>
  );
};

export default TextAnimation;
