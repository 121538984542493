import { Calendly, useStore } from '@marble/website';
import * as Sentry from '@sentry/gatsby';
import styled from 'styled-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { H4, H5, RenderIfDesktop, RenderIfTablet } from '@marbletech/components';
import { navigate, PageProps } from 'gatsby';
import { EventName, eventService } from '../services/eventService';
import { BackButton } from '../components/BackButton';
import { theme } from '@marbletech/theme';
import { PrivacyNote } from '../components/PrivacyNote';
import { extractRootDomain, joinWithPipe, useHash } from './ThankYou/utils';
import { useScrollIntoView } from '../hooks/useScrollIntoView';
import { statesObject } from '@marble/utils';
import { NewBiEventsOptions, triggerNewBiEvent } from '../utils/newEvents';
import { isBrowser } from '@marbletech/utils';

export interface BookACallProps {
  pageContent: any;
}

const BackButtonWrapper = styled.div`
  position: absolute;
  left: 112px;
  top: 10px;
  cursor: pointer;

  @media (max-width: ${theme.breakpoints.md}px) {
    left: 27px;
    top: -40px;
  }
`;

const Container = styled.div`
  padding-top: ${theme.gutters.base * 18}px;
  position: relative;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${theme.gutters.base * 16.5}px;
  }
`;

export const BookACall: FunctionComponent<PageProps<BookACallProps>> = ({ data }) => {
  const { pageContent } = data || {};
  const { title, calendlyLinksMap, privacyNote, learnMoreText, learnMoreLink } = pageContent || {};
  const { utm, person, funnelMeta, externalStateCode } = useStore();
  const state = externalStateCode ? statesObject[externalStateCode] : null;
  const practiceArea = funnelMeta?.practiceArea?.toUpperCase();
  const hashUser = useHash();
  const { ref, scrollIntoView } = useScrollIntoView();
  const [loading, setLoading] = useState<boolean>(true);
  const [rootDomain, setRootDomain] = useState<string>('');
  const anonymousId = isBrowser() && window.eventService.anonymousUserId;

  // we extract the root domain name and send it as part of utm_term to calendly
  // Whenever calendly redirects after confirmation, cloudfront will handle it according to the root domain
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRootDomain(extractRootDomain());
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      eventService.triggerEvent({ eventName: EventName.OnlineFunnelSchedulePageView });
    }
  }, [loading]);

  const handleEventSchedule = async (MessageEvent: MessageEvent) => {
    const { phone, email, firstName, lastName } = person;
    const userDetails = { phone, email, firstName, lastName, fullName: `${firstName} ${lastName || ''}` };
    try {
      const hashResult = await hashUser(userDetails);
      const { hashedSaltedUser, hashedUnsaltedUser } = hashResult;

      eventService.setDataLayerVariables({
        hashed_user_details: hashedUnsaltedUser,
      });

      eventService.triggerEvent({
        eventName: EventName.OnlineFunnelCalendlyFlowSuccess,
        eventData: { personal_details: hashedSaltedUser, raw_personal_details: userDetails },
      });

      triggerNewBiEvent({
        eventName: NewBiEventsOptions.WebCalendarSuccess,
        eventData: {
          email_hash: hashedSaltedUser.email,
          phone_hash: hashedSaltedUser.phone,
        },
      });
    } catch (err) {
      Sentry.captureException(err, { user: userDetails });
    }
  };

  return (
    <Container ref={ref}>
      <BackButtonWrapper onClick={() => navigate(-1)}>
        <BackButton />
      </BackButtonWrapper>
      <RenderIfDesktop>
        <H4 textAlign={'center'} margin={{ bottom: '0px', top: '0px' }} fill>
          {title}
        </H4>
      </RenderIfDesktop>
      <RenderIfTablet>
        <H5 textAlign={'center'} margin={{ bottom: '40px', top: '0px' }} fill>
          {title}
        </H5>
      </RenderIfTablet>
      <Calendly
        {...{
          utm: {
            utmCampaign: utm.campaignId,
            utmContent: utm.content,
            utmMedium: utm.medium,
            utmSource: utm.source,
            utmTerm: joinWithPipe(utm.keyword, practiceArea, state, rootDomain, anonymousId),
          },
          onEventScheduled: handleEventSchedule,
          onDateAndTimeSelected: () => {
            triggerNewBiEvent({
              eventName: NewBiEventsOptions.WebCalendarClick,
            });

            scrollIntoView();
          },
          onLoad: () => {
            setLoading(false);
          },
          calendlyLinksMap: calendlyLinksMap ?? {},
        }}
      />
      <PrivacyNote text={privacyNote} linkText={learnMoreText} link={learnMoreLink} />
    </Container>
  );
};

export default BookACall;
