import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marbletech/theme';

import { absoluteBlow } from '@marble/style-utils';

import { Typography } from '@marbletech/components';
import { SectionSeparator } from '@marble/website';
import { Link, PageProps } from 'gatsby';

import { Image, useNavigation } from '@marble/website/src/context/Context';
import { ContentItem, StoryblokImage } from '../storyblok-defs';
import { TopContainer } from '../components/HeroGeneral/HeroGeneral';
import GradientButton from '../components/GradientButton/GradientButton';
import PracticeAreaCard from '../components/PracticeAreaCard/PracticeAreaCard';

export interface ChoosePracticeAreaProps {
  page: {
    id: string;
    content: string;
    lang: string;
    slug: string;
    uuid: string;
    name: string;
    full_slug: string;
  };
}

interface Content extends ContentItem {
  title: string;
  subtitle: string;
  practiceAreas: Array<
    {
      icon: Image;
      title: string;
      text: string;
      link: string;
    } & ContentItem
  >;
  backgroundImageMobile: StoryblokImage;
  backgroundImageDesktop: StoryblokImage;

  phoneNumber: string;
  phoneLink: string;
  funnelLink: string;

  meta_title: string;
  meta_description: string;
  meta_image: StoryblokImage;
  noIndex: boolean;
  canonicalHref: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  zIndex: 10,
  backgroundImage: `linear-gradient(to bottom, ${theme.palette.main.secondary3}, #fff)`,
  backgroundSize: '100%',
}));

export const HeroImageMobile = styled('img')<{ theme: Theme }>(({ theme }) => ({
  ...absoluteBlow,
  objectFit: 'contain',
  width: '100%',
  maxHeight: '100%',
  zIndex: -1,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const HeroImageDesktop = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
    ...absoluteBlow,
    objectFit: 'contain',
    width: '100%',
    maxHeight: '100%',
    zIndex: -1,
  },
}));

export const PageTitleWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 14,
  marginBottom: theme.gutters.base * 4,
  maxWidth: 300,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 28,
    marginBottom: theme.gutters.base * 8,
    maxWidth: 'initial',
  },
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h3,
  fontFamily: theme.typography.fontFamily[1],
  textAlign: 'center',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h2,
  },
}));

export const PageTitleSupportingTextWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 370,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    maxWidth: 470,
  },
}));

export const PageTitleSupportingText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.subtitle1,
  textAlign: 'center',
  marginBottom: theme.gutters.base * 5,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: theme.gutters.base * 8,
  },
}));

export const PracticesContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(6, max-content)',
  gridGap: theme.gutters.base * 2,
  padding: `0 ${theme.gutters.base * 2.5}px`,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    justifyContent: 'center',
    gridTemplateRows: 'repeat(2, max-content)',
    gridTemplateColumns: 'repeat(3, minmax(max-content, 359px))',
    gridGap: theme.gutters.base * 4,
  },
}));

export const MobileButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const DesktopCardWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'block',
  },
}));

export const ChoosePracticeArea: React.FunctionComponent<PageProps<ChoosePracticeAreaProps>> = ({ data }) => {
  const content: Content = data.pageContent;
  const { linkPrefix } = useNavigation();

  return (
    <Container>
      <TopContainer>
        <HeroImageMobile
          {...{
            src: content.backgroundImageMobile?.filename,
            alt: content.backgroundImageMobile?.alt,
          }}
        />
        <HeroImageDesktop
          {...{
            src: content.backgroundImageDesktop?.filename,
            alt: content.backgroundImageDesktop?.alt,
          }}
        />
        <PageTitleWrap>
          <Title>{content?.title}</Title>
        </PageTitleWrap>
      </TopContainer>
      <PracticesContainer>
        {content?.practiceAreas?.map(({ icon, title, text, link }) => {
          return (
            <div {...{ key: text }}>
              <MobileButtonWrap>
                <Link {...{ to: linkPrefix + link, style: { textDecoration: 'none' } }}>
                  <GradientButton {...{ title }} />
                </Link>
              </MobileButtonWrap>

              <DesktopCardWrap>
                <Link {...{ to: linkPrefix + link, style: { textDecoration: 'none' } }}>
                  <PracticeAreaCard {...{ icon: icon.filename, title, text, link }} />
                </Link>
              </DesktopCardWrap>
            </div>
          );
        })}
      </PracticesContainer>
      <SectionSeparator {...{ mobileSeperation: 100, desktopSeperation: 0 }} />
    </Container>
  );
};

export default ChoosePracticeArea;
