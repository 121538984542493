import styled from 'styled-components';
import { P2 } from '@marbletech/components';
import { theme } from '@marbletech/theme';

export const Card = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 40px 24px 40px;
  min-height: 472px;
  cursor: pointer;
  :hover {
    background: ${({
      theme: {
        global: { colors },
      },
    }) => colors.green100};
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 48px 28px 24px 28px;
    min-height: 456px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Img = styled.img`
  width: 112px;
  max-height: 112px;
  height: 100%;
`;

export const Text = styled(P2)`
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LearnMoreWrapper = styled.div`
  display: flex;
  border-top: 1px solid #192348;
  width: 100%;
  padding: 16px 16px 0 16px;

  @media (max-width: ${theme.breakpoints.md}px) {
    justify-content: center;
  }
`;

export const LearnMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled(P2)`
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-right: 8px;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-weight: 600;
  }
`;
