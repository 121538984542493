import { Store } from '@marble/website';
import { HashUserResult } from './ThankYouPage.types';
import { useHashMutation } from '../../generated/graphql';
import { PracticeArea } from '../../api/api';

export function getFunnelAnswers(
  funnelAnswers: Store['funnelAnswers'],
  abTestData: Store['abTestData'],
): Store['funnelAnswers'][PracticeArea] {
  const practiceAreas: PracticeArea[] = [
    'Employment',
    'DUI',
    'Immigration',
    'Bankruptcy',
    'Family',
    'Criminal',
    'Estate_Planning',
    'Tax',
  ];

  for (const practiceArea of practiceAreas) {
    if (practiceArea in funnelAnswers) {
      return {
        ...(abTestData?.testName ? abTestData : {}),
        ...enrichFunnelAnswers(funnelAnswers[practiceArea]),
      };
    }
  }
  return null;
}

function enrichFunnelAnswers(
  funnelAnswers: Store['funnelAnswers'][PracticeArea],
): Store['funnelAnswers'][PracticeArea] {
  let enrichedFunnelAnswers = { ...funnelAnswers };

  const zipCode = funnelAnswers?.zipCode;
  const courtDate = funnelAnswers?.courtDateExist;

  if (zipCode && typeof zipCode === 'object') {
    const zipCodeRecord = zipCode as Record<string, string>;
    enrichedFunnelAnswers.countyFunnel = zipCodeRecord?.county ?? '';
    enrichedFunnelAnswers.zipCode = zipCodeRecord?.zipCode ?? '';
  }

  if (courtDate) {
    enrichedFunnelAnswers.courtDateFunnel = courtDate;
  }

  return enrichedFunnelAnswers;
}

export const useHash = () => {
  const [hash] = useHashMutation();

  const hashUser = async (userDetails: Record<string, string>): Promise<HashUserResult> => {
    const [hashedSaltedUser, hashedUnsaltedUser] = await Promise.all(
      [null, { useSalt: false }].map(async (hashOptions) => {
        const hashResult = await hash({
          variables: {
            input: userDetails,
            hashOptions,
          },
        });
        return hashResult?.data?.hash;
      }),
    );
    return {
      hashedSaltedUser: hashedSaltedUser.hashed,
      hashedUnsaltedUser: hashedUnsaltedUser.hashed,
      hashVersion: hashedSaltedUser.version,
    };
  };

  return hashUser;
};

export const joinWithPipe = (...args: string[]) => args.map((value) => value ?? 'null').join('|');

export const extractRootDomain = () => {
  const hostname = window.location?.hostname;
  return hostname.replace('www.', '');
};
