import React from 'react';
import { Box } from 'grommet';
import { XIcon } from '@marble/icons';
import { P2, P3, H5, H6, OptimizedAssetImage } from '@marbletech/components';
import { spacings, colors } from '@marbletech/theme';
import { ComparisonTableSecondaryCardProps } from './ComparisonTableSecondaryCard.types';
import {
  SecondaryCard,
  SecondaryCardHeader,
  SecondaryCarditem,
  SecondaryCardListItemIcon,
} from './ComparisonTableSecondaryCard.styled';
import { CardItemsContainer } from '../../ComparisonTableSection.styled';
import { ImageComponent } from '../shared/ImageComponent';

export const ComparisonTableSecondaryCard: React.FC<ComparisonTableSecondaryCardProps> = (props) => {
  const { title, items, image, color, isDesktop } = props;
  const { filename: imageLink, alt } = image;

  const iconComponent = (
    <SecondaryCardListItemIcon background={colors[color]}>
      <XIcon />
    </SecondaryCardListItemIcon>
  );

  return (
    <SecondaryCard>
      <SecondaryCardHeader>
        {imageLink && <OptimizedAssetImage src={imageLink} alt={alt} maxWidth={0} />}
        <H5 whiteSpace={'pre-wrap'}>{title}</H5>
      </SecondaryCardHeader>
      <CardItemsContainer>
        {items.map(({ title, subtitle, image: { filename: itemimage, alt: itemAlt } }, index) => (
          <SecondaryCarditem key={`${subtitle}${index}`}>
            <ImageComponent image={itemimage} alt={itemAlt} iconComponent={iconComponent} />
            {isDesktop && <P2 whiteSpace={'pre-wrap'}>{subtitle}</P2>}
            {!isDesktop && (
              <Box gap={spacings[0.5]}>
                <H6>{title}</H6>
                <P3 whiteSpace={'normal'}>{subtitle}</P3>
              </Box>
            )}
          </SecondaryCarditem>
        ))}
      </CardItemsContainer>
    </SecondaryCard>
  );
};
