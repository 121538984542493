import styled from 'styled-components';
import { Colors, devices } from '@marbletech/theme';
import { Section } from '../../../Homepage/shared';

export const Container = styled(Section)<{ bgColor?: Colors }>`
  background:  ${({ bgColor, theme }) => theme.global.colors[bgColor] ?? theme.global.colors.primary300};
  padding: 152px 0px;
  box-sizing: border-box;

  ${devices.mobile} {
    padding: 136px 0px;
  }
`;
