import * as React from 'react';
import { navigate, PageProps } from 'gatsby';
import { FunnelComponent, StepsDescription } from '@marble/website/src/components/Funnel_New/Funnel';

import { ContentItem, StoryblokImage } from '../storyblok-defs';
import { PracticeArea } from '../api/api';
import { FEATURE_FLAG_FUNNELS_AA_TEST, useFeatureFlag } from '@marble/website';

export interface FunnelProps {
  pageContent: FunnelContent;
}

interface FunnelContent extends ContentItem {
  practiceArea: PracticeArea;
  steps: StepsDescription[];
  backgroundImage: StoryblokImage;
  calendlyLink: string;
  smsButtonText: string;
  isReviewsWidgetEnabled?: boolean;
  phoneNumbers: Array<
    {
      key: string;
      phoneLink: string;
      prettyPhone: string;
    } & ContentItem
  >;
}

export const Funnel: React.FunctionComponent<PageProps<FunnelProps>> = ({ data }) => {
  useFeatureFlag(FEATURE_FLAG_FUNNELS_AA_TEST);

  const content: FunnelContent = data.pageContent;

  const { practiceArea, isReviewsWidgetEnabled = false } = content;

    console.log('XXX Funnel');

  // TODO - FunnelComponent uses Category, we need to kill it, need to make sure what the BE expects.
  return (
    <>
      <FunnelComponent
        {...{
          navigate,
          steps: content.steps.reduce((acc: Record<string, StepsDescription>, step: StepsDescription) => {
            return {
              ...acc,
              [step.key]: step,
            };
          }, {}),
          backgroundImage: content.backgroundImage,
          practiceArea: practiceArea,
          category: practiceArea,
          calendlyLink: content.calendlyLink,
          isReviewsWidgetEnabled,
        }}
      />
    </>
  );
};

export default Funnel;
