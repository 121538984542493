import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Button, Typography } from '@marbletech/components';
import { NavItem } from '../MainNavigation/MainNavigation';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import MobileSubmenu from '../MobileSubmenu/MobileSubmenu';
import PortalButton from '../PortalButton/PortalButton';
import { useNavigation, useStore } from '../../../context/Context';

export interface MobileMenuProps {
  navItems: Array<NavItem>;
  setIsExpanded: (flag: boolean) => void;
  buttonText: string;
  signInText: string;
  signInButtonText: string;
}

export const DisplayContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    display: 'none',
  },
}));

export const Container = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  position: 'absolute',
  top: 24,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.main.bright,
  width: '100%',
  minHeight: 'calc(100vh - 32px - 24px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 3}px ${theme.gutters.base * 4}px`,
  overflowY: 'scroll',
}));

export const BottomContainer = styled('div')<{ theme: Theme; isSubmenuExpanded: boolean }>(
  ({ theme, isSubmenuExpanded }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'repeat(2, min-content)',
    gridGap: theme.gutters.base * 2,
    justifyItems: 'center',
    padding: `${theme.gutters.base * 2}px ${theme.gutters.base * 2.5}px`,
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.main.bright,
    ...(isSubmenuExpanded && {
      boxShadow: '0px -8px 20px rgba(162, 193, 201, 0.435888)',
    }),
  }),
);

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: 200,
}));

export const SignInContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  button: {
    paddingLeft: 0,
  },
}));

const containerAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export const MobileMenu: React.FunctionComponent<MobileMenuProps> = ({
  navItems,
  setIsExpanded,
  buttonText,
  signInText,
  signInButtonText,
}) => {
  const { userDetails } = useStore();
  const { linkPrefix } = useNavigation();
  const [isSubmenuExpanded, setIsSubmenuExpanded] = React.useState<boolean>(false);

  return (
    <DisplayContainer>
      <Container {...{ variants: containerAnimation, initial: 'hidden', animate: 'show' }}>
        <div {...{ style: { height: 'calc(100% + 130px)', width: '100%' } }}>
          {navItems?.map(({ title, subItems, link }) => {
            if (subItems) {
              return (
                <div
                  {...{
                    onClick: () => setIsSubmenuExpanded(!isSubmenuExpanded),
                    style: {
                      width: '100%',
                    },
                    key: title,
                  }}
                >
                  <MobileSubmenu
                    {...{
                      title,
                      subItems,
                      setIsExpanded,
                    }}
                  />
                </div>
              );
            } else {
              if (link) {
                return (
                  <div
                    {...{
                      onClick: () => setIsExpanded(false),
                      key: title,
                    }}
                  >
                    <MobileSubmenu
                      {...{
                        title,
                        setIsExpanded,
                        link,
                      }}
                    />
                  </div>
                );
              }
            }
          })}
          <div {...{ style: { height: 130 } }} />
        </div>
        <BottomContainer {...{ isSubmenuExpanded }}>
          <ButtonWrap>
            <Link {...{ to: linkPrefix + '/practice', style: { width: '100%' } }}>
              <Button
                {...{
                  size: 'medium',
                }}
              >
                {buttonText}
              </Button>
            </Link>
          </ButtonWrap>
          {!userDetails ? (
            <SignInContainer>
              <Typography {...{ variant: 'p1SemiBold' }}>{signInText}</Typography>
              &nbsp;
              <PortalButton {...{ signInText: signInButtonText ?? 'Sign In' }} />
            </SignInContainer>
          ) : (
            <div {...{ style: { height: 40 } }} />
          )}
        </BottomContainer>
      </Container>
    </DisplayContainer>
  );
};

export default MobileMenu;
