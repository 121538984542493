import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ChevronSmallProps extends SVGIconProps {}

export const ChevronSmallIcon: React.FunctionComponent<ChevronSmallProps> = (props) => {
  return (
    <SVGIcon {...{ width: 8, height: 12, viewBox: '0 0 8 12', ...props }}>
      <path fillRule="evenodd" d="M0 10.99L1.153 12 8 6 1.153 0 0 1.01 5.695 6z" />
    </SVGIcon>
  );
};
