import React from 'react';
import { LinkProps } from './interfaces';
import StyleContainer from './style';
import { useNavigation } from '../../context/Context';

const Link = ({ children, to, onClick, target, className }: LinkProps) => {
  const { getLocalizedLink } = useNavigation();
  const localizedLink = getLocalizedLink({ pathname: to });

  return (
    <StyleContainer to={localizedLink || to} onClick={onClick} className={className} target={target}>
      {children}
    </StyleContainer>
  );
};

export default Link;
