import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { Typography } from '@marbletech/components';
import { motion } from 'framer-motion';
import { containerStaggerChildrenAnimation, staggeredItemAnimation } from './YourLegalNeeds';

export interface YourLegalNeedsCardDesktopProps {
  number: string;
  title: string;
  text: string;
  imgSrc: string;
  imgPosition: 'left' | 'right';
}

export const Container = styled(motion.div)<{
  theme: Theme;
  imgPosition: YourLegalNeedsCardDesktopProps['imgPosition'];
}>(({ theme, imgPosition }) => ({
  display: 'grid',
  gridGap: theme.gutters.base * 5,
  gridTemplateAreas: `
      'content'
      'image'
      `,
  width: 'auto',
  alignItems: 'center',
  ...(imgPosition === 'right' && {
    gridTemplateColumns: 'minmax(300px, 620px) 390px',
    gridGap: `clamp(0px, 10%, ${theme.gutters.base * 18}px)`,
    gridTemplateAreas: `'content image'`,
  }),
  ...(imgPosition === 'left' && {
    gridTemplateColumns: '390px minmax(300px, 620px)',
    gridGap: `clamp(0px, 10%, ${theme.gutters.base * 11.4}px)`,
    gridTemplateAreas: `'image content'`,
  }),
}));

export const ContentContainer = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'content',
  display: 'grid',
  gridTemplateColumns: '43px 1fr',
  gridTemplateRows: 'min-content 1fr',
  gridTemplateAreas: `
    'title title'
    '. text'
    `,
  gridRowGap: theme.gutters.base * 4,
}));

export const Number = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p2Bold,
  color: theme.palette.main.primary,
  gridArea: 'number',
  alignSelf: 'center',
  marginRight: theme.gutters.base * 3,
}));

export const Title = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h3,
}));

export const Text = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h4SemiBold,
}));

export const Img = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  gridArea: 'image',
  width: 390,
  height: 416,
}));

export const YourLegalNeedsCardDesktop: React.FunctionComponent<YourLegalNeedsCardDesktopProps> = ({
  number,
  title,
  text,
  imgSrc,
  imgPosition,
}) => {
  const cardContainerRef = React.useRef<HTMLDivElement>(null);
  const imgContainerRef = React.useRef<HTMLImageElement>(null);
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [isShownCardContainer, setIsShownCardContainer] = React.useState(false);
  const [cardContainerY, setCardContainerY] = React.useState(1000);

  const handleScroll = () => {
    setScrollY(window.pageYOffset);
    cardContainerRef.current && setCardContainerY(cardContainerRef.current.getBoundingClientRect().y);

    if (typeof window !== 'undefined') {
      if (cardContainerY < window.innerHeight * 0.875) {
        setIsShownCardContainer(true);
      }
    }
  };

  React.useEffect(() => {
    cardContainerRef.current && setCardContainerY(cardContainerRef.current.getBoundingClientRect().y);
    window.addEventListener('scroll', () => handleScroll());

    if (isShownCardContainer) {
      return window.removeEventListener('scroll', () => handleScroll());
    }
    return window.removeEventListener('scroll', () => handleScroll());
  }, [scrollY]);

  return (
    <Container
      {...{
        imgPosition,
      }}
    >
      <ContentContainer
        {...{
          ref: cardContainerRef,
          variants: containerStaggerChildrenAnimation,
          initial: 'hidden',
          animate: isShownCardContainer ? 'show' : 'hidden',
        }}
      >
        <motion.div
          {...{
            style: {
              gridArea: 'title',
              display: 'flex',
              alignItems: 'center',
            },
            variants: staggeredItemAnimation,
          }}
        >
          <Number>{number}</Number>
          <Title>{title}</Title>
        </motion.div>
        <motion.div
          {...{
            style: {
              gridArea: 'text',
            },
            variants: staggeredItemAnimation,
          }}
        >
          <Text>{text}</Text>
        </motion.div>
      </ContentContainer>
      <motion.div
        {...{
          variants: staggeredItemAnimation,
          ref: imgContainerRef,
          initial: 'hidden',
          animate: isShownCardContainer ? 'show' : 'hidden',
        }}
      >
        <Img
          {...{
            src: imgSrc,
          }}
        />
      </motion.div>
    </Container>
  );
};

export default YourLegalNeedsCardDesktop;
