import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ArrowSmallLeftProps extends SVGIconProps {}

export const ArrowSmallLeftIcon: React.FunctionComponent<ArrowSmallLeftProps> = (props) => {
  return (
    <SVGIcon {...{ width: 16, height: 16, viewBox: '0 0 16 16', ...props }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42326 0L7.56301 1.04292L2.78 7H16V8.5H2.378L7.56301 14.9571L6.42326 16L0 8L6.42326 0Z"
      />
    </SVGIcon>
  );
};
