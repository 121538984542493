import { eventService } from '@marble/g-website/src/services/eventService';
import { EventName } from '@marbletech/services';

export const getHandleCtaClick =
  ({ cta, position, callback }: { cta: string; position: string; callback?: () => void }) =>
  () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPageCtaClick,
      eventData: {
        cta,
        position,
      },
    });
    callback?.();
  };
