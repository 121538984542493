import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface StarBulletIconProps extends SVGIconProps {}

export const StarBulletIcon: React.FunctionComponent<StarBulletIconProps> = (props) => {
  return (
    <SVGIcon {...{ width: 16, height: 16, viewBox: '0 0 16 16', ...props }}>
      <path
        fillRule="evenodd"
        d="M8 0l.698 4.787L11.272.7l-1.3 4.659 4.006-2.682-3.071 3.725 4.743-.812-4.312 2.147L16 8.934l-4.808.197 3.774 3.002-4.472-1.786 2.234 4.285-3.363-3.46L9.672 16 8 11.463 6.328 16l.307-4.828-3.363 3.46 2.234-4.285-4.472 1.786L4.808 9.13 0 8.934l4.662-1.198L.35 5.589l4.743.812-3.07-3.725 4.004 2.682L4.728.699l2.574 4.088L8 0zm0 6.844c-.683 0-1.238.557-1.238 1.244 0 .688.555 1.245 1.238 1.245.683 0 1.238-.557 1.238-1.245 0-.687-.555-1.244-1.238-1.244z"
        transform="translate(-52 -3664) translate(52 3664)"
      />
    </SVGIcon>
  );
};
