import React from 'react';
import { Box } from 'grommet';
import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';

import { H5, H4, P2, P2Bold, P1, OptimizedAssetImage } from '@marbletech/components';
import { CallLineIcon } from '@marbletech/icons';
import { spacings, colors } from '@marbletech/theme';

import { LawyerInfoProps } from './LawyerInfo.types';
import { JobTitle, CallLineWrapper, PhoneNumberLabelContainer, ImageContainer, StyledLink } from './LawyerInfo.styled';
import { practiceAreaMatch } from '../../utils/practiceAreaMatch';

export const LawyerInfo: React.FC<LawyerInfoProps> = (props) => {
  const {
    name,
    field,
    address,
    phone,
    practiceAreaTitle,
    practiceAreas,
    practiceAreasMap,
    fieldState,
    image,
    attorneyPosition,
  } = props;
  // this function matches any practice area to its link and correct display text
  const practiceAreasWithLinks = practiceAreaMatch(practiceAreas, practiceAreasMap);

  return (
    <Box width={{ max: fieldState ? '100%' : '487px' }}>
      {attorneyPosition ? (
        <>
          <ImageContainer
            direction={'row'}
            justify={'center'}
            align={'flex-end'}
            margin={{ bottom: spacings[3] }}
            height={'213px'}
            background={{ color: colors.primary300 }}
          >
            {image && <OptimizedAssetImage maxWidth={384} src={image.filename} alt={image.alt} width={'100%'} />}
          </ImageContainer>
          <H5 margin={{ bottom: spacings[1], top: '0px' }}>{name}</H5>
          <JobTitle>
            {render(attorneyPosition, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children: any) => <P1>{children}</P1>,
              },
            })}
          </JobTitle>
        </>
      ) : (
        <>
          <H4 margin={{ bottom: spacings[1], top: '0px' }}>{name}</H4>
          <JobTitle>
            <P1>{field}</P1>
          </JobTitle>
        </>
      )}
      <P2 whiteSpace={'pre-line'}>{address}</P2>
      {phone && (
        <PhoneNumberLabelContainer as={'a'} href={`tel:+1 ${phone}`}>
          <CallLineWrapper>
            <CallLineIcon />
          </CallLineWrapper>
          <P2>{phone}</P2>
        </PhoneNumberLabelContainer>
      )}
      {attorneyPosition ? (
        <P2Bold margin={{ bottom: spacings[1], top: spacings[2] }}>{practiceAreaTitle}</P2Bold>
      ) : (
        <H5 margin={{ bottom: spacings[2], top: spacings[4] }}>{practiceAreaTitle}</H5>
      )}
      <P1 color={'primary100'}>
        {practiceAreasWithLinks?.map(
          ({ link, text }, index) =>
            text && (
              <StyledLink to={link} key={`area key${index}`}>
                {index ? ', ' : ''}
                {text}
              </StyledLink>
            ),
        )}
      </P1>
    </Box>
  );
};
