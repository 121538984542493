import styled from 'styled-components';
import { Theme, theme } from '@marble/theme';
import { StyleProps, Variant } from './interfaces';
import { Link } from 'gatsby';

const variants = {
  [Variant.primary]: {
    '&:hover > *': {
      color: theme.palette.main.primary,
    },
    '&:active > *': {
      color: theme.palette.main.primary,
    },
  },
};

const StyledLink = styled(Link)<{ theme: Theme } & StyleProps>(({ variant = Variant.primary }) => ({
  fontFamily: theme.typography.fontFamily[0],
  cursor: 'pointer',
  textAlign: 'center',
  ...variants[variant],
}));

export default StyledLink;
