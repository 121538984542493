import styled from 'styled-components';
import { ResponsiveGroup } from '@marbletech/components';
import { colors, Colors, theme } from '@marbletech/theme';
import { Box } from 'grommet';
import { ItemProps, ReasonsWrapperProps } from './ChooseMarble.types';
import { ContentContainer } from '../ContentContainer';

export const Container = styled(Box)<{ background?: Colors }>`
  min-height: 603px;
  background: ${(props) => colors[props.background as Colors] || colors.neutral100};

  @media (max-width: ${theme.breakpoints.md}px) {
    height: 100%;
    padding: 64px 0;
  }
`;

export const ContentWrapper: React.FC<any> = styled(ContentContainer)`
  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: stretch;
  }
`;

export const ReasonsWrapper = styled(ResponsiveGroup).attrs({ gapBottom: '32px' })<ReasonsWrapperProps>`
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  padding: 80px 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: auto;
    padding: 32px 0 ${(props) => props.desktopPadding ?? '48px'};
  }
`;

export const Reason = styled(Box)<ItemProps>`
  border-right: ${(props) => (props.isLast ? 'none' : '1px solid rgba(25, 35, 72, 0.2)')};
  border-right-color: ${(props) => colors[props.color] || 'initial'};

  @media (max-width: ${theme.breakpoints.md}px) {
    border-right: none;

    padding-bottom: ${(props) => (props.isLast ? 0 : '32px')};
    border-bottom: ${(props) => (props.isLast ? 'none' : '1px solid rgba(25, 35, 72, 0.2)')};
    border-bottom-color: ${(props) => colors[props.color] || 'initial'};
  }
`;
