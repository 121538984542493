/*
 * This is configured by Storyblok. Needs to update when the real pathname is updated.
 * */
import { isBrowser } from '@marbletech/utils';
import * as Sentry from '@sentry/gatsby';

const LSS_DIRECT_PATHNAME = '/family/funnel-start';
const LSS_DIRECT_BOOK_A_CALL_PATHNAME = '/book-a-call-direct';

/*
 * We should probably retrieve calendly links via Split.io,
 * so it'll be configurable, and divided evenly.
 * */
const calendlyLinks = (): Record<string, string[]> => {
  const isDev = window?.location?.host?.includes('dev.marble.co');
  if (process.env.NODE_ENV === 'production' && !isDev) {
    return {
      MI: ['https://calendly.com/anna-tsaturova-session/case-consultation-with-marble'],
      PA: [
        'https://calendly.com/jessica-cabral-session/case-consultation-with-marble',
        'https://calendly.com/jula-rogers-strategy/case-consultation-with-marble',
      ],
      MD: ['https://calendly.com/di-goodman-session/case-consultation-with-marble'],
    };
  } else {
    return {
      MI: ['https://calendly.com/adamkarnis/direct-lss'],
      PA: ['https://calendly.com/adamkarnis/direct-lss'],
      MD: ['https://calendly.com/adamkarnis/direct-lss'],
    };
  }
};

const CA_CALENDLY_URL =
  'https://calendly.com/attorney-consultation-with-marble-ca/attorney-consultation-with-marble-ca';
const TX_CALENDLY_URL =
  'https://calendly.com/attorney-consultation-with-marble-tx/attorney-consultation-with-marble?month=2023-05';

const calednlyToStateDict: Record<'ca' | 'tx', string> = {
  ca: CA_CALENDLY_URL,
  tx: TX_CALENDLY_URL,
};

export function isDirectLssFlow() {
  if (isBrowser()) {
    return [LSS_DIRECT_BOOK_A_CALL_PATHNAME, LSS_DIRECT_PATHNAME].includes(window.location.pathname);
  }

  return false;
}

export function getRandomCalendlyLinkByState(state: string) {
  const defaultCalendly =
    calendlyLinks()?.[state]?.[Math.floor(Math.random() * calendlyLinks()?.[state]?.length)] || '';
  const calendlyLink = calednlyToStateDict[state?.toLowerCase()];

  if (!calendlyLink) {
    Sentry.captureException(new Error('Direct LSS - book-a-call-direct got a state that is not "ca || tx"'));
  }

  return calendlyLink || defaultCalendly;
}
