import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { MenuContentProps } from '@marble/website/src/components/Menu/MainNavigation/MainNavigation.types';
import { useLandingPagePhoneFlag, useStore } from '@marble/website';
import { MenuData, MenuType } from '@marble/website/src/components/Menu/MainNavigation/MainNavigation';
import { useNavigation } from '@marble/website/src/context/Context';
import { DefaultNavbar, DummyNavbar, FunnelNavbar, LandingPageNavbar } from '@marbletech/ca-navbar';
import { isBrowser } from '@marbletech/utils';
import { eventService } from '@marble/website/src/utils';
import { LinkProps } from '@marble/website/src/components/Link/interfaces';
import Link from '../../../../website/src/components/Link';
import { Host } from '../../types/storyblok';
import { useLocation } from '@reach/router';
import { triggerNewBiEvent } from '../../utils/newEvents';

const CUSTOMER_PORTAL_URL = process.env.GATSBY_CUSTOMER_PORTAL_URL ?? 'https://customers.marble.co';

export interface MenuProps {
  funnelLink?: string;
  menuContent: MenuContentProps;
  deprecatedMenuContent: MenuData;
  menuType?: MenuType;
  pageComponent: string;
}

const LinkComponent = styled(({ to, host, children, className, onClick = () => null }: LinkProps & { host: Host }) => {
  const isInternalLink = host === Host.MainWebsite || to === '/';
  const Component = isInternalLink ? Link : 'a';

  console.log('LinkComponent');

  return (
    <Component className={className} onClick={onClick} to={to} href={to}>
      {children}
    </Component>
  );
})`
  &,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`;

const FunnelLinkComponent = ({ children, className, onClick, to }: LinkProps) => {
  console.log('FunnelLinkComponent');

  const handleClick = () => {
    onClick?.();
    window.location.href = to;
  };

  return (
    <a
      className={className}
      onClick={handleClick}
      style={{
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'pointer',
      }}
    >
      {children}
    </a>
  );
};

const Navigation = ({ funnelLink, menuType, pageComponent: pageComponentIn }: MenuProps) => {
  const { navigate } = useNavigation();
  const { pathname } = useLocation();
  const { setCurrentPhoneNumber, currentPhoneNumber, pageComponent, funnelCurrentStep } = useStore();
  const shouldShowPhoneNumberOnLandingPage = useLandingPagePhoneFlag();

  const pageComponentUse = pageComponentIn || pageComponent;

  const isFunnel =
    [
      'funnel',
      'conditionalfunnel',
      'conditionalfunnelrebrand',
      'bookacall',
      'bookacallrebrand',
      'choosepracticearearebrand',
    ].includes(pageComponentUse.toLowerCase()) && funnelCurrentStep !== 'caseUnsupported';

  const isLandingPage = ['landingPage', 'landingPageRebrand', 'dynamicLandingPage'].includes(pageComponentUse);
  const isHomepage = ['homepage', 'page'].includes(pageComponentUse);
  // the cta should be visible only to users who are NOT customers when viewing pdfs
  const isDocumentPageWithoutCta = ['documentsPage'].includes(pageComponentUse) && !menuType;
  const getFunnelCtaLink = pathname.includes('thank-you') && !funnelLink;
  const hasBrowser = isBrowser();

  const getNavbarComponent = useCallback(() => {
    if (!hasBrowser) {
      return DummyNavbar;
    }

    if (isFunnel || isDocumentPageWithoutCta) {
      return FunnelNavbar;
    }

    if (isLandingPage) {
      return LandingPageNavbar;
    }

    return DefaultNavbar;
  }, [isFunnel, isLandingPage, hasBrowser, isDocumentPageWithoutCta]);

  const NavbarComponent = getNavbarComponent();

  if (getFunnelCtaLink) {
    funnelLink = '/practice/2022';
  }

  return (
    <NavbarComponent
      hidePhone={!shouldShowPhoneNumberOnLandingPage || isHomepage}
      LinkComponent={isFunnel ? FunnelLinkComponent : LinkComponent}
      funnelLink={funnelLink}
      navigate={navigate}
      navigateToCustomerPortal={() => {
        location.href = CUSTOMER_PORTAL_URL;
      }}
      setCurrentPhoneNumber={setCurrentPhoneNumber}
      currentPhoneNumber={currentPhoneNumber}
      triggerEvent={eventService.triggerEvent}
      triggerNewEvent={triggerNewBiEvent}
    />
  );
};

export default Navigation;
