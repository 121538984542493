import React, { useMemo } from 'react';
import { ABTestData, FunnelMeta, Person, PracticeArea } from '@marble/g-website/src/api/api';
import { emptyUTMs } from '../utils';
import { getItem, setItem } from '../utils/local-storage/local-storage';
import { RequireExactlyOne } from 'type-fest';
import { navigate as gatsbyNavigate } from 'gatsby';
import { textContextVarNames } from '../consts';
import { StateCode, statesObject } from '@marble/utils';
import { StateInfo } from '@marble/g-website/src/layouts/LandingPage/LandingPage.types';
import { isBrowser } from '@marbletech/utils';
import { getRandomCalendlyLinkByState, isDirectLssFlow } from '@marble/g-website/src/utils/directLss';

export interface Image {
  filename: string;
  alt: string;
  title?: string;
}

export interface UserDetails {
  name: { first: string; last: string };
}

export interface GeoLocation {
  city: string;
  ipCity?: string;
  ipState?: string;
  ipCountryCode?: string;
  ipCountryName?: string;
}

export interface Store {
  lang: 'en' | 'es';
  setLang: (lang: Language) => void;
  /**
   * This is used for getting user details if he's signed in.
   */
  userDetails: UserDetails | null;
  /**
   * set user details if he's signed in.
   */
  setUserDetails: (userDetails: UserDetails) => void;

  stateInfo: StateInfo;
  setStateInfo: (stateInfo: StateInfo) => void;

  phoneNumbers: PhoneNumbersArray;
  setPhoneNumbers: (phoneNumbers: PhoneNumbersArray) => void;

  stateCode: OperationalState;
  setStateCode: (stateCode: OperationalState) => void;
  externalStateCode: OperationalState | null;

  utm: UTMsToPass;
  setUTM: (utm: UTMsToPass) => void;

  geoLocation: GeoLocation;
  setGeoLocation: (geoLocation: GeoLocation) => void;

  textContext: Record<string, string>;
  setTextContext: (textContext: Partial<GeoLocation>, { key, value }: any) => void;

  funnelMeta: {
    category: FunnelMeta['category'] | null;
    entry_url: FunnelMeta['entry_url'] | null;
    practiceArea: FunnelMeta['practiceArea'] | null;
  };
  setFunnelMeta: (funnelMeta: Partial<Store['funnelMeta']>) => void;

  person: Person;
  setPerson: (person: Partial<Person>) => void;

  isRebrand: boolean;
  setIsRebrand: (flag: boolean) => void;

  funnelCurrentStep: string;
  setFunnelCurrentStep: (funnelCurrentStep: string) => void;

  funnelAnswers:
    | RequireExactlyOne<Record<PracticeArea, Record<string, string | string[] | Record<string, string>>>>
    | Record<PracticeArea, null>;

  updateFunnelAnswers: (
    practiceArea: FunnelMeta['practiceArea'],
    data: Record<string, string | string[] | Record<string, string>> | null,
  ) => void;

  clearState: () => void;

  pageComponent: string;
  setPageComponent: (pageComponent: string) => void;

  currentPhoneNumber: { prettyPhone: string; phoneLink: string };
  setCurrentPhoneNumber: (by: 'state' | 'url' | 'fallback', state?: string) => void;

  calendlyLink: string;
  setCalendlyLink: (meetingURL: string) => void;

  isFromLandingPage: boolean;
  setIsFromLandingPage: (flag: boolean) => void;

  isFromRebrandLandingPage: boolean;
  setIsFromRebrandLandingPage: (flag: boolean) => void;

  abTestData: ABTestData;
  setABTestData: (abTestData: ABTestData) => void;
}

export interface UTMsToPass {
  source: string | null;
  campaignId: string | null;
  adgroupId: string | null;
  keyword: string | null;
  content: string | null;
  medium: string | null;
  marbleCategory: string | null;
  gclid: string | null;
  fbclid: string | null;
  ttclid: string | null;
  msclkid: string | null;
  referrer: string | null;
  affiliateId: string | null;
  matchLocation: string | null;
  matchType: string | null;
  searchNetwork: string | null;
}

const emptyAbTestData = {
  testName: '',
  testGroup: '',
};

const emptyPerson = {
  email: '',
  firstName: '',
  lastName: '',
  location: '',
  phone: '',
};

const emptyFunnelAnswers: Record<PracticeArea, null> = {
  DUI: null,
  Employment: null,
  Immigration: null,
  Bankruptcy: null,
  Family: null,
  Criminal: null,
  Estate_Planning: null,
  Tax: null,
  FreeTax: null,
};

const emptyFunnelMeta = {
  category: null,
  entry_url: null,
  practiceArea: null,
};

export type OperationalState = StateCode;

export type Language = 'en' | 'es';

const DEFAULT_PHONE_NUMBER: PhoneNumber = {
  prettyPhone: '(888)-412-4420',
  phoneLink: '+1-(888)-412-4420',
};

const STATE_AGNOSTIC_PRACTICE_AREAS: PracticeArea[] = ['Immigration'];

const getStateCode = (defaultStateCode = 'CA') => {
  if (!isBrowser()) {
    return;
  }
  const params = new URLSearchParams(location.search);
  const stateCode = (params.get('stint') ?? '').toUpperCase();
  if (stateCode && stateCode in statesObject) {
    return stateCode as OperationalState;
  }
  return defaultStateCode;
};

export const getExternalStateCode = (stateCode: OperationalState, practiceArea: PracticeArea | null) => {
  if (practiceArea && STATE_AGNOSTIC_PRACTICE_AREAS.includes(practiceArea)) {
    return null;
  }
  return stateCode;
};

// @ts-ignore
const StoreContext = React.createContext<Store>(null);
export const StoreProvider: React.FunctionComponent = ({ children }) => {
  const [stateInfo, setStateInfo] = React.useState<StateInfo>({
    state_name: '',
    url: '',
    allowed_cities: [],
    dynamic_text: '',
  });
  const [phoneNumbers, setPhoneNumbers] = React.useState<PhoneNumbersArray>([]);
  const [pageComponent, setPageComponent] = React.useState<string>('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState<PhoneNumber>({ ...DEFAULT_PHONE_NUMBER });

  const [stateCode, setStateCode] = React.useState<OperationalState>(
    (getItem('locationState') as OperationalState) || getStateCode('CA'),
  );

  const [geoLocation, setGeoLocation] = React.useState<GeoLocation>({ city: '' });
  const [textContext, setTextContext] = React.useState<Record<string, string>>({});

  const [utm, setUTMs] = React.useState<UTMsToPass>(JSON.parse(getItem('utm') as string) ?? emptyUTMs);
  const [funnelMeta, setFunnelMeta] = React.useState<Store['funnelMeta']>(
    JSON.parse(getItem('funnelMeta') as string) ?? emptyFunnelMeta,
  );
  const [person, setPerson] = React.useState<Person>(JSON.parse(getItem('person') as string) ?? emptyPerson);
  const [funnelAnswers, setFunnelAnswers] = React.useState<Store['funnelAnswers']>(
    JSON.parse(getItem('funnelAnswers') as string) ?? emptyFunnelAnswers,
  );

  const [userDetails, setUserDetails] = React.useState<{
    name: { first: string; last: string };
  } | null>(null);

  const [lang, setLang] = React.useState<Language>('en');

  const [calendlyLink, setCalendlyLink] = React.useState<string>(getItem('calendlyLink') as string);

  const [isFromLandingPage, setIsFromLandingPage] = React.useState<boolean>(
    ((getItem('isFromLandingPage') as string) ?? 'false') === 'true',
  );
  const [isRebrand, setIsRebrand] = React.useState<boolean>(((getItem('isRebrand') as string) ?? 'false') === 'true');
  const [funnelCurrentStep, setFunnelCurrentStep] = React.useState<string>('intro');
  const [isFromRebrandLandingPage, setIsFromRebrandLandingPage] = React.useState<boolean>(
    ((getItem('isFromRebrandLandingPage') as string) ?? 'false') === 'true',
  );
  const [abTestData, setABTestData] = React.useState<ABTestData>(
    JSON.parse(getItem('abTestData') as string) ?? emptyAbTestData,
  );

  if (isBrowser()) {
    window?.FS?.log(`StoreProvider funnelMeta: ${(getItem('funnelMeta') as string) ?? emptyFunnelMeta}`);
  }

  const externalStateCode: StateCode | null = useMemo(() => getExternalStateCode(stateCode, funnelMeta?.practiceArea), [
    stateCode,
    funnelMeta,
  ]);

  const allTogether: Store = {
    lang,
    setLang,

    phoneNumbers,
    setPhoneNumbers: (phoneNumbers) => {
      setPhoneNumbers(phoneNumbers);
    },

    stateInfo,
    setStateInfo,

    stateCode,
    setStateCode: (stateCode) => {
      setStateCode(stateCode);
      setItem('locationState', stateCode);
    },
    externalStateCode,
    textContext,
    setTextContext: ({ key, value }: any) => {
      const updatedVariables = {
        ...textContext,
        [key]: value,
      };
      setTextContext(updatedVariables);
    },
    geoLocation,
    setGeoLocation: (newLocation) => {
      setGeoLocation((prevGeoLocation) => {
        const updatedGeoLocation = {
          ...prevGeoLocation,
          ...newLocation,
        };
        setTextContext({
          ...textContext,
          [textContextVarNames.ipCity]: newLocation.city,
        });
        return updatedGeoLocation;
      });
    },
    isRebrand,
    setIsRebrand: (flag) => {
      const boolFlag = !!flag;
      setIsRebrand(boolFlag);
      setItem('isRebrand', boolFlag.toString());
    },
    funnelCurrentStep,
    setFunnelCurrentStep,
    utm,
    setUTM: (_utm) => {
      setUTMs(_utm);
      setItem('utm', JSON.stringify(_utm));
    },

    funnelMeta,
    setFunnelMeta: (meta) => {
      setFunnelMeta((prevFunnelMeta) => {
        const newMeta = {
          ...prevFunnelMeta,
          ...meta,
        };
        setItem('funnelMeta', JSON.stringify(newMeta));
        return newMeta;
      });
    },

    person,
    setPerson: (p) => {
      setPerson((prevPerson) => {
        const newPerson = {
          ...prevPerson,
          ...p,
        };
        setItem('person', JSON.stringify(newPerson));
        return newPerson;
      });
    },

    funnelAnswers,
    updateFunnelAnswers: (practiceArea, data) => {
      // @ts-ignore
      setFunnelAnswers((prevFunnelAnswers) => {
        const newAnswers = {
          [practiceArea]: {
            // @ts-ignore
            ...prevFunnelAnswers[practiceArea],
            ...data,
          },
        };
        setItem('funnelAnswers', JSON.stringify(newAnswers));
        return newAnswers;
      });
    },

    clearState: () => {
      setFunnelAnswers(emptyFunnelAnswers);
      setFunnelMeta(emptyFunnelMeta);
      setABTestData(emptyAbTestData);
    },

    userDetails,
    setUserDetails: (userDetails) => {
      setUserDetails(userDetails);
    },
    pageComponent,
    setPageComponent,

    currentPhoneNumber,
    setCurrentPhoneNumber: (by, state) => {
      const fallbackNumber: PhoneNumber = { ...DEFAULT_PHONE_NUMBER };
      let currentNumber: PhoneNumber = { ...DEFAULT_PHONE_NUMBER };

      if (by === 'state') {
        currentNumber = phoneNumbers.find((number) => number.key === state) as PhoneNumber;
      } else if (by === 'url') {
        // Could be a better solution if I could get the stateCode for the current page
        currentNumber = phoneNumbers[0] ?? currentNumber;
      }
      setCurrentPhoneNumber({
        phoneLink: currentNumber?.phoneLink ?? fallbackNumber.phoneLink,
        prettyPhone: currentNumber?.prettyPhone ?? fallbackNumber.prettyPhone,
      });
    },

    calendlyLink: calendlyLink,
    setCalendlyLink: (meetingURL) => {
      setCalendlyLink(meetingURL);
      setItem('calendlyLink', meetingURL);
    },
    isFromLandingPage,
    setIsFromLandingPage: (flag) => {
      setIsFromLandingPage((prevFlag) => {
        const isOn = prevFlag || flag;
        setItem('isFromLandingPage', isOn.toString());
        return isOn;
      });
    },
    isFromRebrandLandingPage,
    setIsFromRebrandLandingPage: (flag) => {
      setIsFromRebrandLandingPage((prevFlag) => {
        const isOn = prevFlag || flag;
        setItem('isFromRebrandLandingPage', isOn.toString());
        return isOn;
      });
    },
    abTestData,
    setABTestData: (abTestData: ABTestData) => {
      setItem('abTestData', JSON.stringify(abTestData));
      setABTestData(abTestData);
    },
  };

  return <StoreContext.Provider value={allTogether}>{children}</StoreContext.Provider>;
};

/*
 * Defining it outside of the hook so it'll be called once when the module load.
 * Otherwise, it can return different urls on each render.
 * */

export const useMeetingURL = (calendlyLinksMap?: Record<string, string>) => {
  const _store = React.useContext(StoreContext);
  const { calendlyLink, setCalendlyLink, funnelMeta, externalStateCode } = _store;

  let meetingURL = 'https://calendly.com/marble-specialists/free-consultation';
  const otherCalendlyLink = calendlyLinksMap?.[funnelMeta.practiceArea ?? ''];

  if (calendlyLink) {
    meetingURL = calendlyLink;
  } else if ((!calendlyLink || !calendlyLink.length) && otherCalendlyLink) {
    meetingURL = otherCalendlyLink;
  }

  /*
   * In case we're in directLss a/b test, we want to return a custom Calendly link.
   * */
  if (isDirectLssFlow() && externalStateCode) {
    console.log(`externalStateCode is ${externalStateCode}`);
    const urlByState = getRandomCalendlyLinkByState(externalStateCode);
    if (urlByState) {
      meetingURL = urlByState;
      console.log(`new link is ${meetingURL}, for state ${externalStateCode}`);
    }
  }

  return {
    meetingURL,
    calendlyLink,
    setCalendlyLink,
  };
};

export const useLang = () => {
  const _store = React.useContext(StoreContext);

  const { lang, setLang } = _store;
  return { lang, setLang };
};

export const useNavigation = () => {
  const { lang } = useLang();
  const linkPrefix = lang === 'en' ? '' : `/${lang}`;

  const getLocalizedLink = ({ pathname = '' }: { pathname: string }) => {
    const normalizedPathname = pathname[0] === '/' ? pathname : `/${pathname}`;
    return `${linkPrefix}${normalizedPathname}`;
  };

  const navigate = (pathname: string, options = {}) => {
    const localizedLink = getLocalizedLink({ pathname });
    return gatsbyNavigate(localizedLink, options);
  };

  const navigateBack = (pages = -1) => {
    return gatsbyNavigate(pages);
  };

  return { linkPrefix, getLocalizedLink, navigate, navigateBack };
};

export const useStore = () => {
  const _store = React.useContext(StoreContext);
  // @ts-ignore
  typeof window !== 'undefined' && (window.alex = _store);
  return _store;
};

export interface PhoneNumber {
  prettyPhone: string;
  phoneLink: string;
}

export type PhoneNumbersRecord = Record<string, PhoneNumber>;
export type PhoneNumbersArray = Array<PhoneNumber & { key: string }>;

export const usePhoneNumber = () => {
  const { phoneNumbers: phoneNumbersArray, stateCode, pageComponent } = React.useContext(StoreContext);
  const isFunnelPage: boolean = pageComponent === 'funnel';

  const phoneNumbers = phoneNumbersArray.reduce<PhoneNumbersRecord>((acc, { key, phoneLink, prettyPhone }) => {
    return {
      ...acc,
      [key]: {
        phoneLink,
        prettyPhone,
      },
    };
  }, {});

  return phoneNumbers[isFunnelPage ? stateCode : 'general'] ?? { ...DEFAULT_PHONE_NUMBER };
};

export function useFunnelAnswers(): any {
  const { funnelAnswers } = useStore();
  if ('DUI' in funnelAnswers) {
    return funnelAnswers.DUI;
  }
  if ('Employment' in funnelAnswers) {
    return funnelAnswers.Employment;
  }
  if ('Criminal' in funnelAnswers) {
    return funnelAnswers.Criminal;
  }
  if ('Immigration' in funnelAnswers) {
    return funnelAnswers.Immigration;
  }
  if ('Bankruptcy' in funnelAnswers) {
    return funnelAnswers.Bankruptcy;
  }
  if ('Family' in funnelAnswers) {
    return funnelAnswers.Family;
  }

  return null;
}
