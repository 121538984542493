import React from 'react';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../../consts';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: ${MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  width: 100%;
`;

export const Section = ({ className, children }: any) => {
  return (
    <Wrapper className={className}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Section;
