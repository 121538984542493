import { FunnelTitle } from '../FunnelTitle/FunnelTitle';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ChevronSmallIcon } from '@marble/icons';
import { Theme } from '@marble/theme';
import { Container } from '../shared-funnel/Container';
import { useNavigation } from '../../context/Context';

export interface SubmissionThankYouStepProps {
  title: string;
  buttonText: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 5,
}));

export const TextButton = styled('button')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  fontFamily: theme.typography.fontFamily[0],
  ...theme.typography.meta.p1SemiBold,
  color: theme.palette.main.primary,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& svg': {
    marginInlineStart: theme.gutters.base * 1,
  },
}));

export const SubmissionThankYouStep: React.FunctionComponent<SubmissionThankYouStepProps> = ({
  title,
  buttonText,
  onClick,
}) => {
  const { linkPrefix } = useNavigation();
  return (
    <Container>
      <FunnelTitle>{title}</FunnelTitle>
      <ButtonWrap>
        <Link to={`${linkPrefix}/`} onClick={onClick}>
          <TextButton>
            {buttonText} <ChevronSmallIcon {...{ color: 'primary' }} />
          </TextButton>
        </Link>
      </ButtonWrap>
    </Container>
  );
};

export default SubmissionThankYouStep;
