import styled from 'styled-components';

import { breakpoints, colors, spacings } from '@marbletech/theme';

export const Container = styled.div`
  background-color: ${colors.dark300};
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 528px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 0 ${spacings[4.5]};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-basis: 50%;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.md}px) {
    justify-content: center;
    padding: ${spacings[7]} 0;
  }
`;
