import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ArrowMediumRotatedProps extends SVGIconProps {}

export const ArrowMediumRotated: React.FunctionComponent<ArrowMediumRotatedProps> = (props) => {
  return (
    <SVGIcon {...{ width: 14, height: 14, viewBox: '0 0 14 14', ...props }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0638 1.68629C13.0638 1.27208 12.728 0.936292 12.3138 0.936291L5.56383 0.936292C5.14962 0.936292 4.81383 1.27208 4.81383 1.68629C4.81383 2.10051 5.14962 2.43629 5.56383 2.43629H11.5638V8.43629C11.5638 8.85051 11.8996 9.18629 12.3138 9.18629C12.728 9.18629 13.0638 8.85051 13.0638 8.43629L13.0638 1.68629ZM1.53045 13.5303L12.8442 2.21662L11.7835 1.15596L0.469792 12.4697L1.53045 13.5303Z"
      />
    </SVGIcon>
  );
};
