import { theme } from '@marbletech/theme';
import styled from 'styled-components';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../consts';

export const HomepageContainer = styled.div`
  width: 100%;
  padding-top: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${HEADER_HEIGHT_MOBILE}px;
  }
`;
