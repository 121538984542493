import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface ResearchProps extends SVGIconProps {}

export const ResearchIcon: React.FunctionComponent<ResearchProps> = (props) => {
  return (
    <SVGIcon {...{ width: 24, height: 24, viewBox: '0 0 24 24', ...props }}>
      <path
        fillRule="evenodd"
        d="M9.178 0c5.068 0 9.177 4.109 9.177 9.178 0 1.795-.515 3.47-1.407 4.885l6.455 6.455c.796.797.796 2.088 0 2.885-.797.796-2.088.796-2.885 0l-6.455-6.455c-1.415.892-3.09 1.407-4.885 1.407C4.109 18.355 0 14.246 0 9.178 0 4.109 4.109 0 9.178 0zm7.014 15.471l-.721.721 6.129 6.13c.177.176.452.196.65.058l.071-.059c.177-.177.197-.452.06-.65l-.06-.071-6.13-6.129zM9.178 1.53c-4.224 0-7.648 3.424-7.648 7.648 0 4.223 3.424 7.648 7.648 7.648 4.223 0 7.648-3.425 7.648-7.648 0-4.224-3.425-7.648-7.648-7.648z"
      />
    </SVGIcon>
  );
};
