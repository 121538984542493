import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@marble/ui-lib';

export interface TimeProps extends SVGIconProps {}

export const TimeIcon: React.FunctionComponent<TimeProps> = (props) => {
  return (
    <SVGIcon {...{ width: 16, height: 16, viewBox: '0 0 16 16', ...props }}>
      <path
        fillRule="evenodd"
        d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1.5C4.41 1.5 1.5 4.41 1.5 8s2.91 6.5 6.5 6.5 6.5-2.91 6.5-6.5S11.59 1.5 8 1.5zm.833 1.833V8L12.667 8v1.5H7.333V3.333h1.5z"
        transform="translate(-98 -289) translate(98 289)"
      />
    </SVGIcon>
  );
};
