import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marbletech/theme';
import { Typography } from '@marbletech/components';
import { roundButtonSecondary } from '@marble/icons';

export interface PracticeAreaCardProps {
  title: string;
  icon: string;
  text: string;
  link: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  height: 160,
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
  '& *': {
    boxSizing: 'border-box',
  },
}));

export const Front = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.secondary3,
  display: 'grid',
  gridTemplateColumns: 'min-content max-content',
  gridGap: theme.gutters.base * 4,
  alignItems: 'center',
  padding: `${theme.gutters.base * 3}px ${theme.gutters.base * 4}px`,
  height: '100%',
}));

export const Icon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 56,
  height: 64,
}));

export const TextContainer = styled('div')<{ theme: Theme; isShowText: boolean }>(({ theme, isShowText }) => ({
  backgroundColor: theme.palette.main.bright,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: isShowText ? 'grid' : 'none',
  gridTemplateColumns: '1fr min-content',
  gridGap: theme.gutters.base * 3,
  alignItems: 'center',
  padding: `0 ${theme.gutters.base * 3}px`,
}));

export const PracticeAreaCard: React.FunctionComponent<PracticeAreaCardProps> = ({ title, icon, text, link }) => {
  const [isShowText, setIsShowText] = React.useState(false);

  return (
    <Container {...{ onMouseEnter: () => setIsShowText(true), onMouseLeave: () => setIsShowText(false) }}>
      <Front>
        <Icon {...{ src: icon }} />
        <Typography {...{ variant: 'h4' }}>{title}</Typography>
      </Front>
      <TextContainer {...{ isShowText }}>
        <Typography {...{ variant: 'p1SemiBold' }}>{text}</Typography>
        <img {...{ src: roundButtonSecondary, style: { transform: 'rotate(180deg)' } }} />
      </TextContainer>
    </Container>
  );
};

export default PracticeAreaCard;
