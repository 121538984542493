import { theme } from '@marbletech/theme';
import { Box } from 'grommet';
import styled from 'styled-components';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../consts';

export const Container = styled(Box)`
  flex-direction: column;
  padding-top: ${HEADER_HEIGHT}px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-top: ${HEADER_HEIGHT_MOBILE}px;
  }
`;
