import { eventService, EventName } from '../eventService';
import * as Sentry from '@sentry/gatsby';
import { isBrowser } from '@marbletech/utils';

export const fullstoryReadyHandler = (retry = 10) => {
  if (!isBrowser()) {
    return;
  }
  if (retry === 0) {
    return;
  }
  if (!window?.FS || !window?.FS?.getCurrentSessionURL?.(true)) {
    setTimeout(() => {
      fullstoryReadyHandler(retry - 1);
    }, 300);
    return;
  }

  const fullstorySession = window?.FS?.getCurrentSessionURL?.(true);
  if (!fullstorySession) {
    return;
  }
  eventService.triggerEvent({
    eventName: EventName.OnlineFunnelFullstoryStarted,
    eventData: {
      session: fullstorySession,
    },
  });

  Sentry.setContext('Recording', {
    fullstory: fullstorySession,
  });
};
