import { useEffect, useState } from 'react';
import { ABTestingType } from '../layouts/FunnelRebrand/FunnelRebrandAbTest/FunnelRebrandAbTest.types';
import { FunnelContent } from '../layouts/FunnelRebrand/FunnelRebrand.types';
import { useStore } from '@marble/website';

interface AbTestingProps {
  ABTesting: FunnelContent['ABTesting'];
}

export const useStoryblokAbTesting = ({ ABTesting }: AbTestingProps) => {
  const { stateCode } = useStore();
  const [isAssignedAbTestValue, setIsAssignedAbTestValue] = useState<boolean | undefined>();
  const [abTestData, setAbTestData] = useState<ABTestingType>({
    abTestSlug: '',
    flagName: '',
    testStatesCodes: [],
    isABTest: false,
  });

  useEffect(() => {
    // we don't want to change behavior after this has been set.
    if (isAssignedAbTestValue !== undefined) {
      return;
    }
    let abTestData: ABTestingType = { abTestSlug: '', flagName: '', testStatesCodes: [], isABTest: false };
    let shouldAbTest = false;
    if (ABTesting && ABTesting?.length) {
      for (let test of ABTesting) {
        abTestData = {
          ...test,
        };
        const testStatesCodes = abTestData?.testStatesCodes?.map((item) => item?.list_item) ?? [];
        // We want to run the ab test if the test is:
        // 1. on
        // 2.1 test states codes is not set == the state is agnostic to state
        // 2.2 test states codes is set and the stateCode matches the test states
        shouldAbTest =
          abTestData.isABTest && (!testStatesCodes.length || (stateCode && testStatesCodes.includes(stateCode)));
        if (shouldAbTest) {
          break;
        }
      }
    }

    setAbTestData(abTestData);
    setIsAssignedAbTestValue(shouldAbTest);
  }, [ABTesting, stateCode]);

  return {
    isAssignedAbTestValue,
    abTestData,
  };
};
