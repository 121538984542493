import styled from 'styled-components';
import { Box } from 'grommet';
import { colors, spacings, devices } from '@marbletech/theme';
import { H2, Page, ResponsiveGroup } from '@marbletech/components';
import { ArrowSmallRightIcon } from '@marbletech/icons';

export const Container = styled(Box).attrs({ align: 'center' })`
  padding: 152px 0px;

  ${devices.mobile} {
    padding: ${spacings[8]} 0px;
  }
`;

export const Content = styled(Page.Content)`
  gap: ${spacings[10]};

  ${devices.mobile} {
    padding: 0px;
    gap: ${spacings[2]};
  }
`;

export const JumboTitle = styled(H2).attrs({ color: colors.bright })`
  text-align: center;

  ${devices.mobile} {
    text-align: left;
    padding: 0px ${spacings[2.5]};
  }
`;

export const Icon = styled(ArrowSmallRightIcon).attrs({ fill: colors.bright, width: 34, height: 22 })`
  margin-bottom: ${spacings[2.5]};
  padding-right: ${spacings[1.5]};
`;

export const CardItemsContainer = styled(ResponsiveGroup).attrs({ gap: '0px', gapBottom: '0px' })`
  grid-auto-rows: minmax(131px, auto);
`;
