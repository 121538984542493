import React from 'react';
import { Page, P1, Flex } from '@marbletech/components';
import { spacings } from '@marbletech/theme';
import { DocumentProps } from './Document.types';
import { PdfViewer } from './components/PdfViewer';

export const Document: React.FC<DocumentProps> = ({ data: { pageContent } }) => {
  const {
    document: { filename: link = '' },
    download_link_text,
    invalid_link_text,
  } = pageContent;

  return (
    <>
      <Page.HeaderOffset width={{ max: '100vw' }} />
      <Flex margin={{ bottom: spacings[1.5], top: spacings[1.5] }}>
        {link ? (
          <Flex gap={spacings[4]}>
            <a href={link} target={'_blank'} download>
              <P1>{download_link_text}</P1>
            </a>
          </Flex>
        ) : (
          <P1>{invalid_link_text}</P1>
        )}
      </Flex>
      <PdfViewer documentLink={link} />
    </>
  );
};

export default Document;
