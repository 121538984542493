import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'grommet';

import { RebrandedButton, H1, H2 } from '@marbletech/components';
import { spacings } from '@marbletech/theme';
import { isBrowser } from '@marbletech/utils';
import { getHandleCtaClick } from '@marble/website/src/utils/eventsService/eventHandlers';

import { MovesYouForwardProps } from './MovesYouForward.types';
import { Container } from './MovesYouForward.styled';

export const MovesYouForward: React.FC<MovesYouForwardProps> = (props) => {
  const { data, isDesktop } = props || {};
  const { fold4_h1_title: title, fold4_btn_text: btnText, fold4_btn_link: link } = data || {};

  const isMobileView = isBrowser() && !isDesktop;
  const handleCtaClick = getHandleCtaClick({ cta: btnText, position: 'MovesYouForward Section' });

  return (
    <Container>
      <Box align="center" justify="center">
        {isMobileView ? (
          <H2
            color={'bright'}
            whiteSpace={'pre-wrap'}
            textAlign={'center'}
            margin={{ bottom: spacings[5], top: '0px' }}
          >
            {title}
          </H2>
        ) : (
          <H1
            color={'bright'}
            whiteSpace={'pre-wrap'}
            textAlign={'center'}
            margin={{ bottom: spacings[6], top: '0px' }}
          >
            {title}
          </H1>
        )}
        <Link to={link}>
          <RebrandedButton size={'xlarge'} variant={'secondary'} width={{ min: '202px' }} onClick={handleCtaClick}>
            {btnText}
          </RebrandedButton>
        </Link>
      </Box>
    </Container>
  );
};
