import { Typography } from '@marbletech/components';
import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';

export interface MenuTestimonialsProps {
  testimonials: Array<{
    _uid: string;
    text: string;
    name: string;
    location: string;
  }>;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content max-content max-content',
  gridTemplateRows: 'max-content min-content',
  gridTemplateAreas: `
  'icon text text'
  '. dots details'
  `,
  gridGap: theme.gutters.base * 4,
  rowGap: theme.gutters.base * 2,
  alignItems: 'start',
}));

export const DetailsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'details',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, max-content)',
  gridGap: theme.gutters.base * 3,
  justifyContent: 'end',
}));

export const DotsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  paddingTop: theme.gutters.base * 2,
  gridTemplateColumns: 'repeat(3, min-content)',
  gridGap: theme.gutters.base * 1,
  gridArea: 'dots',
}));

export const Dot = styled('div')<{ theme: Theme; isPicked: boolean }>(({ theme, isPicked }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor: isPicked ? theme.palette.main.primary : theme.palette.main.support2,
  cursor: 'pointer',
}));

export const MenuTestimonials: React.FunctionComponent<MenuTestimonialsProps> = ({ testimonials = [] }) => {
  const [shownTestimonialIndex, setShownTestimonialIndex] = React.useState(0);

  let changeTimeoutId: number;

  React.useEffect(() => {
    const playTestimonials = () => {
      if (shownTestimonialIndex >= testimonials.length - 1) {
        setShownTestimonialIndex(0);
      } else {
        setShownTestimonialIndex((shownTestimonialIndex) => shownTestimonialIndex + 1);
      }
    };

    changeTimeoutId = window.setTimeout(playTestimonials, 5000);

    return () => window.clearTimeout(changeTimeoutId);
  }, [shownTestimonialIndex]);

  return (
    <Container>
      <img
        {...{
          style: {
            height: 84,
            display: 'block',
            gridArea: 'icon',
          },
          src: 'https://assets.website-files.com/5f3452e289aec70d5efcf4d6/5fe846f8a16885c7cf3a4c56_group-4.svg',
        }}
      />
      <div {...{ style: { maxWidth: 470, gridArea: 'text' } }}>
        <Typography {...{ variant: 'p1Bold' }}>
          {testimonials[shownTestimonialIndex]?.text?.length < 250
            ? testimonials[shownTestimonialIndex]?.text
            : `${testimonials[shownTestimonialIndex]?.text?.slice(0, 250)}...`}
        </Typography>
      </div>
      <DetailsContainer>
        <Typography {...{ variant: 'p1SemiBold' }}>{testimonials[shownTestimonialIndex]?.name}</Typography>
        <Typography {...{ variant: 'p1SemiBold' }}>{testimonials[shownTestimonialIndex]?.location}</Typography>
      </DetailsContainer>
      <DotsContainer>
        {testimonials?.map((testimonial, ind) => (
          <Dot
            {...{
              key: testimonial.name,
              isPicked: testimonial?._uid === testimonials[shownTestimonialIndex]?._uid,
              onClick: () => {
                window.clearTimeout(changeTimeoutId);
                setShownTestimonialIndex(ind);
              },
            }}
          />
        ))}
      </DotsContainer>
    </Container>
  );
};

export default MenuTestimonials;
