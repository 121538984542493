import React, { FC, useState } from 'react';
import { H2, H4, P1, P1Bold, Page, RebrandedButton, OptimizedAssetImage } from '@marbletech/components';
import { useBreakpoint } from '@marbletech/hooks';
import { spacings } from '@marbletech/theme';
import { PageProps } from 'gatsby';
import { Box } from 'grommet';
import { Step } from '../Step';
import { ContentContainer, StepsContainer, TopContainer } from './DownloadPage.styled';
import { DownloadPageProps } from './DownloadPage.types';
import { ContinueButton, PanelLayout } from '@marble/website/src/components/FunnelQuestion/shared/shared.styled';
import {
  FreeTaxGeneratorPayload,
  GetFreeTaxFunnelPdfQueryVariables,
  useGetFreeTaxFunnelPdfLazyQuery,
} from '../../../../generated/graphql';
import { useStore } from '@marble/website';
import { openBase64NewTab } from '@marbletech/utils';
import { eventService, EventName } from '../../../../services/eventService';

const DownloadPage: FC<PageProps<DownloadPageProps>> = ({ data: { pageContent } }) => {
  const {
    title,
    subtitle,
    buttonText,
    mobileButtonText,
    image,
    stepsText,
    stepsDisagreeReason,
    stepsNoDisagreeReason,
  } = pageContent;
  const { desktop } = useBreakpoint();
  const Title = desktop ? H2 : H4;
  const [getPdf, { loading }] = useGetFreeTaxFunnelPdfLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const { funnelAnswers, person } = useStore();
  const [generatePdfError, setGeneratePdfError] = useState(false);

  React.useEffect(() => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelFreeTaxLeaveSiteView,
      eventData: {
        title: title,
        source_page: sourcePage,
      },
    });
  }, []);

  const sourcePage = 'emailCollector';
  const steps = funnelAnswers?.FreeTax?.disagreeReason ? stepsDisagreeReason : stepsNoDisagreeReason;

  const getPdfPayload = (): FreeTaxGeneratorPayload => {
    const { address, city, country, firstName, lastName, state, zip } = person;
    const { taxYearSelector = '', irsTaxSelector = '', disagreementRichText = '' } = funnelAnswers?.FreeTax;
    const fullAddress = `${address}, ${city}, ${state}, ${zip}, ${country}`;
    const fullName = `${firstName} ${lastName}`;
    return {
      fullName,
      address: fullAddress,
      irsFormName: String(irsTaxSelector),
      taxPeriod: String(taxYearSelector),
      reasoning: String(disagreementRichText),
    };
  };

  const onClick = () => {
    setGeneratePdfError(false);
    const variables: GetFreeTaxFunnelPdfQueryVariables = {
      payload: getPdfPayload(),
      isAgreement: !funnelAnswers?.FreeTax?.disagreeReason,
    };
    getPdf({ variables })
      .then((res) => {
        const base64 = res?.data?.getFreeTaxFunnelPDF;
        if (!base64) {
          setGeneratePdfError(true);
        } else {
          openBase64NewTab(base64);
          eventService.triggerEvent({
            eventName: EventName.OnlineFunnelFreeTaxAgreementPackageView,
            eventData: {
              title: 'Download package',
              source_page: sourcePage,
            },
          });
        }
      })
      .catch(() => {
        setGeneratePdfError(true);
      });

    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelFreeTaxLeaveSiteCtaClick,
      eventData: {
        title: title,
        source_page: sourcePage,
        cta_origin: 'download-letter-page',
        cta_name: desktop ? buttonText : mobileButtonText,
      },
    });
  };

  return (
    <Page>
      <ContentContainer>
        <TopContainer>
          <Box align={'start'} justify={'start'}>
            <Title margin={{ bottom: desktop ? spacings[4] : spacings[2], top: desktop ? '0px' : spacings[4] }}>
              {title}
            </Title>
            <P1Bold margin={{ bottom: desktop ? spacings[4] : spacings[2], top: '0px' }}>{subtitle}</P1Bold>
            {desktop && (
              <Box>
                <RebrandedButton size={'large'} onClick={onClick} type={'submit'}>
                  {!loading ? buttonText : 'Creating your document...'}
                </RebrandedButton>
                {generatePdfError && (
                  <P1 margin={{ top: desktop ? spacings[4] : spacings[2] }}>An error occurred, please try again.</P1>
                )}
              </Box>
            )}
          </Box>
          <OptimizedAssetImage src={image.filename} alt={image.alt} maxWidth={226} />
        </TopContainer>
        <P1Bold margin={{ top: desktop ? spacings[8] : spacings[6], bottom: desktop ? spacings[3] : spacings[2] }}>
          {stepsText}
        </P1Bold>
        <StepsContainer>
          {steps.map((step) => (
            <Step key={step.number} number={step.number} description={step.description} />
          ))}
        </StepsContainer>
        {!desktop && (
          <PanelLayout>
            <ContinueButton size={'large'} onClick={onClick} type={'submit'}>
              {mobileButtonText}
            </ContinueButton>
          </PanelLayout>
        )}
      </ContentContainer>
    </Page>
  );
};

export default DownloadPage;
