import React from 'react';
import { RichTextResolver } from '../RichTextResolver';
import { Container } from './BannerText.styled';
import { BannerTextProps } from './BannerText.types';

export const BannerText = ({ text, bgColor }: BannerTextProps) => {
  return (
    <Container bgColor={bgColor}>
      <RichTextResolver document={text} />
    </Container>
  );
};
