import styled from 'styled-components';
import { Box } from 'grommet';
import { theme } from '@marbletech/theme';
import { P2 } from '@marbletech/components';

export const Container = styled(Box)`
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.gutters.base * 19}px 0;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: ${(props) => props.theme.gutters.base * 8}px 0 ${(props) => props.theme.gutters.base * 10}px 0;
  }
`;

export const Text = styled(P2)`
  & > :first-child {
    margin-top: 0;
    & p {
      margin: 0;
    }
  }

  & > :last-child {
    margin-bottom: 0;

    & p {
      margin: 0;
    }
  }
`;
