import { useEffect } from 'react';
import axios from 'axios';
import { useStore, GeoLocation } from '@marble/website';

const isBrowser = typeof window !== 'undefined';
const baseDevUrl = 'https://dev.themarbleway.com';
const previewUrlSuffix = 'gtsb.io';

// DO NOT DELETE! this pixel is for user's location according to his ip.
const getUserLocation = async (): Promise<GeoLocation> => {
  let pixelUrl = `/pixel.png?${new Date().getTime()}`;
  if (isBrowser && window.location?.host?.includes(previewUrlSuffix)) {
    pixelUrl = `${baseDevUrl}${pixelUrl}`;
  }
  const res = await axios.get(pixelUrl);
  const city = res?.headers?.['cloudfront-viewer-city'];
  const state = res?.headers?.['cloudfront-viewer-country-region-name'];
  return {
    city,
    ipCity: city,
    ipState: state,
    ipCountryCode: res?.headers?.['cloudfront-viewer-country'],
    ipCountryName: res?.headers?.['cloudfront-viewer-country-name'],
  };
};

export const getLocationFromUrlParam = () => {
  if (isBrowser) {
    const params = new URLSearchParams(window.location?.search); // We will take the user location param when the user's search will be different from his location. (for example, will search lp/family-az and the location will be la)
    return params?.get('marble_location') ?? '';
  }
};

export const GeoLocationService = (): any => {
  const { setGeoLocation } = useStore();
  useEffect(() => {
    const location = getLocationFromUrlParam();

    getUserLocation().then(({ city, ...rest }) => {
      setGeoLocation({ city: location || city, ...rest });
    });
  }, []);

  return null;
};
