import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '@marble/theme';
import { absoluteBlow } from '@marble/style-utils';
import { Button, Typography } from '@marbletech/components';
import Lottie from 'react-lottie';
import homepageLottie from './../../lotties/homepage.json';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useNavigation } from '../../context/Context';
import { getHandleCtaClick } from '../../utils/eventsService/eventHandlers';

export interface HeroHomePageProps {
  title: string;
  subtitle: string;
  buttonText: string;
  caption: string;
  buttonLink: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  zIndex: 10,
  display: 'grid',
  gridTemplateRows: 'repeat(2, min-content)',
  overflow: 'hidden',
  minHeight: '100vh',
  [`@media (min-width: ${theme.breakpoints?.md}px)`]: {
    gridGap: theme.gutters.base * 5,
    maxWidth: theme.breakpoints.lg,
    width: '100%',
    height: '100vh',
    minHeight: 810,
    margin: '0 auto',
    boxSizing: 'border-box',
    gridTemplateRows: '100vh',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export const TopContainer = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: '93vh',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    alignItems: 'start',
    maxHeight: 'initial',
  },
}));

export const PageTitleWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: '13vh',
  marginBottom: '5%',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: 485,
    marginTop: theme.gutters.base * 29,
    marginBottom: theme.gutters.base * 5,
  },
}));

export const PageTitle = styled(Typography)<{
  theme: Theme;
}>(({ theme }) => {
  return {
    color: theme.palette.main.dark,
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '56px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily[1],

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      fontSize: 60,
      lineHeight: '72px',
      textAlign: 'start',
    },
  };
});

export const PageTitleSupportingText = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.subtitle1,
  textAlign: 'center',
  marginBottom: '5%',
  maxWidth: 428,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: theme.gutters.base * 5,
    textAlign: 'start',
    maxWidth: 'initial',
  },
}));

export const HeroImage = styled('img')<{ theme: Theme }>(({ theme }) => ({
  ...absoluteBlow,
  objectFit: 'contain',
  width: '100%',
  maxHeight: '100%',
  zIndex: -1,
}));

export const BottomContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    width: 272,
  },
}));

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginBottom: theme.gutters.base * 3,
  width: '100%',
  maxWidth: 303,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginBottom: theme.gutters.base * 2,
    maxWidth: 272,
  },
}));

export const LottieWrap = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 6,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: theme.gutters.base * 23,
  },
}));

const topContainerAnimation = {
  exit: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      type: 'tween',
      stifness: 300,
    },
  },
};

const lottieContainerAnimation = {
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 1,
      type: 'tween',
      stifness: 300,
    },
  },
};

export const HeroHomePage: React.FunctionComponent<HeroHomePageProps> = ({
  title,
  subtitle,
  buttonText,
  caption,
  buttonLink,
}) => {
  const [isLottieStopped, setIsLottieStopped] = React.useState(true);
  const { linkPrefix } = useNavigation();
  React.useEffect(() => {
    window.setTimeout(() => setIsLottieStopped(false), 1000);
  }, []);

  const handleCtaClick = getHandleCtaClick({ cta: buttonText, position: 'Hero Section' });

  return (
    <Container>
      <TopContainer
        {...{
          variants: topContainerAnimation,
          initial: 'exit',
          animate: 'enter',
        }}
      >
        <PageTitleWrap>
          <PageTitle>{title}</PageTitle>
        </PageTitleWrap>
        <PageTitleSupportingText>{subtitle}</PageTitleSupportingText>
        <BottomContainer>
          <ButtonWrap>
            <Link {...{ to: linkPrefix + buttonLink }}>
              <Button onClick={handleCtaClick} variant={'primary'} size={'large'}>
                {buttonText}
              </Button>
            </Link>
          </ButtonWrap>
          <Typography {...{ variant: 'p1', center: true, style: { fontWeight: 600 } }}>{caption}</Typography>
        </BottomContainer>
      </TopContainer>
      <LottieWrap
        {...{
          variants: lottieContainerAnimation,
          initial: 'exit',
          animate: 'enter',
        }}
      >
        <Lottie
          {...{
            style: {
              height: 'unset',
            },
            options: {
              loop: true,
              autoplay: false,
              animationData: homepageLottie,
            },
            isPaused: isLottieStopped,
          }}
        />
      </LottieWrap>
    </Container>
  );
};

export default HeroHomePage;
