import styled from 'styled-components';
import { devices, spacings } from '@marbletech/theme';
import { Box } from 'grommet';
import { RebrandedButton } from '@marbletech/components';

export const TopContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${devices.mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 112px;

  ${devices.mobile} {
    margin-top: ${spacings[2]};
    align-items: center;
    justify-content: center;
  }
`;

export const StepsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: start;
  width: 100%;
  margin-bottom: 152px;
  column-gap: ${spacings[4]};

  ${devices.mobile} {
    flex-direction: column;
    justify-content: center;
    row-gap: ${spacings[2]};
    margin-bottom: ${spacings[7]};
  }
`;

export const Button = styled(RebrandedButton)`
  ${devices.mobile} {
    width: 100%;
  }
`;
