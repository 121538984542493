import styled from 'styled-components';

import { breakpoints } from '@marbletech/theme';
import { MaxWidthContainer } from '@marbletech/components';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../consts';

export const OurLawyersContainer = styled.div`
  width: 100%;
  padding-top: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.md}px) {
    padding-top: ${HEADER_HEIGHT_MOBILE}px;
  }
`;

export const ContentWidthContainer = styled(MaxWidthContainer)`
  max-width: 1216px;
`;
