import * as React from 'react';
import { Subtitle2, P1, RebrandedButton, P3, Input } from '@marbletech/components';
import { useNavigation, useStore } from '@marble/website/src/context/Context';
import { Box } from 'grommet';
import { ConflictCheckDirectProps } from './ConflictCheck.types';
import {
  ContentContainer,
  InputWrapper,
  LearnMoreContainer,
  MainContainer,
  ScheduleDesktopIcon,
  ScheduleMobileIcon,
} from './ConflictCheck.styled';
import { eventService } from '../../services/eventService';

const ConflictCheck = ({ data }: ConflictCheckDirectProps) => {
  const { updateFunnelAnswers, funnelMeta, funnelAnswers } = useStore();
  const { scheduleIconDesktop, scheduleIconMobile } = data;
  const [opposingParty, setOpposingParty] = React.useState('');
  const { navigate } = useNavigation();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpposingParty(e?.target?.value);
  };

  const onSubmit = () => {
    eventService.triggerEvent({
      eventName: 'online_funnel_opposing_party_page_submit',
      eventData: {
        is_field_fulfilled: !!opposingParty,
      },
    });
    const fromLocalStorage = localStorage.getItem('funnelAnswers');
    const jsoned = JSON.parse(fromLocalStorage);
    const { Family } = jsoned || {};
    let funnelAnswersUpdatePayload: {} = {
      ...Family,
      isDirectLss: 'true',
    };
    if (opposingParty) {
      funnelAnswersUpdatePayload = {
        ...funnelAnswersUpdatePayload,
        conflictCheckOpposingParty: opposingParty,
      };
    }
    updateFunnelAnswers('Family', funnelAnswersUpdatePayload);
    const { origin, search } = location;
    window.location.href = `${origin}/thank-you/${search}`;
  };

  return (
    <MainContainer>
      <ContentContainer>
        <Box justify="between" height={'100%'}>
          <Box align="center" direction={'column'}>
            <ScheduleMobileIcon src={scheduleIconMobile?.filename} />
            <ScheduleDesktopIcon src={scheduleIconDesktop?.filename} />
            <P3
              textAlign={'center'}
              margin={{ bottom: '48px', top: '0' }}
              color={'dark100'}
              // I think I might have to create those texts in the CMS, because we will need it to be translated
            >
              Great! Just one last thing
            </P3>
            <Subtitle2 margin={{ bottom: '0', top: '0' }} color={'dark100'} textAlign="center">
              What’s the name of your
              <br /> opposing party?
            </Subtitle2>
            <P3 textAlign={'center'} margin={{ top: '8px', bottom: '56px' }} color={'dark100'}>
              We’re legally required to check that our lawyers <br />
              don’t already work with the opposing party.
            </P3>
            <Box margin={{ bottom: '32px' }}>
              <InputWrapper data-test-answer={'opposing-party-input-direct-lss'}>
                <Input value={opposingParty} onChange={onInputChange} placeholder={'Full legal name'} />
              </InputWrapper>
            </Box>
            <RebrandedButton onClick={onSubmit} size={'large'}>
              Complete
            </RebrandedButton>
          </Box>
          <LearnMoreContainer>
            <P3 textAlign={'center'} color={'dark100'}>
              We take your privacy seriously.
              <br />
              Any information you share is safe. <u>Learn more</u>
            </P3>
          </LearnMoreContainer>
        </Box>
      </ContentContainer>
    </MainContainer>
  );
};

export default ConflictCheck;
