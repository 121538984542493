import styled from 'styled-components';
import { Flex, ResponsiveGroup, Page } from '@marbletech/components';
import { colors, spacings, devices } from '@marbletech/theme';
import { ItemProps } from './WeCanHelp.types';

export const Container = styled(Page.Content)`
  align-items: center;
  gap: ${spacings[10]};
  padding-top: ${spacings[18]};
  padding-bottom: ${spacings[6]};

  ${devices.mobile} {
    gap: ${spacings[4]};
    padding: ${spacings[8]} ${spacings[2.5]} ${spacings[7]};
  }
`;

export const Item = styled(Flex).attrs({
  justify: 'start',
  gap: spacings[4],
  pad: { top: spacings[4.5], bottom: spacings[4.5] },
})<ItemProps>`
  border-bottom: 1px solid ${colors.disabled};
  border-top: ${({ topBorder }) => (topBorder ? `1px solid ${colors.disabled}` : '')};
`;

export const ItemsWrapper = styled(ResponsiveGroup).attrs({ gap: spacings[4], gapBottom: '0px' })`
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  ${devices.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const IconContainer = styled(Flex)`
  background-color: ${colors.dark200};
  width: ${spacings[4]};
  height: ${spacings[4]};
  border-radius: 50%;
`;
