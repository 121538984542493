import { getItem, setItem } from '@marble/website/src/utils/local-storage/local-storage';
import cookie from 'cookie';
import { UserDetails } from '@marble/website/src/context/Context';
import { eventService } from '../eventService';

export interface SplitUserData {
  splitKey: string;
  trafficType: string;
}

export enum TrafficTypes {
  Anonymous = 'anonymous',
  User = 'user',
}

const ID_KEY = 'marbleSplitKey';
export const DUMMY_KEY = 'dummy_key';

const getInitialUserData = (): SplitUserData => {
  const splitLocalKey = getItem(ID_KEY) as string;
  let userData: SplitUserData = { splitKey: splitLocalKey ?? DUMMY_KEY, trafficType: TrafficTypes.User };

  try {
    userData = JSON.parse(splitLocalKey);
  } catch (err) {}

  return userData;
};

const getLoggedInUserKey = (): string | null => {
  try {
    const cookies = cookie.parse((typeof window !== 'undefined' && document.cookie) || '');
    const sharedCustomer = JSON.parse(cookies['shared-customer']) as UserDetails;
    if (sharedCustomer?.name?.last && sharedCustomer?.name?.first) {
      return `${sharedCustomer?.name?.first}_${sharedCustomer?.name?.last}`.toLowerCase();
    }
  } catch (err) {}
  return null;
};

export const getUniqueUserKey = (): SplitUserData => {
  const userData = getInitialUserData();

  if (userData?.splitKey && userData?.splitKey !== DUMMY_KEY) {
    return userData;
  }

  const loggedInUser = getLoggedInUserKey();
  if (loggedInUser) {
    return setUniqueUserKey(loggedInUser, TrafficTypes.User);
  }

  if (eventService.anonymousUserId) {
    return setUniqueUserKey(eventService.anonymousUserId);
  }

  return setUniqueUserKey(DUMMY_KEY);
};

export const setUniqueUserKey = (splitKey: string, trafficType = TrafficTypes.User): SplitUserData => {
  const userData = {
    splitKey,
    trafficType,
  };
  setItem(ID_KEY, JSON.stringify(userData));

  return userData;
};
