import React from 'react';
import { Link } from 'gatsby';
import { OptimizedAssetImage } from '@marble/ui-lib';
import { useNavigation } from '@marble/website/src/context/Context';
import { Content, Title, Img, ImgWrapper, SectionContainer } from './MovesYouForward.styled';
import { MovesYouForwardProps } from './MovesYouForward.types';
import { Button } from '../../shared';
import { eventService, EventName } from '../../../../services/eventService';
import { NewBiEventsOptions, triggerNewBiEvent } from '../../../../utils/newEvents';

export const MovesYouForward = ({ pageContent, isDesktop }: MovesYouForwardProps) => {
  const {
    footer_h1: title,
    footer_cta: buttonText,
    footer_cta_link: link,
    footer_image,
    footer_alt_text: alt,
  } = pageContent;
  const { getLocalizedLink } = useNavigation();
  const handleButtonClick = () => {
    eventService.triggerEvent({
      eventName: EventName.OnlineFunnelPageCtaClick,
      eventData: {
        cta: buttonText,
        position: 'MovesYouForward Section',
      },
    });
    triggerNewBiEvent({
      eventName: NewBiEventsOptions.WebPageCtaClick,
      eventData: {
        location: 'Moves You Forward',
        cta_text: buttonText,
        click_url: link,
      },
    });
  };

  return (
    <SectionContainer>
      <Content>
        {footer_image?.filename && (
          <ImgWrapper>
            <OptimizedAssetImage src={footer_image.filename} width="100%" component={Img} maxWidth={230} alt={alt} />
          </ImgWrapper>
        )}
        <Title>{title}</Title>
        <Link to={getLocalizedLink({ pathname: link })}>
          <Button text={buttonText} onClick={handleButtonClick} />
        </Link>
      </Content>
    </SectionContainer>
  );
};

export default MovesYouForward;
